// import { createSelector } from 'reselect';

export const getIsDeleting = state => state.getIn(['consultations', 'isDeleting']);
export const getIsPosting = state => state.getIn(['consultations', 'isPosting']);
export const getErrMsg = state => state.getIn(['consultations', 'errMsg']);

export const getIsGettingConsultationList = state => state.getIn(['consultations', 'isGettingConsultationList']);
export const getIsGettingConsultationDetail = state => state.getIn(['consultations', 'isGettingConsultationDetail']);
export const getCurrentConsultationId = state => state.getIn(['consultations', 'currentConsultationId']);
export const getCurrentDetailTab = state => state.getIn(['consultations', 'currentDetailTab']);
export const getConsultationIdList = state => state.getIn(['consultations', 'consultationIdList']);
export const getConsultations = state => state.getIn(['consultations', 'consultations']);
export const getConsultationDetail = state => state.getIn(['consultations', 'consultationDetail']);