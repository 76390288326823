import { fromJS  } from 'immutable';

export const State = fromJS({
	isPosting: false,
	isGetting: false,
	errMsg: '',
	storage: {},
	plan: {},
	expire: {},
	upgradeList: [],
});
