// @flow

import { Component } from "react";

import type { T_PDFJS_Document } from "../types";

import * as pdfjsLib from 'pdfjs-dist';

type Props = {
  url: string,
  beforeLoad: React$Element<*>,
  children: (pdfDocument: T_PDFJS_Document) => React$Element<*>
};

type State = {
  pdfDocument: ?T_PDFJS_Document
};

class PdfLoader extends Component<Props, State> {
  state = {
    pdfDocument: null
  };

  componentDidMount() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
    this.loadingTask = pdfjsLib.getDocument(this.props.url);   
    this.loadingTask.promise.then((doc) => {
      // console.log(`Document ${this.props.url} loaded ${doc.numPages} page(s)`);
      this.setState({
        pdfDocument: doc
      }, () => this.loadingTask = null);
    }, (reason) => {
      console.error(`Error during ${this.props.url} loading: ${reason}`);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.url !== this.props.url) {
      if (this.state.pdfDocument) {
        this.state.pdfDocument.destroy();
        this.setState({ pdfDocument: null }, () => {
          if (this.loadingTask) {
            this.loadingTask.destroy();
          }
          this.loadingTask = pdfjsLib.getDocument(this.props.url);
          this.loadingTask.promise.then((doc) => {
            // console.log(`Document ${this.props.url} loaded ${doc.numPages} page(s)`);
            this.setState({
              pdfDocument: doc
            }, () => this.loadingTask = null);
          }, (reason) => {
            console.error(`Error during ${this.props.url} loading: ${reason}`);
          });
        });
      }
    }
  }

  render() {
    const { children, beforeLoad } = this.props;
    const { pdfDocument } = this.state;

    if (pdfDocument) {
      return children(pdfDocument);
    }

    return beforeLoad;
  }
}

export default PdfLoader;
