import { handleActions } from 'redux-actions';
import { State } from './model';


const Reducer = handleActions({
  'pdf/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'pdf/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'pdf/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'pdf/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'pdf/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'pdf/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'pdf/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'pdf/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'pdf/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'pdf/UPDATE_CACHE_PAGE_URL': (state, { payload }) => {
    return state.setIn(['cachePageUrl'], payload);
  },
  'pdf/UPDATE_CACHE_PAGES': (state, { payload }) => {
    return state.setIn(['pageBase64List'], payload.pageBase64List)
                .setIn(['pageBase64s'], payload.pageBase64s);
  },
  'pdf/UPDATE_PAGE_TEXT': (state, { payload }) => {
    return state.setIn(['PageText'], payload.text);
  },
}, State);

export default Reducer;

