import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { message, Input, DatePicker } from 'antd';
import { DATE_FORMAT, TAX_VOUCHER, TAXABLE } from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const DEFAULT_FEE = {
  isTaxabled: 0,
  taxAmount: '',
  appointFee: '',
  payDate: null,
  taxYear: '',
  taxVoucher: 0,
  receipt: {}
}
const DEFAULT_RECEIPT = {
  name: '',
  category: '',
  note: '',
  date: null,
  cost: '',
  reason: ''
}

class AppointmentFeeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      showReceiptEditor: false,
      fee: DEFAULT_FEE,
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleEditMode(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.appointmentId !== this.props.appointmentId && this.state.editMode === true) {
      this.onToggleEditMode(false);
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onToggleEditMode(editMode) {
    if (editMode === true) {
      this.setState({ 
        editMode, 
        fee: this.props.fee.toJS()
      });
    } else {
      this.setState({ editMode });
    }
    this.props.onChangeIsEditing(editMode);
  }
  onChangeFee(key, value) {
    let { fee } = this.state;
    fee = JSON.parse(JSON.stringify(fee));
    fee[key] = value;
    if( key === 'taxYear' && fee['taxYear'].length > 4 ) {
      fee['taxYear'] = fee['taxYear'].slice(0, 4)
    } else if ( key === 'appointFee' && fee['appointFee'].length > 10 ) {
      fee['appointFee'] = fee['appointFee'].slice(0, 10)
    } else if ( key === 'taxAmount' && fee['taxAmount'].length > 10 ) {
      fee['taxAmount'] = fee['taxAmount'].slice(0, 10)
    } else if ( key === 'cost' && fee['cost'].length > 10 ) {
      fee['cost'] = fee['cost'].slice(0, 10)
    }
    this.setState({ fee });
  }
  onChangeReceipt(key, value) {
    let { fee } = this.state;
    fee = JSON.parse(JSON.stringify(fee));
    fee.receipt[key] = value;
    if (key === 'cost' && fee.receipt['cost'].length > 10) {
      fee.receipt['cost'] = fee.receipt['cost'].slice(0, 10)
    }
    this.setState({ fee });
  }
  onSubmitFee() {
    const { fee } = this.state;
    const payDate = fee.payDate ? moment(fee.payDate).format(DATE_FORMAT) : null
    // const receiptDate = fee.receipt && fee.receipt.date ? moment(fee.receipt.date).format(DATE_FORMAT) : null
    this.props.onUpdateFee(
      this.props.appointmentId, 
      fee.isTaxabled,
      fee.taxAmount,
      fee.appointFee,
      payDate,
      fee.taxYear,
      fee.taxVoucher,
      fee.receipt && fee.receipt.name ? fee.receipt.name : null,
      fee.receipt && fee.receipt.category ? fee.receipt.category : null,
      fee.receipt && fee.receipt.note ? fee.receipt.note : null,
      fee.receipt && fee.receipt.date ? moment(fee.receipt.date).format(DATE_FORMAT) : null,
      fee.receipt && fee.receipt.cost ? fee.receipt.cost : null,
      fee.receipt && fee.receipt.reason ? fee.receipt.reason : null,
    );
  }
  render() {
    const { editMode, fee } = this.state;
    if (!this.props.fee || this.props.fee.size === 0) {
      return null;
    }
    return (
      <div className="appointmentfee-section section-col-item">
        {
          editMode
          ?
            <form onSubmit={e => {e.preventDefault();this.onSubmitFee()}}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>取消</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>更新</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">應／未稅：</div>
                  <div className="col-edit">
                    {
                      Object.entries(TAXABLE).map(([key, value]) =>
                        <span key={`isTaxabled-${key}`}>
                          <input type="radio" id={`isTaxabled-${key}`}
                            value={key}
                            checked={`${fee.isTaxabled}` === key}
                            onChange={e => this.onChangeFee('isTaxabled', e.target.value)}
                          />
                          <label htmlFor={`isTaxabled-${key}`} className="edit-isTaxabled">{value}</label>
                        </span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">報稅年度：</div>
                  <Input className="col-edit"
                    size="small"
                    type="number"
                    suffix="年度"
                    value={fee.taxYear}
                    onChange={e => this.onChangeFee('taxYear', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">實收金額：</div>
                  <Input className="col-edit"
                    size="small"
                    type="number"
                    suffix="元"
                    value={fee.appointFee}
                    onChange={e => this.onChangeFee('appointFee', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">報稅金額：</div>
                  <Input className="col-edit"
                    size="small"
                    type="number"
                    suffix="元"
                    value={fee.taxAmount}
                    onChange={e => this.onChangeFee('taxAmount', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">付款日：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={fee.payDate ? moment(fee.payDate, DATE_FORMAT) : null}
                    onChange={value => this.onChangeFee('payDate', value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">扣繳憑單：</div>
                  <div className="col-edit">
                    {
                      Object.entries(TAX_VOUCHER).map(([key, value]) =>
                        <span key={`taxVoucher-${key}`}>
                          <input type="radio" id={`taxVoucher-${key}`}
                            value={key}
                            checked={`${fee.taxVoucher}` === key}
                            onChange={e => this.onChangeFee('taxVoucher', e.target.value)}
                          />
                          <label htmlFor={`taxVoucher-${key}`} className="edit-taxVoucher">{value}</label>
                        </span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">收據：</div>
                  <div className="col-edit">
                    <input type="radio" name="receipt" id="receipt-2" checked={fee.receipt === null}
                      onChange={e => this.onChangeFee('receipt', null)}
                    />
                    <label htmlFor="receipt-2" className="edit-receipt">無</label>
                    <input type="radio" name="receipt" id="receipt-1" checked={fee.receipt !== null}
                      onChange={e => this.onChangeFee('receipt', DEFAULT_RECEIPT)}
                    />
                    <label htmlFor="receipt-1" className="edit-receipt">有</label>
                  </div>
                </div>
                {
                  fee.receipt
                  &&
                  <>
                    <div className="col-item">
                      <div className="col-title">開立日期：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={DATE_FORMAT}
                        value={fee.receipt.date ? moment(fee.receipt.date, DATE_FORMAT) : null}
                        onChange={value => this.onChangeReceipt('date', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">收據人：</div>
                      <Input className="col-edit"
                        size="small"
                        type="text"
                        value={fee.receipt.name}
                        onChange={e => this.onChangeReceipt('name', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">請款類別：</div>
                      <Input className="col-edit"
                        size="small"
                        type="text"
                        value={fee.receipt.category}
                        onChange={e => this.onChangeReceipt('category', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">金額：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="元"
                        value={fee.receipt.cost}
                        onChange={e => this.onChangeReceipt('cost', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">事由：</div>
                      <Input className="col-edit"
                        size="small"
                        type="text"
                        value={fee.receipt.reason}
                        onChange={e => this.onChangeReceipt('reason', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">備註：</div>
                      <Input className="col-edit"
                        size="small"
                        type="text"
                        value={fee.receipt.note}
                        onChange={e => this.onChangeReceipt('note', e.target.value)}
                      />
                    </div>
                  </>
                }
              </div>
            </form>
          :
            <>
              <div className="col-item-tool">
                <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">應／未稅：</div>
                  <div className="col-text">
                    { this.props.fee.getIn(['isTaxabled']) ? '應稅' : '未稅' }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">報稅年度：</div>
                  <div className="col-text">
                    { this.props.fee.getIn(['taxYear']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">實收金額：</div>
                  <div className="col-text">
                    { this.props.fee.getIn(['appointFee']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">報稅金額：</div>
                  <div className="col-text">
                    { this.props.fee.getIn(['taxAmount']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">付款日：</div>
                  <div className="col-text">
                    { this.props.fee.getIn(['payDate']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">扣繳憑單：</div>
                  <div className="col-text">
                    { TAX_VOUCHER[this.props.fee.getIn(['taxVoucher'])] }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">收據：</div>
                  <div className="col-text">
                    { this.props.fee.getIn(['receipt']) ? '有' : '無' }
                  </div>
                </div>
                {
                  this.props.fee.getIn(['receipt'])
                  &&
                  <>
                    <div className="col-item">
                      <div className="col-title">開立日期：</div>
                      <div className="col-text">
                        { this.props.fee.getIn(['receipt', 'date']) }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">收據人：</div>
                      <div className="col-text">
                        { this.props.fee.getIn(['receipt', 'name']) }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">請款類別：</div>
                      <div className="col-text">
                        { this.props.fee.getIn(['receipt', 'category']) }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">金額：</div>
                      <div className="col-text">
                        { this.props.fee.getIn(['receipt', 'cost']) }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">事由：</div>
                      <div className="col-text">
                        { this.props.fee.getIn(['receipt', 'reason']) }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">備註：</div>
                      <div className="col-text">
                        { this.props.fee.getIn(['receipt', 'note']) }
                      </div>
                    </div>                    
                  </>
                }
              </div>
            </>
        }
      </div>
    )
  }
}
export default withRouter(AppointmentFeeComponent);