import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentListComponent from './component';

const mapStateToProps = (state) => ({
  isDeleting: selectors.getIsDeleting(state),
  isGettingAppointmentList: selectors.getIsGettingAppointmentList(state),
  isGettingAppointmentDetail: selectors.getIsGettingAppointmentDetail(state),
  currentAppointmentId: selectors.getCurrentAppointmentId(state),
  currentDetailTab: selectors.getCurrentDetailTab(state),
  errMsg: selectors.getErrMsg(state),
  appointmentIdList: selectors.getAppointmentIdList(state),
  appointments: selectors.getAppointments(state),
  appointmentDetail: selectors.getAppointmentDetail(state),
  cachedAppointmentId: selectors.getCachedAppointmentId(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateCurrentDetailTab: (currentDetailTab) => {
    dispatch(actions.doUpdateCurrentDetailTab(currentDetailTab));
  },
  onLoadAppointmentList: (name, text, appointmentId) => {
    dispatch(actions.doLoadAppointmentList(name, text, appointmentId));
  },
  onLoadAppointmentDetail: (appointmentId) => {
    dispatch(actions.doLoadAppointmentDetail(appointmentId));
  },
  onDeleteAppointment: (appointmentId) => {
    dispatch(actions.doDeleteAppointment(appointmentId));
  },
  onCreatDocket: (caseId, docket) => {
    dispatch(actions.doCreatDocket(caseId, docket));
  },
  onUpdateDocket: (caseId, docket) => {
    dispatch(actions.doUpdateDocket(caseId, docket));
  },
  onDeleteDocket:(caseId, docketId) => {
    dispatch(actions.doDeleteDocket(caseId, docketId));
  },
  onChangeCurrentAppointmentId: (appointmentId) => {
    dispatch(actions.doChangeCurrentAppointmentId(appointmentId));
  },
  onUpdateCachedAppointmentId: (appointmentId) => {
    dispatch(actions.doUpdateCachedAppointmentId(appointmentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentListComponent);