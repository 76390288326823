export const getIsPosting = state => state.getIn(['mailroomSentList', 'isPosting']);
export const getIsDeleting = state => state.getIn(['mailroomSentList', 'isDeleting']);
export const getErrMsg = state => state.getIn(['mailroomSentList', 'errMsg']);

export const getSentList = state => state.getIn(['mailroomSentList', 'sentList']);
export const getDockets = state => state.getIn(['mailroomSentList', 'dockets']);
export const getDocketNames = state => state.getIn(['mailroomSentList', 'docketNames']);
export const getDocketList = state => state.getIn(['mailroomSentList', 'docketList']);
export const getCsvRowList = state => state.getIn(['mailroomSentList', 'csvRowList']);

export const getDeliveryTypeIdList = state => state.getIn(['mailroom', 'deliveryTypeIdList']);
export const getDeliveryTypes = state => state.getIn(['mailroom', 'deliveryTypes']);
