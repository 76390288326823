import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import PaymentComponent from './component';


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({  

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentComponent);