import {connect} from 'react-redux';
import * as actions from './actions';
import TopBarComponent from './component';
import * as selectors from './selectors';
import * as mailroomReceivedListActions from '../mailroomReceivedList/actions';
import * as appointmentListActions from '../appointmentList/actions';


const mapStateToProps = (state) => ({
  errMsg: selectors.getErrMsg(state),
  isLoading: selectors.getIsLoading(state),
  notificationList: selectors.getNotification(state),
  historyList: selectors.getHistory(state),
  historyCount: selectors.getHistoryCount(state),
  showRWDNavbar: selectors.getShowRWDNavbar(state),
  loginCheckNotification: selectors.getLoginCheckNotification(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadNotification: () => {
    dispatch(actions.doLoadNotification());
  },
  onLoadHistory: (page) => {
    dispatch(actions.doLoadHistory(page));
  },
  onUpdateNotification: (messageId) => {
    dispatch(actions.doUpdateNotificationStatus(messageId));
  },
  onClickLogout: () => {
    dispatch(actions.doLogout());
  },
  onUpdateRWDNavbar: (show) => {
    dispatch(actions.updateRWDNavbar(show));
  },
  onUpdateCachedReceivedId: (receivedId) => {
    dispatch(mailroomReceivedListActions.doUpdateCachedReceivedId(receivedId));
  },
  onUpdateCachedAppointmentId: (appointmentId) => {
    dispatch(appointmentListActions.doUpdateCachedAppointmentId(appointmentId));
  },
  onUpdateLoginCheckNotification: (showNotification) => {
    dispatch(actions.updateLoginCheckNotification(showNotification));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBarComponent);
