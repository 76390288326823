import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { submitApply } from '../../utils/api';


export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export function doSubmitApply(email, password, lastName, firstName, lawOffice, phoneNo, mobile) {
  return dispatch => {
    dispatch(postRequest());
    submitApply(email, password, lastName, firstName, lawOffice, phoneNo, mobile).then(data => {
      dispatch(postSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}

