import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import MailroomComponent from './component';
import * as mailroomReceivedListSelectors from '../mailroomReceivedList/selectors';
import * as mailroomSentListSelectors from '../mailroomSentList/selectors';


const mapStateToProps = (state) => ({
  isSearching: selectors.getIsSearching(state),
  isPosting: selectors.getIsPosting(state),
  isFetching: selectors.getIsFetching(state),
  errMsg: selectors.getErrMsg(state),
  courtIdList: selectors.getCourtIdList(state),
  courts: selectors.getCourts(state), 
  typeIdList: selectors.getTypeIdList(state),
  types: selectors.getTypes(state),
  dockets: selectors.getDockets(state),
  docketNames: selectors.getDocketNames(state),
  docketList: selectors.getDocketList(state),
  deliveryTypeIdList: selectors.getDeliveryTypeIdList(state),
  deliveryTypes: selectors.getDeliveryTypes(state),
  cachedReceivedId: mailroomReceivedListSelectors.getCachedReceivedId(state),
  receivedCsvRowList: mailroomReceivedListSelectors.getCsvRowList(state),
  sentCsvRowList: mailroomSentListSelectors.getCsvRowList(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadCourtList: () => {
    dispatch(actions.doLoadCourtList());
  },
  onLoadTypeList: () => {
    dispatch(actions.doLoadTypeList());
  },
  onLoadDocketList: (contain, size) => {
    dispatch(actions.doLoadDocketList(contain, size));
  },
  onLoadDeliveryTypeList: () => {
    dispatch(actions.doLoadDeliveryTypeList());
  },
  onCreateReceived: (name, typeId, receivedDate, sender, custodianName, sentDate, docketId, expiredDays, fileList) => {
    dispatch(actions.doCreateReceived(name, typeId, receivedDate, sender, custodianName, sentDate, docketId, expiredDays, fileList));
  },
  onCreateSent: (name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId, fileList) => {
    dispatch(actions.doCreateSent(name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId, fileList));
  },
  onSearchMailroom: (keyword) => {
    dispatch(actions.doSearchMailroom(keyword));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailroomComponent);