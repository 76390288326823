import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  createPageNote,
  updatePageNote,
  updatePageNotePosition
} from '../../utils/api';
import * as config from '../../config'


// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const putPositionRequest = createAction(types.PUT_POSITION_REQUEST)
export const putPositionSuccess = createAction(types.PUT_POSITION_SUCCESS)
export const putPositionFailure = createAction(types.PUT_POSITION_FAILURE)

export const updateCurrentNoteId = createAction(types.PUT_CURRENT_NOTE_ID)

export function doSubmitPageNote(pageId, pageNoteId, title, content, position) {
  return dispatch => {
    dispatch(updateCurrentNoteId(pageNoteId));
    if (pageNoteId) {
      dispatch(putRequest());
      updatePageNote(pageId, pageNoteId, title, content, position).then(data => {
        dispatch(putSuccess());
      }).catch((errMsg) => {
       dispatch(putFailure({ errMsg }));
      })
    } else {
      dispatch(postRequest());
      createPageNote(pageId, title, content, position).then(data => {
        dispatch(postSuccess());
      }).catch((errMsg) => {
        dispatch(postFailure({ errMsg }));
      })
    }
  }
}

export function doSubmitPagePosition(pageId, pageNoteId, position) {
  return dispatch => {
    dispatch(updateCurrentNoteId(pageNoteId));
    dispatch(putPositionRequest());
    updatePageNotePosition(pageId, pageNoteId, position).then(data => {
      dispatch(putPositionSuccess());
    }).catch((errMsg) => {
      dispatch(putPositionFailure({ errMsg }));
    })
  }
}