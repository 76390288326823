import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ConsultationFilesComponent from './component';


const mapStateToProps = (state) => ({
  isPutting: selectors.getIsPutting(state),
  isPosting: selectors.getIsPosting(state),
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onUploadConsultationFile: (consultationId, fileList) => {
    dispatch(actions.doUploadConsultationFile(consultationId, fileList));
  },
  onDownloadConsultationFile: (consultationId, consultationName) => {
    dispatch(actions.doDownloadConsultationFile(consultationId, consultationName));
  },
  onDeleteConsultationFile: (consultationId) => {
    dispatch(actions.doDeleteConsultationFile(consultationId));
  },
  onSubmitUpdateFileName: (fileId, name) => {
    dispatch(actions.doSubmitUpdateFileName(fileId, name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationFilesComponent);