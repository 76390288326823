import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import MailroomReceivedListComponent from './component';
import * as appointmentListActions from '../appointmentList/actions';

const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
  receivedList: selectors.getReceivedList(state),
  dockets: selectors.getDockets(state),
  docketNames: selectors.getDocketNames(state),
  docketList: selectors.getDocketList(state),
  cachedReceivedId: selectors.getCachedReceivedId(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadReceivedList: (action) => {
    dispatch(actions.doLoadReceivedList(action));
  },
  onDeleteReceivedFile: (fileId) => {
    dispatch(actions.doDeleteReceivedFile(fileId));
  },
  onUploadReceivedFile: (receivedId, fileList) => {
    dispatch(actions.doUploadReceivedFile(receivedId, fileList));
  },
  onDownloadReceivedFile: (fileId, fileName) => {
    dispatch(actions.doDownloadReceivedFile(fileId, fileName));
  },
  onLoadDocketList: (contain, size) => {
    dispatch(actions.doLoadDocketList(contain, size));
  },
  onUpdateReceived: (receivedId, sender, docketId, expiredDays, sentDate, receivedDate, custodianName, typeId, name) => {
    dispatch(actions.doUpdateReceived(receivedId, sender, docketId, expiredDays, sentDate, receivedDate, custodianName, typeId, name));
  },
  onUpdateCachedReceivedId: (receivedId) => {
    dispatch(actions.doUpdateCachedReceivedId(receivedId));
  },
  onDeleteReceived: (receivedId) => {
    dispatch(actions.doDeleteReceived(receivedId));
  },
  onUpdateCachedAppointmentId: (appointmentId) => {
    dispatch(appointmentListActions.doUpdateCachedAppointmentId(appointmentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailroomReceivedListComponent);