// import { createSelector } from 'reselect';

export const getIsGetting = state => state.getIn(['profile', 'isGetting']);
export const getIsPutting = state => state.getIn(['profile', 'isPutting']);
export const getIsPosting = state => state.getIn(['profile', 'isPosting']);
export const getErrMsg = state => state.getIn(['profile', 'errMsg']);


export const getFirstName = state => state.getIn(['profile', 'firstName']);
export const getLastName = state => state.getIn(['profile', 'lastName']);
export const getAddress = state => state.getIn(['profile', 'address']);
export const getPhoneNo = state => state.getIn(['profile', 'phoneNo']);
export const getEmail = state => state.getIn(['profile', 'email']);
export const getMobile = state => state.getIn(['profile', 'mobile']);
export const getBank = state => state.getIn(['profile', 'bank']);
export const getBankAccount = state => state.getIn(['profile', 'bankAccount']);
export const getTwoFactorAuthenticationId = state => state.getIn(['profile', 'twoFactorAuthenticationId']);
export const getLawFirmLogo = state => state.getIn(['profile', 'lawFirmLogo']);
export const getLawFirmName = state => state.getIn(['profile', 'lawFirmName']);
export const getPinCode = state => state.getIn(['profile', 'pinCode']);