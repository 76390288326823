import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  updateConsultationCase,
  casesRelated
} from '../../utils/api';

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const getCaseRelatedRequest = createAction(types.GET_CASE_RELATED_REQUEST)
export const getCaseRelatedSuccess = createAction(types.GET_CASE_RELATED_SUCCESS)
export const getCaseRelatedFailure = createAction(types.GET_CASE_RELATED_FAILURE)

export function doUpdateCase(consultationId, typeId, statusId, relatedList, plaintiffList, defendantList, consultationContent, consultationNote) {
  return dispatch => {
    dispatch(putRequest());
    updateConsultationCase(consultationId, typeId, statusId, relatedList, plaintiffList, defendantList, consultationContent, consultationNote).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export const updateCaseRelateList = createAction(types.UPDATE_CASE_RELATE_LIST)
export function doLoadRelatedList(contain, size) {
  return dispatch => {
    dispatch(getCaseRelatedRequest());
    casesRelated(contain, size).then(data => {
      dispatch(updateCaseRelateList(data));
      dispatch(getCaseRelatedSuccess());
    }).catch((errMsg) => {
      dispatch(getCaseRelatedFailure({ errMsg }));
    })
  }
}