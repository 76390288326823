import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Input, message, Modal, Tooltip, Upload, Spin} from 'antd';
import {fromJS} from 'immutable';
import React, {Component} from 'react';
import {Col, Nav, Tab} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import dnaLogoIcon from '../../assets/img/dna-onlyLogo.svg'
import pdf from '../../modules/pdf';
import reviewNotes from '../../modules/reviewNotes';
import FullSpinner from '../../utils/FullSpinner';
import Spinner from '../../utils/Spinner';
import Draggable from 'react-draggable';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

class DocComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // case
      fileTotal: '',
      newSerialNumber: '',
      uploadFileList: [],
      showDocumentBlock: true,
      documentBlockScroll: 0,
      // category
      expandCategorySet: new Set(),
      // Right Bar
      showRightBarBlock: true,
      // HighLight
      showHighLightBlock: true,
      // duplicate
      showDuplicateBlock: true,
      // Notes
      showNotes: true,
      // label
      editingLabelName: '',
      showLabelForm: false,
      modifiedNoteLabelIdSet: new Set(),
      showLabelBlock: true,
      currentLabelId: 0,
      showCopyLabelPagesToFolder: false,
      labelToCaseId: '',
      labelToUserFolderId: '',
      showUserTool: false,
      tabValue: "files",
      // doc folder
      fileFolderTypeDict: {},
      fileFolderDict: {},
      currentMovingFile: null,
      currentMovingFilePageType: null,
      targetFileFolder: null,
      targetFilePageType: null,
      targetFilePages: null,
      isMovingFilePageType: false,
      isMovingFilePages: false,
      // folder
      folderTypeDict: {},
      folderDict: {},
      tagList: [],
      currentMovingPageType: null,
      currentMovingPages: null,
      targetFolder: null,
      targetPageType: null,
      targetPages: null,
      isMovingPageType: false,
      isMovingPages: false,

      // pdf viewer
      showAddPagesToLabelForm: false,
      isAddingRangeToLabel: false,
      addToLabelId: '',
      currentHighlight: '',
      currentFileTypeId: 0,
      currentPageIndex: 1,

      isChangePage: false,
      pageTypeIdList: [],
      fileDict: {},
      pageTypeDict: {},
      typeDict: {},
      prevSection: false,
      nextSection: false,
      switchView: "pdf",
      currentScale: 1,
      currentPageType: null,
      // hasItem
      hasItem: new Set(),
      // edit
      showNewPageType: false,
      breakingPageType: {},
      newPageTypeList: [],
      // search
      showSearchForm: false,
      searchWord: '',
    };
    this.onToggleNotes = this.onToggleNotes.bind(this);
  }

  componentDidMount() {
    this.props.onLoadCaseName(this.props.caseId);
    this.props.onChangeCategoryPdf([]);
    this.props.onLoadCaseCategory(this.props.caseId);
    this.props.onLoadAnnotation(this.props.caseId);
    this.props.onLoadLabels(this.props.caseId);
    this.props.onLoadDuplicates(this.props.caseId);
    this.props.onLoadCaseList();
    this.props.onLoadUserFolderList();
    this.props.onCleanSearchResult();
    const windowWidth = window.innerWidth || document.body.clientWidth;
    if (windowWidth < 1025) {
      this.setState({ showRightBarBlock: false, showDocumentBlock: false });
    }
    this.restoreReviewSetting();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        if (this.state.showAddPagesToLabelForm === true) {
          this.setState({ showAddPagesToLabelForm: false });
        }
        if (this.state.showLabelForm === true) {
          this.setState({ showLabelForm: false });
        }
        if (this.state.showCopyLabelPagesToFolder === true) {
          this.setState({ showCopyLabelPagesToFolder: false })
        }
        if (this.state.showUploadFileForm === true) {
          if (this.props.isExceededQuota === true && this.props.encryptedPdfNameList.size > 0) {  // 超出額度，且包含加密檔案，仍顯示上傳 pop up，但加密檔案會被移除。
            this.onShowEncryptedFileModal();
            message.warning('已超出限額');
          } else if (this.props.isExceededQuota === true) {  // 超出額度，仍顯示上傳 pop up，讓使用者能調整檔案。
            message.warning('已超出限額');
          } else if (this.props.encryptedPdfNameList.size > 0) {  // 包含加密檔案，消取上傳 pop up。
            this.onShowEncryptedFileModal();
            this.setState({ showUploadFileForm: false, uploadFileList: [] });
          } else {  // 成功上傳。
            message.success('Uploaded successfully');
            this.setState({ showUploadFileForm: false, uploadFileList: [] });
          }
        } else {
          message.success('Created successfully');
        }
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        if (this.state.showNewPageType === true) {
          this.setState({ showNewPageType: false });
          this.props.onChangeCategoryPdf([]);
        }
        if (this.state.documentBlockScroll > 0) {
          setTimeout(() => {
            document.getElementById("documents").scrollTop = this.state.documentBlockScroll;
            this.setState({ documentBlockScroll: 0 });
          }, 150); // todo
        }
        message.success('Updated successfully');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isDownloading === true && this.props.isDownloading === false && this.props.errMsg !== null) {
      message.error(this.props.errMsg);
    }
    if (prevProps.isDeleting === true && this.props.isDeleting === false) {
      if (this.props.errMsg === null) {
        message.success('Deleted successfully');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isGettingCaseCategory === true && this.props.isGettingCaseCategory === false) {
      if (this.props.errMsg === null) {
        this.loadCaseCategory();
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (Object.keys(prevState.pageTypeDict).length === 0 && Object.keys(this.state.pageTypeDict).length !== 0) {
      const fileId = localStorage.getItem('cachedFileId');
      if (fileId) {
        const fileIdStatus = this.props.fileList.find(file => `${file.getIn(['id'])}` === `${fileId}`).getIn(['statusId'])
        localStorage.removeItem('cachedFileId');
        if (fileIdStatus === 4) {
          this.setState({ currentScale: 1});
          this.props.onChangePdfScale('auto');
          const file_ = this.props.fileList.find(file_ => `${file_.getIn(['id'])}` === `${fileId}`)
          this.changeCategoryPdf(file_.getIn(['pageTypeList', 0]));
          this.onChangeHighlight(`currentHighlight-${file_.getIn(['id'])}`)
          localStorage.removeItem('cachedFileId');
        }
      }
    }
    if (localStorage.getItem('cachedNoteFileId') && localStorage.getItem('cachedNotePageTypeId') && this.state.fileTotal !== '') {
      const file_ = this.props.fileList.find(file_ => `${file_.getIn(['id'])}` === `${localStorage.getItem('cachedNoteFileId')}`)
      const pageType = file_.getIn(['pageTypeList']).find(pageType => `${pageType.getIn(['id'])}` === `${localStorage.getItem('cachedNotePageTypeId')}`)
      localStorage.removeItem('cachedNoteFileId');
      localStorage.removeItem('cachedNotePageTypeId');
      this.changeCategoryPdf(pageType);
      this.toggleCategory(`file-${file_.getIn(['id'])}`, 'open');
      this.onChangeHighlight(`currentHighlight-${pageType.getIn(['id'])}`);
      this.hideNewPageType(pageType.getIn(['id']));     
    }
    if (localStorage.getItem('cachedNotePageNo') && this.props.pageId && `${this.props.pageId}` !== localStorage.getItem('cachedNotePageId')) {
      const cachedNotePageNo = localStorage.getItem('cachedNotePageNo');
      localStorage.removeItem('cachedNotePageNo');
      localStorage.removeItem('cachedNotePageId');
      this.setState({ isChangePage: true, currentPageIndex: parseInt(cachedNotePageNo) }, () => {
        this.onClickPageGo();
      });
    }
    this.restoreReviewCache();
  }

  async onClickSubmitUploadFiles(caseId, uploadFileList) {
    if( uploadFileList.length === 0 ) {
      return message.warning('請新增檔案')
    } else {
      this.props.onSubmitUploadFiles(caseId, uploadFileList);
    }
  }
  componentWillUnmount() {
    const history = this.props.history.location.pathname;
    if (history.indexOf("attachment") === -1) {
      localStorage.removeItem("attachmentCache");
      localStorage.removeItem("attachmentCacheExpandCategorySet");
      localStorage.removeItem("attachmentCacheSwitchView");
      localStorage.removeItem("attachmentCacheSearchCriteria");
      localStorage.removeItem("reviewCacheHasPageIndex");
      localStorage.removeItem("reviewCacheSwitchView");
      localStorage.removeItem("reviewCacheHighlight");
      localStorage.removeItem("reviewCacheTabValue");
      localStorage.removeItem("reviewCacheExpandCategorySet");
      localStorage.removeItem("reviewCacheHasItem");
      localStorage.removeItem("reviewCacheSearchWord");
    }
  }

  // category
  loadCaseCategory = () => {
    let pageTypeIdList = [], pageTypeDict = {}, folderDict = {}, fileDict = {}, typeDict = {}, fileFolderDict = {}, tagList = [];
    const fileFolderTypeDict = this.props.fileList.groupBy((type) => type.get("folderId")).toJS();
    const folderTypeDict = this.props.typeList.groupBy((type) => type.get("folderId")).toJS();
    tagList = this.props.tagList.map((tags) => {
      return {
        id: tags.get("id"),
        tag: tags.get("tag"),
        pageTypeList: tags.get("pageTypeList").groupBy((tag) => tag.get("type")),
      }
    });
    const fileTotal = this.props.fileList.size;
    this.props.fileFolderList.forEach((folder) => {
      fileFolderDict[folder.get("id")] = folder.get("name");
      if (!fileFolderTypeDict.hasOwnProperty(folder.get("id"))) {
        fileFolderTypeDict[folder.get("id")] = [];
      }
    });
    fileFolderDict[""] = "";
    this.props.pageTypeFolderList.forEach((folder) => {
      folderDict[folder.get("id")] = folder.get("name");
      if (!folderTypeDict.hasOwnProperty(folder.get("id"))) {
        folderTypeDict[folder.get("id")] = [];
      }
    });
    folderDict[""] = "";
    this.props.fileList.forEach((files) => {
      fileDict[files.getIn(["id"])] = {
        fileName: files.getIn(["title"]),
        pageTypeList: files.getIn(['pageTypeList']).map((pageType) => pageType.id),
      };
      files.getIn(['pageTypeList']).forEach((file) => {
        pageTypeIdList.push(file.getIn(['id']));
        typeDict[file.getIn(["type"])] = file.getIn(["type"]);
        pageTypeDict[file.getIn(['id'])] = {
          fileId: files.getIn(["id"]),
          type: file.getIn(["type"]),
          fromPageNo: file.getIn(["fromPageNo"]),
          toPageNo: file.getIn(["toPageNo"]),
          pageIdList: file.getIn(["pageIdList"]),
          pageTypeList: files.getIn(['pageTypeList']),
          filePageCount: files.getIn(['pageTypeList', files.getIn(['pageTypeList']).size - 1, "toPageNo"]),
        };
      });
    });
    this.props.fileList.forEach((type) => {
      type.getIn(['pageTypeList']).forEach((pageType) => {
        pageTypeDict[pageType.getIn(['id'])]["folderId"] = type.getIn(['folderId']);
      });
    });
    this.props.typeList.forEach((type) => {
      type.getIn(['pageTypeList']).forEach((pageType) => {
        pageTypeDict[pageType.getIn(['id'])]["folderId"] = type.getIn(['folderId']);
      });
    });
    this.setState({
      fileTotal,
      pageTypeIdList,
      fileDict,
      pageTypeDict,
      typeDict,
      folderTypeDict,
      folderDict,
      fileFolderTypeDict,
      fileFolderDict,
      tagList
    });
  };
  toggleCategory = (item, action) => {
    const { expandCategorySet, hasItem } = this.state;
    if (expandCategorySet.has(item) || hasItem.has(item)) {
      if (action === "close" || action === undefined) {
        expandCategorySet.delete(item);
        hasItem.delete(item)
      }
    } else {
      if (action === "open" || action === undefined) {
        expandCategorySet.add(item);
        hasItem.add(item);
      }
    }
    this.setState({ expandCategorySet, hasItem })
  };
  onChangeSequences = (caseId, fileId, e) => {
    e.stopPropagation();
    this.props.onChangeSequence(caseId, fileId, e.target.value);
    this.setState({ newSerialNumber: e.target.value })
  };
  // label
  onChangeLabelNote = (action, labelId) => {
    const { modifiedNoteLabelIdSet } = this.state;
    if (action === 'add' && !modifiedNoteLabelIdSet.has(labelId)) {
      modifiedNoteLabelIdSet.add(labelId);
      this.setState({ modifiedNoteLabelIdSet });
    } else if (action === 'remove' && modifiedNoteLabelIdSet.has(labelId)) {
      modifiedNoteLabelIdSet.delete(labelId);
      this.setState({ modifiedNoteLabelIdSet });
    }
  };
  // control
  onClickPageUp = () => {
    const { pdfPageIndex } = this.props;
    const { currentPageIndex, pageTypeDict, currentFileTypeId, pageTypeIdList } = this.state;
    window.PdfViewer.viewer.currentScaleValue = 'auto';
    this.setState({ currentScale: 1});
    this.props.onChangePdfScale('auto');
    if (pdfPageIndex > 0) {
      this.props.onChangePdfPage(pdfPageIndex - 1);
      this.setState({ currentPageIndex: +currentPageIndex - 1 });
    } else {
      const currentFileIndex = pageTypeIdList.findIndex((id) => id === currentFileTypeId);
      if (currentFileIndex !== 0) {
        let currentPageType = pageTypeDict[pageTypeIdList[currentFileIndex - 1]];
        currentPageType.id = pageTypeIdList[currentFileIndex - 1];
        this.setState({
          currentPageType: fromJS(currentPageType),
          currentFileTypeId: pageTypeIdList[currentFileIndex - 1],
          currentPageIndex: +pageTypeDict[pageTypeIdList[currentFileIndex - 1]].toPageNo,
        });
        this.props.onChangeCategoryPdf(pageTypeDict[pageTypeIdList[currentFileIndex - 1]].pageIdList.toJS());
        this.toggleCategory(`file-pagetype-${pageTypeIdList[currentFileIndex - 1]}`, "open");
        this.toggleCategory(`type-pagetype-${pageTypeIdList[currentFileIndex - 1]}`, "open");
        this.onChangeHighlight(`currentHighlight-${pageTypeIdList[currentFileIndex - 1]}`);
        if (pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["fileId"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["fileId"]) {
          this.toggleCategory(`file-${pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["fileId"]}`, "open");
          this.toggleCategory(`file-${pageTypeDict[pageTypeIdList[currentFileIndex]]["fileId"]}`, "close");
        }
        if (pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["folderId"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"] ||
          pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["type"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["type"]) {
          this.toggleCategory(`folder-pagetype-${pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["type"]}-${pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["folderId"]}`, "open");
          this.toggleCategory(`folder-pagetype-${pageTypeDict[pageTypeIdList[currentFileIndex]]["type"]}-${pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"]}`, "close");
        }
        if (pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["folderId"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"]) {
          this.toggleCategory(`folder-${pageTypeDict[pageTypeIdList[currentFileIndex - 1]]["folderId"]}`, "open");
          this.toggleCategory(`folder-${pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"]}`, "close");
        }
        this.hideNewPageType(pageTypeIdList[currentFileIndex - 1]);
        this.props.onChangePdfPage(pageTypeDict[pageTypeIdList[currentFileIndex - 1]].toPageNo - pageTypeDict[pageTypeIdList[currentFileIndex - 1]].fromPageNo);
      }
    }
    this.setState({ isChangePage: false });
  };
  onClickPageDown = () => {
    const { pdfPageIndex, pdfPageListSize } = this.props;
    const { currentPageIndex, pageTypeDict, currentFileTypeId, pageTypeIdList, fileDict } = this.state;
    window.PdfViewer.viewer.currentScaleValue = 'auto';
    this.setState({ currentScale: 1});
    this.props.onChangePdfScale('auto');
    if (pdfPageIndex < pdfPageListSize - 1) {
      this.props.onChangePdfPage(pdfPageIndex + 1);
      this.setState({ currentPageIndex: +currentPageIndex + 1 });
    } else {
      const currentFileIndex = pageTypeIdList.findIndex((id) => id === currentFileTypeId);
      if (currentFileIndex + 1 !== pageTypeIdList.length) {
        let currentPageType = pageTypeDict[pageTypeIdList[currentFileIndex + 1]];
        currentPageType.id = pageTypeIdList[currentFileIndex + 1];
        this.setState({
          currentPageType: fromJS(currentPageType),
          currentFileTypeId: pageTypeIdList[currentFileIndex + 1],
          currentPageIndex: +pageTypeDict[pageTypeIdList[currentFileIndex + 1]].fromPageNo,
        });
        this.props.onChangeCategoryPdf(pageTypeDict[pageTypeIdList[currentFileIndex + 1]].pageIdList.toJS());
        this.toggleCategory(`file-pagetype-${pageTypeIdList[currentFileIndex + 1]}`, "open");
        this.toggleCategory(`type-pagetype-${pageTypeIdList[currentFileIndex + 1]}`, "open");
        if (pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["fileId"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["fileId"]) {
          this.toggleCategory(`file-${pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["fileId"]}`, "open");
          this.toggleCategory(`file-${pageTypeDict[pageTypeIdList[currentFileIndex]]["fileId"]}`, "close");
        }
        if (pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["folderId"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"] ||
          pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["type"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["type"]) {
          this.toggleCategory(`folder-pagetype-${pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["type"]}-${pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["folderId"]}`, "open");
          this.toggleCategory(`folder-pagetype-${pageTypeDict[pageTypeIdList[currentFileIndex]]["type"]}-${pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"]}`, "close");
        }
        if (pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["folderId"] !== pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"]) {
          this.toggleCategory(`folder-${pageTypeDict[pageTypeIdList[currentFileIndex + 1]]["folderId"]}`, "open");
          this.toggleCategory(`folder-${pageTypeDict[pageTypeIdList[currentFileIndex]]["folderId"]}`, "close");
        }
        this.onChangeHighlight(`currentHighlight-${pageTypeIdList[currentFileIndex + 1]}`);
        this.hideNewPageType(pageTypeIdList[currentFileIndex + 1]);
      }
    }
    this.setState({ isChangePage: false });
  };
  onClickPageGo = (pageIndex, fileTypeId) => {
    const { currentPageIndex, pageTypeDict, currentFileTypeId } = this.state;
    const selectedIndex = pageIndex ? pageIndex : currentPageIndex;
    const selectedFileTypeId = fileTypeId ? fileTypeId : currentFileTypeId;
    window.PdfViewer.viewer.currentScaleValue = 'auto';
    this.setState({ currentScale: 1});
    this.props.onChangePdfScale('auto');
    if (+selectedIndex && selectedIndex > 0 &&
      selectedIndex <= pageTypeDict[selectedFileTypeId].filePageCount) {
      this.setState({ isChangePage: false });
      pageTypeDict[selectedFileTypeId].pageTypeList.forEach((files) => { // todo
        if (selectedIndex >= files.get("fromPageNo") && selectedIndex <= files.get("toPageNo")) {
          this.props.onChangeCategoryPdf(files.getIn(['pageIdList']).toJS());
          this.toggleCategory(`file-pagetype-${files.get("id")}`);
          this.toggleCategory(`type-pagetype-${files.get("id")}`);
          if (pageTypeDict[files.getIn(['id'])]["fileId"] !== pageTypeDict[selectedFileTypeId]["fileId"]) {
            this.toggleCategory(`file-${pageTypeDict[files.getIn(['id'])]["fileId"]}`, "open");
            this.toggleCategory(`file-${pageTypeDict[selectedFileTypeId]["fileId"]}`, "close");
          }
          if (pageTypeDict[files.getIn(['id'])]["folderId"] !== pageTypeDict[selectedFileTypeId]["folderId"] ||
            pageTypeDict[files.getIn(['id'])]["type"] !== pageTypeDict[selectedFileTypeId]["type"]) {
            this.toggleCategory(`folder-pagetype-${pageTypeDict[files.getIn(['id'])]["type"]}-${pageTypeDict[files.getIn(['id'])]["folderId"]}`, "open");
            this.toggleCategory(`folder-pagetype-${pageTypeDict[selectedFileTypeId]["type"]}-${pageTypeDict[selectedFileTypeId]["folderId"]}`, "close");
          }
          if (pageTypeDict[files.getIn(['id'])]["folderId"] !== pageTypeDict[selectedFileTypeId]["folderId"]) {
            this.toggleCategory(`folder-${pageTypeDict[files.getIn(['id'])]["folderId"]}`, "open");
            this.toggleCategory(`folder-${pageTypeDict[selectedFileTypeId]["folderId"]}`, "close");
          }
          this.onChangeHighlight(`currentHighlight-${files.get("id")}`);
          this.hideNewPageType(files.get("id"));
          this.setState({
            currentPageType: files,
            currentFileTypeId: files.getIn(['id']),
          });
          if (selectedIndex !== files.get("fromPageNo")) {
            this.props.onChangePdfPage(selectedIndex - files.get("fromPageNo"));
          }
          return false;
        }
      })
    } else {
      alert("請輸入範圍內的數字");
    }
  };
  onChangePageIndex = (value) => {
    if (!this.state.isChangePage && this.state.currentPageIndex !== this.props.currentPdfPage.getIn(['pageNo'])) {
      this.setState({ currentPageIndex: this.props.currentPdfPage.getIn(['pageNo']) });
    }
    this.setState({ isChangePage: true, currentPageIndex: value });
  };
  onChangePageScale = (scareAct) => {
    if (scareAct === "plus") {
      if (this.state.currentScale + 0.1 < 3) {
          this.state.currentScale = +(this.state.currentScale + 0.1).toFixed(1) ;
      }
    } else {
      if (this.state.currentScale - 0.1 > 0) {
          this.state.currentScale = +(this.state.currentScale - 0.1).toFixed(1) ;
      }
    }
    window.PdfViewer.viewer.currentScaleValue = 'auto';
    if (this.state.currentScale != 1) {
        window.PdfViewer.viewer.currentScale = +(window.PdfViewer.viewer.currentScale * this.state.currentScale );
    }
    this.setState({ currentScale: this.state.currentScale});
    this.props.onChangePdfScale(this.state.currentScale);
  };
  // pdf
  changeCategoryPdf = (pageType) => {
    this.setState({
      currentPageType: pageType,
      currentFileTypeId: pageType.getIn(['id']),
      currentPageIndex: this.state.pageTypeDict[pageType.getIn(['id'])].fromPageNo,
      isChangePage: false,
    });
    this.props.onChangeCategoryPdf(pageType.getIn(['pageIdList']).toJS());
  };
  onChangeHighlight = (HighlightId) => {
    this.setState({
      currentHighlight: HighlightId,
    })
  };
  showActionNewPageType = (pageTypeId, pageTypeName, fromPageNo, toPageNo) => {
    if (this.state.breakingPageType.pageTypeId === pageTypeId) {
      this.setState({ showNewPageType: false, breakingPageType: {}, newPageTypeList: [] })
    } else {
      let firstNewPageType = {
        pageTypeName,
        isPageTypeNameValidated: true,
        fromPageNo,
        isFromPageNoValidated: true,
        toPageNo: '',
        isToPageNoValidated: false,
      };
      let secondNewPageType = {
        pageTypeName: '',
        isPageTypeNameValidated: false,
        fromPageNo: '',
        isFromPageNoValidated: false,
        toPageNo,
        isToPageNoValidated: true,
      };
      if (this.props.currentPdfPage) {
        const currentPageNo = this.props.currentPdfPage.getIn(['pageNo']);
        if (currentPageNo !== '' && currentPageNo >= fromPageNo && currentPageNo <= toPageNo) {
          firstNewPageType.isToPageNoValidated = true;
          secondNewPageType.isFromPageNoValidated = true;
          if (currentPageNo - 1 >= firstNewPageType.fromPageNo) {
            firstNewPageType.toPageNo = currentPageNo - 1;
            secondNewPageType.fromPageNo = currentPageNo;
          } else {
            firstNewPageType.toPageNo = firstNewPageType.fromPageNo;
            secondNewPageType.fromPageNo = currentPageNo + 1;
          }
        }
      }
      this.setState({
        showNewPageType: true,
        breakingPageType: {
          pageTypeId,
          pageTypeName,
          fromPageNo,
          toPageNo
        },
        newPageTypeList: [firstNewPageType, secondNewPageType]
      })
    }
  };
  hideNewPageType = (id) => {
    if (this.state.breakingPageType['pageTypeId'] !== id) {
      this.setState({ showNewPageType: false })
    }
  };
  validateNewPageTypeList = (newPageTypeList) => {
    const { breakingPageType } = this.state;
    newPageTypeList.forEach((newPageType, idx) => {
      newPageType.isPageTypeNameValidated = newPageType.pageTypeName.trim() !== '';

      let isFromPageNoValidated = false;
      let isToPageNoValidated = false;
      let minFromPageNo = breakingPageType.fromPageNo + idx;
      let maxToPageNo = breakingPageType.toPageNo - newPageTypeList.length + idx + 1;
      if (newPageType.toPageNo && newPageType.toPageNo >= minFromPageNo && newPageType.toPageNo <= maxToPageNo) {
        isToPageNoValidated = true;
      }
      if (newPageType.fromPageNo && newPageType.fromPageNo >= minFromPageNo && newPageType.fromPageNo <= maxToPageNo) {
        isFromPageNoValidated = true
      }

      newPageType.isFromPageNoValidated = isFromPageNoValidated;
      newPageType.isToPageNoValidated = isToPageNoValidated;
    })
  };
  onChangeNewPageTypeList = (newPageTypeListIdx, key, value) => {
    let newPageTypeList = JSON.parse(JSON.stringify(this.state.newPageTypeList));

    if (key === 'toPageNo' && newPageTypeListIdx < newPageTypeList.length - 1) {
      if (value.trim() === '') {
        newPageTypeList[newPageTypeListIdx + 1]['fromPageNo'] = '';
      } else {
        newPageTypeList[newPageTypeListIdx + 1]['fromPageNo'] = parseInt(value) + 1;
      }
    } else if (key === 'fromPageNo' && newPageTypeListIdx > 0) {
      if (value.trim() === '') {
        newPageTypeList[newPageTypeListIdx - 1]['toPageNo'] = '';
      } else {
        newPageTypeList[newPageTypeListIdx - 1]['toPageNo'] = parseInt(value) - 1;
      }
    }

    if (key === 'pageTypeName') {
      newPageTypeList[newPageTypeListIdx][key] = value;
    } else {
      newPageTypeList[newPageTypeListIdx][key] = parseInt(value);
    }

    this.validateNewPageTypeList(newPageTypeList);

    this.setState({ newPageTypeList });
  };
  onClickAddNewPageType = () => {
    let { newPageTypeList, breakingPageType } = this.state;
    if ((breakingPageType.toPageNo - breakingPageType.fromPageNo + 1) <= newPageTypeList.length) {
      return;
    }

    newPageTypeList = JSON.parse(JSON.stringify(newPageTypeList));

    let addingNewPageType = {
      pageTypeName: '',
      isPageTypeNameValidated: false,
      fromPageNo: '',
      isFromPageNoValidated: false,
      toPageNo: '',
      isToPageNoValidated: false
    };

    addingNewPageType.toPageNo = breakingPageType.toPageNo;
    newPageTypeList[newPageTypeList.length - 1].toPageNo = '';

    newPageTypeList.push(addingNewPageType);

    this.validateNewPageTypeList(newPageTypeList);

    this.setState({ newPageTypeList });
  };
  onClickRemoveNewPageType = (removingNewPageTypeListIdx) => {
    let { breakingPageType, newPageTypeList } = this.state;

    if (newPageTypeList.length === 2) {
      return;
    }

    newPageTypeList = JSON.parse(JSON.stringify(newPageTypeList));
    if (removingNewPageTypeListIdx === 0) {
      newPageTypeList[1].fromPageNo = breakingPageType.fromPageNo;
    } else if (removingNewPageTypeListIdx === newPageTypeList.length - 1) {
      newPageTypeList[removingNewPageTypeListIdx - 1].toPageNo = breakingPageType.toPageNo;
    } else {
      newPageTypeList[removingNewPageTypeListIdx + 1].fromPageNo = newPageTypeList[removingNewPageTypeListIdx].fromPageNo;
    }

    newPageTypeList.splice(removingNewPageTypeListIdx, 1);

    this.validateNewPageTypeList(newPageTypeList);

    this.setState({ newPageTypeList });
  };
  onClickSaveNewPageType = () => {
    let isAnyInvalidated = this.state.newPageTypeList.some(newPageType => newPageType.isPageTypeNameValidated === false || newPageType.isFromPageNoValidated === false || newPageType.isToPageNoValidated === false);
    if (isAnyInvalidated) {
      message.error('請修正錯誤頁碼或目錄名稱後再重新送出');
    } else {
      this.props.onSubmitSaveNewPageTypeList(this.state.breakingPageType.pageTypeId, this.state.newPageTypeList, this.props.caseId);
    }
  };
  onClickMergePageType = (pageTypeId, name) => {
    // todo
    const isDisplayFile = pageTypeId === this.state.currentFileTypeId;
    Modal.confirm({
      title: `您是否要刪除此分頁 [${name}]`,
      content: '按下"確認"按鈕將會刪除此分頁，其內容頁數會合併至上ㄧ切割分頁。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.setState({ showUploadFileForm: true });
        this.props.onSubmitMergePageType(pageTypeId, this.props.caseId, isDisplayFile);
      },
    });
  };
  onClickCaseDownload = (caseId, caseName, fileList) => {
    Modal.confirm({
      title: `下載《${caseName}》文件總覽`,
      content: <div>
        按下"確認"按鈕將下載此案文件總覽。
        {
          fileList.toJS().some( f => f.statusId !== 4 )
          &&
          <div style={{ color: 'red' }}>請留意有檔案尚未處理完成。</div>
        }
      </div>,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.onDownloadCase(caseId, caseName);
      },
    });
  };
  onClickFileDownload = (fileId, fileTitle) => {
    Modal.confirm({
      title: `下載《${fileTitle}》卷宗`,
      content: '按下"確認"按鈕將下載此卷宗。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.onDownloadFile(fileId, fileTitle);
      },
    });
  };
  onClickTypesDownload = (caseId, caseName) => {
    Modal.confirm({
      title: `下載《${caseName}》目錄總覽`,
      content: '按下"確認"按鈕將下載本案目錄總覽。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.onDownloadTypes(caseId, caseName);
      },
    });
  };
  onClickPageTypeDownload = (pageTypeIdList, pageTypeName) => {
    Modal.confirm({
      title: `下載〈${pageTypeName}〉目錄`,
      content: '按下"確認"按鈕將下載目錄。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.onPageTypeDownload(pageTypeIdList, pageTypeName);
      },
    });
  };
  onClickTagsDownload = (caseId, caseName) => {
    Modal.confirm({
      title: `下載《${caseName}》索引總覽`,
      content: '按下"確認"按鈕將下載本案索引總覽。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.onDownloadTags(caseId, caseName);
      },
    });
  };
  onClickFileEdit = (fileId, fileTitle) => {
    const originalFileTitle = fileTitle;
    const { caseId } = this.props;
    Modal.confirm({
      title: '編輯卷宗名稱',
      content: <Input.TextArea
        placeholder="請輸入卷宗名稱"
        defaultValue={fileTitle}
        onChange={e => fileTitle = e.target.value}
      />,
      okText: '確認編輯',
      cancelText: '取消編輯',
      onOk: () => {
        fileTitle = fileTitle.trim();
        if (fileTitle !== originalFileTitle) {
          this.setState({ documentBlockScroll: document.getElementById("documents").scrollTop });
          this.props.onFileEdit(fileId, fileTitle, caseId);
        } else {
          alert('請確認您輸入的卷宗名稱是否空白或未做修改')
        }
      },
    });
  };
  onClickFileDelete = (fileId) => {
    const { caseId } = this.props;
    Modal.confirm({
      title: '刪除卷宗',
      content: '按下"確認"按鈕將刪除此卷宗。',
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onFileDelete(fileId, caseId);
      },
    });
  };
  onClickPageTypeEdit = (pageTypeIdList, pageTypeName) => {
    const originalPageTypeName = pageTypeName;
    const { caseId } = this.props;
    Modal.confirm({
      title: '編輯目錄名稱',
      content: <Input.TextArea
        placeholder="請輸入目錄名稱"
        defaultValue={pageTypeName}
        onChange={e => pageTypeName = e.target.value}
      />,
      okText: '確認編輯',
      cancelText: '取消編輯',
      onOk: () => {
        pageTypeName = pageTypeName.trim();
        if (pageTypeName !== originalPageTypeName) {
          this.setState({ documentBlockScroll: document.getElementById("documents").scrollTop });
          this.props.onPageTypeEdit(pageTypeIdList, pageTypeName, caseId);
        } else {
          alert('請確認您輸入的目錄名稱是否空白或未做修改')
        }
      },
    });
  };
  onClickTagAdd = (pageTypeId) => {
    let tagNameAdd = '';
    const { caseId } = this.props;
    Modal.confirm({
      title: '在此目錄下新增索引',
      content: <Input.TextArea
        placeholder="新增索引"
        onChange={e => tagNameAdd = e.target.value}
      />,
      okText: '確認新增',
      cancelText: '取消新增',
      onOk: () => {
        tagNameAdd = tagNameAdd.trim();
        if (tagNameAdd !== '') {
          this.props.onTagAdd(pageTypeId, tagNameAdd, caseId);
        } else {
          alert('請確認您輸入的索引名稱是否空白或未做修改')
        }
      },
    });
  };
  onClickTagEdit = (tagId, tagTitle, pageTypeId = null) => {
    const originalTagName = tagTitle;
    const { caseId } = this.props;
    Modal.confirm({
      title: '編輯索引名稱',
      content: <Input.TextArea
        placeholder="請輸入索引名稱"
        defaultValue={tagTitle === "無法辨識" ? "" : tagTitle}
        onChange={e => tagTitle = e.target.value}
      />,
      okText: '確認編輯',
      cancelText: '取消編輯',
      onOk: () => {
        tagTitle = tagTitle.trim();
        if (tagTitle !== originalTagName) {
          this.props.onTagEdit(tagId, tagTitle, pageTypeId, caseId);
        } else {
          alert('請確認您輸入的索引名稱是否空白或未做修改')
        }
      },
    });
  };
  onClickMigrateEdit = (pageTypeId, tagId) => {
    let { toTagName } = this.state;
    const { caseId } = this.props;
    Modal.confirm({
      title: '編輯此目錄之索引',
      content: <Input.TextArea
        placeholder="請輸入索引名稱"
        onChange={e => {
          this.setState({ toTagName: e.target.value })
        }}
      />,
      okText: '確認編輯',
      cancelText: '取消編輯',
      onOk: () => {
        toTagName = this.state.toTagName.trim();
        if (toTagName !== "") {
          this.props.onTagMigrateEdit(pageTypeId, tagId, toTagName, caseId);
        } else {
          alert('請確認您輸入的索引名稱是否空白或未做修改')
        }
      },
    });
  };
  onClickAddKeyword = (caseId) => {
    let addKeyword = '';
    Modal.confirm({
      title: '新增關鍵字',
      content: <Input.TextArea
        placeholder="新增關鍵字"
        onChange={e => addKeyword = e.target.value}
      />,
      okText: '確認新增',
      cancelText: '取消新增',
      onOk: () => {
        addKeyword = addKeyword.trim();
        if (addKeyword !== '') {
          this.props.onAddKeyword(caseId, addKeyword);
        } else {
          alert('請確認是否空白')
        }
      },
    });
  };
  onClickLabelDownload = (labelId, labelName) => {
    Modal.confirm({
      title: `下載 ${labelName} 標籤`,
      content: '按下"確認"按鈕將下載此標籤。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.onLabelDownload(labelId, labelName);
      },
    });
  };
  onClickGotoHighlight = (highItem, highlight) => {
    const { currentPageIndex, currentFileTypeId } = this.state;
    if (+highlight.get("pageTypeId") === currentFileTypeId) {
      if (+highlight.get("pageNo") === currentPageIndex) {
        // Same Page
        window.location.hash = `highlight-${highItem.get("id")}`
      } else {
        // Same Section
        this.setState({ currentPageIndex: +highlight.get("pageNo"), isChangePage: false });
        window.location.hash = `highlight-goto-${highItem.get("id")}`;
        this.props.onChangePdfPage(+highlight.get("pageNo") - this.state.pageTypeDict[+highlight.get("pageTypeId")].fromPageNo);
      }
    } else {
      this.setState({
        currentPageType: highlight,
        currentFileTypeId: +highlight.get("pageTypeId"),
        currentPageIndex: +highlight.get("pageNo"),
        isChangePage: false,
      });
      this.props.onChangeCategoryPdf(this.state.pageTypeDict[+highlight.get("pageTypeId")].pageIdList.toJS());
      this.toggleCategory(`file-pagetype-${+highlight.get("pageTypeId")}`);
      this.toggleCategory(`type-pagetype-${+highlight.get("pageTypeId")}`);
      this.onChangeHighlight(`currentHighlight-${+highlight.get("pageTypeId")}`);
      this.hideNewPageType(+highlight.get("pageTypeId"));
      this.props.onChangePdfPage(+highlight.get("pageNo") - this.state.pageTypeDict[+highlight.get("pageTypeId")].fromPageNo);
      window.location.hash = `highlight-goto-${highItem.get("id")}`;
    }
  };
  resetHighlights = () => {
    this.props.updateHighLights([]);
    this.props.onDeleteAnnotations(this.props.pageId, 'highlight');
  };
  // Moving
  onClickUploadFileFolder = (folderId, folderName, action) => {
    let newFolderName = folderName;
    const { fileFolderDict } = this.state;
    Modal.confirm({
      title: action === "create" ? "新增資料夾" : "編輯資料夾",
      content: <Input
        placeholder="資料夾名稱"
        defaultValue={folderName}
        onChange={e => newFolderName = e.target.value}
      />,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        newFolderName = newFolderName.trim();
        if (newFolderName !== '') {
          if (Object.keys(fileFolderDict).find(Id => fileFolderDict[Id].toLowerCase() === newFolderName.toLowerCase())) {
            alert('此資料夾名稱已存在')
          } else {
            if (action === "create") {
              this.props.onCreateFilesFolder(this.props.caseId, newFolderName);
            } else {
              this.props.onModifyFilesFolder(this.props.caseId, folderId, newFolderName);
            }
          }
        } else {
          alert('請正確輸入修改名稱。確認是否空白或未修改')
        }
      },
    });
  };

  onClickUploadPageTypesFolder = (folderId, folderName, action) => {
    let newFolderName = folderName;
    const { folderDict } = this.state;
    Modal.confirm({
      title: action === "create" ? "新增資料夾" : "編輯資料夾",
      content: <Input
        placeholder="資料夾名稱"
        defaultValue={folderName}
        onChange={e => newFolderName = e.target.value}
      />,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        newFolderName = newFolderName.trim();
        if (newFolderName !== '') {
          if (Object.keys(folderDict).find(Id => folderDict[Id].toLowerCase() === newFolderName.toLowerCase())) {
            alert('此資料夾名稱已存在')
          } else {
            if (action === "create") {
              this.props.onCreatePageTypesFolder(this.props.caseId, newFolderName);
            } else {
              this.props.onModifyFolder(this.props.caseId, folderId, newFolderName);
            }
          }
        } else {
          alert('請正確輸入修改名稱。確認是否空白或未修改')
        }
      },
    });
  };
  onClickDeleteFolder = (folderId, folderName) => {
    Modal.confirm({
      title: "刪除資料夾",
      content: `您是否確定刪除資料夾 [${folderName}]`,
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteFolder(this.props.caseId, folderId);
      },
    });
  };

  onClickDeleteFileFolder = (folderId, folderName) => {
    Modal.confirm({
      title: "刪除資料夾",
      content: `您是否確定刪除資料夾 [${folderName}]`,
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteFileFolder(this.props.caseId, folderId);
      },
    });
  };

  // selectedMovingNote = (noteId, event) => {
  //   event.stopPropagation();
  //   this.setState({
  //     isMovingNote: true,
  //     currentMovingNote: noteId
  //   });
  //   document.getElementById(noteId).style.left = event.clientX - document.getElementById("page-review").offsetLeft + "px";
  //   document.getElementById(noteId).style.top = event.clientY - document.getElementById("page-review").offsetTop + "px";
  // }
  // onMouseMoveNote = (noteId, event) => {
  //   const { isMovingNote, currentMovingNote } = this.state;
  //   if ( isMovingNote && (currentMovingNote === noteId) ) {
  //     this.movingSelectedNote(noteId, event)
  //   } else {
  //     return null;
  //   }
  // };
  // movingSelectedNote = (note, event) => {
  //   if(note === this.state.currentMovingNote) {
  //     document.getElementById(note).style.left = document.getElementById("page-review").offsetLeft + event.clientX + "px";
  //     document.getElementById(note).style.top = document.getElementById("page-review").offsetTop + event.clientY + "px";
  //   }
  // };
  // dstMoveNote = (noteId, event) => {
  //   event.stopPropagation();
  //   this.setState({ isMovingNote: false, currentMovingNote: null });
  //   document.getElementById(noteId).style.left = event.clientX - document.getElementById(noteId).style.left + "px";
  //   document.getElementById(noteId).style.top = event.clientY - document.getElementById(noteId).style.top + "px";
  // }

  selectedMovingFile = (file, event) => {
    event.stopPropagation();
    this.setState({ isMovingFilePageType: true, currentMovingFilePageType: null });
    this.setState({ currentMovingFile: JSON.parse(JSON.stringify(file)) });
    document.getElementById("selectedDocItem").style.left = event.clientX + "px";
    document.getElementById("selectedDocItem").style.top = event.clientY + "px";
  };

  selectedMovingDocPages = (pages, event) => {
    event.stopPropagation();
    this.setState({ isMovingFilePages: true });
    this.setState({ currentMovingFilePageType: JSON.parse(JSON.stringify(pages)) });
    document.getElementById("selectedDocItem").style.left = event.clientX + "px";
    document.getElementById("selectedDocItem").style.top = event.clientY + "px";
  };

  addMovingFileToFolder = (folderId, event) => {
    event.stopPropagation();
    const { currentMovingFile, targetFileFolder } = this.state;
    if (currentMovingFile && targetFileFolder !== null) {
      let newFolderId = folderId !== "" ? +folderId : folderId;
      if (currentMovingFile["id"] !== newFolderId) {
        this.props.onMovingFileToFolder(this.props.caseId, currentMovingFile["id"], newFolderId);
      }
    } else {
      message.error("無法加入資料夾");
    }
    this.cancelSelectedMovingPageType();
  };

  selectedMovingPageType = (type, event) => {
    event.stopPropagation();
    this.setState({ isMovingPageType: true, currentMovingPages: null });
    this.setState({ currentMovingPageType: JSON.parse(JSON.stringify(type)) });
    document.getElementById("selectedFileItem").style.left = event.clientX + "px";
    document.getElementById("selectedFileItem").style.top = event.clientY + "px";
  };
  selectedMovingPages = (type, pages, event) => {
    event.stopPropagation();
    this.setState({ isMovingPages: true, currentMovingPageType: null });
    this.setState({ currentMovingPages: JSON.parse(JSON.stringify(pages)), currentMovingPageType: JSON.parse(JSON.stringify(type)) });
    document.getElementById("selectedFileItem").style.left = event.clientX + "px";
    document.getElementById("selectedFileItem").style.top = event.clientY + "px";
  };
  cancelSelectedMovingPageType = () => {
    this.setState({
      isMovingFilePages: false,
      isMovingFilePageType: false,
      currentMovingFile: null,
      currentMovingFilePageType: null,
      targetFileFolder: null,
      targetFilePageType: null,
      targetFilePages: null,
      isMovingPages: false,
      isMovingPageType: false,
      currentMovingPageType: null,
      currentMovingPages: null,
      targetFolder: null,
      targetPageType: null,
      targetPages: null,
    })
  };
  addMovingTypeToFolder = (folderId, event) => {
    event.stopPropagation();
    const { currentMovingPageType, currentMovingPages, targetFolder } = this.state;
    if (currentMovingPageType && targetFolder !== null) {
      let newFolderId = folderId !== "" ? +folderId : folderId;
      if (currentMovingPageType["folderId"] !== newFolderId) {
        const pageTypeList = currentMovingPages ? [currentMovingPages["id"]] : currentMovingPageType["pageTypeList"].map((pageType) => pageType.id);
        this.props.onMovingToFolder(this.props.caseId, pageTypeList, newFolderId);
      }
    }
    this.cancelSelectedMovingPageType();
  };
  addMovingPagesToPageType = (pageType, event) => {
    event.stopPropagation();
    const { currentMovingPageType, currentMovingPages, targetPageType } = this.state;
    if (currentMovingPages && targetPageType) {
      if (currentMovingPageType["folderId"] !== targetPageType.get("folderId") || currentMovingPageType["type"] !== pageType) {
        this.props.onMovingToPageType(this.props.caseId, [currentMovingPages["id"]], pageType, targetPageType.get("folderId"));
      }
    }
    this.cancelSelectedMovingPageType();
  };
  combineDocPageToPages = (page, event) => {
    event.stopPropagation();
    const { currentMovingFilePageType, pageTypeDict, targetFilePages } = this.state;
    if (currentMovingFilePageType && targetFilePages) {
      if (pageTypeDict[currentMovingFilePageType["id"]]["fileId"] === pageTypeDict[page.get('id')]["fileId"] &&
        (Math.abs(currentMovingFilePageType["fromPageNo"] - page.get("toPageNo")) === 1 ||
          Math.abs(currentMovingFilePageType["toPageNo"] - page.get("fromPageNo")) === 1)) {
        this.setState({ currentFileTypeId: 0 }, () =>
          this.props.onCombineDocPageToPages(this.props.caseId, [currentMovingFilePageType["id"]], page.get('id'))
        )
      } else {
        message.error("無法合併頁數，非同一檔案或頁數不相鄰");
      }
    }
    this.cancelSelectedMovingPageType();
  };
  combinePageToPages = (page, event) => {
    event.stopPropagation();
    const { currentMovingPages, pageTypeDict, targetPages } = this.state;
    if (currentMovingPages && targetPages) {
      if (pageTypeDict[currentMovingPages["id"]]["fileId"] === pageTypeDict[page.get('id')]["fileId"] &&
        (Math.abs(currentMovingPages["fromPageNo"] - page.get("toPageNo")) === 1 ||
          Math.abs(currentMovingPages["toPageNo"] - page.get("fromPageNo")) === 1)) {
        this.setState({ currentFileTypeId: 0 }, () =>
          this.props.onCombinePageToPages(this.props.caseId, [currentMovingPages["id"]], page.get('id'))
        )
      } else {
        message.error("無法合併頁數，非同一檔案或頁數不相鄰");
      }
    }
    this.cancelSelectedMovingPageType();
  };
  movingSelectedFile = (event, selectedItem) => {
    document.getElementById(selectedItem).style.left = event.clientX + "px";
    document.getElementById(selectedItem).style.top = event.clientY + "px";
    if (event.clientY > window.screen.availHeight * 0.8) {
      document.getElementById("documents").scrollTop = document.getElementById("documents").scrollTop + 10;
    } else if (event.clientY < 120) {
      document.getElementById("documents").scrollTop = document.getElementById("documents").scrollTop - 10;
    }
  };
  onMouseMove = (e) => {
    const {
      isMovingFilePageType, currentMovingFile, isMovingFilePages, currentMovingFilePageType,
      isMovingPageType, currentMovingPageType, isMovingPages, currentMovingPages
    } = this.state;
    if ((isMovingPageType && currentMovingPageType) || (isMovingPages && currentMovingPages)) {
      this.movingSelectedFile(e, "selectedFileItem")
    } else if ((isMovingFilePageType && currentMovingFile) || (isMovingFilePages && currentMovingFilePageType)) {
      this.movingSelectedFile(e, "selectedDocItem")
    } else {
      return null;
    }
  };

  gotoAttachmentPage() {
    if (this.state.currentPageType) {
      localStorage.setItem("reviewCache", JSON.stringify(this.state.currentPageType.toJS()));
    }
    localStorage.setItem("reviewCacheHasPageIndex", this.state.currentPageIndex);
    localStorage.setItem("reviewCacheSwitchView", this.state.switchView);
    localStorage.setItem("reviewCacheHighlight", this.state.currentHighlight);
    localStorage.setItem("reviewCacheTabValue", this.state.tabValue);
    localStorage.setItem("reviewCacheExpandCategorySet", Array.from(this.state.expandCategorySet).join(","));
    localStorage.setItem("reviewCacheHasItem", Array.from(this.state.hasItem).join(","));
    localStorage.setItem("reviewCacheSearchWord", this.state.searchWord);
    this.props.history.push(`/attachment/${this.props.caseId}`)
  };

  restoreReviewCache() {
    const reviewCache = localStorage.getItem("reviewCache");
    const pageIndex = localStorage.getItem("reviewCacheHasPageIndex");
    const tabValue = localStorage.getItem("reviewCacheTabValue");
    const searchWord = localStorage.getItem("reviewCacheSearchWord");
    if (reviewCache) {
      const reviewCacheMap = fromJS(JSON.parse(reviewCache));
      if (reviewCacheMap.has("sequence")) { // keywords
        if (this.props.fileList) {
          this.props.onChangeCategoryPdf(reviewCacheMap.getIn(['pageIdList']).toJS());
          this.setState({ currentFileTypeId: 0, isChangePage: false, currentPageType: reviewCacheMap, tabValue });
          localStorage.removeItem("reviewCache");
          localStorage.removeItem("reviewCacheHasPageIndex");
          localStorage.removeItem("reviewCacheTabValue");
        }
      } else if (reviewCacheMap.has("fileTitle")) { // search hit
        if (this.props.fileList) {
          this.setState({ currentFileTypeId: 0, isChangePage: false, searchWord, showSearchForm: true, currentPageType: reviewCacheMap, tabValue });
          this.props.onSubmitSearch(this.props.caseId, searchWord);
          this.props.onChangeCategoryPdf(reviewCacheMap.toJS().pages.map(page => page.id));
          localStorage.removeItem("reviewCache");
          localStorage.removeItem("reviewCacheSearchWord");
          localStorage.removeItem("reviewCacheHasPageIndex");
          localStorage.removeItem("reviewCacheTabValue");
        }
      } else {
        if (this.state.pageTypeDict.hasOwnProperty(reviewCacheMap.getIn(['id']))) {
          if (+pageIndex !== this.state.pageTypeDict[reviewCacheMap.getIn(['id'])].fromPageNo) {
            this.setState({
              currentPageIndex: pageIndex,
              currentFileTypeId: reviewCacheMap.getIn(['id']),
            });
            this.onClickPageGo(pageIndex, reviewCacheMap.getIn(['id']));
          } else {
            this.changeCategoryPdf(reviewCacheMap);
          }
          this.hideNewPageType(reviewCacheMap.getIn(['id']));
          this.setState({ tabValue });
          localStorage.removeItem("reviewCache");
          localStorage.removeItem("reviewCacheHasPageIndex");
          localStorage.removeItem("reviewCacheTabValue");
        }
      }
    }
  }

  restoreReviewSetting() {
    const reviewCacheHighlight = localStorage.getItem("reviewCacheHighlight");
    const reviewCacheExpandCategorySet = localStorage.getItem("reviewCacheExpandCategorySet");
    const reviewCacheHasItem = localStorage.getItem("reviewCacheHasItem");
    const switchView = localStorage.getItem("reviewCacheSwitchView");
    if (reviewCacheHighlight) {
      this.setState({
        currentHighlight: reviewCacheHighlight,
      });
    }
    localStorage.removeItem("reviewCacheHighlight");
    if (reviewCacheExpandCategorySet) {
      this.setState({
        expandCategorySet: new Set(reviewCacheExpandCategorySet.split(",")),
      });
      localStorage.removeItem("reviewCacheExpandCategorySet");
    }
    if (reviewCacheHasItem) {
      this.setState({
        hasItem: new Set(reviewCacheHasItem.split(",")),
      });
      localStorage.removeItem("reviewCacheHasItem");
    }
    if (switchView) {
      this.setState({
        switchView,
      });
      localStorage.removeItem("reviewCacheSwitchView");
    }
  }

  onSelectTab = (tabValue) => {
    this.setState({ tabValue });
  };
  onToggleNotes(showNotes) {
    this.setState({ showNotes })
  }
  onShowEncryptedFileModal = () => {
    Modal.warning({
      title: '檔案為鎖碼檔案，上傳失敗',
      content: (
        <div className="docket-col">
          <div>您有下列加密檔案，請解密後再行上傳。</div>
          {
              this.props.encryptedPdfNameList.map(pdfName => <div key={`doc-encrypted-${pdfName}`}>{pdfName}</div>)
          }
        </div>
      ),
      okText: '知道了',
      onOk: () => {
        this.props.onCleanEncryptedPdfNameList();
      }
    });
  }
  render() {
    const {
      fileTotal, expandCategorySet,
      showLabelForm, editingLabelName, modifiedNoteLabelIdSet, showCopyLabelPagesToFolder, currentLabelId, labelToCaseId, labelToUserFolderId, 
      showAddPagesToLabelForm, addToLabelId, isAddingRangeToLabel,
      showUploadFileForm, uploadFileList,
      showNewPageType, breakingPageType,
      newPageTypeList,
      currentPageIndex, isChangePage, switchView, pageTypeDict, currentFileTypeId, currentScale, fileDict,
      isMovingFilePageType, isMovingFilePages, fileFolderTypeDict, fileFolderDict, currentMovingFile, currentMovingFilePageType, targetFilePageType, targetFilePages, targetFileFolder,
      isMovingPageType, isMovingPages, folderTypeDict, folderDict, currentMovingPageType, currentMovingPages, targetFolder, tagList,
      currentHighlight,
      hasItem, tabValue,
      showSearchForm, searchWord,
      showLabelBlock, showHighLightBlock, showDuplicateBlock, showDocumentBlock, showRightBarBlock, 
      showNotes
    } = this.state;
    const { highlights, highlightDict, highlightList, updateHighLights } = this.props;
    const UploadProps = {
      accept: ".pdf",
      multiple: true,
      defaultFileList: uploadFileList,
      onRemove: file => {
        this.setState(state => {
          const index = state.uploadFileList.indexOf(file);
          const newuploadFileList = state.uploadFileList.slice();
          newuploadFileList.splice(index, 1);
          return {
            uploadFileList: newuploadFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          uploadFileList: [...state.uploadFileList, file],
        }));
        return false;
      }
    };
    return (
      <div className={`wrap main-container ${(isMovingPageType || isMovingPages || isMovingFilePageType || isMovingFilePages) ? "changing" : ""}`}
           onMouseUp={() => (isMovingPageType || isMovingPages || isMovingFilePageType || isMovingFilePages) ? this.cancelSelectedMovingPageType() : null}
           onMouseMove={(e) => this.onMouseMove(e)}>
        <div className={`section ${showDocumentBlock ? "open" : ""}`} id="left_bar">
          <div className="generator">
            <div className="generator_title">
              <div className="generator-left">
                <Tooltip title="回首頁">
                  <p className="user-logo-wrapper"><img className="user-logo" alt="回首頁" src={dnaLogoIcon} onClick={() => this.props.history.push(`/cases`)}/></p>
                </Tooltip>
                <Tooltip title="至委任檔案頁">
                  <p className="user-tab-wrapper" onClick={() => this.gotoAttachmentPage()}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faBookReader}/>
                  </p>
                </Tooltip>
                <p className="user-title-wrapper"><span className="user-mainTitle">法律卷證頁</span></p>
              </div>
              <button type="button" className="btn btn-outline-primary btn-sm btn-upload"
                      onClick={() => this.setState({ showUploadFileForm: true, uploadFileList: [] })}>
                上傳卷宗
              </button>
              {
                this.state.showUserTool &&
                <div className="user-toolBar-popup">
                  {/* <p className="toolBar-wrapper"
                     onClick={() => this.gotoAttachmentPage()}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faPaperclip}/>
                    <span className="toolBar-label">至委任檔案頁</span>
                  </p> */}
                </div>
              }
            </div>
            <Tab.Container id="left-tabs-example" activeKey={tabValue}
                           onSelect={this.onSelectTab}>
              <Col className="generator_tab">
                <Nav variant="pills" className="d-flex">
                  <Nav.Item className="col-sm-3 pl-0 pr-0">
                    <Nav.Link className="text-center" eventKey="files">卷宗</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-sm-3 pl-0 pr-0">
                    <Nav.Link className="text-center" eventKey="types">目錄</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-sm-3 pl-0 pr-0" id="menu-item-tags">
                    <Nav.Link className="text-center" eventKey="tags">索引</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-sm-3 pl-0 pr-0">
                    <Nav.Link className="text-center" eventKey="keywords">關鍵字</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col className="generator_tabContent" id="documents">
                <Tab.Content className="mt-3">
                  <Tab.Pane eventKey="files">
                    <div className="file-top-wrapper">
                      {/*
                      <button type="button" className="btn btn-outline-primary btn-sm"
                              onClick={e => {
                                e.stopPropagation();
                                this.onClickUploadFileFolder(-1, "", "create");
                              }}>新增資料夾
                      </button>
                      */}
                      {
                        this.props.fileList && this.props.fileList.size > 0 
                        &&
                        <button type="button" className="btn btn-outline-info btn-sm download_pdf"
                                onClick={e => {
                                  e.stopPropagation();
                                  this.onClickCaseDownload(this.props.caseId, this.props.caseName, this.props.fileList)
                                }}>文件總覽下載
                        </button>
                      }
                    </div>
                    <div className={`folders-wrapper ${(isMovingFilePageType || isMovingFilePages) ? "changing" : ""}`}
                         onMouseEnter={() => isMovingFilePageType ? this.setState({ targetFileFolder: "" }) : null}
                         onMouseLeave={() => (isMovingFilePageType || isMovingFilePages) ? this.setState({ targetFileFolder: null }) : null}
                         onMouseUp={(e) => (isMovingFilePageType) ? this.addMovingFileToFolder("", e) : null}>
                      {
                        Object.keys(fileFolderTypeDict).map((folderId) =>
                          <div className={`folder-wrapper ${folderId !== "" ? "isFolder" : ""}`}
                               key={folderId}>
                            {
                              folderId !== ""
                              &&
                              <div className="folder-name-wrapper"
                                   onClick={() => (!isMovingFilePageType && !isMovingFilePages) ? this.toggleCategory(`folder-${folderId}`) : null}
                                   onMouseEnter={() => (isMovingFilePageType || isMovingPages) ? this.setState({ targetFileFolder: folderId }) : null}
                                   onMouseLeave={() => isMovingFilePageType ? this.setState({ targetFileFolder: "" }) : isMovingFilePages ? this.setState({ targetFileFolder: null }) : null}
                                   onMouseUp={(e) => (isMovingFilePageType || isMovingFilePages) ? this.addMovingFileToFolder(folderId, e) : null}>
                              <span className="folder-icon">
                                {
                                  (!isMovingFilePageType && !isMovingFilePages)
                                  &&
                                  (
                                    hasItem.has(`folder-${folderId}`)
                                      ?
                                      <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleDown}/>
                                      :
                                      <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleRight}/>
                                  )
                                }
                              </span>
                                <span className="folder-name">
                                <FontAwesomeIcon className="mr-1" icon={far.faFolder}/>{fileFolderDict[folderId]}
                              </span>
                                <p className="folder-tools">
                                <span className="folder-tool folder-edit"
                                      onClick={e => {
                                        e.stopPropagation();
                                        this.onClickUploadFileFolder(folderId, fileFolderDict[folderId], "edit");
                                      }}>
                                  <Tooltip title="編輯資料夾名稱">
                                    <FontAwesomeIcon icon={fas.faPencilAlt}/>
                                  </Tooltip>
                                </span>
                                  <span className="folder-tool folder-trash text-danger"
                                        onClick={e => {
                                          e.stopPropagation();
                                          this.onClickDeleteFileFolder(folderId, fileFolderDict[folderId]);
                                        }}>
                                  <Tooltip title="刪除資料夾">
                                    <FontAwesomeIcon icon={fas.faTrash}/>
                                  </Tooltip>
                                </span>
                                </p>
                              </div>
                            }
                            <div className="folder-children">
                              {
                                (folderId === "" || expandCategorySet.has(`folder-${folderId}`))
                                &&
                                fromJS(fileFolderTypeDict[folderId]).map((file, fileIdx) =>
                                  <React.Fragment key={fileIdx}>
                                    {
                                      this.props.isLoading
                                      &&
                                      <button type="button" className="btn btn-outline-info text-right btn-sm download_pdf mr-3 mt-1"
                                              onClick={() => this.props.onLoadCaseCategory(this.props.caseId)}>
                                        <Tooltip title="載入中"><FontAwesomeIcon icon={fas.faSyncAlt}/></Tooltip>
                                      </button>
                                    }
                                    {
                                      <div key={`file-${fileIdx}`}>
                                        <div className={`system_item`}
                                          // onClick={() => {
                                          //   this.changeCategoryPdf(file.getIn(['pageTypeList', 0]));
                                          //   this.onChangeHighlight(`currentHighlight-${file.getIn(['id'])}`)
                                          // }}
                                        >
                                          <div className="serial-number">
                                            <select onChange={e => this.onChangeSequences(this.props.caseId, file.getIn(['id']), e)}
                                                    value={file.getIn(['sequence'])}>
                                              {
                                                [...Array(fileTotal).keys()].map((i, idx) =>
                                                  <option key={idx} value={i + 1}>{i + 1}</option>
                                                )
                                              }
                                            </select>

                                          </div>
                                          {
                                            file.getIn(['pageTypeList']).toJS().length > 0
                                            &&
                                            <div className="haveItem">
                                              {
                                                hasItem.has(`file-${file.getIn(['id'])}`)
                                                  ?
                                                  <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleDown}/>
                                                  :
                                                  <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleRight}/>
                                              }
                                            </div>
                                          }
                                          {
                                            (file.getIn(['statusId']) === 1 || file.getIn(['statusId']) === 2)
                                            &&
                                            <ul>
                                              <li className="item_title statusId_wait">{file.getIn(['title'])} <span style={{paddingLeft: "10px", color: "#2b95eb"}}><Spin size="small"/> 卷證處理中</span></li>
                                            </ul>
                                          }
                                          {
                                            file.getIn(['statusId']) === 3
                                            &&
                                            <ul className="statusId_failure pb-1">
                                              <li className="item_title statusId_wait">{file.getIn(['title'])}</li>
                                              <li>
                                                <button type="button" className="btn btn-outline-danger btn-sm"
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          this.onClickFileDelete(file.getIn(['id']))
                                                        }}
                                                >
                                                  檔案處理失敗，點擊刪除
                                                </button>
                                              </li>
                                            </ul>
                                          }
                                          {
                                            file.getIn(['statusId']) === 4
                                            &&
                                            <ul
                                              onMouseEnter={() => isMovingFilePageType ? this.setState({ targetFileFolder: null }) : isMovingPages ? this.setState({ targetFilePageType: file }) : null}
                                              onMouseLeave={() => isMovingFilePageType || isMovingFilePages ? this.setState({
                                                targetFileFolder: "",
                                                targetFilePageType: null
                                              }) : null}
                                              onMouseUp={(e) => isMovingFilePages ? this.addMovingPagesToPageType(file.getIn(['type']), e) : null}
                                            >
                                              <li className="item_title"
                                                  onClick={() => this.toggleCategory(`file-${file.getIn(['id'])}`)}>
                                                {file.getIn(['title'])}
                                              </li>
                                              <li className="edit_tool">
                                                <p>
                                                  <Tooltip title="下載卷宗">
                                                    <i onClick={e => {
                                                      e.stopPropagation();
                                                      this.onClickFileDownload(file.getIn(['id']), file.getIn(['title']))
                                                    }}><FontAwesomeIcon icon={fas.faFileDownload}/></i>
                                                  </Tooltip>
                                                </p>
                                                <p>
                                                  <Tooltip title="移動卷宗">
                                                    <i className="action"
                                                       onMouseDown={(e) => this.selectedMovingFile(file, e)}>
                                                      <FontAwesomeIcon icon={fas.faArrowsAlt}/></i>
                                                  </Tooltip>
                                                </p>
                                                <p>
                                                  <Tooltip title="編輯卷宗名稱">
                                                    <i onClick={e => {
                                                      e.stopPropagation();
                                                      this.onClickFileEdit(file.getIn(['id']), file.getIn(['title']), fileIdx)
                                                    }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                                  </Tooltip>
                                                </p>
                                                <p>
                                                  <Tooltip title="刪除卷宗">
                                                    <i className="text-danger" onClick={e => {
                                                      e.stopPropagation();
                                                      this.onClickFileDelete(file.getIn(['id']), fileIdx)
                                                    }}><FontAwesomeIcon icon={fas.faTrash}/></i>
                                                  </Tooltip>
                                                </p>
                                              </li>
                                            </ul>
                                          }
                                        </div>
                                        {// 二層
                                          expandCategorySet.has(`file-${file.getIn(['id'])}`) && file.getIn(['pageTypeList']) && file.getIn(['pageTypeList']).map((pageType, pageTypeIdx) =>
                                            <div key={`file-pagetype-${pageTypeIdx}`}>
                                              <div
                                                className={`system_item system_item_child ${currentHighlight === `currentHighlight-${pageType.getIn(['id'])}` ? "alert-primary" : ""} ${showNewPageType && breakingPageType.pageTypeId === pageType.getIn(['id']) ? "breaking" : ""}`}
                                                onClick={() => {
                                                  this.changeCategoryPdf(pageType);
                                                  this.toggleCategory(`file-pagetype-${pageType.getIn(['id'])}`);
                                                  this.onChangeHighlight(`currentHighlight-${pageType.getIn(['id'])}`);
                                                  this.hideNewPageType(pageType.getIn(['id']));
                                                }}>
                                                <div className="haveItem">
                                                  {
                                                    pageType.getIn(['tagList']).toJS().length > 0
                                                    &&
                                                    <>
                                                      {
                                                        hasItem.has(`file-pagetype-${pageType.getIn(['id'])}`)
                                                          ?
                                                          <FontAwesomeIcon className="text-primary" icon={fas.faAngleDown}/>
                                                          :
                                                          <FontAwesomeIcon className="text-primary" icon={fas.faAngleRight}/>
                                                      }
                                                    </>
                                                  }
                                                </div>
                                                <ul
                                                  onMouseEnter={() => (isMovingFilePages && currentMovingFilePageType["id"] !== pageType.getIn(['id'])) ? this.setState({ targetFilePages: file }) : null}
                                                  onMouseLeave={() => isMovingFilePages ? this.setState({ targetFilePages: null }) : null}
                                                  onMouseUp={(e) => isMovingFilePages ? this.combineDocPageToPages(pageType, e) : null}>
                                                  <li className="item_number">
                                                    <span className="text-primary">{pageType.getIn(['fromPageNo'])}</span>
                                                    <span className="text-primary">~</span>
                                                    <span className="text-primary">{pageType.getIn(['toPageNo'])}</span>
                                                  </li>
                                                  <li className="item_number_title">
                                                    {pageType.getIn(['type'])}
                                                  </li>
                                                  <li className="edit_tool">
                                                    {
                                                      pageType.getIn(['fromPageNo']) < pageType.getIn(['toPageNo'])
                                                      &&
                                                      <p>
                                                        <Tooltip title="分割目錄頁數">
                                                          <i onClick={e => {
                                                            e.stopPropagation();
                                                            this.showActionNewPageType(pageType.getIn(['id']), pageType.getIn(['type']), pageType.getIn(['fromPageNo']), pageType.getIn(['toPageNo']))
                                                          }}><FontAwesomeIcon icon={fas.faCut}/>
                                                          </i>
                                                        </Tooltip>
                                                      </p>
                                                    }
                                                    {
                                                      +pageType.getIn(['fromPageNo']) !== 1
                                                      &&
                                                      <p>
                                                        <Tooltip title="刪除此分頁並合併">
                                                          <i onClick={e => {
                                                            e.stopPropagation();
                                                            this.onClickMergePageType(pageType.getIn(['id']), `(${pageType.getIn(['fromPageNo'])} ~ ${pageType.getIn(['toPageNo'])}) - ${pageType.getIn(['type'])}`);
                                                          }}><FontAwesomeIcon icon={fas.faObjectGroup}/></i>
                                                        </Tooltip>
                                                      </p>
                                                    }
                                                    <p>
                                                      <Tooltip title="編輯目錄名稱">
                                                        <i onClick={e => {
                                                          e.stopPropagation();
                                                          this.onClickPageTypeEdit(Array.of(pageType.getIn(['id'])), pageType.getIn(['type']))
                                                        }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                                      </Tooltip>
                                                    </p>
                                                    <p>
                                                      <Tooltip title="移動合併目錄頁">
                                                        <i className="action"
                                                           onMouseDown={(e) => this.selectedMovingDocPages(pageType, e)}>
                                                          <FontAwesomeIcon icon={fas.faArrowsAlt}/></i>
                                                      </Tooltip>
                                                    </p>
                                                    <p>
                                                      <Tooltip title="新增索引">
                                                        <i onClick={e => {
                                                          e.stopPropagation();
                                                          this.onClickTagAdd(pageType.getIn(['id']))
                                                        }}><FontAwesomeIcon icon={fas.faPlus}/></i>
                                                      </Tooltip>
                                                    </p>
                                                    {
                                                      (showNewPageType && breakingPageType.pageTypeId === pageType.getIn(['id']))
                                                      &&
                                                      <p className="edit_tool_breaking">
                                                        <Tooltip title="取消分割目錄頁數">
                                                          <i onClick={e => {
                                                            e.stopPropagation();
                                                            this.showActionNewPageType(pageType.getIn(['id']), pageType.getIn(['type']), pageType.getIn(['fromPageNo']), pageType.getIn(['toPageNo']))
                                                          }}><FontAwesomeIcon icon={fas.faTimes}/>
                                                          </i>
                                                        </Tooltip>
                                                      </p>
                                                    }
                                                  </li>
                                                </ul>
                                                {
                                                  (showNewPageType && breakingPageType.pageTypeId === pageType.getIn(['id']))
                                                  &&
                                                  <div className="pt-1 w-100" onClick={(e) => e.stopPropagation()}>
                                                    {
                                                      newPageTypeList.map((newPageType, newPageTypeIdx) =>
                                                        <div key={`new-pagetype-${newPageTypeIdx}`} className="form-row">
                                                          <div className="col-md-2">
                                                            <input
                                                              className={'form-control form-control-sm ' + (newPageType.isFromPageNoValidated ? '' : 'page-type-break-input-error')}
                                                              value={newPageType.fromPageNo}
                                                              disabled={newPageTypeIdx === 0}
                                                              onChange={e => this.onChangeNewPageTypeList(newPageTypeIdx, 'fromPageNo', e.target.value)}
                                                            />
                                                          </div>
                                                          <div className="col-md-2">
                                                            <input
                                                              className={'form-control form-control-sm ' + (newPageType.isToPageNoValidated ? '' : 'page-type-break-input-error')}
                                                              type="number"
                                                              value={newPageType.toPageNo}
                                                              disabled={newPageTypeIdx === newPageTypeList.length - 1}
                                                              onChange={e => this.onChangeNewPageTypeList(newPageTypeIdx, 'toPageNo', e.target.value)}
                                                            />
                                                          </div>
                                                          <div className="col-md-7">
                                                            <input
                                                              className={'form-control form-control-sm ' + (newPageType.isPageTypeNameValidated ? '' : 'page-type-break-input-error')}
                                                              type="text"
                                                              value={newPageType.pageTypeName}
                                                              onChange={e => this.onChangeNewPageTypeList(newPageTypeIdx, 'pageTypeName', e.target.value)}
                                                            />
                                                          </div>
                                                          <div className="form-group col-md-1 mt-1">
                                                            <button type="button" className="edit_icon button_icon">
                                                              <i className="action text-danger "
                                                                 onClick={() => this.onClickRemoveNewPageType(newPageTypeIdx)}><FontAwesomeIcon
                                                                icon={fas.faMinus}/></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )
                                                    }
                                                    <div className="d-flex justify-content-end mt-2">
                                                      <button type="button" className="btn btn-outline-success btn-sm mr-2"
                                                              onClick={() => this.onClickAddNewPageType()}>
                                                        新增分割區塊
                                                      </button>
                                                      <button type="button" className="btn btn-outline-primary btn-sm"
                                                              onClick={() => this.onClickSaveNewPageType()}>
                                                        儲存分割
                                                      </button>
                                                    </div>
                                                  </div>
                                                }
                                              </div>
                                              { // 三層 函文
                                                (expandCategorySet.has(`file-pagetype-${pageType.getIn(['id'])}`) && pageType.getIn(['letters']))
                                                &&
                                                <div className="system_letters">
                                                  { pageType.getIn(['letters', 'from']) && <div>來文者：{pageType.getIn(['letters', 'from'])}</div>}
                                                  { pageType.getIn(['letters', 'to']) && <div>受文者：{pageType.getIn(['letters', 'to'])}</div>}
                                                  { pageType.getIn(['letters', 'when']) && <div>發文日期：{pageType.getIn(['letters', 'when'])}</div>}
                                                </div>
                                              }
                                              { // 三層
                                                expandCategorySet.has(`file-pagetype-${pageType.getIn(['id'])}`) && pageType.getIn(['tagList']) && pageType.getIn(['tagList']).map((tag, tagIdx) =>
                                                  <div key={`file-tag-${tagIdx}`} className="system_item">
                                                    <ul className="last_item"
                                                        onClick={() => {
                                                          this.toggleCategory(`tag-${tag.getIn(['id'])}`, "open");
                                                          this.toggleCategory(`type-pagetype-${pageType.getIn(['type'])}`, "open");
                                                          document.getElementById("menu-item-tags").childNodes[0].click();
                                                          setTimeout(() => {
                                                            document.getElementById("documents").scrollTop = document.getElementById(`tag-${tag.getIn(['id'])}`).offsetTop;
                                                          }, 500);
                                                        }}>
                                                      <li className="item_number empty">
                                                      </li>
                                                      <li className="item_number_title">
                                                        {tag.getIn(['tag'])}
                                                      </li>
                                                      <li className="edit_tool">
                                                        <p>
                                                          <Tooltip title="編輯索引名稱">
                                                            <i onClick={e => {
                                                              e.stopPropagation();
                                                              this.onClickTagEdit(tag.getIn(['id']), tag.getIn(['tag']), pageType.getIn(['id']))
                                                            }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                                          </Tooltip>
                                                        </p>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                )
                                              }
                                            </div>
                                          )
                                        }
                                      </div>
                                    }
                                  </React.Fragment>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                    <div
                      className={`selectedFileItem ${(currentMovingFile && isMovingFilePageType) || (currentMovingFilePageType && isMovingFilePages) ? "display" : ""}`}
                      id="selectedDocItem">
                      <span className="selectedFileItem-icon">
                        {
                          (currentMovingFile && !currentMovingFilePageType)
                          &&
                          ((targetFileFolder === null && targetFilePageType === null && targetFilePages === null) ?
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faBan}/>
                            :
                            targetFolder === "" ?
                              <FontAwesomeIcon className="haveItemIcon" icon={fas.faIndent}/>
                              :
                              <FontAwesomeIcon className="haveItemIcon" icon={fas.faPlus}/>)
                        }
                        {
                          currentMovingFilePageType
                          &&
                          (((targetFileFolder === null || targetFileFolder === "") && targetFilePageType === null && targetFilePages === null) ?
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faBan}/>
                            :
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faPlus}/>)
                        }
                      </span>
                      {
                        currentMovingFile
                        &&
                        <span className="selectedFileItem-name">{currentMovingFile["title"]}</span>
                      }
                      {
                        currentMovingFilePageType
                        &&
                        <span className="selectedFileItem-name">{currentMovingFilePageType["type"]}</span>
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="types">
                    <div className="file-top-wrapper">
                      {/*
                      <button type="button" className="btn btn-outline-primary btn-sm"
                              onClick={e => {
                                e.stopPropagation();
                                this.onClickUploadPageTypesFolder(-1, "", "create");
                              }}>新增資料夾
                      </button>
                      */}
                      {
                        Object.values(folderTypeDict).some( type => type.length > 0 )
                        &&
                        <button type="button" className="btn btn-outline-info btn-sm download_pdf"
                                onClick={e => {
                                  e.stopPropagation();
                                  this.onClickTypesDownload(this.props.caseId, this.props.caseName)
                                }}>目錄總覽下載
                        </button>
                      }
                    </div>
                    <div className={`folders-wrapper pagetype-wrapper ${(isMovingPageType || isMovingPages) ? "changing" : ""}`}
                         onMouseEnter={() => isMovingPageType ? this.setState({ targetFolder: "" }) : null}
                         onMouseLeave={() => (isMovingPageType || isMovingPages) ? this.setState({ targetFolder: null }) : null}
                         onMouseUp={(e) => (isMovingPageType) ? this.addMovingTypeToFolder("", e) : null}>
                      {
                        Object.keys(folderTypeDict).map((folderId) =>
                          <div className={`folder-wrapper ${folderId !== "" ? "isFolder" : ""}`}
                               key={folderId}>
                            {
                              folderId !== ""
                              &&
                              <div className="folder-name-wrapper"
                                   onClick={() => (!isMovingPageType && !isMovingPages) ? this.toggleCategory(`folder-${folderId}`) : null}
                                   onMouseEnter={() => (isMovingPageType || isMovingPages) ? this.setState({ targetFolder: folderId }) : null}
                                   onMouseLeave={() => isMovingPageType ? this.setState({ targetFolder: "" }) : isMovingPages ? this.setState({ targetFolder: null }) : null}
                                   onMouseUp={(e) => (isMovingPageType || isMovingPages) ? this.addMovingTypeToFolder(folderId, e) : null}>
                                <span className="folder-icon">
                                  {
                                    (!isMovingPageType && !isMovingPages)
                                    &&
                                    (
                                      hasItem.has(`folder-${folderId}`)
                                        ?
                                        <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleDown}/>
                                        :
                                        <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleRight}/>
                                    )
                                  }
                                </span>
                                <span className="folder-name">
                                  <FontAwesomeIcon className="mr-1" icon={far.faFolder}/>{folderDict[folderId]}
                                </span>
                                <p className="folder-tools">
                                  <span className="folder-tool folder-edit"
                                        onClick={e => {
                                          e.stopPropagation();
                                          this.onClickUploadPageTypesFolder(folderId, folderDict[folderId], "edit");
                                        }}>
                                  <Tooltip title="編輯資料夾名稱">
                                    <FontAwesomeIcon icon={fas.faPencilAlt}/>
                                  </Tooltip>
                                </span>
                                  <span className="folder-tool folder-trash text-danger"
                                        onClick={e => {
                                          e.stopPropagation();
                                          this.onClickDeleteFolder(folderId, folderDict[folderId]);
                                        }}>
                                   <Tooltip title="刪除資料夾">
                                    <FontAwesomeIcon icon={fas.faTrash}/>
                                  </Tooltip>
                                </span>
                                </p>
                              </div>
                            }
                            <div className="folder-children">
                              {
                                (folderId === "" || expandCategorySet.has(`folder-${folderId}`))
                                &&
                                fromJS(folderTypeDict[folderId]).map((type, typeIdx) =>
                                  <div key={`type-${typeIdx}`}>
                                    <div className="system_item">
                                      {
                                        (type.getIn(['pageTypeList']).toJS().length > 0 && !isMovingPageType && !isMovingPages)
                                        &&
                                        <div className="haveItem">
                                          {
                                            hasItem.has(`folder-pagetype-${type.getIn(['type'])}`)
                                              ?
                                              <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleDown}/>
                                              :
                                              <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleRight}/>
                                          }
                                        </div>
                                      }
                                      <ul
                                        onMouseEnter={() => isMovingPageType ? this.setState({ targetFolder: null }) : isMovingPages ? this.setState({ targetPageType: type }) : null}
                                        onMouseLeave={() => isMovingPageType || isMovingPages ? this.setState({
                                          targetFolder: "",
                                          targetPageType: null
                                        }) : null}
                                        onMouseUp={(e) => isMovingPages ? this.addMovingPagesToPageType(type.getIn(['type']), e) : null}>
                                        <li className="item_title"
                                            onClick={() => (!isMovingPageType && !isMovingPages) ? this.toggleCategory(`folder-pagetype-${type.getIn(['type'])}-${type.getIn(['folderId'])}`) : null}>
                                          {type.getIn(['type'])}
                                        </li>
                                        <li className="edit_tool">
                                          <p>
                                            <Tooltip title="下載目錄">
                                              <i onClick={e => {
                                                e.stopPropagation();
                                                this.onClickPageTypeDownload(type.getIn(['pageTypeList']).map(pageTypeId => pageTypeId.getIn(['id'])).toJS(), type.getIn(['type']))
                                              }}><FontAwesomeIcon icon={fas.faFileDownload}/></i>
                                            </Tooltip>
                                          </p>
                                          <p>
                                            <Tooltip title="移動目錄">
                                              <i className="action"
                                                 onMouseDown={(e) => this.selectedMovingPageType(type, e)}>
                                                <FontAwesomeIcon icon={fas.faArrowsAlt}/></i>
                                            </Tooltip>
                                          </p>
                                          <p>
                                            <Tooltip title="編輯目錄名稱">
                                              <i className="action" onClick={e => {
                                                e.stopPropagation();
                                                this.onClickPageTypeEdit(type.getIn(['pageTypeList']).toJS().map(pageType => Object.values(pageType)[0]), type.getIn(['type']))
                                              }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                            </Tooltip>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    { // 二層
                                      !isMovingPageType && expandCategorySet.has(`folder-pagetype-${type.getIn(['type'])}-${type.getIn(['folderId'])}`) && type.getIn(['pageTypeList']) && type.getIn(['pageTypeList']).map((pageType, pageTypeIdx) =>
                                        <div key={`type-pagetype-${pageTypeIdx}`}>
                                          <div
                                            className={`system_item system_item_child ${currentHighlight === `currentHighlight-${pageType.getIn(['id'])}` ? "alert-primary" : ""} ${showNewPageType && breakingPageType.pageTypeId === pageType.getIn(['id']) ? "breaking" : ""}`}
                                            onClick={() => {
                                              this.changeCategoryPdf(pageType);
                                              this.toggleCategory(`type-pagetype-${pageType.getIn(['id'])}`);
                                              this.onChangeHighlight(`currentHighlight-${pageType.getIn(['id'])}`);
                                            }}>

                                            <ul
                                              onMouseEnter={() => (isMovingPages && currentMovingPages["id"] !== pageType.getIn(['id'])) ? this.setState({ targetPages: type }) : null}
                                              onMouseLeave={() => isMovingPages ? this.setState({ targetPages: null }) : null}
                                              onMouseUp={(e) => isMovingPages ? this.combinePageToPages(pageType, e) : null}>
                                              <li className="item_number">
                                                <span className="text-primary">{pageType.getIn(['fromPageNo'])}</span>
                                                <span className="text-primary">~</span>
                                                <span className="text-primary">{pageType.getIn(['toPageNo'])}</span>
                                              </li>
                                              {
                                                (pageType.getIn(['tagList']).toJS().length > 0 && !isMovingPages)
                                                &&
                                                <div className="haveItem">
                                                  {
                                                    hasItem.has(`type-pagetype-${pageType.getIn(['id'])}`)
                                                      ?
                                                      <FontAwesomeIcon className="text-primary" icon={fas.faAngleDown}/>
                                                      :
                                                      <FontAwesomeIcon className="text-primary" icon={fas.faAngleRight}/>
                                                  }
                                                </div>
                                              }
                                              <li className="item_number_types">
                                                {pageType.getIn(['fileTitle'])}
                                              </li>
                                              <li className="edit_tool">
                                                {
                                                  pageType.getIn(['fromPageNo']) < pageType.getIn(['toPageNo'])
                                                  &&
                                                  <p>
                                                    <Tooltip title="分割卷宗頁數">
                                                      <i onClick={e => {
                                                        e.stopPropagation();
                                                        this.showActionNewPageType(pageType.getIn(['id']), type.getIn(['type']), pageType.getIn(['fromPageNo']), pageType.getIn(['toPageNo']))
                                                      }}><FontAwesomeIcon icon={fas.faCut}/>
                                                      </i>
                                                    </Tooltip>
                                                  </p>
                                                }
                                                {
                                                  +pageType.getIn(['fromPageNo']) !== 1
                                                  &&
                                                  <p>
                                                    <Tooltip title="刪除此分頁並合併">
                                                      <i onClick={e => {
                                                        e.stopPropagation();
                                                        this.onClickMergePageType(pageType.getIn(['id']),
                                                          `(${pageType.getIn(['fromPageNo'])} ~ ${pageType.getIn(['toPageNo'])}) - ${pageType.getIn(['fileTitle'])}`);
                                                      }}><FontAwesomeIcon icon={fas.faObjectGroup}/></i>
                                                    </Tooltip>
                                                  </p>
                                                }
                                                <p>
                                                  <Tooltip title="移動卷宗">
                                                    <i className="action"
                                                       onMouseDown={(e) => this.selectedMovingPages(type, pageType, e)}>
                                                      <FontAwesomeIcon icon={fas.faArrowsAlt}/></i>
                                                  </Tooltip>
                                                </p>
                                                {
                                                  (showNewPageType && breakingPageType.pageTypeId === pageType.getIn(['id']))
                                                  &&
                                                  <p className="edit_tool_breaking">
                                                    <Tooltip title="取消分割目錄頁數">
                                                      <i onClick={e => {
                                                        e.stopPropagation();
                                                        this.showActionNewPageType(pageType.getIn(['id']), pageType.getIn(['type']), pageType.getIn(['fromPageNo']), pageType.getIn(['toPageNo']))
                                                      }}><FontAwesomeIcon icon={fas.faTimes}/>
                                                      </i>
                                                    </Tooltip>
                                                  </p>
                                                }
                                              </li>
                                            </ul>
                                            {
                                              (showNewPageType && breakingPageType.pageTypeId === pageType.getIn(['id']))
                                              &&
                                              <div className="pt-1 w-100" onClick={(e) => e.stopPropagation()}>
                                                {
                                                  newPageTypeList.map((newPageType, newPageTypeIdx) =>
                                                    <div key={`new-pagetype-${newPageTypeIdx}`} className="form-row">
                                                      <div className="col-md-2">
                                                        <input
                                                          className={'form-control form-control-sm ' + (newPageType.isFromPageNoValidated ? '' : 'page-type-break-input-error')}
                                                          value={newPageType.fromPageNo}
                                                          disabled={newPageTypeIdx === 0}
                                                          onChange={e => this.onChangeNewPageTypeList(newPageTypeIdx, 'fromPageNo', e.target.value)}
                                                        />
                                                      </div>
                                                      <div className="col-md-2">
                                                        <input
                                                          className={'form-control form-control-sm ' + (newPageType.isToPageNoValidated ? '' : 'page-type-break-input-error')}
                                                          type="number"
                                                          value={newPageType.toPageNo}
                                                          disabled={newPageTypeIdx === newPageTypeList.length - 1}
                                                          onChange={e => this.onChangeNewPageTypeList(newPageTypeIdx, 'toPageNo', e.target.value)}
                                                        />
                                                      </div>
                                                      <div className="col-md-7">
                                                        <input
                                                          className={'form-control form-control-sm ' + (newPageType.isPageTypeNameValidated ? '' : 'page-type-break-input-error')}
                                                          type="text"
                                                          value={newPageType.pageTypeName}
                                                          onChange={e => this.onChangeNewPageTypeList(newPageTypeIdx, 'pageTypeName', e.target.value)}
                                                        />
                                                      </div>
                                                      <div className="form-group col-md-1 mt-1">
                                                        <button type="button" className="edit_icon button_icon">
                                                          <i className="action text-danger "
                                                             onClick={() => this.onClickRemoveNewPageType(newPageTypeIdx)}><FontAwesomeIcon
                                                            icon={fas.faMinus}/></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                                <div className="d-flex justify-content-end mt-2">
                                                  <button type="button" className="btn btn-outline-success btn-sm mr-2"
                                                          onClick={() => this.onClickAddNewPageType()}>
                                                    新增分割區塊
                                                  </button>
                                                  <button type="button" className="btn btn-outline-primary btn-sm"
                                                          onClick={() => this.onClickSaveNewPageType()}>
                                                    儲存分割
                                                  </button>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                          { // 三層
                                            !isMovingPages && expandCategorySet.has(`type-pagetype-${pageType.getIn(['id'])}`) && pageType.getIn(['tagList']) && pageType.getIn(['tagList']).map((tag, tagIdx) =>
                                              <div key={`type-tag-${tagIdx}`} className="system_item">
                                                <ul className="last_item"
                                                    onClick={() => {
                                                      this.toggleCategory(`tag-${tag.getIn(['id'])}`, "open");
                                                      this.toggleCategory(`type-pagetype-${type.getIn(['type'])}`, "open");
                                                      document.getElementById("menu-item-tags").childNodes[0].click();
                                                      setTimeout(() => {
                                                        document.getElementById("documents").scrollTop = document.getElementById(`tag-${tag.getIn(['id'])}`).offsetTop;
                                                      }, 500);
                                                    }}>
                                                  <li className="item_number">
                                                    <span className="text-primary">{pageType.getIn(['fromPageNo'])}</span>
                                                    <span className="text-primary">~</span>
                                                    <span className="text-primary">{pageType.getIn(['toPageNo'])}</span>
                                                  </li>
                                                  <li className="item_number_title">
                                                    {tag.getIn(['tag'])}
                                                  </li>
                                                </ul>
                                              </div>
                                            )
                                          }
                                        </div>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                    <div
                      className={`selectedFileItem ${(currentMovingPageType && isMovingPageType) || (currentMovingPages && isMovingPages) ? "display" : ""}`}
                      id="selectedFileItem">
                      <span className="selectedFileItem-icon">
                        {
                          (currentMovingPageType && !currentMovingPages)
                          &&
                          ((targetFileFolder === null && targetFilePageType === null && targetFilePages === null) ?
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faBan}/>
                            :
                            targetFileFolder === "" ?
                              <FontAwesomeIcon className="haveItemIcon" icon={fas.faIndent}/>
                              :
                              <FontAwesomeIcon className="haveItemIcon" icon={fas.faPlus}/>)
                        }
                        {
                          currentMovingPages
                          &&
                          (((targetFileFolder === null || targetFileFolder === "") && targetFilePageType === null && targetFilePages === null) ?
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faBan}/>
                            :
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faPlus}/>)
                        }
                      </span>
                      {
                        currentMovingPageType
                        &&
                        <span className="selectedFileItem-name">{currentMovingPageType["type"]}</span>
                      }
                      {
                        currentMovingPages
                        &&
                        <span
                          className="selectedFileItem-name">{currentMovingPages["fileTitle"]}</span>
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tags">
                    {
                      tagList && tagList.size > 0
                      &&
                      <button type="button" className="btn btn-outline-info text-right btn-sm download_pdf mr-3"
                              onClick={e => {
                                e.stopPropagation();
                                this.onClickTagsDownload(this.props.caseId, this.props.caseName)
                              }}
                      >
                        索引總覽下載
                      </button>
                    }
                    {
                      tagList && tagList.map((tags, tagsIdx) =>
                        <div key={`tag-${tagsIdx}`} className="tag-wrapper" id={`tag-${tags.id}`}>
                          <div className="system_item tag_item">
                            {
                              tags.pageTypeList.size > 0
                              &&
                              <div className="haveItem">
                                {
                                  hasItem.has(`tag-${tags.id}`)
                                    ?
                                    <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleDown}/>
                                    :
                                    <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleRight}/>
                                }
                              </div>
                            }
                            <ul>
                              <li className="item_title"
                                  onClick={() => this.toggleCategory(`tag-${tags.id}`)}>
                                {tags.tag}
                              </li>
                              <li className="edit_tool">
                                <p>
                                  <Tooltip title="編輯索引名稱">
                                    <i className="action" onClick={e => {
                                      e.stopPropagation();
                                      this.onClickTagEdit(tags.id, tags.tag)
                                    }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                  </Tooltip>
                                </p>
                              </li>
                            </ul>
                          </div>
                          { // 二層
                            expandCategorySet.has(`tag-${tags.id}`) &&
                            tags.pageTypeList && Object.keys(tags.pageTypeList.toJS()).map((pageType, pageTypeIdx) =>
                              <div className="pageType_wrapper" key={`tag-pagetype-${pageTypeIdx}`}>
                                <div
                                  className={`system_item system_child ${currentHighlight === `currentHighlight-${pageType.id}` ? "alert-primary" : ""} `}
                                  onClick={() => {
                                    this.toggleCategory(`type-pagetype-${pageType}`);
                                    this.onChangeHighlight(`currentHighlight-${pageType}`);
                                  }}>
                                  <ul>
                                    {
                                      ((tags.pageTypeList.get(pageType)).toJS().length > 0 && !isMovingPages)
                                      &&
                                      <div className="haveItem">
                                        {
                                          hasItem.has(`type-pagetype-${pageType}`)
                                            ?
                                            <FontAwesomeIcon className="text-primary" icon={fas.faAngleDown}/>
                                            :
                                            <FontAwesomeIcon className="text-primary" icon={fas.faAngleRight}/>
                                        }
                                      </div>
                                    }
                                    <li className="pageType_title">
                                      {pageType}
                                    </li>
                                    <li className="migrate_edit_tool">
                                      <p>
                                        <Tooltip title="編輯目錄名稱">
                                          <i onClick={e => {
                                            e.stopPropagation();
                                            this.onClickPageTypeEdit((tags.pageTypeList.get(pageType)).map((tag) => tag.get("id")).toJS(), pageType);
                                          }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                        </Tooltip>
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                                { // 三層
                                  expandCategorySet.has(`type-pagetype-${pageType}`) &&
                                  tags.pageTypeList.get(pageType) && tags.pageTypeList.get(pageType).map((tag, tagIdx) =>
                                    <div key={`tag-${tagIdx}`}>
                                      <div
                                        className={`system_item system_child ${currentHighlight === `currentHighlight-${tag.get("id")}` ? "alert-primary" : ""} `}
                                        onClick={() => {
                                          this.changeCategoryPdf(tag);
                                          this.onChangeHighlight(`currentHighlight-${tag.get("id")}`)
                                        }}>
                                        <ul>
                                          <li className="item_number">
                                            <span className="text-primary">{tag.get("fromPageNo")}</span>
                                            <span className="text-primary">~</span>
                                            <span className="text-primary">{tag.get("toPageNo")}</span>
                                          </li>
                                          <li className="item_migrate_title">
                                            <div>{tag.get("fileTitle")}</div>
                                          </li>
                                          <li className="migrate_edit_tool">
                                            {
                                              +tag.get("fromPageNo") !== 1
                                              &&
                                              <p>
                                                <Tooltip title="刪除此分頁並合併">
                                                  <i onClick={e => {
                                                    e.stopPropagation();
                                                    this.onClickMergePageType(tag.get("id"),
                                                      `(${tag.get("fromPageNo")} ~ ${tag.get("toPageNo")}) - ${tag.get("fileTitle")}`);
                                                  }}><FontAwesomeIcon icon={fas.faObjectGroup}/></i>
                                                </Tooltip>
                                              </p>
                                            }
                                            <p>
                                              <Tooltip title="編輯此分頁之目錄名稱">
                                                <i onClick={e => {
                                                  e.stopPropagation();
                                                  this.onClickPageTypeEdit([tag.get("id")], pageType)
                                                }}><FontAwesomeIcon icon={fas.faPencilAlt}/></i>
                                              </Tooltip>
                                            </p>
                                            <p>
                                              <Tooltip title="編輯此分頁之索引名稱">
                                                <i onClick={e => {
                                                  e.stopPropagation();
                                                  this.onClickMigrateEdit(tag.get("id"), tags.id)
                                                }}><FontAwesomeIcon icon={fas.faUserEdit}/></i>
                                              </Tooltip>
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </Tab.Pane>
                  <Tab.Pane eventKey="keywords">
                    <button type="button" className="btn btn-outline-info text-right btn-sm add_keyword mr-3"
                            onClick={e => {
                              e.stopPropagation();
                              this.onClickAddKeyword(this.props.caseId)
                            }}
                    >
                      增加關鍵字
                    </button>
                    {
                      this.props.keywordList && this.props.keywordList.map((keyword, keywordIdx) =>
                        <div key={`keyword-${keywordIdx}`} className="keyword-wrapper">
                          <div className="system_item">
                            {
                              keyword.getIn(['fileList']).toJS().length > 0
                              &&
                              <div className="haveItem">
                                {
                                  hasItem.has(`keyword-${keyword.getIn(['id'])}`)
                                    ?
                                    <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleDown}/>
                                    :
                                    <FontAwesomeIcon className="haveItemIcon" icon={fas.faAngleRight}/>
                                }
                              </div>
                            }
                            <ul>
                              <li className="item_title"
                                  onClick={() => this.toggleCategory(`keyword-${keyword.getIn(['id'])}`)}>
                                {keyword.getIn(['keyword'])}
                              </li>
                            </ul>
                          </div>
                          { // 二層
                            expandCategorySet.has(`keyword-${keyword.getIn(['id'])}`) && keyword.getIn(['fileList']) && keyword.getIn(['fileList']).map((file, idx) =>
                              <div key={`keyword-file-${idx}`}>
                                <div className="system_item">
                                  <ul className="ul_col"
                                      onClick={() => {
                                        this.props.onChangeCategoryPdf(file.getIn(['pageIdList']).toJS());
                                        this.setState({ currentFileTypeId: 0, isChangePage: false, currentPageType: file });
                                      }}>
                                    <li className="text-primary pageNos">{file.getIn(['pageNos'])}</li>
                                    <li>{file.getIn(['title'])}</li>
                                  </ul>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </div>
          <div className="active-bar" onClick={() => this.setState({ showDocumentBlock: !showDocumentBlock })}>
            {
              showDocumentBlock ?
                <FontAwesomeIcon className="active-bar-mark" icon={fas.faAngleLeft}/>
                :
                <img className="logo" alt="" src={dnaLogoIcon}/>
            }
          </div>
        </div>
        <div className="view_block section" id="top_bar">
          <div className="view_tool">
            {
              (this.props.pdfPageListSize > 0 && this.props.isLabelPdf === false)
              &&
              <>
                <div className="page-detail">
                  {
                    currentFileTypeId !== 0
                    &&
                    <>
                      <p className="page-detail-title">{fileDict[pageTypeDict[currentFileTypeId].fileId].fileName}</p>
                      <p className="page-detail-subtitle">{`-${pageTypeDict[currentFileTypeId].type}-`}</p>
                    </>
                  }
                </div>
                <div className="btn-label-wrapper">
                  <button type="button"
                          className="btn btn-outline-primary"
                          onClick={() => this.setState({ showAddPagesToLabelForm: true, isAddingRangeToLabel: true, addToLabelId: '' })}>
                    加本區段頁
                  </button>
                  <button type="button"
                          className="btn btn-outline-info"
                          onClick={() => this.setState({ showAddPagesToLabelForm: true, isAddingRangeToLabel: false, addToLabelId: '' })}>
                    加本頁
                  </button>
                </div>
              </>
            }
          </div>
          <div className="pdf-view-container">
            {
              (this.props.pdfPageListSize > 0 && currentFileTypeId !== 0 && !this.props.isLabelPdf)
              &&
              <div className="page-control-wrapper">
                <i className="icon_tool" onClick={() => this.onClickPageUp()}>
                  <FontAwesomeIcon icon={fas.faAngleLeft}/>
                  <span>{`${currentPageIndex === 1 ? "上一章" : "上一頁"}`}</span>
                </i>
                <i className="icon_tool" onClick={() => this.onClickPageDown()}>
                  <span>{`${currentPageIndex === pageTypeDict[currentFileTypeId].filePageCount ? "下一章" : "下一頁"}`}</span>
                  <FontAwesomeIcon icon={fas.faAngleRight}/></i>
              </div>
            }
            <div className="doc-search-section">
              {
                showSearchForm
                  ?
                  <div className="doc-search-box">
                    <form className={'doc-search-form' + (this.props.hitList.length > 0 ? ' after-search' : '')} onSubmit={e => {
                      e.preventDefault();
                      this.props.onSubmitSearch(this.props.caseId, searchWord)
                    }}>
                      <input
                        className="search-word"
                        type="text"
                        ref={input => {
                          this.searchWordRef = input
                        }}
                        value={searchWord}
                        onChange={e => this.setState({ searchWord: e.target.value })}
                      />
                      <button type="submit" className="action-box search-btn"><FontAwesomeIcon className="action-icon" icon={fas.faSearch}/></button>
                      <i className="action-box cancel-btn" onClick={() => {
                        this.setState({ searchWord: '' });
                        this.props.onCleanSearchResult()
                      }}>
                        <FontAwesomeIcon className="action-icon" icon={fas.faTimesCircle}/>
                      </i>
                      <i className="action-box hide-btn" onClick={() => this.setState({ showSearchForm: false })}>
                        <FontAwesomeIcon className="action-icon"
                                         icon={fas.faAngleDoubleRight}/></i>
                    </form>
                    {
                      this.props.hitList.length > 0
                      &&
                      <div className="doc-search-result">
                        {
                          this.props.hitList.map((hit, idx) =>
                            <div key={`search-result-${idx}`}
                                 className="search-result-item"
                                 onClick={() => {
                                   this.setState({ currentPageType: fromJS(hit) });
                                   this.props.onChangeCategoryPdf(hit.pages.map(page => page.id));
                                   this.onChangeHighlight(`currentHighlight-${idx}`);
                                 }}>
                              <p className={'result-title' + (currentHighlight === `currentHighlight-${idx}` ? ' highlight' : '')}>{hit.fileTitle}</p>
                              <p className="result-pages">{hit.pages.map(page => page.no).join(', ')}</p>
                            </div>
                          )
                        }
                      </div>
                    }
                  </div>
                  :
                  <i className="big-search-btn"
                     onClick={() => this.setState({ showSearchForm: true }, () => this.searchWordRef.focus())}><FontAwesomeIcon
                    className="search-icon" icon={fas.faSearch}/></i>
              }
            </div>
            <pdf.container
              caseId={this.props.caseId}
              highlights={highlights}
              highlightList={highlightList}
              updateHighLights={updateHighLights}
              switchView={switchView}
            />
          </div>
          {
            (this.props.pdfPageListSize > 0)
            &&
            <div className="view-bottom">
              <div className="pages-wrapper">
                {
                  (currentFileTypeId !== 0 && !this.props.isLabelPdf) ?
                    <div className="pages-controls-wrapper">
                      <i className={currentPageIndex === 1 ? "pages-section-mark" : ""}
                         onClick={() => this.onClickPageUp()}>
                        <FontAwesomeIcon icon={fas.faAngleLeft} size="2x" className="icon_tool"/>
                        <span className="step-text">{`${currentPageIndex === 1 ? "上一章" : "上一頁"}`}</span>
                      </i>
                      <div className="pages-controls">
                        <input
                          className="pages-input"
                          type="text"
                          value={currentPageIndex} required
                          onChange={(event) => this.onChangePageIndex(event.target.value)}
                        />
                        <span>{` /  ${pageTypeDict[currentFileTypeId].filePageCount}`}</span>
                        {
                          isChangePage && currentPageIndex !== this.props.currentPdfPage.getIn(['pageNo'])
                          &&
                          <button type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() => this.onClickPageGo()}>跳頁
                          </button>
                        }
                      </div>
                      <i className={currentPageIndex === pageTypeDict[currentFileTypeId].filePageCount ? "pages-section-mark" : ""}
                         onClick={() => this.onClickPageDown()}>
                        <span className="step-text">{`${currentPageIndex === pageTypeDict[currentFileTypeId].filePageCount ? "下一章" : "下一頁"}`}</span>
                        <FontAwesomeIcon icon={fas.faAngleRight} size="2x" className="icon_tool"/></i>
                    </div>
                    :
                    <div className="pages-single-wrapper">
                      <i className={currentPageIndex === 1 ? "disabled" : ""}
                         onClick={() => currentPageIndex > 1 ? this.onClickPageUp() : null}>
                        <FontAwesomeIcon icon={fas.faAngleLeft} size="2x" className="icon_tool"/>
                      </i>
                      {
                        this.props.isLabelPdf ?
                          <span>{`${currentPageIndex}/${this.props.pdfPageListSize}`}</span>
                          :
                          <span>{`頁碼${this.props.currentPdfPage.getIn(['pageNo'])} / 共${this.props.pdfPageListSize}頁`}</span>
                      }
                      <i className={currentPageIndex === this.props.pdfPageListSize ? "disabled" : ""}
                         onClick={() => currentPageIndex !== this.props.pdfPageListSize ? this.onClickPageDown() : null}>
                        <FontAwesomeIcon icon={fas.faAngleRight} size="2x" className="icon_tool"/>
                      </i>
                    </div>
                }
              </div>
              <div className="pdf-scare-wrapper">
                <i className="pdf-scare-icon" onClick={() => this.onChangePageScale("plus")}>
                  <FontAwesomeIcon icon={fas.faPlus}/>
                </i>
                <span className="pdf-scare-text">{`${Math.round(currentScale * 100)}%`}</span>
                <i className="pdf-scare-icon" onClick={() => this.onChangePageScale("minus")}>
                  <FontAwesomeIcon icon={fas.faMinus}/>
                </i>
              </div>
              <div className="switch-wrapper">
                <p className={`switch-item ${switchView === "pdf" ? "active" : ""}`}
                   onClick={() => this.setState({ switchView: "pdf" })}>PDF</p>
                <p className={`switch-item ${switchView === "text" ? "active" : ""}`}
                   onClick={() => this.setState({ switchView: "text" })}>TEXT</p>
                <span className={`switch-active ${switchView === "pdf" ? "pdf-view" : "text-view"}`}/>
              </div>
            </div>
          }
        </div>
        <div className={`section ${showRightBarBlock ? "open" : ""}`} id="right_bar">
          <div className={showLabelBlock ? "right-item-wrapper open" : "right-item-wrapper"} id="label-wrapper">
            <div className="right-item-title"
                 onClick={() => this.setState({ showLabelBlock: !showLabelBlock })}>
              <div className="title-wrapper">
                <FontAwesomeIcon className="title-active-mark" icon={showLabelBlock ? fas.faAngleDown : fas.faAngleRight}/>
                <span className="title-name">自訂標籤列</span>
              </div>
              <div className="tool-wrapper">
                <button type="button"
                        className="btn btn-outline-info"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({ showLabelForm: true, editingLabelName: '' })
                        }}>新增標籤
                </button>
              </div>
            </div>
            <div className="right-item-content" id="label_Content">
              {
                this.props.labelIdList && this.props.labelIdList.map(labelId =>
                  <div className="label-item" key={`label_${labelId}`} id={`label_${labelId}`}>
                    <div>
                      <div
                        className={`label-item-title d-flex justify-content-between ${currentHighlight === `label-${labelId}` ? "alert-primary" : ""}`}
                        onClick={() => this.onChangeHighlight(`label-${labelId}`)}>
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-form-label col-form-label-sm"
                          onClick={() => {
                            this.props.onChangeLabelPdf(labelId);
                            this.setState({ currentPageIndex: 1 });
                          }}>
                          <i className="mr-2"><FontAwesomeIcon icon={fas.faTag}/></i>
                          <span>{this.props.labels.getIn([labelId, 'name'])}</span>
                        </label>
                        <div>
                          <Tooltip title="加入資料夾">
                            <i className="label-userfolder" onClick={e => {
                              e.stopPropagation();
                              this.setState({ showCopyLabelPagesToFolder: true, currentLabelId: labelId, labelToCaseId: '', labelToUserFolderId: '' })
                            }}><FontAwesomeIcon icon={fas.faFileExport}/></i>
                          </Tooltip>
                          <Tooltip title="下載標籤">
                            <i className="label-download" onClick={e => {
                              e.stopPropagation();
                              this.onClickLabelDownload(labelId, this.props.labels.getIn([labelId, 'name']));
                            }}><FontAwesomeIcon icon={fas.faFileDownload}/></i>
                          </Tooltip>
                          <Tooltip title="刪除標籤">
                            <i className="label-delete" onClick={e => {
                              e.stopPropagation();
                              this.props.onDeleteLabel(this.props.caseId, labelId);
                            }}><FontAwesomeIcon icon={fas.faTrashAlt}/></i>
                          </Tooltip>
                        </div>
                      </div>
                      <ul className="label-item-content">
                        {
                          this.props.labels.getIn([labelId, 'files']) && this.props.labels.getIn([labelId, 'files']).entrySeq().map(([fileId, file]) =>
                            <li key={`label-file-${fileId}`} className="d-flex justify-content-between">
                              <span>{`${file.getIn(['pageNos'])} ${file.getIn(['title'])} `}</span>
                              <i onClick={() => this.props.onRemoveLabelPage(this.props.caseId, labelId, fileId)}><FontAwesomeIcon
                                icon={fas.faTimes}/></i>
                            </li>
                          )
                        }
                        <div className="d-flex label-item-note">
                        <textarea
                          className="form-control form-control-sm"
                          id="colFormLabelSm"
                          rows="2"
                          placeholder="標籤備註"
                          value={this.props.labels.getIn([labelId, 'note'])}
                          onChange={e => {
                            this.onChangeLabelNote('add', labelId);
                            this.props.onChangeLabel(labelId, 'note', e.target.value)
                          }}>
                        </textarea>
                          {
                            modifiedNoteLabelIdSet.has(labelId)
                            &&
                            <i onClick={() => {
                              this.props.onSaveLabelNote(labelId);
                              this.onChangeLabelNote('remove', labelId)
                            }}><FontAwesomeIcon icon={far.faSave} size="2x" className="ml-2 text-success"/></i>
                          }
                        </div>
                      </ul>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          <reviewNotes.container
            visible={showNotes}
            pageId={this.props.pageId}
            onToggleNotes={this.onToggleNotes}
          />

          {/* <div className={showHighLightBlock ? "right-item-wrapper sidebar open" : "right-item-wrapper sidebar"} id="highlight-wrapper">
            <div className="right-item-title"
                 onClick={() => this.setState({ showHighLightBlock: !showHighLightBlock })}>
              <div className="title-wrapper">
                <FontAwesomeIcon className="title-active-mark" icon={showHighLightBlock ? fas.faAngleDown : fas.faAngleRight}/>
                <span className="title-name">卷宗頁標籤</span>
              </div>
              {
                (highlights && highlights.length > 0)
                &&
                <div className="tool-wrapper">
                  <button type="button"
                          className="btn btn-outline-info"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.resetHighlights();
                          }}>重新設定Highlights
                  </button>
                </div>
              }
            </div>
            <ul className="sidebar-highlights right-item-content">
              {
                highlightDict && Object.keys(highlightDict.toJS()).map((highlights, index) =>
                  <li
                    key={index}
                    className="sidebar-highlight">
                    <p className="highlights-title">{highlightDict.getIn([highlights, 0, "fileTitle"])}</p>
                    {
                      highlightDict.get(highlights).map((highlight, highlightIdx) =>
                        <div className="highlight-wrapper"
                             key={highlightIdx}>
                          {
                            highlight.get("highlight").map((item, itemIdx) =>
                              <div key={itemIdx}
                                   className="highlight-content-wrapper"
                                   onClick={() => this.onClickGotoHighlight(item, highlight)}>
                                <p className="highlight-pageindex">
                                  {`頁碼 ${highlight.get("pageNo")}`}
                                </p>
                                <p className="highlight-content">{item.getIn(["comment", "text"])}</p>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </li>
                )
              }
            </ul>
          </div>
          <div className={showDuplicateBlock ? "right-item-wrapper open" : "right-item-wrapper"} id="duplicate-wrapper">
            <div className="right-item-title"
                 onClick={() => this.setState({ showDuplicateBlock: !showDuplicateBlock })}>
              <div className="title-wrapper">
                <FontAwesomeIcon className="title-active-mark" icon={showDuplicateBlock ? fas.faAngleDown : fas.faAngleRight}/>
                <span className="title-name">與此頁相似之文件</span>
                {
                  (this.props.duplicateList && this.props.duplicateList.length > 0)
                  &&
                  <span className="title-number">{this.props.duplicateList.length}</span>
                }
              </div>
            </div>
            <div className="right-item-content">
              {
                this.props.duplicateList.length !== 0
                  ?
                  this.props.duplicateList && this.props.duplicateList.map((duplicate, idx) =>
                    <ul key={`duplicate-${idx}`}>
                      <li className={currentHighlight === `currentHighlight-${duplicate.pageId}` ? "alert-primary" : ""}
                          onClick={() => {
                            this.props.onChangeCategoryPdf([duplicate.pageId]);
                            this.onChangeHighlight(`currentHighlight-${duplicate.pageId}`)
                          }}>{`第 ${duplicate.pageNo}頁 ${duplicate.fileTitle} (${duplicate.similarity}%)`}</li>
                    </ul>
                  )
                  :
                  <div className="duplicateList">本頁無重複頁面</div>
              }
            </div>
          </div> */}
          <div className="active-bar" onClick={() => this.setState({ showRightBarBlock: !showRightBarBlock })}>
            <FontAwesomeIcon className="active-bar-mark" icon={showRightBarBlock ? fas.faAngleRight : fas.faTools}/>
          </div>
        </div>
        {
          showLabelForm
          &&
          <div className={'label-form modalbox ' + (showLabelForm ? 'show' : '')}>
            <form className="modalbox-content" onSubmit={e => {
              e.preventDefault();
              this.props.onSubmitLabel(this.props.caseId, editingLabelName)
            }}>
              <div>
                <label htmlFor="labels-label-name">標籤：</label>
                <input
                  id="labels-label-name"
                  className="modalbox-content-input"
                  type="text"
                  value={editingLabelName}
                  onChange={e => this.setState({ editingLabelName: e.target.value })}
                  required
                />
              </div>
              <div className="col-item-tool">
                <button className="btn-add" type="submit" disabled={this.props.isPosting}>新增</button>
                <button className="btn-cancel" type="button" onClick={() => this.setState({ showLabelForm: false })}>關閉</button>
              </div>
            </form>
          </div>
        }
        {
          showCopyLabelPagesToFolder
          &&
          <div className={'label-form modalbox ' + (showCopyLabelPagesToFolder ? 'show' : '')}>
            <form className="modalbox-content" onSubmit={e => {
              e.preventDefault();
              this.props.onSubmitCopyLabelToFolder(labelToUserFolderId, currentLabelId)
            }}>
              <div>
                <div className="form-item">
                  <label className="item-title" htmlFor="label-userfolder-userfolder">資料夾：</label>
                  <select
                    id="label-userfolder-userfolder"
                    className="modalbox-content-input fix_focus_shadow"
                    value={labelToUserFolderId}
                    onChange={e => this.setState({ labelToUserFolderId: e.target.value })}
                    required
                  >
                    <option value="" disabled>請選擇</option>
                    {
                      this.props.userFolderList && this.props.userFolderList.map(userFolder =>
                        <option key={`label-userfolder-userfolder-${userFolder.getIn(['id'])}`} value={userFolder.getIn(['id'])}>{ userFolder.getIn(['name']) }</option>
                      )
                    }
                  </select>
                </div>
              </div>
              <div className="col-item-tool">
                <button className="btn-add" type="submit" disabled={this.props.isPosting}>加入</button>
                <button className="btn-cancel" type="button" onClick={() => this.setState({ showCopyLabelPagesToFolder: false })}>關閉</button>
              </div>
            </form>
          </div>
        }

        {
          showAddPagesToLabelForm
          &&
          <div className={'label-form modalbox ' + (showAddPagesToLabelForm ? 'show' : '')}>
            <form className="modalbox-content" onSubmit={e => {
              e.preventDefault();
              this.props.onSubmitAddPagesToLabel(this.props.caseId, isAddingRangeToLabel, addToLabelId)
            }}>
              <div>
                <label htmlFor="pages-label-selector">標籤：</label>
                <select
                  id="pages-label-selector"
                  className="modalbox-content-input fix_focus_shadow"
                  value={addToLabelId}
                  onChange={e => this.setState({ addToLabelId: e.target.value })}
                  required
                >
                  {
                    addToLabelId === ''
                    &&
                    <option value="" disabled>請選擇</option>
                  }
                  {
                    this.props.labelIdList && this.props.labelIdList.map(labelId =>
                      <option key={`pages-label-${labelId}`} value={labelId}>{this.props.labels.getIn([labelId, 'name'])}</option>
                    )
                  }
                </select>
              </div>
              <div className="col-item-tool">
                <button className="btn-add" type="submit" disabled={this.props.isPosting}>加入</button>
                <button className="btn-cancel" type="button" onClick={() => this.setState({ showAddPagesToLabelForm: false })}>關閉
                </button>
              </div>
            </form>
          </div>
        }
        {
          showUploadFileForm
          &&
          <div className={'upload-feils modalbox ' + (showUploadFileForm ? 'show' : '')}>
            {
              this.props.isPosting
                ?
                <Spinner/>
                :
                <form className="modalbox-content" onSubmit={e => {
                  e.preventDefault();
                  this.onClickSubmitUploadFiles(this.props.caseId, uploadFileList)
                }}>
                  <Upload {...UploadProps}>
                    <Button>
                      <i className="mr-1"><FontAwesomeIcon icon={fas.faFileUpload}/></i>選擇檔案
                    </Button>
                  </Upload>
                  <div className="col-item-tool">
                    <button className="btn-add" type="submit" disabled={this.props.isPosting}>新增</button>
                    <button className="btn-cancel" type="button" onClick={() => this.setState({ showUploadFileForm: false })}>關閉</button>
                  </div>
                  {
                    this.props.errMsg
                    &&
                    <div className="err-msg">{this.props.errMsg}</div>
                  }
                </form>
            }
          </div>
        }
        {
          (this.props.isDownloading || this.props.isGettingCaseCategory)
          &&
          <FullSpinner/>
        }
      </div>
    )
  }
}

export default withRouter(DocComponent);
