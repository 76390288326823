import React, { Component } from 'react';
import login from '../modules/login';


class LoginPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-login">
        <login.container />
	    </div>
	  )
  }
}

export default LoginPageComponent;
