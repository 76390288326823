import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'exportConsultations/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'exportConsultations/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'exportConsultations/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'exportConsultations/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'exportConsultations/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'exportConsultations/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'exportConsultations/UPDATE_CASE_RELATE_LIST': (state, { payload }) => {
    if (payload.length === 0 ) {
      return state.setIn(['isFetching'], false);
    } else {
      return state.setIn(['caseRelateList'],  fromJS(payload));
    }
  },
}, State);

export default Reducer;