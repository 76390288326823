import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import Immutable from 'immutable';
import rootReducer from './rootReducer'

const initialState = Immutable.fromJS();
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk),
);

export default store

