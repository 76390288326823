import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message } from 'antd';
import Spinner from '../../utils/Spinner';
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import dnaBgIcon from '../../assets/img/dna-bg.png'


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        message.success('信件已寄出，請從信中連結進行密碼重設');
        this.props.history.push(`/login`);
      }
    }
  }
  render() {
    const {
      email
    } = this.state;

    return (
      <div className="login-section">  
        <img className="login-logo-bg" alt="" src={dnaBgIcon}/>
        <img className="login-logo" alt="" src={dnaLogoIcon}/>
        <h2 className="mt-3">忘記密碼</h2>
        {
          this.props.isPosting
          ?
            <Spinner />
          :
            <form className="login-form" onSubmit={e => {e.preventDefault();this.props.onSubmitForgotPassword(email)}}>
              <label className="login-form-label text-center" htmlFor="forget-email">請輸入您的E-mail<br/>系統將會寄送確認信給您</label>
              <input className="p-2"
                id="forget-email"
                type="email" 
                placeholder="user@domain.com" 
                value={email} 
                onChange={e => this.setState({ email: e.target.value })}
                required 
              />
              <div className="login-form-bottom">
                <button type="submit" className="btn btn-primary w-100 mb-3">繼續</button>
                <Link to={`/login`}>
                  <button type="button" className="btn btn-outline-primary w-100">返回登入頁</button>
                </Link>
              </div>
              {
                this.props.errMsg
                &&
                <p className="err-msg">{this.props.errMsg}</p>
              }
            </form>
        }
      </div>
    )
  }
}

export default withRouter(ForgotPasswordComponent);