export const GET_REQUEST = 'profile/GET_REQUEST'
export const GET_SUCCESS = 'profile/GET_SUCCESS'
export const GET_FAILURE = 'profile/GET_FAILURE'
export const PUT_REQUEST = 'profile/PUT_REQUEST'
export const PUT_SUCCESS = 'profile/PUT_SUCCESS'
export const PUT_FAILURE = 'profile/PUT_FAILURE'
export const POST_REQUEST = 'profile/POST_REQUEST'
export const POST_SUCCESS = 'profile/POST_SUCCESS'
export const POST_FAILURE = 'profile/POST_FAILURE'

export const UPDATE_PROFILE = 'profile/UPDATE_PROFILE';

