import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ConsultationCaseComponent from './component';


const mapStateToProps = (state) => ({
  isPutting: selectors.getIsPutting(state),
  errMsg: selectors.getErrMsg(state),
  isGettingCaseRelated: selectors.getIsGettingCaseRelated(state),
  caseRelateList: selectors.getCaseRelateList(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateCase: (consultationId, typeId, statusId, relatedList, plaintiffList, defendantList, consultationContent, consultationNote) => {
    dispatch(actions.doUpdateCase(consultationId, typeId, statusId, relatedList, plaintiffList, defendantList, consultationContent, consultationNote));
  },
  onLoadRelatedList: (contain, size) => {
    dispatch(actions.doLoadRelatedList(contain, size));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationCaseComponent);