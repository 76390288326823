import { createSelector } from 'reselect';

// common
export const getIsPosting = state => state.getIn(['doc', 'isPosting']);
export const getIsPutting = state => state.getIn(['doc', 'isPutting']);
export const getIsDeleting = state => state.getIn(['doc', 'isDeleting']);
export const getErrMsg = state => state.getIn(['doc', 'errMsg']);

export const getIsExceededQuota = state => state.getIn(['doc', 'isExceededQuota']);
export const getEncryptedPdfNameList = state => state.getIn(['doc', 'encryptedPdfNameList']);
// case
export const getCaseName = state => state.getIn(['doc', 'caseName']);
export const getIsLoading = state => state.getIn(['doc', 'isLoading']);
// category
export const getFileList = state => state.getIn(['doc', 'fileList']);
export const getTypeList = state => state.getIn(['doc', 'typeList']);
export const getTagList = state => state.getIn(['doc', 'tagList']);
export const getFileFolderList = state => state.getIn(['doc', 'fileFolderList']);
export const getPageTypeFolderList = state => state.getIn(['doc', 'pageTypeFolderList']);
export const getKeywordList = state => state.getIn(['doc', 'keywordList']);
export const getIsDownloading = state => state.getIn(['doc', 'isDownloading']);
export const getIsGettingCaseCategory = state => state.getIn(['doc', 'isGettingCaseCategory']);
// label
export const getCaseList = state => state.getIn(['doc', 'caseList']);
export const getUserFolderList = state => state.getIn(['doc', 'userFolderList']);
export const getLabelIdLIst = state => state.getIn(['doc', 'labelIdList']);
export const getLabels = state => state.getIn(['doc', 'labels']);
// pdf
export const getIsLabelPdf = state => state.getIn(['doc', 'isLabelPdf']);
export const getPdfPageIndex = state => state.getIn(['doc', 'pdfPageIndex']);
export const getPdfPageList = state => state.getIn(['doc', 'pdfPageList']);
export const getPdfPageListSize = createSelector(
  [getPdfPageList],
  (pdfPageList) => {
    return pdfPageList.size
  }
);
// pageDuplicates
export const getDuplicates = state => state.getIn(['doc', 'duplicates']);
export const getFileNames = state => state.getIn(['doc', 'fileNames']);
export const getDuplicateList = createSelector(
  [getPdfPageList, getPdfPageIndex, getDuplicates, getFileNames],
  (pdfPageList, pdfPageIndex, duplicates, fileNames) => {
    const duplicateList = [];
    const pageId = `${pdfPageList.getIn([pdfPageIndex, 'pageId'])}`;
    if (duplicates.hasIn([pageId])) {
      duplicates.getIn([pageId]).forEach(duplicate =>
        duplicateList.push({
          'fileTitle': fileNames.getIn([`${duplicate.getIn(['fileId'])}`]),
          'pageId': duplicate.getIn(['pageId']),
          'pageNo': duplicate.getIn(['pageNo']),
          'similarity': duplicate.getIn(['similarity'])
        })
      );
    }
    return duplicateList;
  }
)
export const getCurrentPdfPage = createSelector(
  [getPdfPageList, getPdfPageIndex],
  (pdfPageList, pdfPageIndex) => {
    return pdfPageList.getIn([pdfPageIndex]);
  }
);

// seaerch
export const getHitList = state => state.getIn(['doc', 'hitList'])?state.getIn(['doc', 'hitList']):[];

// Highlight
export const getHighlightList = state => state.getIn(['doc', 'highlightList']);
export const getHighlightDict = state => state.getIn(['doc', 'highlightDict']);

export const getPageId = createSelector(
  [getPdfPageList, getPdfPageIndex],
  (pdfPageList, pdfPageIndex) => pdfPageList.getIn([pdfPageIndex, 'pageId'])
)