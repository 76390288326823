import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  isExceededUploadQuota,
  uploadUserFile,
  moveUserFile,
  downloadUserFile,
  downloadUserAllFile,
  deleteUserFile,
  updateUserFileName,
  postBackupUserFile,
  getOauthUrl,
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)
export const downloadRequest = createAction(types.DOWNLOAD_REQUEST);
export const downloadSuccess = createAction(types.DOWNLOAD_SUCCESS);
export const downloadFailure = createAction(types.DOWNLOAD_FAILURE);
export const backupRequest = createAction(types.BACKUP_REQUEST);
export const backupSuccess = createAction(types.BACKUP_SUCCESS);
export const backupFailure = createAction(types.BACKUP_FAILURE);

export const updateIsExceededQuota = createAction(types.UPDATE_IS_EXCEEDED_QUOTA)
export function doUploadUserFile(appointmentId, userFolderId, fileList) {
  return dispatch => {
    dispatch(postRequest());
    const totalFileSize = fileList.map(f => f.size).reduce((a, b) => a + b, 0)
    const userId = localStorage.getItem('userId');
    isExceededUploadQuota(userId, totalFileSize).then(data => {
      if (data.isExceeded === false) {
        uploadUserFile(appointmentId, userFolderId, fileList).then(data => {
          dispatch(updateIsExceededQuota(false));
          dispatch(postSuccess());
        }).catch((errMsg) => {
          console.log("doUploadAttachments:", errMsg);
          dispatch(postFailure({ errMsg }));
        })
      } else {
        dispatch(updateIsExceededQuota(true));
        dispatch(postSuccess());
      }
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}


export function doMoveUserFile(userFolderId, userFileId) {
  return dispatch => {
    dispatch(putRequest());
    moveUserFile(userFolderId, userFileId).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDownloadUserFile(userFileId, userFileName) {
  return dispatch => {
    dispatch(getRequest());
    downloadUserFile(userFileId, userFileName).then(data => {
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doDownloadUserAllFile(appointmentId) {
  return dispatch => {
    dispatch(downloadRequest());
    downloadUserAllFile(appointmentId).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg }));
    })
  }
}

export function doDeleteUserFile(userFileId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteUserFile(userFileId).then(data => {
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doSubmitUpdateFileName(fileId, name) {
  return dispatch => {
    dispatch(putRequest());
    updateUserFileName(fileId, name).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDirectToOauthPage(appointmentId) {
  return () => {
    getOauthUrl(appointmentId).then(result => {
      window.location.href = result
    }).catch((errMsg) => {
      console.log(errMsg)
    })
  }
}


export function doBackupUserFile(caseId, code) {
  return dispatch => {
    dispatch(backupRequest());
    postBackupUserFile(caseId, code).then(data => {
      dispatch(backupSuccess());
    }).catch((errMsg) => {
      dispatch(backupFailure({ errMsg }));
    })
  }
}