import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { Select, Upload, Icon, message, Tooltip, Spin, Input, InputNumber, DatePicker, Tabs } from 'antd';
import { Form, Button } from 'react-bootstrap';
import Spinner from '../../utils/Spinner';
import mailroomReceivedList from '../mailroomReceivedList';
import mailroomSentList from '../mailroomSentList';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = 'YYYY/MM/DD';
const emptyEditingReceived = {
  name: '',
  docketId: '',
  typeId: '',
  sender: '',
  custodianName: '',
  receivedDate: null,
  sentDate: null,
  expiredDays: 0
}

const emptyEditingSent = {
  name: '',
  docketId: '',
  typeId: '',
  recipient: '',
  custodianName: '',
  receivedDate: null,
  sentDate: null,
  deliveryTypeId: ''
}


class MailroomComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'get',
      editingFileList: [],
      showReceivedEditor: false,
      editingReceived: JSON.parse(JSON.stringify(emptyEditingReceived)),
      reloadReceives: false,
      showSentEditor: false,
      editingSent: JSON.parse(JSON.stringify(emptyEditingSent)),
      reloadSends: false,
      keyword: '',
      isSearch: false,
    };
  }
  componentDidMount() {
    this.props.onLoadCourtList();
    this.props.onLoadTypeList();
    this.props.onLoadDeliveryTypeList();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        message.success('新增成功');
        this.cleanForms();
        this.reloadReceivedList();
        // this.onToggleReceivedEditor(false);
        this.reloadSentList();
        // this.onToggleSentEditor(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.cachedReceivedId === null && this.props.cachedReceivedId !== null) {
      this.onChangeTab('get');
    }
    if (prevProps.isSearching === true && this.props.isSearching === false) {
      this.setState({ isSearch: true })
    }
    if ((prevState.showReceivedEditor === false && this.state.showReceivedEditor === true) ||
        (prevState.showSentEditor === false && this.state.showSentEditor === true)) { 
      this.setState({ keyword: '', isSearch: true }, () => this.setState({ isSearch: false }))
    }
  }
  reloadReceivedList = () => {
    this.setState({ reloadReceives: true }, () => this.setState({ reloadReceives: false }));
  }
  reloadSentList = () => {
    this.setState({ reloadSends: true }, () => this.setState({ reloadSends: false }));
  }
  onSelectFile = (file_) => {
    const { editingFileList } = this.state;
    let newEditingFileList = Object.assign([], editingFileList);
    if (!Array.isArray(file_)) {
      newEditingFileList.splice(newEditingFileList.indexOf(file_), 1);
    } else {
      newEditingFileList = newEditingFileList.concat(file_);
    }

    this.setState({ editingFileList: newEditingFileList });
  }
  cleanForms = () => {
    const editingReceived = JSON.parse(JSON.stringify(emptyEditingReceived));
    const editingSent = JSON.parse(JSON.stringify(emptyEditingSent));
    this.setState({ editingReceived, editingSent, editingFileList: [] });
  }
  onChangeTab = (currentTab) => {
    this.setState({ currentTab, showReceivedEditor: false, showSentEditor: false }, () => this.cleanForms());
  }
  onUpdateEditingReceived = (key, value) => {
    const editingReceived = Object.assign({}, this.state.editingReceived);
    editingReceived[key] = value;
    this.setState({ editingReceived });
  }
  onSubmitCreateReceived = () => {
    const { editingReceived, editingFileList } = this.state;
    if(editingReceived.name === '' && editingReceived.typeId === '' && editingReceived.sender === '' && editingReceived.receivedDate === '' && editingReceived.expiredDays){
      return message.error('請輸入收文欄位')
    } else {
      this.props.onCreateReceived(
        editingReceived.name,
        editingReceived.typeId,
        moment(editingReceived.receivedDate).format('YYYY/MM/DD'),
        editingReceived.sender,
        editingReceived.custodianName,
        editingReceived.sentDate ? moment(editingReceived.sentDate).format('YYYY/MM/DD') : null,
        editingReceived.docketId,
        editingReceived.expiredDays,
        editingFileList
      )
      this.setState({ showReceivedEditor: false })
    }
  }
  // onToggleReceivedEditor = (showReceivedEditor) => {
  //   this.setState({ showReceivedEditor })
  // }
  onUpdateDocketId = (type, docketValue) => {
    const { editingReceived, editingSent } = this.state;
    const custodianNames = this.props.docketNames.toJS();
    Object.entries(this.props.dockets.toJS()).map(([key, value]) => {
      if(docketValue === value) {
        if(type === 'editingReceived') {
          this.setState({
            editingReceived: {
              ...editingReceived,
              custodianName: editingReceived.custodianName ? editingReceived.custodianName : custodianNames[key],
            }
          }, ()=> this.onUpdateEditingReceived('docketId', key))
          
        };
        if(type === 'editingSent') {
          this.setState({
            editingSent: {
              ...editingSent,
              custodianName: editingSent.custodianName ? editingSent.custodianName : custodianNames[key],
            }
          }, ()=> this.onUpdateEditingSent('docketId', key))
        }
      }
    })
  }
  onUpdateEditingSent = (key, value) => {
    const editingSent = Object.assign({}, this.state.editingSent);
    editingSent[key] = value;
    this.setState({ editingSent });
  }
  onSubmitCreateSent = () => {
    const { editingSent, editingFileList } = this.state;
    if(editingSent.name === '' && editingSent.typeId === '' && editingSent.receivedDate === '' && editingSent.recipient === '' && editingSent.sentDate === '' && editingSent.deliveryTypeId){
      return message.error('請輸入發文欄位')
    } else {
      this.props.onCreateSent(
        editingSent.name,
        editingSent.typeId,
        moment(editingSent.receivedDate).format('YYYY/MM/DD'),
        editingSent.recipient,
        editingSent.custodianName,
        moment(editingSent.sentDate).format('YYYY/MM/DD'),
        editingSent.docketId,
        editingSent.deliveryTypeId,
        editingFileList,
      )
      this.setState({ showSentEditor: false })
    }
  }
  onSubmitSearch() {
    let { keyword } = this.state;
    keyword = keyword.trim();
    if(keyword === '') {
      return message.error('請輸入欄位');
    }
    this.props.onSearchMailroom(keyword);
  }
  onCleanSearch() {
    this.setState({ keyword: '' }, this.setState({ isSearch: false }))
  }

  render() {
    const { keyword, showReceivedEditor, showSentEditor, currentTab, editingReceived, editingSent, editingFileList, reloadReceives, reloadSends, isSearch } = this.state;
    return (
      <div className="mailroom-section">
        {
          this.props.isPosting === true
          ?
          <Spinner />
          :
          <>
            <div className="tool-bar">
              <Form className="search"
                onSubmit={e => {e.preventDefault(); this.onSubmitSearch()}}
              >
                <div className="search-col search-mailroom">
                  <label htmlFor="searchText"></label>
                  <Input id="searchText"
                    placeholder="請輸入案號、當事人、收發文檔案名搜尋"
                    value={keyword}
                    onChange={e => this.setState({ keyword: e.target.value })}
                  />
                </div>
                <button data-txt="搜尋收發文" className="btn-search btn-empty" variant="primary" type="submit">
                  <i><FontAwesomeIcon icon={fas.faSearch} /></i>搜尋收發文
                </button>
                <Tooltip title="重新搜尋">
                  <button className="btn-empty" data-txt="重新搜尋"
                    type="button"
                    onClick={e => this.onCleanSearch()}
                  ><FontAwesomeIcon icon={fas.faRedoAlt} /></button>
                </Tooltip>
              </Form>
              <div className="btn-tool">
                {
                  currentTab === 'get'
                  ?
                  <>
                    <CSVLink className="btn-case" filename={`收文-${moment().format('YYYYMMDDhhmmss')}.csv`} data={this.props.receivedCsvRowList.toJS()}>
                      <i><FontAwesomeIcon icon={fas.faDownload} /></i>下載
                    </CSVLink>
                    <button className="btn-add" onClick={e => this.setState({ showReceivedEditor: !showReceivedEditor, editingReceived: JSON.parse(JSON.stringify(emptyEditingReceived)) })}>
                      <i>{showReceivedEditor ? <FontAwesomeIcon icon={fas.faTimes} /> : <FontAwesomeIcon icon={fas.faPlus} />}</i>
                      {showReceivedEditor ? '取消新增' : '新增收文'}
                    </button>
                  </>
                  :
                  <>
                    <CSVLink className="btn-case" filename={`發文-${moment().format('YYYYMMDDhhmmss')}.csv`} data={this.props.sentCsvRowList.toJS()}>
                      <i><FontAwesomeIcon icon={fas.faDownload} /></i>下載
                    </CSVLink>
                    <button className="btn-add" onClick={e => this.setState({ showSentEditor: !showSentEditor, editingSent: JSON.parse(JSON.stringify(emptyEditingSent)) })}>
                      <i>{showSentEditor ? <FontAwesomeIcon icon={fas.faTimes} /> : <FontAwesomeIcon icon={fas.faPlus} />}</i>
                      {showSentEditor ? '取消新增' : '新增發文'}
                    </button>
                  </>
                }
              </div>
            </div>
            <div className="section-col-item mailroom">
              {
                showReceivedEditor
                &&
                <Form className="mailroom-create-receive-section"
                      id="mailroom-create-received-form"
                      onSubmit={e => { e.preventDefault(); this.onSubmitCreateReceived() }}
                >
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>新增收文<span className="tip">* 為必填欄位</span></div>
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">來文者：</span></div>
                      <Input className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        value={editingReceived.sender}
                        onChange={e => this.onUpdateEditingReceived('sender', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">收文日：</span></div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={editingReceived.receivedDate ? moment(editingReceived.receivedDate, dateFormat) : null}
                        onChange={value => this.onUpdateEditingReceived('receivedDate', value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span>案號：</span></div>
                      <Select className="col-edit"
                        size="small"
                        placeholder="請輸入關鍵字"
                        notFoundContent={this.props.isFetching ? <Spin size="small" /> : '無相關資料'}
                        showSearch
                        onSearch={value => this.props.onLoadDocketList(value, 10)}
                        onChange={value => this.onUpdateDocketId('editingReceived', value)}
                      >
                        {
                          this.props.docketList && this.props.docketList.toJS().map((docket, idx) =>
                            <Option key={idx} value={docket}>{`${docket}`}</Option>
                          )
                        }
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span>當事人：</span></div>
                      <Input className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        value={editingReceived.custodianName}
                        onChange={e => this.onUpdateEditingReceived('custodianName', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">期限天數：</span></div>
                      <InputNumber className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        min={0}
                        value={editingReceived.expiredDays}
                        onChange={value => this.onUpdateEditingReceived('expiredDays', value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">類型：</span></div>
                      <Select className="col-edit"
                        size="small"
                        showSearch
                        value={editingReceived.typeId}
                        onChange={value => this.onUpdateEditingReceived('typeId', value)}
                        required
                      >
                        {
                          this.props.typeIdList && this.props.typeIdList.map(typeId =>
                            <Option key={`mailroom-received-type-${typeId}`} value={typeId}>{this.props.types.getIn([typeId])}</Option>
                          )
                        }
                      </Select>
                    </div>
                    {/* <div className="col-item">
                      <div className="col-title"><span>函文發文日期：</span></div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={editingReceived.sentDate ? moment(editingReceived.sentDate, dateFormat) : null}
                        onChange={value => this.onUpdateEditingReceived('sentDate', value)}
                      />
                    </div> */}
                    <div className="col-item">
                      <div className="col-title"><span className="required">函文名稱：</span></div>
                      <Input className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        value={editingReceived.name}
                        onChange={e => this.onUpdateEditingReceived('name', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">上傳檔案：</div>
                      <div className="col-edit upload">
                        <Upload multiple={true}
                          fileList={editingFileList}
                          onRemove={(file_, fileList) => this.onSelectFile(file_)}
                          beforeUpload={(file_, fileList) => { this.onSelectFile(fileList); return false; }}
                        >
                          <Button><i><FontAwesomeIcon icon={fas.faFileUpload} /></i>選擇上傳檔案</Button>
                        </Upload>
                      </div>
                    </div>
                    <div className="col-tool">
                      <button onClick={e => this.setState({ showReceivedEditor: false })}>取消</button>
                      <button type="submit">新增收文</button>
                    </div>
                  </div>
                </Form>
              }
              {
                showSentEditor
                &&
                <Form className="mailroom-create-receive-section"
                      id="mailroom-create-received-form"
                      onSubmit={e => { e.preventDefault(); this.onSubmitCreateSent() }}
                >
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>新增發文<span className="tip">* 為必填欄位</span></div>
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">收受者：</span></div>
                      <Input className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        value={editingSent.recipient}
                        onChange={e => this.onUpdateEditingSent('recipient', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">發文日：</span></div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={editingSent.sentDate ? moment(editingSent.sentDate, dateFormat) : null}
                        onChange={value => this.onUpdateEditingSent('sentDate', value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span>案號：</span></div>
                      <Select className="col-edit"
                        size="small"
                        placeholder="請輸入關鍵字"
                        notFoundContent={this.props.isFetching ? <Spin size="small" /> : '無相關資料'}
                        showSearch
                        onSearch={value => this.props.onLoadDocketList(value, 10)}
                        onChange={value => this.onUpdateDocketId('editingSent', value)}
                      >
                        {
                          this.props.docketList && this.props.docketList.toJS().map((docket, idx) =>
                            <Option key={idx} value={docket}>{`${docket}`}</Option>
                          )
                        }
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span>當事人：</span></div>
                      <Input className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        value={editingSent.custodianName}
                        onChange={e => this.onUpdateEditingSent('custodianName', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">遞送方式：</span></div>
                      <Select placeholder="例如：郵寄、線上..."
                        className="col-edit"
                        size="small"
                        showSearch
                        value={editingSent.deliveryTypeId}
                        onChange={value => this.onUpdateEditingSent('deliveryTypeId', value)}
                        required
                      >
                        {
                          this.props.deliveryTypeIdList && this.props.deliveryTypeIdList.map(deliveryTypeId =>
                            <Option key={`mailroom-sent-deliverytype-${deliveryTypeId}`} value={deliveryTypeId}>{this.props.deliveryTypes.getIn([deliveryTypeId])}</Option>
                          )
                        }
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">類型：</span></div>
                      <Select className="col-edit"
                        size="small"
                        showSearch
                        value={editingSent.typeId}
                        onChange={value => this.onUpdateEditingSent('typeId', value)}
                        required
                      >
                        {
                          this.props.typeIdList && this.props.typeIdList.map(typeId =>
                            <Option key={`mailroom-sent-type-${typeId}`} value={typeId}>{this.props.types.getIn([typeId])}</Option>
                          )
                        }
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span>收受日：</span></div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={editingSent.receivedDate ? moment(editingSent.receivedDate, dateFormat) : null}
                        onChange={value => this.onUpdateEditingSent('receivedDate', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title"><span className="required">函文名稱：</span></div>
                      <Input className="col-edit"
                        size="small"
                        allowClear
                        type="text"
                        value={editingSent.name}
                        onChange={e => this.onUpdateEditingSent('name', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">上傳檔案：</div>
                      <div className="col-edit upload">
                        <Upload className="input-upload"
                          multiple={true}
                          fileList={editingFileList}
                          onRemove={(file_, fileList) => this.onSelectFile(file_)}
                          beforeUpload={(file_, fileList) => { this.onSelectFile(fileList); return false; }}
                        >
                          <Button className="btn-upload">
                            <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>選擇上傳檔案
                          </Button>
                        </Upload>
                      </div>
                    </div>
                    <div className="col-tool">
                      <button onClick={e => this.setState({ showSentEditor: false })}>取消</button>
                      <button type="submit">新增發文</button>
                    </div>
                  </div>
                </Form>
              }
              <Tabs 
                onChange={this.onChangeTab} 
                activeKey={currentTab} 
                type="card"
                destroyInactiveTabPane={true}
              >
                <TabPane tab="收文" key="get">
                  <mailroomReceivedList.container
                    reloadList={reloadReceives}
                    isSearch={isSearch}
                    types={this.props.types}
                    typeIdList={this.props.typeIdList}
                    courts={this.props.courts}
                    courtIdList={this.props.courtIdList}
                  />
                </TabPane>
                <TabPane tab="發文" key="post">
                  <mailroomSentList.container
                    reloadList={reloadReceives}
                    isSearch={isSearch}
                    types={this.props.types}
                    typeIdList={this.props.typeIdList}
                    courts={this.props.courts}
                    courtIdList={this.props.courtIdList}
                    deliveryTypes={this.props.deliveryTypes}
                    deliveryTypeIdList={this.props.deliveryTypeIdList}
                  />
                </TabPane>
              </Tabs>
            </div>
          </>
        }
      </div>
    )
  }
}
export default withRouter(MailroomComponent);
