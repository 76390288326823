import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ReviewNotesComponent from './component';


const mapStateToProps = (state) => ({
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
  noteIdList: selectors.getNoteIdList(state),
  notes: selectors.getNotes(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadPageNoteList: (pageId) => {
    dispatch(actions.doLoadPageNoteList(pageId));
  },
  onUpdateNoteVisible: (noteId, visible) => {
    dispatch(actions.doUpdateNoteVisible(noteId, visible));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewNotesComponent);