import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'casesReceipt/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'casesReceipt/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'casesReceipt/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'casesReceipt/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'casesReceipt/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'casesReceipt/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'casesReceipt/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'casesReceipt/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'casesReceipt/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'casesReceipt/UPDATE_EXPORT_RECEIPT': (state, { payload }) => {
    return state.setIn(['casesReceipt', 'category'], fromJS(payload.category))
                .setIn(['casesReceipt', 'cost'], fromJS(payload.cost))
                .setIn(['casesReceipt', 'date'], fromJS(payload.date))
                .setIn(['casesReceipt', 'name'], fromJS(payload.name))
                .setIn(['casesReceipt', 'note'], fromJS(payload.note))
                .setIn(['casesReceipt', 'reason'], fromJS(payload.reason));
  },
  'casesReceipt/UPDATE_LAWER': (state, { payload }) => {
    return state.setIn(['lawer', 'address'], fromJS(payload.address))
                .setIn(['lawer', 'lastName'], fromJS(payload.lastName))
                .setIn(['lawer', 'firstName'], fromJS(payload.firstName))
                .setIn(['lawer', 'faxNo'], fromJS(payload.faxNo))
                .setIn(['lawer', 'lawFirmName'], fromJS(payload.lawFirmName));
  },
}, State);

export default Reducer;