import React, {Component} from 'react'

class FullSpinner extends Component {
  render() {
    return (
      <div className="full-spinner-box">
        <div className="loading-dna">
          <div className="loading-item blue">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
          <div className="loading-item green">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
          <div className="loading-item-line">
          <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
        </div>
      </div>
    )
  }
}

export default FullSpinner;
