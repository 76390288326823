import React, { Component } from 'react';
import profile from '../modules/profile';


class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-profile">
        <profile.container />
	    </div>
	  )
  }
}

export default ProfilePageComponent;
