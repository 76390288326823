import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ReportComponent from './component';

const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),
  reportName: selectors.getReportName(state),
  rowList: selectors.getRowList(state),
});

const mapDispatchToProps = dispatch => ({
  onSearch: (taxYear, taxable, nontaxable) => {
    dispatch(actions.doSearch(taxYear, taxable, nontaxable));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportComponent);