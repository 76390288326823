import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'creatConsultations/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'creatConsultations/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'creatConsultations/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'creatConsultations/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'creatConsultations/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'creatConsultations/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'creatConsultations/UPDATE_ERR_MSG': (state, { payload }) => {
    return state.setIn(['errMsg'], '');
  },
  'creatConsultations/UPDATE_CASE_RELATE_LIST': (state, { payload }) => {
    if (payload.length === 0 ) {
      return state.setIn(['isFetching'], false);
    } else {
      return state.setIn(['caseRelateList'],  fromJS(payload));
    }
  },
  'creatConsultations/UPDATE_CASE_RELATE_LIST': (state, { payload }) => {
    if (payload.length === 0 ) {
      return state.setIn(['isFetching'], false);
    } else {
      return state.setIn(['caseRelateList'],  fromJS(payload));
    }
  },
  'creatConsultations/GET_CASE_RELATED_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], false)
                .setIn(['errMsg'], null);
  },
  'creatConsultations/GET_CASE_RELATEDT_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'creatConsultations/UPDATE_CASE_RELATE_LIST': (state, { payload }) => {
    return state.setIn(['caseRelateList'],  fromJS(payload));
  },
}, State);

export default Reducer;