import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { 
  createSearches,
  createSearchsLaws,
  getCaseNames,
  createJudgmentFiles
} from '../../utils/api';


export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)

export const updateSearchResult = createAction(types.UPDATE_SEARCH_RESULT)
export function doSubmitSearch(yearRange, case_, noRange, content, summary, judge, text, referenceList) {
  return dispatch => {
    dispatch(postRequest());
    createSearches(yearRange, case_, noRange, content, summary, judge, text, referenceList, [], [], [], []).then(data => {
      dispatch(updateSearchResult(data));
      dispatch(postSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export const updateLawData = createAction(types.UPDATE_LAW_DATA)
export function doLoadReference(law, no) {
  return dispatch => {
    dispatch(getRequest());
    createSearchsLaws(law, no).then(data => {
      dispatch(updateLawData(data));
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}

export const updateCaseList = createAction(types.UPDATE_CASE_LIST)
export function doLoadCaseNames() {
  return dispatch => {
    dispatch(getRequest());
    getCaseNames().then(data => {
      dispatch(updateCaseList(data));
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}
export function doSubmitAddJudgment(caseId, judicialId, esId) {
  return dispatch => {
    dispatch(postRequest());
    createJudgmentFiles(caseId, judicialId, esId).then(data => {
      dispatch(postSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}