import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Input } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
// const { Option } = Select;
// const { TabPane } = Tabs;
// const dateFormat = 'YYYY/MM/DD';
const disableFolder = ['諮詢相關', '收文', '發文']

class FolderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newFolderName: '',
      isEditing: false,
      updateFolderId: '',
      updateFolderName: '',
    };
  }
  componentDidMount() {
    this.props.onLoadFolderList();
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.isPosting === true && this.props.isPosting === false)
      || (prevProps.isPutting === true && this.props.isPutting === false)) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  onClickUpdateFolder() {
    const { updateFolderName, updateFolderId } = this.state;
    if (updateFolderName) {
      let name = updateFolderName ? updateFolderName.trim() : '';
      this.props.onUpdateFolder(updateFolderId, name);
      this.setState({ isEditing: false, updateFolderName: '', updateFileId: '' });
    } else {
      return message.error('請勿空白欄位')
    }
  }
  onClickSubmitNewFolder() {
    const { newFolderName } = this.state;
    if (newFolderName) {
      let name = newFolderName ? newFolderName.trim() : '';
      this.props.onSubmitNewFolder(name);
      this.setState({ newFolderName: '' });
    } else {
      return message.error('請勿空白欄位')
    }
  }

  render() {
    const { newFolderName, isEditing, updateFolderId, updateFolderName } = this.state;
    return (
      <div className="folder-section">
        <div className="tool-bar">
          <form className="creat-folder" onSubmit={e => { e.preventDefault(); this.onClickSubmitNewFolder() }}>
            <Input type="text" value={newFolderName} placeholder="請輸入新增資料夾..." onChange={e => this.setState({ newFolderName: e.target.value })} />
            <button type="submit">
              <i className="feature-tool-icon"><FontAwesomeIcon icon={fas.faPlusCircle} /></i>
              增加資料夾
            </button>
          </form>
        </div>
        <div className="folder">
          <ul className="folder-list">
            {
              this.props.folderList && this.props.folderList.map(folder =>
                <li className="folder-item" key={`folder-${folder.getIn(['id'])}`}>
                  <div className="folder-item-text">
                    {
                      isEditing && updateFolderId === Number(folder.getIn(['id'])) ?
                        <Input
                          type="text"
                          value={updateFolderName}
                          onChange={e => this.setState({ updateFolderName: e.target.value })}
                        />
                        :
                        <div>{folder.getIn(['name'])}</div>
                    }
                  </div>
                  {
                    disableFolder.indexOf(folder.getIn(['name'])) === -1
                    ?
                      <div className={`folder-item-tool ${(isEditing && updateFolderId === Number(folder.getIn(['id']))) ? 'editing' : ''}`}>
                        {
                          isEditing && updateFolderId === Number(folder.getIn(['id'])) ?
                          <>
                            <button onClick={e => this.setState({ isEditing: false, updateFolderId: '', updateFolderName: '' })}><FontAwesomeIcon icon={fas.faTimes} /></button>
                            <button onClick={e => this.onClickUpdateFolder()}><FontAwesomeIcon icon={fas.faCheck} /></button>
                          </>
                          :
                          <button onClick={e => this.setState({ isEditing: true, updateFolderName: folder.getIn(['name']), updateFolderId: folder.getIn(['id']) })}><FontAwesomeIcon icon={fas.faPencilAlt} /></button>
                        }
                      </div>
                    :
                      <div style={{fontSize: "14pxx", color: "#d3d3d3"}}>系統預設</div>
                  }
                </li>
              )
            }
          </ul>
        </div>
      </div>
    )
  }
}
export default withRouter(FolderComponent);
