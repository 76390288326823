import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { message, Input, DatePicker } from 'antd';
import { DATE_FORMAT, CASE_TYPE } from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const DEFAULT_APPOINTOR_CUSTODIAN = {
  name: '',
  ssid: '',
  birthday: null,
  address: '',
  phone: '',
  email: '',
  no: '',
  relationship: '', // 當事人身份
}
const DEFAULT_COMPANY_CUSTODIAN = {
  name: '',
  ban: '',
  representative: '',
  contactPerson: '',
  address: '',
  phone: '',
  email: '',
  relationship: '', // 當事人身份
}

class AppointmentCustodianComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      appointTypeId: 1,
      custodianAppointor: DEFAULT_APPOINTOR_CUSTODIAN,
      custodianCompany: DEFAULT_COMPANY_CUSTODIAN,
      relationshipToCaseDetail: '',
      copyAppointment: '',
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleEditMode(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.appointmentId !== this.props.appointmentId && this.state.editMode === true) {
      this.onToggleEditMode(false);
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onToggleEditMode(editMode) {
    if (editMode === true) {
      const appointTypeId = this.props.appoint.getIn(['appointTypeId']);
      const relationship = this.props.custodian.getIn(['relationship']);
      let typeId = this.props.caseDetail.getIn(['typeId']);
      let relationshipToCaseDetail = '';
      if(relationship) {
        Object.entries(CASE_TYPE.find(t => t.id === typeId)).map(([key, value]) => {
          if(value === relationship){ relationshipToCaseDetail = (key === 'plaintiff') ? 'plaintiffList' : (key === 'defendant') ? 'defendantList' : null ; return key }
        })
      }
      if (appointTypeId === 1) {
        this.setState({
          editMode,
          appointTypeId,
          custodianAppointor: this.props.custodian.toJS(),
          relationshipToCaseDetail,
        });
      }
      if (appointTypeId === 2) {
        this.setState({
          editMode,
          appointTypeId,
          custodianCompany: this.props.custodian.toJS(),
          relationshipToCaseDetail,
        })
      }
    } else {
      this.setState({ editMode });
    }
    this.props.onChangeIsEditing(editMode);
  }
  onCopyAppointment(type) {
    const { copyAppointment } = this.state;
    if( copyAppointment !== '') { this.setState({ copyAppointment: '' }) }
    if( copyAppointment === '' && type === 'appointor' ) {
      const custodianAppointor = this.props.appoint.getIn(['appointor']).toJS();
      this.setState({
        copyAppointment: type,
        custodianAppointor: {
          ...custodianAppointor,
          no: this.state.custodianAppointor.no,
          relationship: this.props.custodian.getIn(['relationship']),
        }
      })
    };
    if( copyAppointment === '' && type === 'company' ) {
      const custodianCompany = this.props.appoint.getIn(['company']).toJS();
      this.setState({
        copyAppointment: type,
        custodianCompany: {
          ...custodianCompany,
          relationship: this.props.custodian.getIn(['relationship']),
        }
      })
    }
  }
  onChangeCustodianAppointor(key, value) {
    let { custodianAppointor } = this.state;
    custodianAppointor = JSON.parse(JSON.stringify(custodianAppointor));
    custodianAppointor[key] = value;
    if( key === 'phone' && custodianAppointor['phone'].length > 20) {
      custodianAppointor['phone'] = custodianAppointor['phone'].slice(0, 20)
    }
    this.setState({ custodianAppointor })
  }
  onChangeCustodianCompany(key, value) {
    let { custodianCompany } = this.state;
    custodianCompany = JSON.parse(JSON.stringify(custodianCompany));
    custodianCompany[key] = value;
    if( key === 'phone' && custodianCompany['phone'].length > 20) {
      custodianCompany['phone'] = custodianCompany['phone'].slice(0, 20)
    }
    this.setState({ custodianCompany })
  }
  onCopyCustodianRelationship(relationship) {
    if ( relationship ) { this.setState({ relationshipToCaseDetail: relationship }) }
  }
  onSubmitCustodian() {
    const { custodianAppointor, custodianCompany, relationshipToCaseDetail } = this.state;
    if ( this.props.appoint.getIn(['appointTypeId']) === 1 ) {
      const birthday = custodianAppointor.birthday ? moment(custodianAppointor.birthday).format(DATE_FORMAT) : null;
      this.props.onUpdateCustodian(this.props.appointmentId, custodianAppointor.name, custodianAppointor.address, custodianAppointor.phone, custodianAppointor.email, custodianAppointor.relationship, custodianAppointor.ssid, birthday, custodianAppointor.no);
      if(custodianAppointor.relationship) {
        let caseDetail = JSON.parse(JSON.stringify(this.props.caseDetail.toJS()));
        let newList = caseDetail[`${relationshipToCaseDetail}`];
        if( newList && newList.indexOf(custodianAppointor.name) === -1) {
          newList = [...newList, custodianAppointor.name]
          caseDetail[`${relationshipToCaseDetail}`] = newList;
          this.props.onUpdateCustodianRelationship(
            this.props.appointmentId,
            caseDetail.name, 
            caseDetail.appointNoId,
            caseDetail.typeId, 
            caseDetail.statusId, 
            caseDetail.relatedList, 
            caseDetail.keywordList,
            caseDetail.plaintiffList,
            caseDetail.defendantList,
            caseDetail.lawNo,
            caseDetail.appointContent,
            caseDetail.appointNote,
          )
        }
      }
    };
    if ( this.props.appoint.getIn(['appointTypeId']) === 2 ) {
      this.props.onUpdateCustodianCompany(this.props.appointmentId, custodianCompany.name, custodianCompany.ban, custodianCompany.representative, custodianCompany.contactPerson, custodianCompany.address, custodianCompany.phone, custodianCompany.email, custodianCompany.relationship)
      if(custodianCompany.relationship) {
        let caseDetail = JSON.parse(JSON.stringify(this.props.caseDetail.toJS()));
        let newList = caseDetail[`${relationshipToCaseDetail}`];
        if( newList && newList.indexOf(custodianCompany.name) === -1) {
          newList = [...newList, custodianCompany.name]
          caseDetail[`${relationshipToCaseDetail}`] = newList;
          this.props.onUpdateCustodianRelationship(
            this.props.appointmentId,
            caseDetail.name, 
            caseDetail.appointNoId,
            caseDetail.typeId, 
            caseDetail.statusId, 
            caseDetail.relatedList, 
            caseDetail.keywordList,
            caseDetail.plaintiffList,
            caseDetail.defendantList,
            caseDetail.lawNo,
            caseDetail.appointContent,
            caseDetail.appointNote,
          )
        }
      }
    }
  }
  render() {
    const { editMode, custodianAppointor, custodianCompany, copyAppointment } = this.state;
    if (!this.props.appoint || this.props.appoint.size === 0) {
      return null;
    }
    return (
      <div className="appointmentcustodian-section section-col-item">
        {
          editMode && this.props.appoint.getIn(['appointTypeId']) === 1 ?
            <form onSubmit={e => {e.preventDefault(); this.onSubmitCustodian()}}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>取消</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>更新</button>
              </div>
              <div className="col-item-group">
                {
                  this.props.caseTypeId !== 6
                  &&
                  <div className="col-item">
                    <div className="col-title">當事人身份：</div>
                    <div className="col-edit">
                      {
                        CASE_TYPE.map(type => {
                          if (type.id === this.props.caseTypeId) {
                            return (
                              <>
                                <input type="radio" name="custodianName" id="custodianName-1" value={`${type.plaintiff}`}
                                  checked={custodianAppointor.relationship === type.plaintiff}
                                  onChange={e => {this.onChangeCustodianAppointor('relationship', type.plaintiff); this.onCopyCustodianRelationship('plaintiffList')}}
                                />
                                <label className={`edit-custodianName`} htmlFor="custodianName-1">{type.plaintiff}</label>
                                <input type="radio" name="custodianName" id="custodianName-2" value={type.defendant}
                                  checked={custodianAppointor.relationship === type.defendant}
                                  onChange={e => {this.onChangeCustodianAppointor('relationship', type.defendant); this.onCopyCustodianRelationship('defendantList')}}
                                />
                                <label className={`edit-custodianName`} htmlFor="custodianName-2">{type.defendant}</label>
                              </>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                }
                <div className="col-item">
                  <div className="col-title">同委任人：</div>
                  <div className="col-edit">
                    <input type="checkbox" name="copyEditAppointor" id="copyEditAppointor"
                      checked={copyAppointment === 'appointor'}
                      onChange={e => this.onCopyAppointment('appointor')}
                    />
                    <label className="edit-copyEditAppointor" htmlFor="copyEditAppointor">同委任人</label>
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">編號：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianAppointor.no}
                    onChange={e => this.onChangeCustodianAppointor('no', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">當事人：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianAppointor.name}
                    onChange={e => this.onChangeCustodianAppointor('name', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">身分證字號：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianAppointor.ssid}
                    onChange={e => this.onChangeCustodianAppointor('ssid', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">生日：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={custodianAppointor.birthday ? moment(custodianAppointor.birthday, DATE_FORMAT) : null}
                    onChange={value => this.onChangeCustodianAppointor('birthday', value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianAppointor.address}
                    onChange={e => this.onChangeCustodianAppointor('address', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianAppointor.phone}
                    onChange={e => this.onChangeCustodianAppointor('phone', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianAppointor.email}
                    onChange={e => this.onChangeCustodianAppointor('email', e.target.value)}
                  />
                </div>
              </div>
            </form>
          : editMode && this.props.appoint.getIn(['appointTypeId']) === 2 ?
            <form onSubmit={e => {e.preventDefault(); this.onSubmitCustodian()}}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>取消</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>更新</button>
              </div>
              <div className="col-item-group">
                {
                  this.props.caseTypeId !== 6
                  &&
                  <div className="col-item">
                    <div className="col-title">當事人身份：</div>
                    <div className="col-edit">
                      {
                        CASE_TYPE.map(type => {
                          if (type.id === this.props.caseTypeId) {
                            return (
                              <>
                                <input type="radio" name="custodianName" id="custodianName-1" value={`${type.plaintiff}`}
                                  checked={custodianCompany.relationship === type.plaintiff}
                                  onChange={e => {this.onChangeCustodianCompany('relationship', type.plaintiff); this.onCopyCustodianRelationship('plaintiffList')}}
                                />
                                <label className={`edit-custodianName`} htmlFor="custodianName-1">{type.plaintiff}</label>
                                <input type="radio" name="custodianName" id="custodianName-2" value={type.defendant}
                                  checked={custodianCompany.relationship === type.defendant}
                                  onChange={e => {this.onChangeCustodianCompany('relationship', type.defendant); this.onCopyCustodianRelationship('defendantList')}}
                                />
                                <label className={`edit-custodianName`} htmlFor="custodianName-2">{type.defendant}</label>
                              </>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                }
                <div className="col-item">
                  <div className="col-title">同委任人：</div>
                  <div className="col-edit">
                    <input type="checkbox" name="copyEditCompany" id="copyEditCompany"
                      checked={copyAppointment === 'company'}
                      onChange={e => this.onCopyAppointment('company')}
                    />
                    <label className="edit-copyEditCompany" htmlFor="copyEditCompany">同委任人</label>
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">公司名稱：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianCompany.name}
                    onChange={e => this.onChangeCustodianCompany('name', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">統一編號：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianCompany.ban}
                    onChange={e => this.onChangeCustodianCompany('ban', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">代表人：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianCompany.representative}
                    onChange={e => this.onChangeCustodianCompany('representative', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡人：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianCompany.contactPerson}
                    onChange={e => this.onChangeCustodianCompany('contactPerson', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={custodianCompany.address}
                    onChange={e => this.onChangeCustodianCompany('address', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <Input className="col-edit"
                    size="small"
                    type="number"
                    value={custodianCompany.phone}
                    onChange={e => this.onChangeCustodianCompany('phone', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <Input className="col-edit"
                    size="small"
                    type="email"
                    value={custodianCompany.email}
                    onChange={e => this.onChangeCustodianCompany('email', e.target.value)}
                  />
                </div>
              </div>
            </form>
          : this.props.appoint.getIn(['appointTypeId']) === 1 ?
            <>
              <div className="col-item-tool">
                {
                  this.props.appoint.getIn(['appointTypeId']) === 1
                  &&
                  <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
                }
              </div>
              <div className="col-item-group">
                {
                  this.props.caseTypeId !== 6
                  &&
                  <div className="col-item">
                    <div className="col-title">當事人身份：</div>
                    <div className="col-text">
                      { this.props.custodian.getIn(['relationship']) }
                    </div>
                  </div>
                }
                <div className="col-item">
                  <div className="col-title">編號：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['no']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">當事人：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['name']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">身分證字號：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['ssid']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">生日：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['birthday']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['address']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['phone']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['email']) }
                  </div>
                </div>
              </div>
            </>
          : this.props.appoint.getIn(['appointTypeId']) === 2 ?
            <>
              <div className="col-item-tool">
                <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
              </div>
              <div className="col-item-group">
                {
                  this.props.caseTypeId !== 6
                  &&
                  <div className="col-item">
                    <div className="col-title">當事人身份：</div>
                    <div className="col-text">
                      { this.props.custodian.getIn(['relationship']) }
                    </div>
                  </div>
                }
                <div className="col-item">
                  <div className="col-title">公司名稱：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['name']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">統一編號：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['ban']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">代表人：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['representative']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡人：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['contactPerson']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['address']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['phone']) }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <div className="col-text">
                    { this.props.custodian.getIn(['email']) }
                  </div>
                </div>
              </div>
            </>
          : 
            null
        }
      </div>
    )
  }
}
export default withRouter(AppointmentCustodianComponent);