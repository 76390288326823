import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import MailroomSentListComponent from './component';
import * as appointmentListActions from '../appointmentList/actions';

const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
  sentList: selectors.getSentList(state),
  deliveryTypeIdList: selectors.getDeliveryTypeIdList(state),
  deliveryTypes: selectors.getDeliveryTypes(state),
  dockets: selectors.getDockets(state),
  docketNames: selectors.getDocketNames(state),
  docketList: selectors.getDocketList(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadSentList: (action) => {
    dispatch(actions.doLoadSentList(action));
  },
  onDeleteSentFile: (fileId) => {
    dispatch(actions.doDeleteSentFile(fileId));
  },
  onUploadSentFile: (sentId, fileList) => {
    dispatch(actions.doUploadSentFile(sentId, fileList));
  },
  onDownloadSentFile: (fileId, fileName) => {
    dispatch(actions.doDownloadSentFile(fileId, fileName));
  },
  onLoadDocketList: (contain, size) => {
    dispatch(actions.doLoadDocketList(contain, size));
  },
  onUpdateSent: (sentId, name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId) => {
    dispatch(actions.doUpdateSent(sentId, name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId));
  },
  onDeleteSent: (sentId) => {
    dispatch(actions.doDeleteSent(sentId));
  },
  onUpdateCachedAppointmentId: (appointmentId) => {
    dispatch(appointmentListActions.doUpdateCachedAppointmentId(appointmentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailroomSentListComponent);