import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message } from 'antd';
import dnaLogoIcon from '../../assets/img/dna-logo.svg'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class RegistrationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      email: '',
      password: '',
      confirmPassword: '',
      lastName: '',
      firstName: '',
      lawOffice: '',
      phoneNo: '',
      mobile: ''
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        this.setState({
          currentStep: 1,
        })
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  onClickApply = () => {
    window.scrollTo(0, 0);
    var { email, password, confirmPassword, lastName, firstName, lawOffice, phoneNo, mobile } = this.state;
    email = email.trim();
    lastName = lastName ? lastName.trim() : '';
    firstName = firstName ? firstName.trim() : '';
    lawOffice = lawOffice ? lawOffice.trim() : '';
    phoneNo = phoneNo ? phoneNo.trim() : '';
    if (password !== confirmPassword) {
      return message.error('密碼不一致，請重新輸入');
    }
    if (email !== '' && password !== '' && lastName !== '' && firstName !== '' && lawOffice !== '' && phoneNo !== '' && mobile !== '') {
      this.props.onSubmitApply(email, password, lastName, firstName, lawOffice, phoneNo, mobile);
    } else {
      message.error('尚有欄位未填寫');
    }
  }
  onChangeMobile(value) {
    let mobile = value;
    if (this.state.mobile.length > 10) {
      mobile = mobile.slice(0, 10)
      this.setState({ mobile })
    } else {
      this.setState({ mobile })
    }
  }

  rendeRegistrationData() {
    const { email, password, confirmPassword, lastName, firstName, lawOffice, phoneNo, mobile } = this.state;
    return (
      <div className="registration-form">
        <h2 className="text-center">填寫申請資料</h2>
        <form className="mt-5" onSubmit={(event) => { event.preventDefault(); this.onClickApply() }}>
          <div className="form-group row">
            <label htmlFor="email" className="col-sm-2 col-form-label text-right"><span className="required"></span>使用帳號 / E - mail</label>
            <div className="col-sm-10">
              <input type="email" className="form-control" id="email" placeholder="使用帳號 / E - mail"
                value={email}
                onChange={event => this.setState({ email: event.target.value })}
              />
              <div className="tip">此郵件為您登入 Law DNA 帳號</div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="password" className="col-sm-2 col-form-label text-right"><span className="required"></span>密碼</label>
            <div className="col-sm-10">
              <input type="password" className="form-control" id="password" placeholder="密碼"
                defaultValue=''
                value={password}
                onChange={event => this.setState({ password: event.target.value })}
              />
              {/* <div className="tip">密碼</div> */}
              {/* 目前無特殊規則，僅變更密碼、忘記密碼嚴格 */}
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="confirmPassword" className="col-sm-2 col-form-label text-right"><span className="required"></span>再次確認密碼</label>
            <div className="col-sm-10">
              <input type="password" className="form-control" id="confirmPassword" placeholder="再次確認密碼"
                value={confirmPassword}
                onChange={event => this.setState({ confirmPassword: event.target.value })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="user_name" className="col-sm-2 col-form-label text-right"><span className="required"></span>律師姓名</label>
            <div className="col-sm-10 d-flex">
              <input className="form-control col-2 mr-3"
                value={lastName}
                defaultValue=''
                onChange={e => this.setState({ lastName: e.target.value })}
                type="text"
                placeholder="姓氏"
                required
              />
              <input className="form-control col-4 mr-3"
                value={firstName}
                defaultValue=''
                onChange={e => this.setState({ firstName: e.target.value })}
                type="text"
                id="user_name"
                placeholder="名字"
                required
              />
              <div className="col-form-label">律師</div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="lawOffice" className="col-sm-2 col-form-label text-right"><span className="required"></span>所屬事務所</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="lawOffice" placeholder="所屬事務所"
                value={lawOffice}
                onChange={event => this.setState({ lawOffice: event.target.value })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="phoneNo" className="col-sm-2 col-form-label text-right"><span className="required"></span>聯絡電話</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="phoneNo" placeholder="聯絡電話"
                value={phoneNo}
                maxLength="10"
                onChange={event => this.setState({ phoneNo: event.target.value })}
              />
              <div className="tip">市話，如有分機也請填寫</div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="mobile" className="col-sm-2 col-form-label text-right"><span className="required"></span>手機</label>
            <div className="col-sm-10">
              <input type="number" className="form-control" id="mobile" placeholder="手機。例：0911111111"
                value={mobile}
                min="0"
                onChange={event => this.onChangeMobile(event.target.value)}
              />
              <div className="tip">手機。10 碼數字中間不須空格或其他符號</div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 text-center mt-5">
              <button type="submit" className="btn btn-primary">
                申請帳號
              </button>
            </div>
            {
              this.props.errMsg
              &&
              <p className="err-msg">{this.props.errMsg}</p>
            }
          </div>
        </form>
      </div>
    )
  }
  renderRegistrationResult() {
    return (
      <div>
        <h2 className="text-center text-success">申請成功</h2>
        <div className="text-center mt-5">
          <Link to={`/login`}>
            <button type="button" className="btn btn-primary">
              開始使用 Law DNA
            </button>
          </Link>
        </div>
      </div>
    )
  }

  render() {
    const steps = [
      {
        title: '填寫資料',
        content: this.rendeRegistrationData()
      }, {
        title: '申請成功',
        content: this.renderRegistrationResult()
      }
    ];
    const { currentStep } = this.state;
    return (
      <div className="container registration">
        <div className="registration-nav">
          <Link className="login-form-forgotpw" to={`/`}><img className="login-logo" alt="" src={dnaLogoIcon} /></Link>
          <a className="login-newmember" href="http://www.ediscovery.com.tw/lawdna.html">了解有關 LAW DNA</a>
        </div>
        {steps[currentStep].content}

      </div>
    )
  }
}

export default withRouter(RegistrationComponent);
