import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'consultations/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'consultations/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'consultations/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultations/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'consultations/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'consultations/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultations/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'consultations/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'consultations/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultations/GETTING_CONSULTATION_LIST_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingConsultationList'], true)
                .setIn(['errMsg'], null);
  },
  'consultations/GETTING_CONSULTATION_LIST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingConsultationList'], false)
                .setIn(['errMsg'], null);
  },
  'consultations/GETTING_CONSULTATION_LIST_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingConsultationList'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultations/GETTING_CONSULTATION_DETAIL_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingConsultationDetail'], true)
                .setIn(['errMsg'], null);
  },
  'consultations/GETTING_CONSULTATION_DETAIL_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingConsultationDetail'], false)
                .setIn(['errMsg'], null);
  },
  'consultations/GETTING_CONSULTATION_DETAIL_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingConsultationDetail'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultations/UPDATE_CURRENT_CONSULTATION_ID': (state, { payload }) => {
    return state.setIn(['currentConsultationId'], payload);
  },
  'consultations/UPDATE_CURRENT_DETAIL_TAB': (state, { payload }) => {
    return state.setIn(['currentDetailTab'], payload);
  },
  'consultations/UPDATE_CONSULTATION_LIST': (state, { payload }) => {
    return state.setIn(['consultationIdList'], fromJS(payload.consultationIdList))
                .setIn(['consultations'], fromJS(payload.consultations));
  },
  'consultations/UPDATE_CONSULTATION_DETAIL': (state, { payload }) => {
    return state.setIn(['consultationDetail'], fromJS(payload.consultationDetail))
                .setIn(['currentConsultationId'], payload.consultationId);
  },
}, State);

export default Reducer;