import React, { Component } from 'react';
import forgotPassword from '../modules/forgotPassword';


class ForgotPasswordPageComponent extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  };
	}
	componentDidMount() {
	}  
	render() {
		return (
		  <div id="page-forgotPassword">
		  	<forgotPassword.container />
		  </div>
		)
	}
}

export default ForgotPasswordPageComponent;
