import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getProfile,
  getReceipt,
  updateReceipt
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST);
export const putSuccess = createAction(types.PUT_SUCCESS);
export const putFailure = createAction(types.PUT_FAILURE);

export const updateLawer = createAction(types.UPDATE_LAWER);
export function doLoadProfile(userId) {
  return dispatch => {
    dispatch(getRequest());
    getProfile(userId).then(data => {
      dispatch(updateLawer(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export const updateExportReceipt = createAction(types.UPDATE_EXPORT_RECEIPT)
export function doLoadExportReceipt(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getReceipt(caseId).then(data => {
      dispatch(updateExportReceipt(data));
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}
export function doUpdateReceipt(caseId, category, cost, date, name, note, reason) {
  return dispatch => {
    dispatch(putRequest());
    updateReceipt(caseId, category, cost, date, name, note, reason).then(data => {
      dispatch(updateExportReceipt());
      dispatch(doLoadExportReceipt(caseId));
      dispatch(putSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(putFailure({ errMsg }));
    })
  }
}