import { fromJS  } from 'immutable';

export const State = fromJS({
  isGetting: false,
  isPutting: false,
  isDeleting: false,
  errMsg: null,

  cachePageUrl: null,
  pageBase64List: [],
  PageText: "",
  pageBase64s: {}
});
