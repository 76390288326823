export const API_ENDPOINT = 'https://www.lawdna.com.tw';
//export const API_ENDPOINT = 'http://192.168.1.126:10002';
export const logoutPage = '/';
export const DEFAULT_ERROR_MSG = 'default error message...';

export const MAX_SEARCH_RESULT = 1000;
export const SEARCH_RESULT_PAGE_SIZE = 20;
export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

export const CATEGORIES = {
  'V': {
    'isHigh': true,
    'isSummary': true,
    'name': '民事',
  },
  'M': {
    'isHigh': true,
    'isSummary': true,
    'name': '刑事',
  },
  'A': {
    'isHigh': true,
    'isSummary': false,
    'name': '行政',
  },
  'P': {
    'isHigh': true,
    'isSummary': false,
    'name': '公懲',
  }
}

export const COURTS = {
  'TPC':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'司法院－刑事補償'
  },
  'TPU':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'司法院－訴願決定'
  },
  'TPJ':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'司法院職務法庭'
  },
  'TPS':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': true,
    'name':'最高法院'
  },
  'TPA':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'最高行政法院'
  },
  'TPP':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'公務員懲戒委員會'
  },
  'TPH':{
    'isHighCourt':true,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺灣高等法院'
  },
  '001':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺灣高等法院－訴願決定'
  },
  'TPB':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺北高等行政法院'
  },
  'TCB':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺中高等行政法院'
  },
  'KSB':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'高雄高等行政法院'
  },
  'IPC':{
    'isHighCourt':false,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'智慧財產法院'
  },
  'TCH':{
    'isHighCourt':true,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺灣高等法院 臺中分院'
  },
  'TNH':{
    'isHighCourt':true,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺灣高等法院 臺南分院'
  },
  'KSH':{
    'isHighCourt':true,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺灣高等法院 高雄分院'
  },
  'HLH':{
    'isHighCourt':true,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'臺灣高等法院 花蓮分院'
  },
  'TPD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣臺北地方法院'
  },
  'SLD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣士林地方法院'
  },
  'PCD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣新北地方法院'
  },
  'ILD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣宜蘭地方法院'
  },
  'KLD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣基隆地方法院'
  },
  'TYD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣桃園地方法院'
  },
  'SCD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣新竹地方法院'
  },
  'MLD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣苗栗地方法院'
  },
  'TCD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣臺中地方法院'
  },
  'CHD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣彰化地方法院'
  },
  'NTD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣南投地方法院'
  },
  'ULD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣雲林地方法院'
  },
  'CYD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣嘉義地方法院'
  },
  'TND':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣臺南地方法院'
  },
  'KSD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣高雄地方法院'
  },
  'CTD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣橋頭地方法院'
  },
  'HLD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣花蓮地方法院'
  },
  'TTD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣臺東地方法院'
  },
  'PTD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣屏東地方法院'
  },
  'PHD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣澎湖地方法院'
  },
  'KMH':{
    'isHighCourt':true,
    'isDistrictCourt':false,
    'isSupremeCourt': false,
    'name':'福建高等法院金門分院'
  },
  'KMD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'福建金門地方法院'
  },
  'LCD':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'福建連江地方法院'
  },
  'KSY':{
    'isHighCourt':false,
    'isDistrictCourt':true,
    'isSupremeCourt': false,
    'name':'臺灣高雄少年及家事法院'
  }
}


export const SUMMARY_COURTS = {
  "TPE":"臺北",
  "STE":"新店",
  "SLE":"士林",
  "NHE":"內湖",
  "PCE":"板橋",
  "SJE":"三重",
  "TYE":"桃園",
  "CLE":"中壢",
  "SCD":"新竹",
  "CPE":"竹北(含竹東)",
  "MLD":"苗栗",
  "TCE":"臺中",
  "SDE":"沙鹿",
  "FYE":"豐原",
  "CHE":"彰化",
  "OLE":"員林",
  "PDE":"北斗",
  "NTE":"南投(含埔里)",
  "TLE":"斗六",
  "HUE":"虎尾",
  "CYE":"嘉義(含朴子)",
  "PKE":"北港",
  "TNE":"臺南",
  "SYE":"柳營",
  "SSE":"新市",
  "KSE":"高雄",
  "FSE":"鳳山",
  "CDE":"橋頭",
  "GSE":"岡山",
  "CSE":"旗山",
  "PTE":"屏東",
  "CCE":"潮州",
  "TTE":"臺東、成功",
  "HLE":"花蓮(含玉里)",
  "ILE":"宜蘭",
  "LTE":"羅東",
  "KLD":"基隆、瑞芳",
  "MKE":"馬公",
  "KME":"金城"
}

export const CASE_TYPE = [
  {
    id: 1,
    name: "民事",
    plaintiff: "原告",
    defendant: "被告",
  },
  {
    id: 2,
    name: "刑事",
    plaintiff: "告訴人",
    defendant: "被告",
  },
  {
    id: 3,
    name: "行政",
    plaintiff: "原告",
    defendant: "被告",
  },
  {
    id: 4,
    name: "家事",
    plaintiff: "聲請人",
    defendant: "相對人",
  },
  {
    id: 5,
    name: "少年",
    plaintiff: "聲請人",
    defendant: "相對人",
  },
  {
    id: 6,
    name: "法顧",
    plaintiff: "",
    defendant: "",
  },
]

export const CASE_STATEID = {
  1: '進行中',
  2: '結案',
}

export const CONSULTATION_CASE_STATEID = {
  1: '進行中',
  2: '已轉委任',
}

export const CASE_INSTANCE_LEVEL = {
  0: '偵查',
  1: '一審',
  2: '二審',
  3: '三審',
}

export const TAXABLE = {
  0: '未稅',
  1: '應稅',
}

export const TAX_VOUCHER = {
  0: '無',
  1: '有',
  2: '尚未',
}

export const APPOINTOR_TYPE = {
  1: '自然人',
  2: '法人',
}
// APPOINTOR_TYPE === APPOINTOR_TYPE_FORM，APPOINTOR_TYPE_FORM 用於諮詢表單
export const APPOINTOR_TYPE_FORM = {
  1: '個人',
  2: '公司',
}


export const CUSTODIAN_METTING = {
  0: '未約定',
  1: '電話',
  2: '面談',
  3: '律見',
  4: '出庭',
}

export const PLAN_TYPE = {
  1: '月費',
  2: '年費',
}
