import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { 
  createSearches,
  createClickSearchResultActivity,
  getSearches,
  createJudgmentFiles,
  getCaseNames
} from '../../utils/api';


export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)

export const searchRequest = createAction(types.SEARCH_REQUEST)
export const searchSuccess = createAction(types.SEARCH_SUCCESS)
export const searchFailure = createAction(types.SEARCH_FAILURE)
export const updateSearchResult = createAction(types.UPDATE_SEARCH_RESULT)
export function doSubmitSearch(fromYear, toYear, case_, fromNo, toNo, content, summary, judge, text, referenceName, referenceNo, isExecuteChecked, isInterpretationChecked, fjudCategorySet, fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudeCategorySet, fjudeCourtSet) {
  return dispatch => {
    dispatch(doClearSearchResult());
    dispatch(searchRequest());

    const fjudCategoryList = Array.from(fjudCategorySet);
    const fjudHighCourtList = Array.from(fjudHighCourtSet);
    const fjudDistrictCourtList = Array.from(fjudDistrictCourtSet);
    const fjudOtherCourtList = Array.from(fjudOtherCourtSet);
    const fjudCourtList = [...fjudSupremeCourtSet, ...fjudHighCourtList, ...fjudDistrictCourtList, ...fjudOtherCourtList];
    const fjudeCategoryList = Array.from(fjudeCategorySet);
    const fjudeCourtList = Array.from(fjudeCourtSet);

    const yearRange = {};
    if (fromYear && toYear) {
      yearRange['from'] = fromYear;
      yearRange['to'] = toYear;
    }

    const noRange = {};
    if (fromNo && toNo) {
      noRange['from'] = fromNo;
      noRange['to'] = toNo;
    }

    const referenceList = [];
    if (referenceName && referenceNo) {
      referenceList.push({'law': referenceName, 'no': referenceNo})
    }

    createSearches(yearRange, case_, noRange, content, summary, judge, text, referenceList, isExecuteChecked, isInterpretationChecked, fjudCategoryList, fjudCourtList, fjudeCategoryList, fjudeCourtList).then(data => {
      dispatch(updateSearchResult(data));
      dispatch(searchSuccess());

      const searchCriteria = {
        cacheId: data.cacheId,
        fromYear,
        toYear,
        'case': case_,
        fromNo,
        toNo,
        content,
        summary,
        judge,
        text,
        referenceName,
        referenceNo,
        isExecuteChecked,
        isInterpretationChecked,
        fjudCategoryList,
        fjudHighCourtList,
        fjudDistrictCourtList,
        fjudOtherCourtList,
        fjudeCategoryList,
        fjudeCourtList,
      };
      localStorage.setItem('searchCriteria', JSON.stringify(searchCriteria));
    }).catch((errMsg) => {
      dispatch(searchFailure({ errMsg }));
    })
  }
}

export function doClickSearchResult(esId, link) {
  return dispatch => {
    // dispatch(postRequest());
    createClickSearchResultActivity(esId, link).then(data => {
      // dispatch(postSuccess());
    }).catch((errMsg) => {
      // dispatch(postFailure({ errMsg }));
    })
  }
}

export function doGetSearch(index, page, cacheId=null) {
  return (dispatch, getState) => {
    if (cacheId === null || cacheId === undefined) {
      const state = getState();
      cacheId = state.getIn(['search', 'cacheId']);
    }
    if (!cacheId) {
      return;
    }
    dispatch(searchRequest());
    getSearches(cacheId, index, page).then(data => {
      dispatch(updateSearchResult(data));
      dispatch(searchSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(searchFailure({ errMsg }));
    })
  }
}

export function doClearSearchResult() {
  return dispatch => {
    if (localStorage.hasOwnProperty('searchCriteria') === true) {
      localStorage.removeItem('searchCriteria');
    }
    dispatch(updateSearchResult({ totalHits: 0, hitList: [], cacheId: '' }));
  }
}

export function doSubmitAddJudgment(caseId, judicialId, esId) {
  return dispatch => {
    dispatch(postRequest());
    createJudgmentFiles(caseId, judicialId, esId).then(data => {
      dispatch(postSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export const updateCaseList = createAction(types.UPDATE_CASE_LIST)
export function doLoadCaseNames() {
  return dispatch => {
    dispatch(getRequest());
    getCaseNames().then(data => {
      dispatch(updateCaseList(data));
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}