import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getMailroomSentList,
  createMailroomSent,
  deleteMailroomSentFile,
  uploadMailroomSentFile,
  downloadMailroomSentFile,
  getDocketList,
  updateSent,
  deleteSent
} from '../../utils/api';
import * as config from '../../config'

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)


export const updateSentList = createAction(types.UPDATE_RECEIVED_LIST)
export const updateCsvRowList = createAction(types.UPDATE_CSV_ROW_LIST)
export function doLoadSentList(action) {
  return (dispatch, getState) => {
    const state = getState();
    let sends = state.getIn(['mailroom', 'searchMailroom', 'sends']);
    if(action === 'search') {
      dispatch(updateSentList(sends.toJS()));
      const csvRowList = [['函文名稱', '案號', '當事人', '類型', '遞送方式', '發送日', '收受者', '收受日']];
        sends.toJS().forEach(sent => {
          csvRowList.push([
            sent.name,
            sent.docketName,
            sent.custodianName,
            sent.typeName,
            sent.deliveryTypeName,
            sent.sentDate,
            sent.recipient,
            sent.receivedDate
          ])
        })
        dispatch(updateCsvRowList(csvRowList));
    } else {
      dispatch(getRequest());
      getMailroomSentList().then(sentList => {
        dispatch(updateSentList(sentList));
        const csvRowList = [['函文名稱', '案號', '當事人', '類型', '遞送方式', '發送日', '收受者', '收受日']];
        sentList.forEach(sent => {
          csvRowList.push([
            sent.name,
            sent.docketName,
            sent.custodianName,
            sent.typeName,
            sent.deliveryTypeName,
            sent.sentDate,
            sent.recipient,
            sent.receivedDate
          ])
        })
        dispatch(updateCsvRowList(csvRowList));
        dispatch(getSuccess());
      }).catch((errMsg) => {
        dispatch(getFailure({ errMsg }));
      })
    }
  }
}


export function doDeleteSentFile(fileId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteMailroomSentFile(fileId).then(data => {
      dispatch(doLoadSentList());
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}


export function doUploadSentFile(sentId, fileList) {
  return dispatch => {
    dispatch(postRequest());
    uploadMailroomSentFile(sentId, fileList).then(data => {
      dispatch(doLoadSentList());
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doDownloadSentFile(fileId, fileName) {
  return dispatch => {
    downloadMailroomSentFile(fileId, fileName).then(data => {
    }).catch((errMsg) => {
    })
  }
}

export const updateDocketList = createAction(types.UPDATE_DOCKET_LIST)
export function doLoadDocketList(contain, size) {
  return dispatch => {
    getDocketList(contain, size).then(data => {
      dispatch(updateDocketList(data));
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export function doUpdateSent(sentId, name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId) {
  return dispatch => {
    dispatch(putRequest());
    updateSent(sentId, name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId).then(data => {
      dispatch(doLoadSentList());
      dispatch(putSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDeleteSent(sentId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteSent(sentId).then(data => {
      dispatch(doLoadSentList());
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}