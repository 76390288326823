import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, message, Input, Spin } from 'antd';
import { CASE_STATEID, CASE_TYPE } from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const { TextArea } = Input;
const DEFAULT_CASE = {
  name: '',
  appointNoId: '',
  typeId: '',
  statusId: '',
  relatedList: [],
  keywordList: [],
  plaintiffList: [],
  defendantList: [],
  lawNo: [],
  appointContent: '',
  appointNote: '',
  // docketList: []
}
// const DEFAULT_DOCKET = {
//   level: 1,
//   courts: '',
//   trial: '',
//   year: '',
//   word: '',
//   no: '',
//   unit: '',
//   ext: ''
// }

class AppointmentCaseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      caseDetail: DEFAULT_CASE,
      // docket: DEFAULT_DOCKET
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleEditMode(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.appointmentId !== this.props.appointmentId && this.state.editMode === true) {
      this.onToggleEditMode(false);
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onToggleEditMode(editMode) {
    if (editMode === true) {
      this.setState({ 
        editMode, 
        caseDetail: this.props.caseDetail.toJS()
      });
    } else {
      this.setState({ editMode });
    }
    this.props.onChangeIsEditing(editMode);
  }
  onChangeCase(key, value) {
    let { caseDetail } = this.state;
    caseDetail = JSON.parse(JSON.stringify(caseDetail));
    caseDetail[key] = value;
    this.setState({ caseDetail })
  }
  onSubmitCase() {
    const { caseDetail } = this.state;
    if (!caseDetail.name || !caseDetail.appointNoId) {
      return message.error('請輸入必填欄位，「所內編號」、「委任案由」');
    }
    this.props.onUpdateCase(
      this.props.appointmentId, 
      caseDetail.name, 
      caseDetail.appointNoId, 
      caseDetail.typeId, 
      caseDetail.statusId, 
      caseDetail.relatedList, 
      caseDetail.keywordList,
      caseDetail.plaintiffList,
      caseDetail.defendantList,
      caseDetail.lawNo,
      caseDetail.appointContent,
      caseDetail.appointNote
    );
  }
  render() {
    const { editMode, caseDetail } = this.state;
    if (!this.props.caseDetail || this.props.caseDetail.size === 0) {
      return null;
    }
    return (
      <div className="appointmentcase-section section-col-item">
        {
          editMode
          ?
            <form onSubmit={e => {e.preventDefault();this.onSubmitCase()}}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>取消</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>更新</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title required">所內編號：</div>
                  <div className="col-edit">
                    <Input className="col-txt"
                      size="small"
                      type="text"
                      value={caseDetail.appointNoId}
                      onChange={e => this.onChangeCase('appointNoId', e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title required">委任案由：</div>
                  <div className="col-edit">
                    <Input className="col-txt"
                      size="small"
                      type="text"
                      value={caseDetail.name}
                      onChange={e => this.onChangeCase('name', e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title required">案件種類：</div>
                  <Select className="col-edit"
                    size="small"
                    showSearch
                    value={caseDetail.typeId}
                    onChange={value => this.onChangeCase('typeId', value)}
                  >
                    {
                      CASE_TYPE.map(caseType =>
                        <Option key={`casetypeedit-${caseType.id}`} value={caseType.id}>{caseType.name}</Option>
                      )
                    }
                  </Select>
                </div>
                <div className="col-item">
                  <div className="col-title">委任狀態：</div>
                  <div className="col-edit">
                    <Select className="col-edit"
                      size="small"
                      value={CASE_STATEID[caseDetail.statusId]}
                      onChange={value => this.onChangeCase('statusId', value)}
                    >
                      {
                        Object.entries(CASE_STATEID).map(([key, value]) =>
                          <Option key={key} value={key}>{value}</Option>
                        )
                      }
                    </Select>
                  </div>
                </div>
                {
                  caseDetail.typeId !== 6
                  &&
                  <>
                    <div className="col-item">
                      <div className="col-title">
                        { CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff }：
                      </div>
                      <div className="col-text">
                        <Select className="col-edit"
                          size="small"
                          mode="tags"
                          dropdownStyle={{ display: 'none' }}
                          value={caseDetail.plaintiffList}
                          onChange={value => this.onChangeCase('plaintiffList', value)}
                          tokenSeparators={[',']}>
                        </Select>
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">
                        { CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant }：
                      </div>
                      <div className="col-text">
                        <Select className="col-edit"
                          size="small"
                          mode="tags"
                          dropdownStyle={{ display: 'none' }}
                          value={caseDetail.defendantList}
                          onChange={value => this.onChangeCase('defendantList', value)}
                          tokenSeparators={[',']}>
                        </Select>
                      </div>
                    </div>
                  </>
                }
                <div className="col-item">
                  <div className="col-title">案件相關：</div>
                  <Select className="col-edit"
                    size="small"
                    placeholder="請輸入關鍵字。例：損害賠償"
                    mode="multiple"
                    value={caseDetail.relatedList}
                    notFoundContent={this.props.isGettingCaseRelated ? <Spin size="small" /> : '無相關資料'}
                    filterOption={false}
                    onSearch={value => this.props.onLoadRelatedList(value, 10)}
                    onChange={value => this.onChangeCase('relatedList', value)}
                  >
                    {
                      this.props.caseRelateList && this.props.caseRelateList.map(relate =>
                        <Option key={`caserelated-${relate}`} value={relate}>{`${relate}`}</Option>
                      )
                    }
                  </Select>
                </div>
                <div className="col-item">
                  <div className="col-title">法條相關：</div>
                  <Select className="col-edit"
                    size="small"
                    mode="tags" dropdownStyle={{ display: 'none' }}
                    defaultValue={caseDetail.lawNo}
                    onChange={value => this.onChangeCase('lawNo', value)}
                    tokenSeparators={[',']}>
                  </Select>
                </div>
                <div className="col-item">
                  <div className="col-title">關鍵字：</div>
                  <Select className="col-edit"
                    size="small"
                    mode="tags" dropdownStyle={{ display: 'none' }}
                    defaultValue={caseDetail.keywordList}
                    onChange={value => this.onChangeCase('keywordList', value)}
                    tokenSeparators={[',']}>
                  </Select>
                </div>
                <div className="col-item">
                  <div className="col-title">委任內容：</div>
                  <TextArea className="col-edit"
                    rows="3"
                    size="small"
                    type="text"
                    value={caseDetail.appointContent}
                    onChange={e => this.onChangeCase('appointContent', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">備註：</div>
                  <TextArea className="col-edit"
                    rows="3"
                    size="small"
                    type="text"
                    value={caseDetail.appointNote}
                    onChange={e => this.onChangeCase('appointNote', e.target.value)}
                  />
                </div>
              </div>
            </form>
          :
            <>
              <div className="col-item-tool">
                <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">所內編號：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['appointNoId'])
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">委任案由：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['name'])
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">案件種類：</div>
                  <div className="col-text">
                    { 
                      CASE_TYPE.find(ct => ct.id === this.props.caseDetail.getIn(['typeId'])).name
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">委任狀態：</div>
                  <div className="col-text">
                    { 
                      CASE_STATEID[this.props.caseDetail.getIn(['statusId'])] 
                    }
                  </div>
                </div>
                {
                  this.props.caseDetail.getIn(['typeId']) !== 6
                  &&
                  <>
                    <div className="col-item">
                      <div className="col-title">{ CASE_TYPE.find(ct => ct.id === this.props.caseDetail.getIn(['typeId'])).plaintiff }：</div>
                      <div className="col-text">
                        {
                          this.props.caseDetail.getIn(['plaintiffList']).map(plaintiff =>
                            <span key={`plaintiff-${plaintiff}`}>{ plaintiff }</span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">{ CASE_TYPE.find(ct => ct.id === this.props.caseDetail.getIn(['typeId'])).defendant }：</div>
                      <div className="col-text">
                        {
                          this.props.caseDetail.getIn(['defendantList']).map(defendant =>
                            <span key={`defendant-${defendant}`}>{ defendant }</span>
                          )
                        }
                      </div>
                    </div>
                  </>
                }
                <div className="col-item">
                  <div className="col-title">案件相關：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['relatedList']).map(related =>
                        <span key={`related-${related}`}>{ related }</span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">法條相關：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['lawNo']).map(law =>
                        <span key={`law-${law}`}>{ law }</span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">關鍵字：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['keywordList']).map(keyword =>
                        <span key={`keyword-${keyword}`}>{ keyword }</span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">委任內容：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['appointContent'])
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">備註：</div>
                  <div className="col-text">
                    {
                      this.props.caseDetail.getIn(['appointNote'])
                    }
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    )
  }
}
export default withRouter(AppointmentCaseComponent);