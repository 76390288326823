import { fromJS  } from 'immutable';

export const State = fromJS({
  isSearching: false,
  isPosting: false,
  isGetting: false,
  isFetching: true,
  courtIdList: [],
  courts: {},
  typeIdList: [],
  types: {},
  dockets: {},
  docketNames: {},
  docketIdList: [],
  docketList: [],
  deliveryTypeIdList:[],
  deliveryTypes: {},
  errMsg: '',
  searchMailroom: {
    sends: [],
    receives: [],
  },
});