import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'appointmentNotes/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentNotes/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentNotes/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentNotes/UPDATE_NOTES': (state, { payload }) => {
    return state.setIn(['notes'], fromJS(payload));
  },
}, State);

export default Reducer;