import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { message, Input } from 'antd';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { TextArea } = Input;
// const costMaxLength = /d(8,8)/;

class CasesReceiptComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHolding: false,
      isEditing: false,
      lawer: {
        address: '',
        lastName: '',
        firstName: '',
        faxNo: '',
        lawFirmName: '',
      },
      tip: '請依照所得稅法規定超過 20,000 元代扣繳稅款 10 ％，並將扣繳憑單交收款人(所得人)',
      receipt: {
        category: '',
        cost: '',
        date: '',
        name: '',
        note: '',
        reason: '',
      },
      date: {
        year: '',
        month: '',
        day: '',
      },
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem('userId');
    this.props.onLoadProfile(userId);
    this.props.onLoadExportReceipt(this.props.caseId);
    
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.casesReceipt !== '' && prevProps.casesReceipt !== this.props.casesReceipt){
      this.onClickUpdateReceipt('init', this.props.casesReceipt);
    }
    if(prevProps.lawer !== this.props.lawer) {
      this.onUpdateLawer('init', this.props.lawer)
    }
  }
  onUpdateLawer(action, value) {
    if(action === 'init') {
      this.setState({
        lawer: {
          address: value.getIn(['address']) ? value.getIn(['address']) : '',
          lastName: value.getIn(['lastName']) ? value.getIn(['lastName']) : '',
          firstName: value.getIn(['firstName']) ? value.getIn(['firstName']) : '',
          faxNo: value.getIn(['faxNo']) ? value.getIn(['faxNo']) : '',
          lawFirmName: value.getIn(['lawFirmName']) ? value.getIn(['lawFirmName']) : '',
        }
      })
    };
    if(action === 'edit') {
      this.setState({ lawer: this.state.lawer })
    };
    if(action === 'save') {
      this.setState({ lawer: this.state.lawer })
    }
  }
  onClickUpdateReceipt(action, value) {
    if(action === 'init') {
      if(value === null){
        this.setState({ isHolding: false })
      }else{
        this.setState({
          isHolding: true,
          receipt: {
            category: value.getIn(['category']),
            cost: value.getIn(['cost']),
            date: value.getIn(['date']),
            name: value.getIn(['name']),
            note: value.getIn(['note']),
            reason: value.getIn(['reason']),
          },
        })
      }
    }
    if(action === 'edit') {
      this.setState({
        isEditing: true,
      })
    }
    if(action === 'save') {
      let receipt = {}
      receipt = {
        category: this.state.receipt.category ? this.state.receipt.category : '',
        cost: this.state.receipt.cost ? this.state.receipt.cost : '',
        date: this.state.receipt.date ? moment(this.state.receipt.date).format('YYYY/MM/DD') : '',
        name: this.state.receipt.name ? this.state.receipt.name : '',
        note: this.state.receipt.note ? this.state.receipt.note : '',
        reason: this.state.receipt.reason ? this.state.receipt.reason : '',
      };
      this.setState({
        lawer: this.state.lawer,
        tip: this.state.tip,
      })
      if(this.state.receipt.category === '' && this.state.receipt.cost === '' && this.state.receipt.date === '' && this.state.receipt.name === '' && this.state.receipt.note === '' && this.state.receipt.reason === '' ){
        receipt = null;
      }else {
        this.props.onUpdateReceipt(this.props.caseId, this.state.receipt.category, this.state.receipt.cost, this.state.receipt.date, this.state.receipt.name, this.state.receipt.note, this.state.receipt.reason);
        this.setState({ isEditing: false })
      }
      if(receipt === null){
        this.setState({
          isEditing: false,
          receipt: {
            category: '',
            cost: '',
            date: '',
            name: '',
            note: '',
            reason: '',
          }
        })
      }else{
        this.setState({
          isEditing: false,
          receipt,
        })
      }
    }
  }
  onChangeLawer(key, value) {
    let { lawer } = this.state;
    lawer = JSON.parse(JSON.stringify(lawer));
    lawer[key] = value;
    this.setState({ lawer })
  }
  onChangeReceipt(key, value) {
    let { receipt } = this.state;
    receipt = JSON.parse(JSON.stringify(receipt));
    receipt[key] = value;
    this.setState({ receipt })
  }

  render() {
    const { isHolding, isEditing, receipt, lawer, date, tip } = this.state;
  	return (
      <div className="casesReceipt-section casesReceipt">
        <div className="casesReceipt-report">
          <div className="navBar noPrint">
            <button><Link to={`/cases`}>返回委任管理列表</Link></button>
            <div className="title">委任收據預覽列印</div>
          </div>
          {
            isHolding
            ?
            <div className="case-receipt-wrap">
              <div className="tool">
                {
                  isEditing
                  ?
                  <div>
                    <button onClick={e => this.setState({ isEditing: false })}>取消</button>
                    <button onClick={e => {this.onClickUpdateReceipt('save', ''); this.onUpdateLawer('save', '')}}>預覽列印</button>
                  </div>
                  :
                  <button onClick={e => {this.onClickUpdateReceipt('edit', receipt);  this.onUpdateLawer('edit', lawer)}}>編輯</button>
                }
              </div>
              {
                isEditing ?
                  <div className="case-receipt-wrap">
                    <div className="case-receipt">
                      <div className="case-receipt-title">收據</div>
                      <div className="case-receipt-name">
                        <div className="title">茲收到：</div>
                        <Input
                          placeholder="收據人"
                          allowClear
                          type="text"
                          value={receipt.name}
                          onChange={e => this.onChangeReceipt('name', e.target.value)}
                        />
                      </div>
                      <div className="case-receipt-cost">
                        <div className="text">新臺幣</div>
                        <div className="edit">
                          <Input className="input"
                            placeholder="新臺幣"
                            maxLength={8}
                            allowClear
                            type="number"
                            value={receipt.cost}
                            onChange={e => this.onChangeReceipt('cost', e.target.value)}
                          />
                        </div>
                        <div className="text">元整</div>
                      </div>
                      <div className="case-receipt-category">
                        <div className="title">請款類別：</div>
                        <Input
                          placeholder="請款類別"
                          allowClear
                          type="text"
                          value={receipt.category}
                          onChange={e => this.onChangeReceipt('category', e.target.value)}
                        />
                      </div>
                      <div className="case-receipt-reason">
                        <div className="title">事由：</div>
                        <TextArea
                          rows="3"
                          placeholder="領款事由"
                          allowClear
                          type="text"
                          value={receipt.reason}
                          onChange={e => this.onChangeReceipt('reason', e.target.value)}
                        />
                      </div>
                      <div className="case-receipt-note">
                        <div className="title">備註：</div>
                        <TextArea
                          rows="2"
                          placeholder="領款備註"
                          allowClear
                          type="text"
                          value={receipt.note}
                          onChange={e => this.onChangeReceipt('note', e.target.value)}
                        />
                      </div>
                      <div className="case-receipt-">此據</div>
                      <div className="case-receipt-lawer">
                      <div className="data-item">
                        <div className="data-name title"></div>
                        <div className="data-name content">
                          <Input className="input"
                            placeholder="公司"
                            allowClear
                            type="text"
                            value={lawer.lawFirmName}
                            onChange={e => this.onChangeLawer('lawFirmName', e.target.value)}
                          />
                        </div>
                      </div>
                        <div className="data-item">
                          <div className="data-name title">律師：</div>
                          <div className="data-name content">
                            <Input className="input"
                              placeholder=""
                              allowClear
                              type="text"
                              value={lawer.lastName}
                              onChange={e => this.onChangeLawer('lastName', e.target.value)}
                            />
                            <Input className="input"
                              placeholder="律師"
                              allowClear
                              type="text"
                              value={lawer.firstName}
                              onChange={e => this.onChangeLawer('firstName', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="data-item">
                          <div className="data-address title">地址：</div>
                          <div className="data-address content">
                            <Input className="input"
                              placeholder="地址"
                              allowClear
                              type="text"
                              value={lawer.address}
                              onChange={e => this.onChangeLawer('address', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="data-item">
                          <div className="data-phone title">電話：</div>
                          <div className="data-phone content">
                            <Input className="input"
                              placeholder="電話"
                              allowClear
                              type="text"
                              value={lawer.faxNo}
                              onChange={e => this.onChangeLawer('faxNo', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="case-receipt-date">
                        <span className="text">中華民國</span>
                        <span className="edit">
                          <Input
                            placeholder="年"
                            allowClear
                            type="number"
                            value={date.year}
                            onChange={e => this.setState({ date: { ...date, year: e.target.value } })}
                          />
                        </span>
                        <span className="text">年</span>
                        <span className="edit">
                          <Input
                            placeholder="月"
                            allowClear
                            type="number"
                            value={date.month}
                            onChange={e => this.setState({ date: { ...date, month: e.target.value } })}
                          />
                        </span>
                        <span className="text">月</span>
                        <span className="edit">
                          <Input
                            placeholder="日"
                            allowClear
                            type="number"
                            value={date.day}
                            onChange={e => this.setState({ date: { ...date, day: e.target.value } })}
                          />
                        </span>
                        <span className="text">日</span>
                      </div>
                      <div className="case-receipt-tip">
                        <TextArea
                          rows="2"
                          placeholder="提示"
                          allowClear
                          type="text"
                          value={tip}
                          onChange={e => this.setState({ tip: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="case-receipt">
                      <div className="case-receipt-title">收據</div>
                      <div className="case-receipt-name">
                        <div className="title">茲收到：</div>
                        <div className="text-editable">{receipt.name}</div>
                      </div>
                      <div className="case-receipt-cost">
                        <div className="text">新臺幣  </div>
                        <div className="text-editable">{receipt.cost}</div>
                        <div className="text">元整</div>
                      </div>
                      <div className="case-receipt-category">
                        <div className="title">請款類別：</div>
                        <div className="text-editable">{receipt.category}</div>
                      </div>
                      <div className="case-receipt-reason">
                        <div className="title">事由：</div>
                        <div className="text-editable">{receipt.reason}</div>
                      </div>
                      <div className="case-receipt-note">
                        <div className="title">備註：</div>
                        <div className="text-editable">{receipt.note}</div>
                      </div>
                      <div className="case-receipt-">此據</div>
                      <div className="case-receipt-lawer">
                      <div className="data-item">
                        <div className="data-name title"></div>
                        <div className="data-name content">
                          <div className="text-editable">{lawer.lawFirmName}</div>
                        </div>
                      </div>
                        <div className="data-item">
                          <div className="data-name title">律師：</div>
                          <div className="data-name content">
                            <div className="text-editable">{lawer.lastName}{lawer.firstName}  律師</div>
                          </div>
                        </div>
                        <div className="data-item">
                          <div className="data-address title">地址：</div>
                          <div className="data-address content">
                            <div className="text-editable">{lawer.address}</div>
                          </div>
                        </div>
                        <div className="data-item">
                          <div className="data-phone title">電話：</div>
                          <div className="data-phone content">
                            <div className="text-editable">{lawer.faxNo}</div>
                          </div>
                        </div>
                      </div>
                      <div className="case-receipt-date">
                        <span className="text">中華民國</span>
                        <span className="edit">{date.year}</span>
                        <span className="text">年</span>
                        <span className="edit">{date.month}</span>
                        <span className="text">月</span>
                        <span className="edit">{date.day}</span>
                        <span className="text">日</span>
                      </div>
                      <div className="case-receipt-tip">{tip}</div>
                    </div>
                  </div>
                :
                <div className="case-receipt-wrap">
                  <div className="case-receipt">
                    <div className="case-receipt-title">收據</div>
                    <div className="case-receipt-name">
                      <div className="title">茲收到：</div>
                      <div className="text-editable">{receipt.name}</div>
                    </div>
                    <div className="case-receipt-cost">
                      <div className="text">新臺幣  </div>
                      <div className="text-editable">{receipt.cost}</div>
                      <div className="text">元整</div>
                    </div>
                    <div className="case-receipt-category">
                      <div className="title">請款類別：</div>
                      <div className="text-editable">{receipt.category}</div>
                    </div>
                    <div className="case-receipt-reason">
                      <div className="title">事由：</div>
                      <div className="text-editable">{receipt.reason}</div>
                    </div>
                    <div className="case-receipt-note">
                      <div className="title">備註：</div>
                      <div className="text-editable">{receipt.note}</div>
                    </div>
                    <div className="case-receipt-">此據</div>
                    <div className="case-receipt-lawer">
                    <div className="data-item">
                      <div className="data-name title"></div>
                      <div className="data-name content">
                        <div className="text-editable">{lawer.lawFirmName}</div>
                      </div>
                    </div>
                      <div className="data-item">
                        <div className="data-name title">律師：</div>
                        <div className="data-name content">
                          <div className="text-editable">{lawer.lastName}{lawer.firstName}  律師</div>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-address title">地址：</div>
                        <div className="data-address content">
                          <div className="text-editable">{lawer.address}</div>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-phone title">電話：</div>
                        <div className="data-phone content">
                          <div className="text-editable">{lawer.faxNo}</div>
                        </div>
                      </div>
                    </div>
                    <div className="case-receipt-date">
                      <span className="text">中華民國</span>
                      <span className="edit">{date.year}</span>
                      <span className="text">年</span>
                      <span className="edit">{date.month}</span>
                      <span className="text">月</span>
                      <span className="edit">{date.day}</span>
                      <span className="text">日</span>
                    </div>
                    <div className="case-receipt-tip">{tip}</div>
                  </div>
                  <div className="case-receipt">
                    <div className="case-receipt-title">收據</div>
                    <div className="case-receipt-name">
                      <div className="title">茲收到：</div>
                      <div className="text-editable">{receipt.name}</div>
                    </div>
                    <div className="case-receipt-cost">
                      <div className="text">新臺幣  </div>
                      <div className="text-editable">{receipt.cost}</div>
                      <div className="text">元整</div>
                    </div>
                    <div className="case-receipt-category">
                      <div className="title">請款類別：</div>
                      <div className="text-editable">{receipt.category}</div>
                    </div>
                    <div className="case-receipt-reason">
                      <div className="title">事由：</div>
                      <div className="text-editable">{receipt.reason}</div>
                    </div>
                    <div className="case-receipt-note">
                      <div className="title">備註：</div>
                      <div className="text-editable">{receipt.note}</div>
                    </div>
                    <div className="case-receipt-">此據</div>
                    <div className="case-receipt-lawer">
                    <div className="data-item">
                      <div className="data-name title"></div>
                      <div className="data-name content">
                        <div className="text-editable">{lawer.lawFirmName}</div>
                      </div>
                    </div>
                      <div className="data-item">
                        <div className="data-name title">律師：</div>
                        <div className="data-name content">
                          <div className="text-editable">{lawer.lastName}{lawer.firstName}  律師</div>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-address title">地址：</div>
                        <div className="data-address content">
                          <div className="text-editable">{lawer.address}</div>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-phone title">電話：</div>
                        <div className="data-phone content">
                          <div className="text-editable">{lawer.faxNo}</div>
                        </div>
                      </div>
                    </div>
                    <div className="case-receipt-date">
                      <span className="text">中華民國</span>
                      <span className="edit">{date.year}</span>
                      <span className="text">年</span>
                      <span className="edit">{date.month}</span>
                      <span className="text">月</span>
                      <span className="edit">{date.day}</span>
                      <span className="text">日</span>
                    </div>
                    <div className="case-receipt-tip">{tip}</div>
                  </div>
                </div>
              }
            </div>
            :
            <div className="case-receipt-tip">此筆委任尚無收據</div>
          }
        </div>
      </div>
	  )
  }
}
export default withRouter(CasesReceiptComponent);
