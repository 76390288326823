import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import Modal from 'antd/lib/modal';
import { 
  updatePassword
} from '../../utils/api';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export const doUpdateOldPassword = createAction(types.UPDATE_OLD_PASSWORD)
export const doUpdateNewPassword = createAction(types.UPDATE_NEW_PASSWORD)
export const doUpdateConfirmPassword = createAction(types.UPDATE_CONFIRM_PASSWORD)
export const doUpdateErrorMessage = createAction(types.UPDATE_ERROR_MESSAGE)

export function doSubmitResetPassword(history) {
  return (dispatch, getState) => {
  	const state = getState();
    const oldPassword = state.getIn(['resetPasswordWithOldPassword', 'oldPassword']);
  	const newPassword = state.getIn(['resetPasswordWithOldPassword', 'newPassword']);
  	const confirmPassword = state.getIn(['resetPasswordWithOldPassword', 'confirmPassword']);
    const userId = localStorage.getItem('userId');
    dispatch(doUpdateErrorMessage({ message: ''}));

    if (oldPassword === newPassword) {
      dispatch(doUpdateOldPassword({ oldPassword: '' }));
      dispatch(doUpdateNewPassword({ newPassword: '' }));
      dispatch(doUpdateConfirmPassword({ confirmPassword: '' }));
      return dispatch(doUpdateErrorMessage({ message: '密碼相同，請重新輸入'}));
    }

    if (newPassword !== confirmPassword) {
      dispatch(doUpdateOldPassword({ oldPassword: '' }));
      dispatch(doUpdateNewPassword({ newPassword: '' }));
      dispatch(doUpdateConfirmPassword({ confirmPassword: '' }));
      return dispatch(doUpdateErrorMessage({ message: '新密碼不一致，請重新輸入'}));
    }
    updatePassword(userId, oldPassword, newPassword).then(data => {
      Modal.success({
        title: `資料更新成功`,
        okText: `確認`,
        onOk: () => {},
      });
    }).catch(() => {
      return dispatch(doUpdateErrorMessage({ message: '請重新檢查。新密碼至少要 8 個字元以上且含有字母、數字、符號'}));
    })
  }
}
