export const getIsSearching = state => state.getIn(['mailroom', 'isSearching']);
export const getIsPosting = state => state.getIn(['mailroom', 'isPosting']);
export const getErrMsg = state => state.getIn(['mailroom', 'errMsg']);
export const getIsFetching = state => state.getIn(['mailroom', 'isFetching']);

export const getCourtIdList = state => state.getIn(['mailroom', 'courtIdList']);
export const getCourts = state => state.getIn(['mailroom', 'courts']);
export const getTypeIdList = state => state.getIn(['mailroom', 'typeIdList']);
export const getTypes = state => state.getIn(['mailroom', 'types']);

export const getDockets = state => state.getIn(['mailroom', 'dockets']);
export const getDocketNames = state => state.getIn(['mailroom', 'docketNames']);
export const getDocketIdList = state => state.getIn(['mailroom', 'docketIdList']);
export const getDocketList = state => state.getIn(['mailroom', 'docketList']);

export const getDeliveryTypeIdList = state => state.getIn(['mailroom', 'deliveryTypeIdList']);
export const getDeliveryTypes = state => state.getIn(['mailroom', 'deliveryTypes']);