import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentCaseComponent from './component';


const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isPutting: selectors.getIsPutting(state),
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
  isGettingCaseRelated: selectors.getIsGettingCaseRelated(state),
  caseRelateList: selectors.getCaseRelateList(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateCase: (appointmentId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote) => {
    dispatch(actions.doUpdateCase(appointmentId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote));
  },
  onLoadRelatedList: (contain, size) => {
    dispatch(actions.doLoadRelatedList(contain, size));
  },
   onDeleteDocket: (docketId) => {
    dispatch(actions.doDeleteDocket(docketId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentCaseComponent);