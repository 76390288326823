import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  errMsg: null,
  isSearching: false,
  searchErrMsg: null,
  totalHits: {
    'all': {},
    'fjud': {},
    'fjude': {},
    'find_court': {},
    'find_execute': {}
  },  
  hitList: [],
  cacheId: '',
  caseList: []
});