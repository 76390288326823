import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { message } from 'antd';
import dnaBgIcon from '../../assets/img/dna-bg.png'
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import FullSpinner from '../../utils/FullSpinner';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      password: '',
      intervalId: null,
      countdown: -1,
      smsToken: ''
    };
  }
  componentDidMount() {
    localStorage.clear();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg !== null) {
        message.error(this.props.errMsg);
      } else if (this.props.redirectTo) {
        this.props.history.push(this.props.redirectTo);
      }
    }
  }
  componentWillUnmount = () => {
     clearInterval(this.state.intervalId);
  }
  onChangeAccount = (account) => {
    this.setState({ account });
  }
  onChangePassword = (password) => {
    this.setState({ password });
  }
  onSubmitLogin = () => {
    const { account, password } = this.state;
    this.props.onSubmitLogin(account, password);
  }
  onClickSendSms = () => {
    this.props.onSendSms();
    let intervalId = setInterval(this.countdownTimer, 1000);
    this.setState({ intervalId, countdown: 60 });
  }
  countdownTimer = () => {
    var countdown = this.state.countdown - 1;
    this.setState({ countdown });
    if(countdown < 0) { 
       clearInterval(this.state.intervalId);
    }
  }
  conChangeSmsToken = (smsToken) => {
    this.setState({ smsToken })
  }
  onSubmitSms =() => {
    const { smsToken } = this.state;
    this.props.onCheckSms(smsToken);
  }
  render() {
    const { account, password, countdown, smsToken } = this.state;
    return (
      <div className="login-section">
        <img className="login-logo-bg" alt="" src={dnaBgIcon}/>
        <Link to={`/`}><img className="login-logo" alt="" src={dnaLogoIcon}/></Link>
        {
          this.props.twoFactorAuthenticationId === 1 ?
            <form className="login-form" onSubmit={e => {e.preventDefault(); this.onSubmitLogin()}}>
              <label className="login-form-label" htmlFor="login-account">Account</label>
              <input
                placeholder="e-mail"
                id="login-account"
                className="login-form-input"
                type="text"
                value={account}
                onChange={e => this.onChangeAccount(e.target.value)}
                required
              />
              <label className="login-form-label" htmlFor="login-password">
                Password
                <Link className="login-form-forgotpw" to={`/forgot-password`}>Forgot Password?</Link>
              </label>
              <input
                placeholder="password"
                id="login-password"
                className="login-form-input"
                type="password"
                value={password}
                onChange={e => this.onChangePassword(e.target.value)}
                required
              />
              <div className="login-form-bottom">
                <button className="login-form-submit-btn" type="submit" disabled={this.props.isPosting}>Login</button>
              </div>
              <div className="login-newmember">
                還沒有 LAW DNA 帳號？
                <Link to={`/registration`}>註冊</Link> or
                <a href="http://www.ediscovery.com.tw/lawdna.html">關於 LAW DNA</a>
              </div>
            </form>
          : this.props.twoFactorAuthenticationId === 2 ?
            <>
              <form className="login-form" onSubmit={e => {e.preventDefault(); this.onSubmitSms()}}>
                <button className="btn-input" onClick={e => this.onClickSendSms()} disabled={countdown >= 0}>
                  <FontAwesomeIcon icon={fas.faSms} />發送驗證碼簡訊
                </button>{ countdown >= 0 && `${countdown}秒後可重新發送` }
                <label className="login-form-label" htmlFor="login-account">SMS Token</label>
                <input
                  id="login-account"
                  className="login-form-input"
                  type="text"
                  value={smsToken}
                  onChange={e => this.conChangeSmsToken(e.target.value)}
                  required
                />
                <div className="login-form-bottom">
                  <button className="login-form-submit-btn" type="submit" disabled={this.props.isPosting}>Login</button>
                </div>
              </form>
            </>
          :
            null
        }
        {
          this.props.errMsg
          &&
          <p className="err-msg">{this.props.errMsg}</p>
        }
        {
          (this.props.isPosting)
          &&
          <FullSpinner />
        }
      </div>
    )
  }
}

export default withRouter(LoginComponent);