import {fromJS} from 'immutable';

export const State = fromJS({
  isLoading: false,
  errMsg: "",
  notificationList: [],
  historyList: [],
  historyCount: 0,
  showRWDNavbar: false,
});
