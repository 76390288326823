import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { getNotes } from '../../utils/api';
import { convertBase64ToBinary } from "../../utils/convertBase64ToBinary";
import * as config from '../../config'
import { update } from 'immutable';


// common
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const updateNotes = createAction(types.UPDATE_NOTES)

export function doLoadNotes(previewNotesId) {
	return (dispatch, getState) => {
	  dispatch(getRequest());
	  getNotes(previewNotesId).then(data => {
		dispatch(updateNotes(data));
		dispatch(getSuccess());
	  }).catch((errMsg) => {
		dispatch(getFailure({ errMsg }));
	  })
	}
  }