import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
	'consultationsForm/POST_REQUEST': (state, { payload }) => {
		return state.setIn(['isPosting'], true)
					.setIn(['errMsg'], null);
	},
	'consultationsForm/POST_SUCCESS': (state, { payload }) => {
		return state.setIn(['isPosting'], false)
					.setIn(['errMsg'], null);
	},
	'consultationsForm/POST_FAILURE': (state, { payload }) => {
		return state.setIn(['isPosting'], false)
					.setIn(['errMsg'], payload.errMsg);
	},
	'consultationsForm/GET_REQUEST': (state, { payload }) => {
		return state.setIn(['isGetting'], true)
					.setIn(['errMsg'], null);
	},
	'consultationsForm/GET_SUCCESS': (state, { payload }) => {
		return state.setIn(['isGetting'], false)
					.setIn(['errMsg'], null);
	},
	'consultationsForm/GET_FAILURE': (state, { payload }) => {
		return state.setIn(['isGetting'], false)
					.setIn(['errMsg'], payload.errMsg);
	},
	'consultationsForm/UPDATE_LAWYER': (state, { payload }) => {
		return state.setIn(['lawyer'], payload);
	},
}, State);

export default Reducer;