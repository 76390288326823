import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getReport
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)


export const updateRowList = createAction(types.UPDATE_ROW_LIST)
export function doSearch(taxYear, taxable, nontaxable) {
  return dispatch => {
    dispatch(postRequest());
    getReport(taxYear, taxable, nontaxable).then(data => {
      dispatch(updateRowList(data));
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}