import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import FolderComponent from './component';

const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isPutting: selectors.getIsPutting(state),
  isFetching: selectors.getIsFetching(state),
  errMsg: selectors.getErrMsg(state),
  folderList: selectors.getFolderList(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadFolderList: () => {
    dispatch(actions.doLoadFolderList());
  },
  onUpdateFolder: (folderId, name) => {
    dispatch(actions.doUpdateFolder(folderId, name));
  },
  onSubmitNewFolder: (name) => {
    dispatch(actions.doSubmitNewFolder(name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderComponent);