import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  updateAppointmentFee
} from '../../utils/api';

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)

export function doUpdateFee(appointmentId, isTaxabled, taxAmount, appointFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason) {
  return dispatch => {
    dispatch(putRequest());
    updateAppointmentFee(appointmentId, isTaxabled, taxAmount, appointFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}