import React, {Component} from 'react';
import topBar from '../modules/topBar';
import navbar from '../modules/navbar';
import consultations from '../modules/consultations';

class ConsultationsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
  	return (
	    <div id="page-consultations">
        <topBar.container/>
        <div className="bottom-container">
          <navbar.container/>
          <div className="main-section">
            <consultations.container />
          </div>
        </div>
	    </div>
	  )
  }
}

export default ConsultationsPageComponent;
