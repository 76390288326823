import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  createConsultations_a,
  createConsultations_c,
  casesRelated
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const getCaseRelatedRequest = createAction(types.GET_CASE_RELATED_REQUEST)
export const getCaseRelatedSuccess = createAction(types.GET_CASE_RELATED_SUCCESS)
export const getCaseRelatedFailure = createAction(types.GET_CASE_RELATED_FAILURE)

export const updateErrMsg = createAction(types.UPDATE_ERR_MSG)

export function doSubmitCreateConsultations(consultationDate, appointorType, appointorData, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote) {
  return dispatch => {
    dispatch(postRequest());
    if( appointorType === '1' ){
      let appointor = appointorData;
      createConsultations_a(consultationDate, appointorType, appointor, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote).then(data => {
        dispatch(postSuccess());
        return;
      }).catch((errMsg) => {
        return dispatch(postFailure({ errMsg }));
      });
      return;
    }
    if( appointorType === '2' ){
      let company = appointorData;
      createConsultations_c(consultationDate, appointorType, company, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote).then(data => {
        dispatch(postSuccess());
        return;
      }).catch((errMsg) => {
        return dispatch(postFailure({ errMsg }));
      });
      return;
    }
  }
}

export const updateCaseRelateList = createAction(types.UPDATE_CASE_RELATE_LIST)
export function doLoadRelatedList(contain, size) {
  return dispatch => {
    dispatch(getCaseRelatedRequest());
    casesRelated(contain, size).then(data => {
      dispatch(updateCaseRelateList(data));
      dispatch(getCaseRelatedSuccess());
    }).catch((errMsg) => {
      dispatch(getCaseRelatedFailure({ errMsg }));
    })
  }
}