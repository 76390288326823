import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { message } from 'antd';
import Draggable from 'react-draggable';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class ReviewNotesDraggableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingNote: {
        visible: false
      },
      timer: null
    };
    this.onChangeNoteEditer = this.onChangeNoteEditer.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false && this.props.currentNoteId === this.props.note.id) {
      if (this.props.errMsg === null) {
        message.success('新增成功');
        this.props.onLoadPageNoteList(this.props.pageId);
        this.props.onToggleNoteDraggable(`${this.props.note.id}`, false)
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isPutting === true && this.props.isPutting === false && this.props.currentNoteId === this.props.note.id) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onLoadPageNoteList(this.props.pageId);
        this.onChangeNoteEditer('isEdit', false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isPuttingPosition === true && this.props.isPuttingPosition === false && this.props.currentNoteId === this.props.note.id) {
      if (this.props.errMsg === null) {
        this.props.onLoadPageNoteList(this.props.pageId);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (!prevProps.note.visible && this.props.note.visible === true) {
      this.initEditingNote();
    }
  }
  initEditingNote() {
    const editingNote = {};
    Object.entries(this.props.note).forEach(([key, value]) => {
      editingNote[key] = value;
    });
    this.setState({ editingNote });
  }
  onChangeNoteEditer(type, value) {
    const { editingNote } = this.state;
    editingNote[type] = value;
    this.setState({ editingNote })
  }
  handleDrag(e, data) {
    let { timer } = this.state;
    if (this.props.note.id !== 0) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        this.props.onSubmitPagePosition(this.props.pageId, this.props.note.id, editingNote.position);
      }, 300);
    }
    const editingNote = JSON.parse(JSON.stringify(this.state.editingNote));
    editingNote.position = { x: data.x, y: data.y };
    this.setState({ editingNote, timer });
  };
  render() {
    const { editingNote } = this.state;
    return (
      <>
        {
          this.props.note.visible
          ?
            <Draggable
              axis="both"
              handle=".handle"
              defaultPosition={this.props.note.position}
              position={editingNote.position}
              scale={1}
              onDrag={this.handleDrag}
            >
              <div className="note-model note-draggable">
                <div className="note-model-title handle">
                  {
                    editingNote.isEdit
                    ?
                      <input type="text" placeholder="請輸入標題..." value={editingNote.title} onChange={e => this.onChangeNoteEditer('title', e.target.value)}></input>
                    :
                      <span>{ this.props.note.title }</span>
                  }
                  <button onClick={e => this.props.onToggleNoteDraggable(`${this.props.note.id}`, false)}><FontAwesomeIcon icon={fas.faTimes}/></button>
                </div>
                {
                  editingNote.isEdit
                  ?
                    <div className="note-model-content">
                      <textarea rows="5" placeholder="請輸入筆記..." onChange={e => this.onChangeNoteEditer('content', e.target.value)} value={editingNote.content} />
                      <div className="note-model-content-tool">
                        {
                          this.props.note.id === 0
                          ?
                            <button onClick={e => this.props.onToggleNoteDraggable(`${this.props.note.id}`, false)}>關閉</button>
                          :
                            <button onClick={e => this.onChangeNoteEditer('isEdit', false)}>取消</button>
                        }
                        <button onClick={e => this.props.onSubmitPageNote(this.props.pageId, editingNote.id, editingNote.title, editingNote.content, editingNote.position)}>儲存</button>
                      </div>
                    </div>
                  :
                    <div className="note-model-content">
                      <div className="note-model-content-text">{ this.props.note.content }</div>
                      <div className="note-model-content-tool">
                        <button onClick={e => this.props.onToggleNoteDraggable(`${this.props.note.id}`, false)}>關閉</button>
                        <button onClick={e => this.onChangeNoteEditer('isEdit', true)}>編輯</button>
                      </div>
                    </div>
                }
              </div>
            </Draggable>
          :
            null
        }
      </>
    )
  }
}
export default withRouter(ReviewNotesDraggableComponent);