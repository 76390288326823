import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import dnaBgIcon from '../../assets/img/dna-bg.png'
import { message, Progress, Modal } from 'antd';
import { PLAN_TYPE } from '../../config'
const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class PlanComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlusPlan: false,
    };
  }
  componentDidMount() {
    const userId = localStorage.getItem('userId');
    this.props.onLoadPlan(userId);
    this.props.onLoadUpdatable(userId);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClickUpgrade = (planId) => {
    const userId = localStorage.getItem('userId');
    Modal.confirm({
      title: "方案升級",
      content: <div>
        <div>本網站之會員同意於付款後享有使用「LAW DNA服務平台」之帳戶空間。本網站於會員升級申請日起授權期間為1年。</div>
        系統會自動傳送至您的會員EMAIL帳號升級之金額，並提供匯款須知。
      </div>,
      okText: '同意',
      cancelText: '取消',
      onOk: () => {
        this.props.onUpgrade(userId, planId);
      },
    });
  }

  render() {
    const { showPlusPlan } = this.state;
    const { storage, plan, expire } = this.props;
    return (
      <div id="page-plan" className="container">
        <nav className="container p-3 mb-5">
          <Link to={`/cases`}>
            <img className="logo mr-3" alt="" src={dnaLogoIcon} />
          </Link>
          <h4 className="text-black-50">方案內容</h4>
        </nav>
        <div className="mb-5 plan_info">
          <div className="alert alert-light pl-5 pr-5" role="alert">
            <ul>
              <li>帳戶帳號總覽</li>
            </ul>
          </div>
        </div>
        <div className="plan-content">
          <div className="current">
            <div className="current-title">目前方案</div>
            <div className="current-plan">
              <ul className="list-body">
                <li className="body-plan" data-th="方案：">{ PLAN_TYPE[plan.getIn(['type'])] }方案</li>
                <li className="body-fee" data-th="金額：">$ { plan.getIn(['promoPrice']) }</li>
                <li className="body-planContent" data-th="空間："> { plan.getIn(['storage']) } 儲存空間</li>
                {
                  this.props.upgradeList.length > 0
                  ?
                  <li className="body-plus" data-th="升級：">
                    <button onClick={e => this.setState({ showPlusPlan: !showPlusPlan })}>升級</button>
                  </li>
                  :
                  <li className="body-state">目前無升級方案</li>
                }
              </ul>
            </div>
          </div>
          {
            showPlusPlan
            &&
            <div className="plus-plan">
              {
                this.props.upgradeList.length && this.props.upgradeList.map(plan =>
                  <div className="plan-item" key={`${plan.id}`}>
                     <div className="plan-item-title">{plan.storage}</div>
                     <div className="plan-item-plus"><button onClick={e => this.onClickUpgrade(plan.id)}>(優惠) ${plan.promoPrice} / 年</button></div>
                     <div className="plan-item-txt">
                       <div>原價：</div>
                       <div>$ {plan.salePrice} / 年</div>
                     </div>
                   </div>
                )
              }
            </div>
          }
          <div className="current-data">
            <div className="current-capacity">
              <div className="current-capacity-title">空間使用統計</div>
              <Progress strokeLinecap="square" percent={storage.getIn(['percent'])} />
              <div className="current-capacity-use">
                <span>{ storage.getIn(['usage']) } / { storage.getIn(['limit']) }</span>
              </div>
            </div>
            <div className="pay-cycle">
              <div className="pay-cycle-title">使用狀態</div>
              <div className="pay-cycle-content">{ plan.getIn(['type']) === 1 ? `免費方案` : `您的帳戶將於 ${ expire.getIn(['year']) } 年 ${ expire.getIn(['month']) } 月 ${ expire.getIn(['day']) } 日到期`}</div>
            </div>
          </div>
        </div>
        <img className="login-logo-bg" alt="" src={dnaBgIcon} />
        <div className="text-center mt-5 border-top">
          <Link to={`/cases`}>
            <button type="button" className="btn btn-outline-info w-50 mt-5">返回案件列表</button>
          </Link>
        </div>
      </div>
    )
  }
}

export default withRouter(PlanComponent);