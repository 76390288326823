// import { createSelector } from 'reselect';

export const getIsPosting = state => state.getIn(['searchExtend', 'isPosting']);
export const getErrMsg = state => state.getIn(['searchExtend', 'errMsg']);

export const getTotalHits = state => state.getIn(['searchExtend', 'totalHits']);
export const getHitList = state => state.getIn(['searchExtend', 'hitList']);

export const getLawData = state => state.getIn(['searchExtend', 'lawData']);

export const getCaseList = state => state.getIn(['search', 'caseList']);