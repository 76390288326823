import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ResetPasswordComponent from './component';


const mapStateToProps = (state) => ({
  // common
  isGetting: selectors.getIsGetting(state),
  isPutting: selectors.getIsPutting(state),
  errMsg: selectors.getErrMsg(state), 
});

const mapDispatchToProps = dispatch => ({
  onCheckToekn: (token) => {
    dispatch(actions.doCheckToekn(token));
  },
  onUpdatePassword: (password) => {
    dispatch(actions.doUpdatePassword(password));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent);