import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { 
  checkToken,
  passwordReset
} from '../../utils/api';


export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)

export const updateUserId = createAction(types.UPDATE_USER_ID)
export function doCheckToekn(token) {
  return dispatch => {
    localStorage.setItem('token', token);
    dispatch(getRequest());
    checkToken().then(data => {
      dispatch(updateUserId(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doUpdatePassword(password) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.getIn(['resetPassword', 'userId']);
    dispatch(putRequest());
    passwordReset(userId, password).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}