import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import dnaLogoIcon from '../../assets/img/dna-logo.svg';
import { Table } from 'react-bootstrap';
import { message } from 'antd';
import Spinner from '../../utils/Spinner';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class SearchExtendComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchCriteria: '',
      showCaseSelector: false,
      importPdf: {
        caseId: '',
        esId: ''
      },
    };
  }
  componentDidMount() {
    this.props.onLoadCaseNames();
    const searchCriteria = JSON.parse(new Buffer(decodeURIComponent(this.props.match.params.criteria), 'base64').toString())
    this.props.onLoadReference(searchCriteria['referenceName'], searchCriteria['referenceNo'])

    const yearRange = {};
    if (searchCriteria.fromYear && searchCriteria.toYear) {
      yearRange['from'] = searchCriteria.fromYear;
      yearRange['to'] = searchCriteria.toYear;
    }

    const noRange = {};
    if (searchCriteria.fromNo && searchCriteria.toNo) {
      noRange['from'] = searchCriteria.fromNo;
      noRange['to'] = searchCriteria.toNo;
    }

    const referenceList = [{'law': searchCriteria.referenceName, 'no': searchCriteria.referenceNo}];

    this.props.onSubmitSearch(
      yearRange,
      searchCriteria['case'],
      noRange,
      searchCriteria['content'],
      searchCriteria['summary'],
      searchCriteria['judge'],
      searchCriteria['text'],
      referenceList
    );

    const queryList = [];
    if (searchCriteria['text']) {
      queryList.push(`全文檢索(${searchCriteria['text']})`);
    }
    if (searchCriteria['content']) {
      queryList.push(`主文(${searchCriteria['content']})`);
    }
    if (searchCriteria['summary']) {
      queryList.push(`摘要(${searchCriteria['summary']})`);
    }
    if (searchCriteria['year'] || searchCriteria['case'] || searchCriteria['fromNo'] || searchCriteria['toNo']) {
      queryList.push(`${searchCriteria['year']} 年度 ${searchCriteria['case']} 字第 ${searchCriteria['fromNo']} ~ ${searchCriteria['toNo']}`);
    }    
    
    this.setState({ 
      currentPage: 1,
      searchCriteria: queryList.join('、')
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        if (this.state.showCaseSelector === true) {
          this.setState({ showCaseSelector: false });
          message.success('Imported successfully');
        }
      } else {
        message.error(this.props.errMsg);
      }
    }
  }

  render() {
    const { 
      searchCriteria, showCaseSelector, importPdf
    } = this.state;
  	return (
      <div className="container">
        <nav className="p-3">
          <img className="logo mr-3" alt="" src={dnaLogoIcon} onClick={e => this.props.history.push(`/cases`)}/>
          <span>相關法條</span>
        </nav>
        <div className="article_wrap p-5">
          <div className="mb-3">{ this.props.lawData.getIn(['name']) }</div>
          <div className="article pb-3">
            <div>
            {
              this.props.lawData.getIn(['no']) !== undefined 
              ?
              `第 ${this.props.lawData.getIn(['no'])} 條`
              :
              "請重新查詢"
            }
            </div>
            <div>
              { this.props.lawData.getIn(['desc']) }
            </div>
          </div>        
          {/*
          <div className="mb-3">民法 （民國 108 年 06 月 19 日 ）</div>
          <div className="article pb-3">
            <div>第 232 條</div>
            <div>
              判決如有誤寫、誤算或其他類此之顯然錯誤者，法院得依聲請或依職權以裁定更正；其正本與原本不符者，亦同。<br/>
              前項裁定，附記於判決原本及正本；如正本已經送達，不能附記者，應製作該裁定之正本送達。<br/>
              對於更正或駁回更正聲請之裁定，得為抗告。但對於判決已合法上訴者，不在此限。
            </div>
          </div>
          <div className="article">
            <div>第 232 條</div>
            <div>
              判決如有誤寫、誤算或其他類此之顯然錯誤者，法院得依聲請或依職權以裁定更正；其正本與原本不符者，亦同。<br/>
              前項裁定，附記於判決原本及正本；如正本已經送達，不能附記者，應製作該裁定之正本送達。<br/>
              對於更正或駁回更正聲請之裁定，得為抗告。但對於判決已合法上訴者，不在此限。
            </div>
          </div>
          */}
        </div>
        <div className="past_case">
          <div className="text-primary mt-5 pl-3">
            搜尋條件： {searchCriteria}
          </div>
          {
            this.props.isPosting ?
              <Spinner />
            : this.props.errMsg ?
              <div className="past_case_result">
                系統忙錄中，請重新整理此網頁。
              </div>
            :
              <div className="past_case_result">
                <Table responsive>
                  <thead>
                    <tr>  
                      <th>參考</th>
                      <th>裁判字號</th>
                      <th>裁判日期</th>
                      <th className="w-25">裁判案由</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.props.hitList && this.props.hitList.map(hit => 
                      <>
                        <tr>
                          <td className="add_case">
                            {
                              hit.getIn(['pdfLink'])
                              &&
                              <button onClick={e => this.setState({ showCaseSelector: true, importPdf: {caseId: '', judicialId: hit.getIn(['judicialId']), esId: hit.getIn(['esId'])} })}>
                                <FontAwesomeIcon icon={fas.faFolderPlus} />
                              </button>
                            }
                          </td>
                          <td>
                            <a target="_blank" rel="noopener noreferrer" href={hit.getIn(['link'])}>
                              { hit.getIn(['title']) }
                            </a>
                          </td>
                          <td>{hit.getIn(['date'])}</td>
                          <td>{hit.getIn(['summary'])}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="border-top-0">
                            {hit.getIn(['description'])}
                          </td>
                        </tr>
                      </>
                    )
                  }
                  </tbody>
                </Table>
              </div>
          }
          {
            showCaseSelector
            &&
            <div className={'case-form modalbox ' + (showCaseSelector?'show':'')}>
              {
                this.props.isPosting
                ?
                <Spinner />
                :
                <form className="modalbox-content" onSubmit={(e) => {e.preventDefault();this.props.onSubmitAddJudgment(importPdf.caseId, importPdf.judicialId, importPdf.esId)}}>
                  <div>
                    <label htmlFor="pages-label-selector">加入案件：</label>
                    <select
                      id="pages-label-selector"
                      className="modalbox-content-input fix_focus_shadow"
                      value={importPdf.caseId}
                      onChange={e => this.setState({ importPdf: {caseId: e.target.value, judicialId: importPdf.judicialId, esId: importPdf.esId} })}
                      required
                    >
                      {
                        importPdf.caseId === ''
                        &&
                        <option value="" disabled>請選擇</option>
                      }
                      {
                        this.props.caseList && this.props.caseList.map(case_ =>
                          <option key={`searches-case-${case_.getIn(['id'])}`} value={ case_.getIn(['id']) }>{ case_.getIn(['name']) }</option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-item-tool">
                    <button className="btn-import" type="submit" disabled={this.props.isPosting}>匯入</button>
                    <button className="btn-cancel" type="button" onClick={e => this.setState({ showCaseSelector: false })}>關閉</button>
                  </div>
                </form>
              }
            </div>
          }
        </div>
        <div className="back_search">
          <Link to={'/search'}>
            回判決搜尋
          </Link>
        </div>
      </div>
	  )
  }
}

export default withRouter(SearchExtendComponent);