import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'reviewNotes/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'reviewNotes/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'reviewNotes/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'reviewNotes/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'reviewNotes/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'reviewNotes/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'reviewNotes/UPDATE_PAGE_NOTE_LIST': (state, { payload }) => {
    return state.setIn(['noteIdList'], fromJS(payload.noteIdList))
                .setIn(['notes'], fromJS(payload.notes))
  },
  'reviewNotes/UPDATE_NOTES': (state, { payload }) => {
    return state.setIn(['notes'], payload);
  },
}, State);

export default Reducer;