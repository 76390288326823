import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dnaLogoIcon from '../assets/img/dna-logo.svg';
import seminar from '../assets/img/seminar211124.png';

class SeminarPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-seminar">
        <div className="index-header-logo"><Link to="/"><img className="logo" alt="logo" src={dnaLogoIcon}/></Link></div>
        <a className="link" target="_blank" href="https://forms.gle/3ADwoe8A4JkfWgWX8">前往填寫表單</a>
        <div className="seminar-main"><a target="_blank" href="https://forms.gle/3ADwoe8A4JkfWgWX8"><img src={seminar}/></a></div>
	    </div>
	  )
  }
}

export default SeminarPageComponent;
