import {connect} from 'react-redux';
import * as topbarActions from '../topBar/actions';
import * as docActions from '../doc/actions';
import * as docSelectors from '../doc/selectors';
import * as actions from "./actions";
import AttachmentComponent from './component';
import * as selectors from './selectors';

const mapStateToProps = (state) => ({
  // common
  errMsg: selectors.getErrMsg(state),
  isLoading: selectors.getIsLoading(state),
  isUploading: selectors.getIsUploading(state),
  // case
  caseName: docSelectors.getCaseName(state),
  // attachment
  folderDict: selectors.getFolders(state),
  attachmentDict: selectors.getCaseAttachment(state),
  hitList: selectors.getHitList(state),
  isExceededQuota: selectors.getIsExceededQuota(state),
});

const mapDispatchToProps = dispatch => ({
  // case
  onLoadCaseName: (caseId) => {
    dispatch(docActions.doLoadCaseName(caseId));
  },
  onLoadFolderList: () => {
    dispatch(actions.doLoadFolderList());
  },
  onSubmitCreateFolder: (folderName) => {
    return dispatch(actions.doCreateFolder(folderName));
  },
  // attachment
  onLoadAttachments: (caseId) => {
    dispatch(actions.doLoadAttachments(caseId));
  },
  onSubmitUploadAttachments: (caseId, uploadFileList, folderId) => {
    return dispatch(actions.doUploadAttachments(caseId, folderId, uploadFileList));
  },
  onDownloadAttachment: (fileId, fileName) => {
    return dispatch(actions.doDownloadAttachment(fileId, fileName));
  },
  onSubmitMoveFolder: (caseId, folderId, selectedFileId) => {
    return dispatch(actions.doMoveAttachment(caseId, folderId, selectedFileId));
  },
  onDeleteAttachment: (caseId, fileId) => {
    return dispatch(actions.doDeleteAttachment(caseId, fileId));
  },
  onCleanSearchResult: () => {
    dispatch(actions.updateHitList(null));
  },
  onSearchAttachment: (caseId, searchCriteria) => {
    dispatch(actions.doSearchAttachment(caseId, searchCriteria));
  },
  onLoadAttachmentUrl: (pageId) => {
    return dispatch(actions.doLoadAttachmentUrl(pageId));
  },
  onLoadAttachmentText: (pageId) => {
    return dispatch(actions.doLoadAttachmentText(pageId));
  },
  onClickLogout: () => {
    dispatch(topbarActions.doLogout());
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachmentComponent);
