import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentUserFilesComponent from './component';


const mapStateToProps = (state) => ({
  isDownloading: selectors.getIsDownloading(state),
  isPosting: selectors.getIsPosting(state),
  isPutting: selectors.getIsPutting(state),
  isDeleting: selectors.getIsDeleting(state),
  isBackuping: selectors.getIsBackuping(state),
  errMsg: selectors.getErrMsg(state),
  isExceededQuota: selectors.getIsExceededQuota(state),
});

const mapDispatchToProps = dispatch => ({
  onUploadUserFile: (appointmentId, userFolderId, fileList) => {
    dispatch(actions.doUploadUserFile(appointmentId, userFolderId, fileList));
  },
  onMoveUserFile: (userFolderId, userFileId) => {
    dispatch(actions.doMoveUserFile(userFolderId, userFileId));
  },
  onDownloadUserFile: (userFileId, userFileName) => {
    dispatch(actions.doDownloadUserFile(userFileId, userFileName));
  },
  onDeleteUserFile: (userFileId) => {
    dispatch(actions.doDeleteUserFile(userFileId));
  },
  onSubmitUpdateFileName: (fileId, name) => {
    dispatch(actions.doSubmitUpdateFileName(fileId, name));
  },
  onDownloadUserAllFile: (appointmentId) => {
    dispatch(actions.doDownloadUserAllFile(appointmentId));
  },
  onDirectToOauthPage: (appointmentId) => {
    dispatch(actions.doDirectToOauthPage(appointmentId));
  },
  onBackupUserFile: (appointmentId, code) => {
    dispatch(actions.doBackupUserFile(appointmentId, code));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentUserFilesComponent);