import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  uploadConsultationFile,
  deleteConsultationFile,
  downloadConsultationFile,
  updateConsultationFileName
} from '../../utils/api';

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)


export function doUploadConsultationFile(consultationId, fileList) {
  return dispatch => {
    dispatch(postRequest());
    uploadConsultationFile(consultationId, fileList).then(data => {
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doDownloadConsultationFile(consultationId, consultationName) {
  return dispatch => {
    dispatch(getRequest());
    downloadConsultationFile(consultationId, consultationName).then(data => {
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doDeleteConsultationFile(consultationId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteConsultationFile(consultationId).then(data => {
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doSubmitUpdateFileName(fileId, name) {
  return dispatch => {
    dispatch(putRequest());
    updateConsultationFileName(fileId, name).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}