import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        {
          'name': '諮詢紀錄',
          'url': '/consultations'
        },
        {
          'name': '委任列表',
          'url': '/cases'
        },
        {
          'name': '收發管理',
          'url': '/mailroom'
        },
        {
          'name': '報表',
          'url': '/report'
        },
        // {
        //   'name': '判決搜尋',
        //   'url': '/search'
        // },
      ],
    };
  }
  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const { showRWDNavbar } = this.props;
    return (
      <div id="page-navbar" className={`rwd-page-navbar ${showRWDNavbar ? 'open' : ''}`}>
        <nav className="nav">
          <div className="nav-start-items">
            {
              this.state.navItems.map(navItem => (
                <div className="nav-item" key={navItem.url}>
                  <Link className={`nav-item-link ${this.props.location.pathname === navItem.url ? 'active' : ''}`} to={navItem.url}>
                    <i>
                      {
                        navItem.url === '/consultations' ?
                          <FontAwesomeIcon icon={fas.faQuestionCircle} />
                        : navItem.url === '/cases' ?
                          <FontAwesomeIcon icon={fas.faAddressBook} />
                        : navItem.url === '/mailroom' ?
                          <FontAwesomeIcon icon={fas.faMailBulk} />
                        : navItem.url === '/report' ?
                          <FontAwesomeIcon icon={fas.faMailBulk} />
                        // : navItem.url === '/search' ?
                        //   <FontAwesomeIcon icon={fas.faSearch} />
                        :
                          null
                      }
                    </i>
                    <span className="">{navItem.name}</span>
                  </Link>
                </div>
              ))
            }
          </div>
          <div className="nav-end-items">
            <div className="nav-item"><Link className="nav-item-link" to={`/folder`}>資料夾設定</Link></div>
          </div>
        </nav>
      </div>
    )
  }
}

export default withRouter(NavbarComponent);