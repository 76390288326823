import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import PreviewNotesComponent from './component';


const mapStateToProps = (state) => ({
  errMsg: selectors.getErrMsg(state),
  isGetting: selectors.getIsGetting(state),
  notes: selectors.getNotes(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadNotes:(previewNotesId) => {
    dispatch(actions.doLoadNotes(previewNotesId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewNotesComponent);