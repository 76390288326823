import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ReviewNotesDraggableComponent from './component';


const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isPutting: selectors.getIsPutting(state),
  isPuttingPosition: selectors.getIsPuttingPosition(state),
  errMsg: selectors.getErrMsg(state),
  currentNoteId: selectors.getCurrentNoteId(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmitPageNote: (pageId, pageNoteId, title, content, position) => {
    dispatch(actions.doSubmitPageNote(pageId, pageNoteId, title, content, position));
  },
  onSubmitPagePosition: (pageId, pageNoteId, position) => {
    dispatch(actions.doSubmitPagePosition(pageId, pageNoteId, position));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewNotesDraggableComponent);