import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Upload, message, Modal, Input, Tooltip } from 'antd';
import { Button } from 'react-bootstrap';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

class ConsultationFilesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileUploader: false,
      uploadFileList: [],
      showUpdateFileName: false,
      updateFileName: '',
      updateFileId: '',
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.isPosting === true && this.props.isPosting === false)
      || (prevProps.isPutting === true && this.props.isPutting === false)
      || (prevProps.isDeleting === true && this.props.isDeleting === false)) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleFileUploader(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onToggleFileUploader(showFileUploader) {
    this.setState({ showFileUploader })
  }
  onClickDeleteConsultationFile(consultationFileId, consultationFileName) {
    Modal.confirm({
      title: '刪除檔案',
      content: `按下"確認"按鈕將刪除 ${consultationFileName}`,
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteConsultationFile(consultationFileId);
      },
    });
  }
  onSubmitUpload() {
    const { uploadFileList } = this.state;
    this.props.onUploadConsultationFile(this.props.consultationId, uploadFileList);
  }
  onClickSubmitUpdateFileName(fileId) {
    const { updateFileName } = this.state;
    if(updateFileName){
      let name = updateFileName ? updateFileName.trim() : '';
      this.props.onSubmitUpdateFileName(fileId, name);
      this.setState({ updateFileName: '', updateFileId: '' });
    }else{
      return message.error('請勿空白欄位')
    }
  }

  render() {
    const { showFileUploader, uploadFileList, showUpdateFileName, updateFileName } = this.state;
    const UploadProps = {
      accept: ".doc,.docx,.jpg,.jpeg,.png,.pdf,.heic,.heif,.hevc",
      multiple: true,
      fileList: uploadFileList,
      onRemove: file => {
        this.setState(state => {
          const index = state.uploadFileList.indexOf(file);
          const newFileList = state.uploadFileList.slice();
          newFileList.splice(index, 1);
          return {
            uploadFileList: newFileList,
          };
        });
      },
      beforeUpload: (file, fileList) => {
        this.setState(state => ({
          uploadFileList: [...state.uploadFileList, file]
        }));
        return false;
      }
    };
    return (
      <div className="consultationfiles-section section-col-item">
        <div className="case-file-extend">
          {
            this.props.consultationId !== null
            &&
            <div className="case-file-extend-head">
              <button className="btn-empty"
                onClick={e => this.onToggleFileUploader(true, null, '')}
              >
                <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
                上傳檔案
              </button>
            </div>
          }
          <div className="case-file-list">
            {
              this.props.consultationFileList && this.props.consultationFileList.map(consultationFile =>
                <div className="case-file" key={`consultationfile-${consultationFile.getIn(['id'])}`}>
                  <div className="case-file-title">
                    { consultationFile.getIn(['name']) }
                  </div>
                  <div className="case-file-tool">
                    <Tooltip title="檔案更名">
                      <button className="btn-empty"
                        onClick={e => {this.setState({showUpdateFileName: !showUpdateFileName, updateFileName: consultationFile.getIn(['name']), updateFileId: consultationFile.getIn(['id'])})}}
                      ><FontAwesomeIcon icon={fas.faPencilAlt} /></button>
                    </Tooltip>
                    <Tooltip title="刪除檔案">
                      <button className="btn-empty"
                        onClick={e => this.onClickDeleteConsultationFile(consultationFile.getIn(['id']), consultationFile.getIn(['name']))}
                      ><FontAwesomeIcon icon={far.faTrashAlt} /></button>
                    </Tooltip>
                    <Tooltip title="下載檔案">
                      <button className="btn-empty"
                        onClick={e => this.props.onDownloadConsultationFile(consultationFile.getIn(['id']), consultationFile.getIn(['name']))}
                      ><FontAwesomeIcon icon={fas.faFileDownload} /></button>
                    </Tooltip>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <Modal className="case-file-upload"
          title="檔案上傳"
          visible={showFileUploader}
          onOk={() => this.onSubmitUpload()}
          onCancel={() => this.onToggleFileUploader(false)}
          okText= '確認上傳'
          cancelText= '取消'
        >
          <div className="case-file-upload-item">
            <div className="case-file-upload-title">檔案</div>
            <Upload className="case-file-upload-select" {...UploadProps}>
              <Button>
                <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
                選擇上傳檔案
              </Button>
            </Upload>
          </div>
        </Modal>
        <Modal className="update-fileName"
          title="更改檔名"
          visible={this.state.showUpdateFileName}
          okText="確認"
          cancelText="取消"
          onOk={() => {this.onClickSubmitUpdateFileName(this.state.updateFileId); this.setState({ showUpdateFileName: false })}}
          onCancel={() => this.setState({ showUpdateFileName: false })}
        >
          <div className="docket-col">
            <Input className="input"
              placeholder="更新檔案名"
              allowClear
              type="text"
              value={updateFileName}
              onChange={e => this.setState({ updateFileName: e.target.value })}
            />
          </div>
        </Modal>
      </div>
    )
  }
}
export default withRouter(ConsultationFilesComponent);