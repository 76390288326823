import React, { Component } from 'react';
import resetPassword from '../modules/resetPassword';


class resetPasswordPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-resetPassword">
        <resetPassword.container />
	    </div>
	  )
  }
}

export default resetPasswordPageComponent;
