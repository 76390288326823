export const getIsPosting = state => state.getIn(['mailroomReceivedList', 'isPosting']);
export const getIsDeleting = state => state.getIn(['mailroomReceivedList', 'isDeleting']);
export const getErrMsg = state => state.getIn(['mailroomReceivedList', 'errMsg']);

export const getReceivedList = state => state.getIn(['mailroomReceivedList', 'receivedList']);

export const getDockets = state => state.getIn(['mailroomReceivedList', 'dockets']);
export const getDocketNames = state => state.getIn(['mailroomReceivedList', 'docketNames']);
export const getDocketList = state => state.getIn(['mailroomReceivedList', 'docketList']);
export const getCsvRowList = state => state.getIn(['mailroomReceivedList', 'csvRowList']);
export const getCachedReceivedId = state => state.getIn(['mailroomReceivedList', 'cachedReceivedId']);