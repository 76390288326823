import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'consultationCase/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'consultationCase/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'consultationCase/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultationCase/GET_CASE_RELATED_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], true)
                .setIn(['errMsg'], null);
  },
  'consultationCase/GET_CASE_RELATED_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], false)
                .setIn(['errMsg'], null);
  },
  'consultationCase/GET_CASE_RELATEDT_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'consultationCase/UPDATE_CASE_RELATE_LIST': (state, { payload }) => {
    return state.setIn(['caseRelateList'],  fromJS(payload));
  },
}, State);

export default Reducer;