import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  createCase_a,
  createCase_c,
  casesRelated,
  getCaseList,
  checkCustodianName,
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const postCheckRequest = createAction(types.POST_CHECK_REQUEST)
export const postCheckSuccess = createAction(types.POST_CHECK_SUCCESS)
export const postCheckFailure = createAction(types.POST_CHECK_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const getCaseRelatedRequest = createAction(types.GET_CASE_RELATED_REQUEST)
export const getCaseRelatedSuccess = createAction(types.GET_CASE_RELATED_SUCCESS)
export const getCaseRelatedFailure = createAction(types.GET_CASE_RELATED_FAILURE)

export const updateErrMsg = createAction(types.UPDATE_ERR_MSG)

export const updateCustodianConflict = createAction(types.UPDATE_CUSTODIAN_CONFLICT)
export function doCheckConflictOfInterest(custodianName) {
  return dispatch => {
    dispatch(postCheckRequest());
    checkCustodianName(custodianName).then(data => {
      dispatch(updateCustodianConflict(data));
      dispatch(postCheckSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(postCheckFailure({ errMsg }));
    })
  }
}

export const updateCaseList = createAction(types.UPDATE_CASE_LIST)
export function doLoadCaseList() {
  return dispatch => {
    dispatch(getRequest());
    getCaseList().then(data => {
      dispatch(updateCaseList(data));
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}

export function doSubmitCreateCase(docketList, appointDate, appointorType, caseDetail, appointor, company, custodianAppointor, custodianCompany, fee, caseDate) {
  return dispatch => {
    dispatch(postRequest());
    let appointNoId = caseDetail.appointNoId;
    let name = caseDetail.name;
    let receipt = fee.receipt;
    let appointFee = fee.appointFee;
    let isTaxabled = fee.isTaxabled;
    let taxYear = fee.taxYear;
    let taxAmount = fee.taxAmount;
    let payDate = fee.payDate;
    let taxVoucher = fee.taxVoucher;
    let caseNoId = '';
    let statusId = caseDetail.statusId;
    let plaintiffList = caseDetail.plaintiffList;
    let defendantList = caseDetail.defendantList;
    let typeId = caseDetail.typeId;
    let relatedList = caseDetail.relatedList;
    let lawNo = caseDetail.lawNo;
    let keywordList = caseDetail.keywordList;
    let courtDay = caseDate.courtDay;
    let appealLastDay = caseDate.appealLastDay;
    let lastDayFirst = caseDate.lastDayFirst;
    let lastDaySecond = caseDate.lastDaySecond;
    let closeDate = caseDate.closeDate;
    let appointContent = caseDetail.appointContent;
    let appointNote = caseDetail.appointNote;
    if( appointorType === 1 ){
      createCase_a(appointNoId, name, appointDate, appointorType, appointor, custodianAppointor, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseNoId, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList).then(data => {
        dispatch(postSuccess());
        return;
      }).catch((errMsg) => {
        return dispatch(postFailure({ errMsg }));
      });
      return;
    }
    if( appointorType === 2 ){
      createCase_c(appointNoId, name, appointDate, appointorType, company, custodianCompany, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseNoId, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList).then(data => {
        dispatch(postSuccess());
        return;
      }).catch((errMsg) => {
        return dispatch(postFailure({ errMsg }));
      });
      return;
    }
  }
}
export const updateCaseRelateList = createAction(types.UPDATE_CASE_RELATE_LIST)
export function doLoadRelatedList(contain, size) {
  return dispatch => {
    dispatch(getCaseRelatedRequest());
    casesRelated(contain, size).then(data => {
      dispatch(updateCaseRelateList(data));
      dispatch(getCaseRelatedSuccess());
    }).catch((errMsg) => {
      dispatch(getCaseRelatedFailure({ errMsg }));
    })
  }
}