import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentDatesComponent from './component';


const mapStateToProps = (state) => ({
  isPutting: selectors.getIsPutting(state),
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateDates: (appointmentId, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate) => {
    dispatch(actions.doUpdateDates(appointmentId, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDatesComponent);