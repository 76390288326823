import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import LoginComponent from './component';


const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isGetting: selectors.getIsGetting(state),
  errMsg: selectors.getErrMsg(state),
  redirectTo: selectors.getRedirectTo(state),
  twoFactorAuthenticationId: selectors.getTwoFactorAuthenticationId(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmitLogin: (account, password) => {
    return dispatch(actions.doSubmitLogin(account, password));
  },
  onSendSms: () => {
    return dispatch(actions.doSendSms());
  },
  onCheckSms: (smsToken) => {
    return dispatch(actions.doCheckSms(smsToken));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
