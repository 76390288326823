import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { 
  sendForgotPasswordEmail,
} from '../../utils/api';


export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)

export function doSubmitForgotPassword(email) {
  return dispatch => {
    dispatch(postRequest());
    sendForgotPasswordEmail(email).then(data => {
      return dispatch(postSuccess());
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}