import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import resetPasswordWithOldPassword from '../modules/resetPasswordWithOldPassword';

class resetPasswordWithOldPasswordPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div id="page-resetPasswordWithOldPassword">
        <resetPasswordWithOldPassword.container />
	    </div>
	  )
  }
}
export default resetPasswordWithOldPasswordPageComponent;
