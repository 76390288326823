import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, DatePicker } from 'antd';
import Spinner from '../../utils/Spinner';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { RangePicker } = DatePicker;
class ExportConsultationsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAppoint: false,
      showAppointEdit: false,
      showAppointEditt: false,
      uploadFileList: [],
    };
  }

  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
  }

  render() {
  	return (
      <div className="exportConsultations-section exportConsultations">
        <div className="exportConsultations-report">
          <div className="navBar noPrint">
            <button><Link to={`/cases`}>返回諮詢紀錄列表</Link></button>
            <div className="title">諮詢紀錄列印</div>
          </div>

          <div className="case-set">
            <div className="set-item">
              <div className="title">諮詢紀錄：</div>
              <div className="content">王大中案件</div>
            </div>
            <div className="set-item">
              <div className="title">諮詢日期：</div>
              <div className="content">2020/12/08</div>
            </div>
            <div className="set-item">
              <div className="title">諮詢方式：</div>
              <div className="content">電訪</div>
            </div>
            <div className="set-item">
              <div className="title">諮詢人：</div>
              <div className="content">王大中</div>
            </div>
            <div className="set-item">
              <div className="title">聯絡電話：</div>
              <div className="content">0987654321</div>
            </div>
            <div className="set-item">
              <div className="title">Email：</div>
              <div className="content">123412@gmail.com</div>
            </div>
            <div className="set-item">
              <div className="title">與當事人關係：</div>
              <div className="content">父子</div>
            </div>
            <div className="set-item">
              <div className="title">當事人身份：</div>
              <div className="content">小小中</div>
            </div>
            <div className="set-item">
              <div className="title">案件種類：</div>
              <div className="content">民事</div>
            </div>
            <div className="set-item">
              <div className="title">案件相關：</div>
              <div className="content">扶養費、學習費</div>
            </div>
            <div className="set-item">
              <div className="title">案件狀態：</div>
              <div className="content">進行中</div>
            </div>
            <div className="set-item">
              <div className="title">原告：</div>
              <div className="content">小中媽</div>
            </div>
            <div className="set-item">
              <div className="title">被告：</div>
              <div className="content"></div>
            </div>
            <div className="set-item">
              <div className="title">預估費用：</div>
              <div className="content">30000</div>
            </div>
            <div className="set-item">
              <div className="title">報稅年度：</div>
              <div className="content">111</div>
            </div>
            <div className="set-item set-item-row">
              <div className="title">案件內容：</div>
              <div className="content">
                <p>
                  昨日晚間9點19分於宜蘭縣政府東方27.2公里，發生芮氏規模6.7地震，今日凌晨2點15分則有規模5.6的餘震，讓民眾相當緊張；根據氣象局統計，昨日9點19分的主震發生後，至今日清晨5點30分已發生11起餘震，後續到上午時段則還未發生餘震，中央氣象局地震測報中心副主任吳健富表示，隱沒型地震不會在一個地震就把長期蓄積的能量釋放掉，主震後還會慢慢發生餘震陸續把能量釋放，因此，未來一週內不排除還有規模4以上的餘震。
                </p>
              </div>
            </div>
            <div className="set-item set-item-row">
              <div className="title">備註：</div>
              <div className="content"></div>
            </div>
          </div>
        </div>
      </div>
	  )
  }
}
export default withRouter(ExportConsultationsComponent);
