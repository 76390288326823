import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  casesRelated,
  getCaseList,
} from '../../utils/api';
import * as config from '../../config'


// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)


export const updateCaseList = createAction(types.UPDATE_CASE_LIST)
export function doLoadCaseList() {
  return dispatch => {
    dispatch(getRequest());
    getCaseList().then(data => {
      dispatch(updateCaseList(data));
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}
export function doSubmitCreateCase(caseName, relatedList, plaintiffList, defendantList, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, instance, custodian, typeId, fileTypeId, uploadFileList) {
  return dispatch => {
    // dispatch(postRequest());
    // createCase(caseName, relatedList, plaintiffList, defendantList, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, instance, custodian, typeId, fileTypeId, uploadFileList).then(data => {
    //   dispatch(postSuccess());
    //   return;
    // }).catch((errMsg) => {
    //   return dispatch(postFailure({ errMsg }));
    // })
  }
}
export const updateCaseRelateList = createAction(types.UPDATE_CASE_RELATE_LIST)
export function doLoadRelatedList(contain, size) {
  return dispatch => {
    casesRelated(contain, size).then(data => {
      dispatch(updateCaseRelateList(data));
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}