// common
export const POST_REQUEST = 'casesContract/POST_REQUEST'
export const POST_SUCCESS = 'casesContract/POST_SUCCESS'
export const POST_FAILURE = 'casesContract/POST_FAILURE'
export const GET_REQUEST = 'casesContract/GET_REQUEST'
export const GET_SUCCESS = 'casesContract/GET_SUCCESS'
export const GET_FAILURE = 'casesContract/GET_FAILURE'
export const PUT_REQUEST = 'casesContract/PUT_REQUEST'
export const PUT_SUCCESS = 'casesContract/PUT_SUCCESS'
export const PUT_FAILURE = 'casesContract/PUT_FAILURE'

export const UPDATE_POWER_OF_ATTORNEY_INFO = 'casesContract/UPDATE_POWER_OF_ATTORNEY_INFO'
export const UPDATE_POWER_OF_ATTORNEY_APPOINTOR = 'casesContract/UPDATE_POWER_OF_ATTORNEY_APPOINTOR'
export const UPDATE_POWER_OF_ATTORNEY_COMPANY = 'casesContract/UPDATE_POWER_OF_ATTORNEY_COMPANY'