import React, { Component } from 'react';
import search from '../modules/search';

class SearchPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-search">
        <search.container />
	    </div>
	  )
  }
}

export default SearchPageComponent;
