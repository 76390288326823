import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
	getLawyer,
	consultationsForm_a,
	consultationsForm_c,
} from '../../utils/api';

export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)

export const updateLawyer = createAction(types.UPDATE_LAWYER)
export function doLoadConsultationsForm(pincode) {
	return dispatch => {
		dispatch(getRequest());
		getLawyer(pincode).then(data => {
			console.log(data)
			dispatch(updateLawyer(data));
			dispatch(getSuccess());
			return true;
		}).catch((errMsg) => {
			return dispatch(getFailure({ errMsg }));
		})
	}
}

export function doSubmitConsultationsForm(pincode, appointorType, typeId, plaintiffList, defendantList, appointor, company, consultationContent) {
	return dispatch => {
		dispatch(postRequest());
		if (appointorType === 1) {
			console.log(pincode, appointorType, typeId, plaintiffList, defendantList, appointor, consultationContent)
			consultationsForm_a(pincode, appointorType, typeId, plaintiffList, defendantList, appointor, consultationContent).then(data => {
				dispatch(postSuccess());
				return;
			}).catch((errMsg) => {
				return dispatch(postFailure({ errMsg }));
			});
			return;
		};
		if (appointorType === 2) {
			consultationsForm_c(pincode, appointorType, typeId, plaintiffList, defendantList, company, consultationContent).then(data => {
				dispatch(postSuccess());
				return;
			}).catch((errMsg) => {
				return dispatch(postFailure({ errMsg }));
			});
			return;
		};
	}
}