import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
	'plan/POST_REQUEST': (state, { payload }) => {
		return state.setIn(['isPosting'], true)
					.setIn(['errMsg'], null);
	},
	'plan/POST_SUCCESS': (state, { payload }) => {
		return state.setIn(['isPosting'], false)
					.setIn(['errMsg'], null);
	},
	'plan/POST_FAILURE': (state, { payload }) => {
		return state.setIn(['isPosting'], false)
					.setIn(['errMsg'], payload.errMsg);
	},
	'plan/GET_REQUEST': (state, { payload }) => {
		return state.setIn(['isGetting'], true)
					.setIn(['errMsg'], null);
	},
	'plan/GET_SUCCESS': (state, { payload }) => {
		return state.setIn(['isGetting'], false)
					.setIn(['errMsg'], null);
	},
	'plan/GET_FAILURE': (state, { payload }) => {
		return state.setIn(['isGetting'], false)
					.setIn(['errMsg'], payload.errMsg);
	},
	'plan/UPDATE_PLAN': (state, { payload }) => {
		return state.setIn(['storage'], fromJS(payload.storage))
					.setIn(['plan'], fromJS(payload.plan))
					.setIn(['expire'], fromJS(payload.expire));
	},
	'plan/UPDATE_UPGRADE_LIST': (state, { payload }) => {
		return state.setIn(['upgradeList'], payload);
	},
}, State);

export default Reducer;