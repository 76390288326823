import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { Carousel } from 'react-bootstrap';
import { message, Layout, Menu, Carousel } from 'antd';
import dnaLogoIcon from '../../assets/img/dna-logo.svg';
import index01 from '../../assets/img/index-01.png';
import index02 from '../../assets/img/index-02.jpg';
import index03 from '../../assets/img/index-03.jpg';
import index04 from '../../assets/img/index-04.jpg';
import index05 from '../../assets/img/index-05.png';
import index06 from '../../assets/img/index-06.png';
import icon_line_normal from '../../assets/img/icon_line_normal.png';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Header, Content, Footer } = Layout;

class IndexComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {

  }
  componentDidUpdate = (prevProps, prevState) => {

  }
  componentWillUnmount = () => {
  }
  onChange(a, b, c) {
    // console.log(a, b, c);
  }
  
  

  render() {
    return (
      <Layout className="index-section">
        <Header className="index-header" style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
          <div className="index-header-logo"><Link to="/"><img className="logo" alt="logo" src={dnaLogoIcon}/></Link></div>
          <Menu className="index-header-list" mode="horizontal">
            <Menu.Item key="1"><Link to="/registration">免費體驗</Link></Menu.Item>
            <Menu.Item key="2"><a href="#catalog-section">法律卷證 AI 編目</a></Menu.Item>
            <Menu.Item key="3"><a href="#relationship-section">委任關係管理</a></Menu.Item>
            <Menu.Item key="4"><a target="_blank" href="http://www.ediscovery.com.tw/lawdna.html">關於我們</a></Menu.Item>
            <Menu.Item key="5"><Link to="/seminar">應用說明會</Link></Menu.Item>
          </Menu>
          <div className="index-header-tool">
            <Link to="/login">登入</Link>
            <span>/</span>
            <Link to="/registration">註冊</Link>
          </div>
        </Header>
        <Content className="site-layout" style={{ marginTop: 64, background: '#ffffff' }}>
          <div id="index-section">
            <div className="index-description-bg">
              <div className="index-description-text">
                法律人的客戶關係管理系統<br />
                <div><img className="logo" alt="logo" src={dnaLogoIcon}/></div>
                法律科技協助您<br />
                工作更智慧、服務更完善<br />
              </div>
            </div>
            <div className="index-item">
              <div>LAW DNA 透過多種方式，在律師每一次與委託人互動的同時，提高流程效率。運用自動化流程和系統內建置的追蹤提醒工具等功能，協助律師以更少精力，完成更多工作。</div>
              <div className="index-item-focus">
                簡單的事情重複做。重複的事 AI 做<br />
                工作更智慧，服務更完善
              </div>
            </div>
          </div>
          <div id="catalog-section">
            {
              window.screen.width < 768 ?
                <div className="catalog-item-focus">
                  一鍵上傳卷證檔案<br />
                  AI 智慧化頁碼與目錄生成<br />
                  類似現行科技法庭卷證編目
                </div>
              :
                <div className="catalog-item-focus">
                  一鍵上傳卷證檔案，AI 智慧化頁碼與目錄生成。<br />
                  類似現行科技法庭卷證編目
                </div>
            }
            <div className="catalog-item image">
              <div><img src={index02}/></div>
              <div><img src={index01}/></div>
            </div>
            <div className="catalog-item">
              <ul>
                <li>卷證 OCR 處理</li>
                <li>卷證筆記</li>
                <li>全文檢索</li>
                <li>受詢人筆記整合</li>
              </ul>
            </div>
          </div>
          <div id="relationship-section">
            <Carousel afterChange={this.onChange} dotPosition="top">
              <div>
                <div className="carouse-item">
                  <div className="carouse-item-text">
                    <div className="carouse-item-text-title">委任狀、契約書、收據範本生成</div>
                    <div className="carouse-item-text-text">LAW DNA 提供委任狀、契約書、收據範本，自動帶入委任人與律師資料，可依事務所需求自行編輯與列印。</div>
                  </div>
                  <div className="carouse-item-img"><img src={index03}/></div>
                </div>
              </div>
              <div>
                <div className="carouse-item relationship">
                  <div className="carouse-item-text">
                    <div className="carouse-item-text-title">委任關係管理</div>
                    <div className="carouse-item-text-text">從諮詢、委任至案件進行，由 LAW DNA 協助您紀錄與統整所有相關資訊。</div>
                  </div>
                  <div className="carouse-item-img"><img src={index04}/></div>
                </div>
              </div>
              <div>
                <div className="carouse-item">
                  <div className="carouse-item-text">
                    <div className="carouse-item-text-title">庭期提醒、收發文提醒</div>
                    <div className="carouse-item-text-text">LAW DNA 自動提醒功能，提醒您每項工作完成時間。</div>
                  </div>
                  <div className="carouse-item-img"><img src={index05}/></div>
                </div>
              </div>
              <div>
                <div className="carouse-item">
                  <div className="carouse-item-text">
                    <div className="carouse-item-text-title">司法院開庭日期<br />同步 Google 行事曆</div>
                    <div className="carouse-item-text-text">LAW DNA 自動提醒功能，提醒您每項工作完成時間。</div>
                  </div>
                  <div className="carouse-item-img"><img src={index06}/></div>
                </div>
              </div>
            </Carousel>
          </div>
        </Content>
        <div className="community">
          <a href="https://liff.line.me/1645278921-kWRPP32q?accountId=665xbnci&openerPlatform=native&openerKey=talkroom%3Amessage#mst_challenge=TKFXpBV5QA5sp3oG0arXlkyKXrQ-X5-raEZwJJZF9kY" target="blank"><img src={icon_line_normal} alt="lawdna" /></a>
        </div>
        <Footer style={{ textAlign: 'center' }}>LAW DNA ©2021 Created by LYNAZA</Footer>
      </Layout>
    )
  }
}

export default withRouter(IndexComponent);