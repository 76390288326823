import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getPowerOfAttorney,
  updateReceipt
} from '../../utils/api';
import * as config from '../../config'


// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST);
export const putSuccess = createAction(types.PUT_SUCCESS);
export const putFailure = createAction(types.PUT_FAILURE);

export const updatePowerOfAttorneyInfo = createAction(types.UPDATE_POWER_OF_ATTORNEY_INFO)
export const updatePowerOfAttorneyAppointor = createAction(types.UPDATE_POWER_OF_ATTORNEY_APPOINTOR)
export const updatePowerOfAttorneyCompany = createAction(types.UPDATE_POWER_OF_ATTORNEY_COMPANY)
export function doLoadPowerOfAttorney(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getPowerOfAttorney(caseId).then(data => {
      console.log(data, data.appointorType)
      dispatch(updatePowerOfAttorneyInfo(data));
      if(data.appointorType === 1) {
        dispatch(updatePowerOfAttorneyAppointor(data));
      }
      if(data.appointorType === 2) {
        dispatch(updatePowerOfAttorneyCompany(data));
      }
      dispatch(getSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}
export function doUpdateReceipt(caseId, category, cost, date, name, note, reason) {
  return dispatch => {
    dispatch(putRequest());
    updateReceipt(caseId, category, cost, date, name, note, reason).then(data => {
      // dispatch(updateExportReceipt());
      // dispatch(doLoadExportReceipt(caseId));
      dispatch(putSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(putFailure({ errMsg }));
    })
  }
}