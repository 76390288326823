export const POST_REQUEST = 'searchExtend/POST_REQUEST'
export const POST_SUCCESS = 'searchExtend/POST_SUCCESS'
export const POST_FAILURE = 'searchExtend/POST_FAILURE'
export const GET_REQUEST = 'searchExtend/GET_REQUEST'
export const GET_SUCCESS = 'searchExtend/GET_SUCCESS'
export const GET_FAILURE = 'searchExtend/GET_FAILURE'


export const UPDATE_SEARCH_RESULT = 'searchExtend/UPDATE_SEARCH_RESULT'
export const UPDATE_LAW_DATA = 'searchExtend/UPDATE_LAW_DATA'

export const UPDATE_CASE_LIST = 'search/UPDATE_CASE_LIST'
