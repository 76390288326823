import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import PdfComponent from './component';


const mapStateToProps = (state) => ({
  pageUrl: selectors.getPageUrl(state),
  pageId: selectors.getPageId(state),
  cachePageUrl: selectors.getCachePageUrl(state),
  PageText: selectors.getPageText(state),
  pdfScale: selectors.getPdfScale(state),
});

const mapDispatchToProps = dispatch => ({
  onAddAnnotation: (caseId, pageId, type, annotation) => {
    dispatch(actions.doAddAnnotation(caseId, pageId, type, annotation));
  },
  onChangePdf: (pageId) => {
    dispatch(actions.doChangePdf(pageId));
  },
  onChangeText: (pageId) => {
    dispatch(actions.doChangeText(pageId));
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PdfComponent);
