import {fromJS} from 'immutable';

export const State = fromJS({
  // common
  errMsg: "",
  isLoading: false,
  isUploading: false,
  // attachment
  attachmentDict: {},
  hitList: null,
  folderIdList: [],
  folders: {},
  isExceededQuota: false,
});
