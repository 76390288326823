import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { State } from './model';


const Reducer = handleActions({
  // common
  'doc/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'doc/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'doc/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'doc/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'doc/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'doc/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'doc/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'doc/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'doc/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'doc/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'doc/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'doc/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'doc/UPDATE_IS_EXCEEDED_QUOTA': (state, { payload }) => {
    return state.setIn(['isExceededQuota'], payload);
  },
  'doc/UPDATE_ENCRYPTED_PDF_NAME_LIST': (state, { payload }) => {
    return state.setIn(['encryptedPdfNameList'], fromJS(payload));
  },
  // case name
  'doc/UPDATE_CASE_NAME': (state, { payload }) => {
    return state.setIn(['caseName'], fromJS(payload.case.name));
  },
  // pageDuplicates
  'doc/UPDATE_DUPLICATES': (state, { payload }) => {
    return state.setIn(['duplicates'], fromJS(payload.duplicates))
                .setIn(['fileNames'], fromJS(payload.fileNames));
  },
  'doc/UPDATE_IS_LOADING': (state, { payload }) => {
    return state.setIn(['isLoading'], payload)
  },
  // category
  'doc/UPDATE_CATEGORY_AND_PAGEURLS': (state, { payload }) => {
    if (payload) {
      return state.setIn(['fileList'], fromJS(payload.fileList))
                  .setIn(['typeList'], fromJS(payload.typeList))
                  .setIn(['tagList'], fromJS(payload.tagList))
                  .setIn(['fileFolderList'], fromJS(payload.fileFolderList))
                  .setIn(['pageTypeFolderList'], fromJS(payload.pageTypeFolderList))
                  .setIn(['keywordList'], fromJS(payload.keywordList))
                  .setIn(['pageNos'], fromJS(payload.pageNos));
    } else {
      return state.setIn(['fileList'], fromJS([]))
                  .setIn(['typeList'], fromJS([]))
                  .setIn(['tagList'], fromJS([]))
                  .setIn(['fileFolderList'], fromJS([]))
                  .setIn(['pageTypeFolderList'], fromJS([]))
                  .setIn(['keywordList'], fromJS([]))
                  .setIn(['pageNos'], fromJS({}));
    }
  },
  'doc/GET_CASE_CATEGORY_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingCaseCategory'], true)
                .setIn(['errMsg'], null);
  },
  'doc/GET_CASE_CATEGORY_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingCaseCategory'], false)
                .setIn(['errMsg'], null);
  },
  'doc/GET_CASE_CATEGORY_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingCaseCategory'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'doc/DOWNLOAD_REQUEST': (state, { payload }) => {
    return state.setIn(['isDownloading'], true)
                .setIn(['errMsg'], null);
  },
  'doc/DOWNLOAD_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDownloading'], false)
                .setIn(['errMsg'], null);
  },
  'doc/DOWNLOAD_FAILURE': (state, { payload }) => {
    return state.setIn(['isDownloading'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  // pdf
  'doc/UPDATE_PDF_SCALE': (state, { payload }) => {
    return state.setIn(['pdfScale'], payload.pdfScale);
  },
  'doc/UPDATE_PDF_PAGE': (state, { payload }) => {
    return state.setIn(['pdfPageList'], fromJS(payload.pdfPageList))
                .setIn(['pdfPageIndex'], payload.pdfPageIndex)
                .setIn(['isLabelPdf'], payload.isLabelPdf);
  },
  'doc/UPDATE_PDF_PAGE_INDEX': (state, { payload }) => {
    return state.setIn(['pdfPageIndex'], payload.pdfPageIndex);
  },
  // label
  'doc/UPDATE_CASE_LIST': (state, { payload }) => {
    return state.setIn(['caseList'], fromJS(payload));
  },
  'doc/UPDATE_USER_FOLDER_LIST': (state, { payload }) => {
    return state.setIn(['userFolderList'], fromJS(payload));
  },
  'doc/UPDATE_LABELS': (state, { payload }) => {
    return state.setIn(['labelIdList'], fromJS(payload.labelIdList))
                .setIn(['labels'], fromJS(payload.labels));
  },
  'doc/UPDATE_LABEL': (state, { payload }) => {
    if (payload.type === 'note') {
      return state.setIn(['labels', payload.labelId, 'note'], payload.value);
    }
    return state;
  },
  // search
  'doc/UPDATE_HIT_LIST': (state, { payload }) => {
    return state.setIn(['hitList'], payload);
  },
  // HighLight
  'doc/UPDATE_ANNOTATION': (state, { payload }) => {
    return state.setIn(['highlightList'], fromJS(payload))
                .setIn(['highlightDict'], fromJS(fromJS(payload).groupBy((item) => item.get("fileId")).toJS()))
  },
}, State);

export default Reducer;
