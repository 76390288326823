import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getFolder,
  updateFolder,
  creatFolder
} from '../../utils/api';
import * as config from '../../config'

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)

export const updateFolderList = createAction(types.UPDATE_FOLDER_LIST)
export function doLoadFolderList() {
  return dispatch => {
    dispatch(getRequest());
    getFolder().then(data => {
      dispatch(updateFolderList(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doUpdateFolder(folderId, name) {
  return dispatch => {
    dispatch(putRequest());
    updateFolder(folderId, name).then(data => {
      dispatch(putSuccess());
      dispatch(doLoadFolderList());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doSubmitNewFolder(name) {
  return dispatch => {
    dispatch(postRequest());
    creatFolder(name).then(data => {
      dispatch(postSuccess());
      dispatch(doLoadFolderList());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}