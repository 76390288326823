import React, { Component } from 'react';
import payment from '../modules/payment';


class PaymentPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-payment">
        <payment.container />
	    </div>
	  )
  }
}

export default PaymentPageComponent;
