import {connect} from 'react-redux';
import * as pdfSelectors from '../pdf/selectors';
import * as topbarActions from "../topBar/actions";
import * as actions from './actions';
import DocComponent from './component';
import * as selectors from './selectors';


const mapStateToProps = (state) => ({
  // common
  isPosting: selectors.getIsPosting(state),
  isPutting: selectors.getIsPutting(state),
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
  isExceededQuota: selectors.getIsExceededQuota(state),
  encryptedPdfNameList: selectors.getEncryptedPdfNameList(state),
  // pageDuplicates
  duplicateList: selectors.getDuplicateList(state),
  // case
  caseName: selectors.getCaseName(state),
  isLoading: selectors.getIsLoading(state),
  // category
  fileList: selectors.getFileList(state),
  typeList: selectors.getTypeList(state),
  tagList: selectors.getTagList(state),
  fileFolderList: selectors.getFileFolderList(state),
  pageTypeFolderList: selectors.getPageTypeFolderList(state),
  keywordList: selectors.getKeywordList(state),
  isDownloading: selectors.getIsDownloading(state),
  isGettingCaseCategory: selectors.getIsGettingCaseCategory(state),
  // label
  caseList: selectors.getCaseList(state),
  userFolderList: selectors.getUserFolderList(state),
  labelIdList: selectors.getLabelIdLIst(state),
  labels: selectors.getLabels(state),
  // pdf
  isLabelPdf: selectors.getIsLabelPdf(state),
  pdfPageIndex: selectors.getPdfPageIndex(state),
  pdfPageListSize: selectors.getPdfPageListSize(state),
  currentPdfPage: selectors.getCurrentPdfPage(state),
  // search
  hitList: selectors.getHitList(state),

  pageId: selectors.getPageId(state),
  // Highlight
  highlightList: selectors.getHighlightList(state),
  highlightDict: selectors.getHighlightDict(state),
});


const mapDispatchToProps = dispatch => ({
  // pageDuplicates
  onLoadDuplicates: (caseId) => {
    dispatch(actions.doLoadDuplicates(caseId));
  },
  // case
  onLoadCaseName: (caseId) => {
    dispatch(actions.doLoadCaseName(caseId));
  },
  onSubmitUploadFiles: (caseId, uploadFileList) => {
    dispatch(actions.doSubmitUploadFiles(caseId, uploadFileList));
  },
  onCleanEncryptedPdfNameList: () => {
    dispatch(actions.updateEncryptedPdfNameList([]));
  },
  onChangeSequence: (caseId, fileId, newSerialNumber) => {
    dispatch(actions.doChangeSequence(caseId, fileId, newSerialNumber));
  },
  // category
  onLoadCaseCategory: (caseId) => {
    return dispatch(actions.doLoadCaseCategory(caseId));
  },
  onDownloadCase: (caseId, caseName) => {
    dispatch(actions.doDownloadCase(caseId, caseName));
  },
  onDownloadFile: (fileId, fileTitle) => {
    dispatch(actions.doDownloadFile(fileId, fileTitle));
  },
  onDownloadTypes: (caseId, caseName) => {
    dispatch(actions.doDownloadTypes(caseId, caseName));
  },
  onPageTypeDownload: (pageTypeIdList, pageTypeName) => {
    dispatch(actions.doPageTypeDownload(pageTypeIdList, pageTypeName));
  },
  onDownloadTags: (caseId, caseName) => {
    dispatch(actions.doDownloadTags(caseId, caseName));
  },
  // fileEdit
  onFileEdit: (fileId, newFileTitle, caseId) => {
    dispatch(actions.doFileEdit(fileId, newFileTitle, caseId));
  },
  onFileDelete: (fileId, caseId) => {
    dispatch(actions.doFileDelete(fileId, caseId));
  },
  onPageTypeEdit: (pageTypeIdList, newPageType, caseId) => {
    dispatch(actions.doPageTypeEdit(pageTypeIdList, newPageType, caseId));
  },
  onTagAdd: (pageTypeId, addTag, caseId) => {
    dispatch(actions.doTagAdd(pageTypeId, addTag, caseId));
  },
  onTagEdit: (tagId, newTag, pageTypeId, caseId) => {
    dispatch(actions.doTagEdit(tagId, newTag, pageTypeId, caseId));
  },
  onTagMigrateEdit: (pageTypeId, fromTagId, toTagName, caseId) => {
    dispatch(actions.doTagMigrateEdit(pageTypeId, fromTagId, toTagName, caseId));
  },
  onChangePdfScale: (pdfScale) => {
    dispatch(actions.updatePdfScale({ pdfScale }));
  },
  onChangeCategoryPdf: (pageIdList) => {
    dispatch(actions.doChangeCategoryPdf(pageIdList));
  },
  onChangeLabelPdf: (labelId) => {
    dispatch(actions.doChangeLabelPdf(labelId));
  },
  onChangePdfPage: (pdfPageIndex) => {
    dispatch(actions.updatePdfPageIndex({ pdfPageIndex }));
  },
  onSubmitSaveNewPageTypeList: (pageTypeId, pageTypeList, caseId) => {
    dispatch(actions.doSubmitSaveNewPageTypeList(pageTypeId, pageTypeList, caseId));
  },
  onSubmitMergePageType: (pageTypeId, caseId, isDisplayFile) => {
    dispatch(actions.doSubmitMergePageType(pageTypeId, caseId, isDisplayFile));
  },
  onAddKeyword: (caseId, keyword) => {
    dispatch(actions.doAddKeyword(caseId, keyword));
  },
  onLabelDownload: (labelId, labelName) => {
    dispatch(actions.doLabelDownload(labelId, labelName));
  },
  // label
  onLoadCaseList: () => {
    dispatch(actions.doLoadCaseList());
  },
  onLoadUserFolderList: () => {
    dispatch(actions.doLoadUserFolderList());
  },
  onLoadLabels: (caseId) => {
    dispatch(actions.doLoadLabels(caseId));
  },
  onSubmitLabel: (caseId, labelName) => {
    dispatch(actions.doSubmitLabel(caseId, labelName));
  },
  onDeleteLabel: (caseId, labelId) => {
    dispatch(actions.doDeleteLabel(caseId, labelId));
  },
  onChangeLabel: (labelId, type, value) => {
    dispatch(actions.doChangeLabel({ labelId, type, value }));
  },
  onSaveLabelNote: (labelId) => {
    dispatch(actions.doSaveLabelNote(labelId));
  },
  onRemoveLabelPage: (caseId, labelId, fileId) => {
    dispatch(actions.doRemoveLabelPage(caseId, labelId, fileId));
  },
  onSubmitAddPagesToLabel: (caseId, isAddingRangeToLabel, labelId) => {
    dispatch(actions.doSubmitAddPagesToLabel(caseId, isAddingRangeToLabel, labelId));
  },
  onSubmitCopyLabelToFolder: (userFolderId, labelId) => {
    dispatch(actions.doCopyLabelToFolder(userFolderId, labelId));
  },
  // search
  onSubmitSearch: (caseId, searchWord) => {
    dispatch(actions.doSubmitSearch(caseId, searchWord));
  },
  onCleanSearchResult: () => {
    dispatch(actions.updateHitList([]));
  },
  // highlight
  onLoadAnnotation: (caseId) => {
    dispatch(actions.doLoadAnnotation(caseId));
  },
  onDeleteAnnotations: (pageId, type) => {
    dispatch(actions.doDeleteAnnotations(pageId, type));
  },
  // folder
  onCreateFilesFolder: (caseId, folderName) => {
    dispatch(actions.doCreateFilesFolder(caseId, folderName));
  },
  onModifyFilesFolder: (caseId, folderId, folderName) => {
    dispatch(actions.doModifyFilesFolder(caseId, folderId, folderName));
  },
  onDeleteFileFolder: (caseId, folderId) => {
    dispatch(actions.doDeleteFileFolder(caseId, folderId));
  },
  onMovingFileToFolder: (caseId, fileId, toFolderId) => {
    dispatch(actions.doMovingFileToFolder(caseId, fileId, toFolderId));
  },
  onCombineDocPageToPages: (caseId, pageTypeIdList, toPageTypeId) => {
    dispatch(actions.doCombineDocPageToPages(caseId, pageTypeIdList, toPageTypeId));
  },
  onCreatePageTypesFolder: (caseId, folderName) => {
    dispatch(actions.doCreatePageTypesFolder(caseId, folderName));
  },
  onModifyFolder: (caseId, folderId, folderName) => {
    dispatch(actions.doModifyFolder(caseId, folderId, folderName));
  },
  onDeleteFolder: (caseId, folderId) => {
    dispatch(actions.doDeleteFolder(caseId, folderId));
  },
  onMovingToFolder: (caseId, pageTypeIdList, toFolderId) => {
    dispatch(actions.doMovingToFolder(caseId, pageTypeIdList, toFolderId));
  },
  onMovingToPageType: (caseId, pageTypeIdList, toType, toFolderId) => {
    if (toFolderId !== "") {
      dispatch(actions.doMovingToPageTypeInFolder(caseId, pageTypeIdList, toType, toFolderId));
    } else {
      dispatch(actions.doMovingToPageType(caseId, pageTypeIdList, toType));
    }
  },
  onCombinePageToPages: (caseId, pageTypeIdList, toPageTypeId) => {
    dispatch(actions.doCombinePageToPages(caseId, pageTypeIdList, toPageTypeId));
  },
  onClickLogout: () => {
    dispatch(topbarActions.doLogout());
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocComponent);
