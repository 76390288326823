// common
export const POST_REQUEST = 'reviewNoteDraggable/POST_REQUEST'
export const POST_SUCCESS = 'reviewNoteDraggable/POST_SUCCESS'
export const POST_FAILURE = 'reviewNoteDraggable/POST_FAILURE'
export const PUT_REQUEST = 'reviewNoteDraggable/PUT_REQUEST'
export const PUT_SUCCESS = 'reviewNoteDraggable/PUT_SUCCESS'
export const PUT_FAILURE = 'reviewNoteDraggable/PUT_FAILURE'
export const PUT_POSITION_REQUEST = 'reviewNoteDraggable/PUT_POSITION_REQUEST'
export const PUT_POSITION_SUCCESS = 'reviewNoteDraggable/PUT_POSITION_SUCCESS'
export const PUT_POSITION_FAILURE = 'reviewNoteDraggable/PUT_POSITION_FAILURE'

export const PUT_CURRENT_NOTE_ID = 'reviewNoteDraggable/PUT_CURRENT_NOTE_ID'