import {createAction} from 'redux-actions';
import {createHighlight, getPageBase64, getPageText, getPageUrl} from '../../utils/api';
import * as docActions from '../doc/actions';
import * as types from './actionTypes';
import {convertBase64ToBinary} from "../../utils/convertBase64ToBinary";


export const getRequest = createAction(types.GET_REQUEST);
export const getSuccess = createAction(types.GET_SUCCESS);
export const getFailure = createAction(types.GET_FAILURE);

export const putRequest = createAction(types.PUT_REQUEST);
export const putSuccess = createAction(types.PUT_SUCCESS);
export const putFailure = createAction(types.PUT_FAILURE);

export function doAddAnnotation(caseId, pageId, type, annotation) {
  return dispatch => {
    if (type === 'highlight') {
      createHighlight(pageId, annotation).then(() => {
        dispatch(putSuccess());
        dispatch(docActions.doLoadAnnotation(caseId));
      }).catch((errMsg) => {
        dispatch(putFailure({ errMsg }));
      })
    }
  }
}

export const updateCachePageUrl = createAction(types.UPDATE_CACHE_PAGE_URL);
export const updateCachePages = createAction(types.UPDATE_CACHE_PAGES);

export function doChangePdf(pageId) {
  return (dispatch, getState) => {
    if (pageId === 0) {
      dispatch(updateCachePageUrl(''));
      return;
    } else if (pageId === undefined) {
      dispatch(updateCachePageUrl(null));
      return;
    }
    let pageBase64s = getState().getIn(['pdf', 'pageBase64s']);
    const pageBase64 = pageBase64s.getIn([pageId]);
    if (pageBase64 === undefined) {
      dispatch(getRequest());
      getPageBase64(pageId).then(data => {
        const state = getState();
        const pdfPageList = state.getIn(['doc', 'pdfPageList']);
        const pdfPageIndex = state.getIn(['doc', 'pdfPageIndex']);
        const statePageId =  pdfPageList.getIn([pdfPageIndex, 'pageId']);

        let pageBase64List = state.getIn(['pdf', 'pageBase64List']);
        pageBase64List = pageBase64List.unshift(pageId);
        pageBase64s = pageBase64s.setIn([pageId], convertBase64ToBinary(data.b64));
        if (pageBase64List.size > 20) {
          const lastCachePageId = pageBase64List.last();
          pageBase64List = pageBase64List.pop();
          pageBase64s = pageBase64s.delete(lastCachePageId);
        }
        if (statePageId === pageId) {
          dispatch(updateCachePages({ pageBase64List, pageBase64s }));
        }
        dispatch(getSuccess());
      }).catch((errMsg) => {
        dispatch(getFailure({ errMsg }));
      })
    }
  }
}

export const updatePageText = createAction(types.UPDATE_PAGE_TEXT);

export function doChangeText(pageId) {
  return (dispatch) => {
    if (pageId === undefined) {
      dispatch(updatePageText(''));
      return;
    }
    getPageText(pageId).then(data => {
      dispatch(updatePageText(data));
      // dispatch(getSuccess());
    }).catch((errMsg) => {
      // dispatch(getFailure({ errMsg }));
    })
  }
}

