import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message } from 'antd';
import dnaLogoIcon from '../../assets/img/dna-logo.svg';
import dnaBgIcon from '../../assets/img/dna-bg.png';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      errMsg: ''
    };
  }
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    this.props.onCheckToekn(params.get('to'));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isGetting === true && this.props.isGetting === false) {
      if (this.props.errMsg !== null) {
        message.error(this.props.errMsg);
        this.props.history.push(`/login`);
      }
    }
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('密碼更新成功，請使用新密碼登入');
        this.props.history.push(`/login`);
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  onSubmitChangePassword = () => {
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      this.setState({ errMsg: '兩次輸入的密碼不一致，請重新輸入' })
    } else {
      this.props.onUpdatePassword(password);
    }
  }
  render() {
    const { 
      errMsg
    } = this.state;

  	return (
	    <div className="login-section">  
        <img className="login-logo-bg" alt="" src={dnaBgIcon}/>
        <img className="login-logo" alt="" src={dnaLogoIcon}/>
        <div className="mb-3">
          <div className="alert pl-5 pr-5" role="alert">
            <ul>
              <li>更改密碼注意事項</li>
              <li>更改密碼至少要 8 個字元以上且含有字母、數字、符號</li>
            </ul>
          </div>
        </div> 
        <form className="login-form" onSubmit={e => {e.preventDefault();this.setState({ errMsg: '' }, () => this.onSubmitChangePassword())}}>
          <h3 className="login-form-label text-center mb-3">重設密碼</h3>
          <input className="p-2"
            type="password" 
            placeholder="請輸入新密碼" 
            pattern="(?=^.{8,}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[a-z]).*$"
            onChange={e => this.setState({ password: e.target.value })}
            required 
          />
          <input className="p-2 mt-3"
            type="password" 
            placeholder="再一次輸入密碼" 
            pattern="(?=^.{8,}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[a-z]).*$"
            onChange={e => this.setState({ confirmPassword: e.target.value })}
            required 
          />
          <div className="login-form-bottom">
            <button type="submit" className="btn btn-primary w-100 mb-3" disabled={this.props.isPutting}>重設密碼</button>
            <Link to={`/login`}>
              <button type="button" className="btn btn-outline-primary w-100">返回登入頁</button>
            </Link>
          </div>
          {
            errMsg
            &&
            <p className="err-msg">{ errMsg }</p>
          }          
        </form>      
	    </div>
	  )
  }
}

export default withRouter(ResetPasswordComponent);