import React, {Component} from 'react';
import topBar from '../modules/topBar';
import navbar from '../modules/navbar';
import mailroom from '../modules/mailroom';

class MailroomPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
  	return (
	    <div id="page-mailroom">
        <topBar.container/>
        <div className="bottom-container">
          <navbar.container/>
          <div className="main-section">
            <mailroom.container />
          </div>
        </div>
	    </div>
	  )
  }
}

export default MailroomPageComponent;
