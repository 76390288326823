import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import IndexComponent from './component';


const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexComponent);
