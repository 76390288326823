import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import configureStore from './configureStore.js';
import AttachmentPageComponent from './pages/attachmentPage';
import ScrollToTop from './utils/ScrollToTop';
import withAuth from './utils/withAuth';
import IndexPageComponent from './pages/indexPage';
import SeminarPageComponent from './pages/seminarPage';
import RegistrationPageComponent from './pages/registrationPage';
import LoginPageComponent from './pages/loginPage';
import ForgotPasswordPageComponent from './pages/forgotPasswordPage';
import ResetPasswordPageComponent from './pages/resetPasswordPage';
import ResetPasswordWithOldPasswordPageComponent from './pages/resetPasswordWithOldPasswordPage';
import ProfilePageComponent from './pages/profilePage';
import CreatConsultationsPageComponent from './pages/creatConsultationsPage';
import ConsultationsPageComponent from './pages/consultationsPage';
import CreatCasePageComponent from './pages/creatCasePage';
import CasesPageComponent from './pages/casesPage';
import MailroomPageComponent from './pages/mailroomPage';
import ReportPageComponent from './pages/reportPage';
import CasesReceiptPageComponent from './pages/casesReceiptPage';
import PowerOfAttorneyPageComponent from './pages/powerOfAttorneyPage';
import CasesContractPageComponent from './pages/casesContractPage';
import PreviewNotesPageComponent from './pages/previewNotesPage';
import ExportConsultationsPageComponent from './pages/exportConsultationsPage';
import ReviewPageComponent from './pages/reviewPage';
import SearchExtendPageComponent from './pages/searchExtendPage';
import SearchPageComponent from './pages/searchPage';
import PlanPageComponent from './pages/planPage';
import PaymentPageComponent from './pages/paymentPage';
import FolderPageComponent from './pages/folderPage';
import ContractPageComponent from './pages/contractPage';
import ConsultationsFormPageComponent from './pages/consultationsFormPage';
// import '../node_modules/antd/dist/antd.css';
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/main.css';
import './assets/css/index.css';
import './assets/css/icon.css';

// <Route path="/review/:caseId" exact component={withAuth(ReviewPageComponent)} />
// <Route path="/review/:caseId/doc/:docId" component={withAuth(DocPageComponent)} />


ReactDOM.render(
  <Provider store={configureStore}>
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/index" component={IndexPageComponent} />
          <Route path="/seminar" component={SeminarPageComponent} />
          <Route path="/registration" component={RegistrationPageComponent} />
          <Route path="/login" component={LoginPageComponent} />
          <Route path="/forgot-password" component={ForgotPasswordPageComponent} />
          <Route path="/reset-password" component={ResetPasswordPageComponent} />
          <Route path="/password/reset" component={withAuth(ResetPasswordWithOldPasswordPageComponent)} />
          <Route path="/profile" component={withAuth(ProfilePageComponent)} />
          <Route path="/consultationsForm/:pinCode" component={ConsultationsFormPageComponent} />
          <Route path="/creat-consultations" component={withAuth(CreatConsultationsPageComponent)} />
          <Route path="/consultations" component={withAuth(ConsultationsPageComponent)} />
          <Route path="/creat-case" component={withAuth(CreatCasePageComponent)} />
          <Route path="/cases" component={withAuth(CasesPageComponent)} />
          <Route path="/mailroom" component={withAuth(MailroomPageComponent)} />
          <Route path="/report" component={withAuth(ReportPageComponent)} />
          <Route path="/case-receipt/:caseId" component={withAuth(CasesReceiptPageComponent)} />
          <Route path="/power-of-attorney/:caseId" component={withAuth(PowerOfAttorneyPageComponent)} />
          <Route path="/case-contract/:caseId" component={withAuth(CasesContractPageComponent)} />
          <Route path="/preview-notes" component={withAuth(PreviewNotesPageComponent)} />
          {/* <Route path="/consultations/receipt/:caseId" component={withAuth(CasesReceiptPageComponent)} /> */}
          <Route path="/export-consultations" component={withAuth(ExportConsultationsPageComponent)} />
          <Route path="/search/:criteria" component={withAuth(SearchExtendPageComponent)} />
          <Route path="/search" component={withAuth(SearchPageComponent)} />
          <Route path="/review/:caseId" component={withAuth(ReviewPageComponent)} />
          <Route path="/attachment/:caseId" component={withAuth(AttachmentPageComponent)} />
          <Route path="/plan" component={withAuth(PlanPageComponent)} />
          <Route path="/payment" component={withAuth(PaymentPageComponent)} />
          <Route path="/folder" component={withAuth(FolderPageComponent)} />
          <Route path="/contract" component={ContractPageComponent} />
          <Route path="*" component={IndexPageComponent} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);



