import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  updateAppointmentDates
} from '../../utils/api';

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)

export function doUpdateDates(appointmentId, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate) {
  return dispatch => {
    dispatch(putRequest());
    updateAppointmentDates(appointmentId, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}