import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  errMsg: '',
  casesReceipt: {
    category: '',
    cost: '',
    date: '',
    name: '',
    note: '',
    reason: '',
  },
  lawer: {
    address: '',
    lastName: '',
    firstName: '',
    faxNo: '',
    lawFirmName: '',
  },
});