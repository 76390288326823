import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { message, Tooltip } from 'antd';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

class AppointmentNotesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (
      <div className="appointmentNotes-section section-col-item">
        <div>
          <ul className="case-list-head">
            <li className="head-date">日期</li>
            <li className="head-fileName">檔案名</li>
            <li className="head-filePage">檔案頁碼</li>
            <li className="head-notes">筆記</li>
            <li className="head-action"></li>
          </ul>
          {
            this.props.caseDetail && this.props.caseDetail.map(note =>
              <ul className="case-list-body" key={`caseDetail-Note-${note.getIn(['id'])}`}>
                <li className="body-date">{note.getIn(['date'])}</li>
                <li className="body-fileName">{note.getIn(['title'])}</li>
                <li className="body-filePage">{note.getIn(['pageNo'])}</li>
                <li className="body-notes"
                  onClick={e => {
                    localStorage.setItem('cachedNoteId', note.getIn(['id']));
                    localStorage.setItem('cachedNoteFileId', note.getIn(['fileId']));
                    localStorage.setItem('cachedNotePageTypeId', note.getIn(['pageTypeId']));
                    localStorage.setItem('cachedNotePageNo', note.getIn(['pageNo']));
                    localStorage.setItem('cachedNotePageId', note.getIn(['pageId']));
                    this.props.history.push(`review/${this.props.appointmentId}`);
                  }}>
                >{note.getIn(['content'])}</li>
                <li className="body-action">
                  <Tooltip title="預覽列印-卷證筆記">
                    <Link target="_blank" to={`/preview-notes/${note.getIn(['id'])}`} onClick={e => localStorage.setItem("previewNotesId", note.getIn(['id']))}><i><FontAwesomeIcon icon={fas.faPrint} /></i></Link>
                  </Tooltip>
                </li>
              </ul>
            )
          }
        </div>
      </div>
    )
  }
}
export default withRouter(AppointmentNotesComponent);