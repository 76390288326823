import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import CreatCaseComponent from './component';

const mapStateToProps = (state) => ({
  // common
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),  
  caseRelateList: selectors.getCaseRelateList(state),
  isFetching: selectors.getIsFetching(state),
});

const mapDispatchToProps = dispatch => ({
  resetErrMsg: () => {
    dispatch(actions.updateErrMsg());
  },
  onLoadRelatedList: (contain, size) => {
    dispatch(actions.doLoadRelatedList(contain, size));
  },
  onSubmitCreateConsultations: (consultationDate, appointorType, appointorData, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote) => {
    dispatch(actions.doSubmitCreateConsultations(consultationDate, appointorType, appointorData, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatCaseComponent);