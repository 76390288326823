export const POST_REQUEST = 'search/POST_REQUEST'
export const POST_SUCCESS = 'search/POST_SUCCESS'
export const POST_FAILURE = 'search/POST_FAILURE'
export const GET_REQUEST = 'search/GET_REQUEST'
export const GET_SUCCESS = 'search/GET_SUCCESS'
export const GET_FAILURE = 'search/GET_FAILURE'

export const SEARCH_REQUEST = 'search/SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'search/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'search/SEARCH_FAILURE'

export const UPDATE_SEARCH_RESULT = 'search/UPDATE_SEARCH_RESULT'
export const UPDATE_CASE_LIST = 'search/UPDATE_CASE_LIST'