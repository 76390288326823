import React, { Component } from 'react';
import consultationsForm from '../modules/consultationsForm';

class ConsultationsFormPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div id="page-consultationsForm">
        <consultationsForm.container />
      </div>
    )
  }
}

export default ConsultationsFormPageComponent;