import React, { Component } from 'react';
import previewNotes from '../modules/previewNotes';


class PreviewNotesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
  	return (
	    <div id="page-previewNotes">
        <previewNotes.container />
	    </div>
	  )
  }
}

export default PreviewNotesPageComponent;
