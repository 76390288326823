import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { message, Modal, Input, Tooltip, Spin, Upload, Button } from 'antd';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

class AppointmentFilesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateFileName: false,
      updateFileName: '',
      updateFileId: '',
      showFileUploader: false,
      uploadFileList: [],
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.isPosting === true && this.props.isPosting === false)
      || (prevProps.isPutting === true && this.props.isPutting === false)
      || (prevProps.isDeleting === true && this.props.isDeleting === false)) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.docIsPosting === true && this.props.docIsPosting === false) {
      if (this.props.docErrMsg === null) {
        message.success('上傳成功');
        this.props.onChangeDetail();
      } else {
        message.error(this.props.docErrMsg);
      }
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onClickDeleteFile(fileId, fileName) {
    Modal.confirm({
      title: '刪除卷宗',
      content: `按下"確認"按鈕將刪除 ${fileName}`,
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteFile(fileId, this.props.appointmentId);
      },
    });
  }
  onClickSubmitUpdateFileName(fileId) {
    const { updateFileName } = this.state;
    if(updateFileName){
      let name = updateFileName ? updateFileName.trim() : '';
      this.props.onSubmitUpdateFileName(fileId, name);
      this.setState({ updateFileName: '', updateFileId: '' });
    }else{
      return message.error('請勿空白欄位')
    }
  }
  onToggleFileUploader(showFileUploader, currentUserFileId, currentUserFileName) {
    this.setState({ showFileUploader, currentUserFileId, currentUserFileName, selectedUserFolderId: '' })
  }
  async onClickSubmitUploadFiles(caseId, uploadFileList) {
    if( uploadFileList.length === 0 ) {
      return message.warning('請新增檔案')
    } else {
      this.props.onSubmitUploadFiles(caseId, uploadFileList);
    }
  }

  render() {
    const { showUpdateFileName, updateFileName, uploadFileList, showFileUploader } = this.state;
    const UploadProps = {
      accept: ".pdf",
      multiple: true,
      defaultFileList: uploadFileList,
      onRemove: file => {
        this.setState(state => {
          const index = state.uploadFileList.indexOf(file);
          const newFileList = state.uploadFileList.slice();
          newFileList.splice(index, 1);
          return {
            uploadFileList: newFileList,
          };
        });
      },
      beforeUpload: (file, fileList) => {
        this.setState(state => ({
          uploadFileList: [...state.uploadFileList, file]
        }));
        return false;
      }
    };
    return (
      <>
        <div className="appointmentfiles-section section-col-item">
          <div className="case-file-extend">
            <div className="case-file-extend-head">
              <button className="btn-empty"
                onClick={e => this.setState({showFileUploader: true})}
              >
                <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
                上傳檔案
              </button>
              <button className="btn-empty"
                onClick={e => this.props.history.push(`review/${this.props.appointmentId}`)}>
                <i><FontAwesomeIcon icon={fas.faSignInAlt} /></i>
                管理頁面
              </button>
            </div>
            <div className="case-file-list">
              {
                this.props.fileList && this.props.fileList.map(file_ =>
                  <div className="case-file" key={`file_-${file_.getIn(['id'])}`}>
                    <div className="case-file-title"
                        onClick={e => {
                          this.props.history.push(`review/${this.props.appointmentId}`);
                          localStorage.setItem("cachedFileId", file_.getIn(['id']));
                        }}>
                      { file_.getIn(['name']) }
                    </div>
                    {
                      file_.getIn(['status']) === 1 || file_.getIn(['status']) === 2 ?
                        <div style={{color: "#2b95eb"}}><Spin size="small"/> 卷證處理中</div>
                      : file_.getIn(['status']) === 3 ?
                        <div className="case-file-tool">
                          <Tooltip title="檔案處理失敗，刪除檔案">
                            <button className="btn-empty" style={{width: "100px"}}
                              onClick={e => this.onClickDeleteFile(file_.getIn(['id']), file_.getIn(['name']))}
                            ><FontAwesomeIcon icon={far.faTrashAlt} /> 檔案處理失敗</button>
                          </Tooltip>
                        </div>
                      : file_.getIn(['status']) === 4 ?
                        <div className="case-file-tool">
                          <Tooltip title="檔案更名">
                            <button className="btn-empty"
                              onClick={e => {this.setState({showUpdateFileName: !showUpdateFileName, updateFileName: file_.getIn(['name']), updateFileId: file_.getIn(['id'])})}}
                            ><FontAwesomeIcon icon={fas.faPencilAlt} /></button>
                          </Tooltip>
                          <Tooltip title="下載檔案">
                            <button className="btn-empty"
                              onClick={e => this.props.onDownloadFile(file_.getIn(['id']), file_.getIn(['name']))}
                            ><FontAwesomeIcon icon={fas.faFileDownload} /></button>
                          </Tooltip>
                          <Tooltip title="刪除檔案">
                            <button className="btn-empty"
                              onClick={e => this.onClickDeleteFile(file_.getIn(['id']), file_.getIn(['name']))}
                            ><FontAwesomeIcon icon={far.faTrashAlt} /></button>
                          </Tooltip>
                        </div>
                      : null
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <Modal className="case-file-upload"
          title={'法律卷證檔案上傳'}
          visible={showFileUploader}
          onOk={() => {this.onClickSubmitUploadFiles(this.props.appointmentId, uploadFileList); this.setState({showFileUploader: false});}}
          onCancel={() => this.setState({showFileUploader: false})}
        >
          <div className="case-file-upload-item">
            <div className="case-file-upload-title">檔案</div>
            <Upload className="case-file-upload-select" {...UploadProps}>
              <Button>
                <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
                選擇上傳檔案
              </Button>
            </Upload>
          </div>
        </Modal>
        <Modal className="update-docket"
          title="刪除案號"
          visible={this.state.showUpdateFile}
          okText="確認"
          cancelText="取消"
          onOk={() => {this.props.onSubmitDelDocket(); this.setState({ showDelDocketModal: false })}}
          onCancel={() => this.setState({ showDelDocketModal: false })}
        >
          <div className="docket-col">
          </div>
        </Modal>
        <Modal className="update-fileName"
          title="更改檔名"
          visible={this.state.showUpdateFileName}
          okText="確認"
          cancelText="取消"
          onOk={() => {this.onClickSubmitUpdateFileName(this.state.updateFileId); this.setState({ showUpdateFileName: false })}}
          onCancel={() => this.setState({ showUpdateFileName: false })}
        >
          <div className="docket-col">
            <Input className="input"
              placeholder="更新檔案名"
              allowClear
              type="text"
              value={updateFileName}
              onChange={e => this.setState({ updateFileName: e.target.value })}
            />
          </div>
        </Modal>
      </>
    )
  }
}
export default withRouter(AppointmentFilesComponent);
