import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "antd/es/pagination";
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import { NotificationString } from "../../constants/NotificationString";
import FullSpinner from "../../utils/FullSpinner";

class TopBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistory: false,
      showNotificationBlock: false,
      notificationString: NotificationString,
      currentNotification: null,
      showNotificationDetail: false,
      pageNumber: 1,
      showAccountTool: false,
    };
  }

  componentDidMount() {
    this.props.onLoadNotification();
    this.setState({ showNotificationBlock: false })
  }

  getNotificationStyle(type, key) {
    return this.state.notificationString[type][key];
  }

  onDisplayHistory() {
    this.props.onLoadHistory(this.state.pageNumber);
    this.setState({ showHistory: true })
  }

  onChangePage(pageNumber) {
    this.setState({ showHistory: pageNumber })
  }

  onClickNotificationDetail(notification) {
    this.setState({ currentNotification: notification, showNotificationDetail: true });
  }
  componentDidUpdate(prevProps, prevState) {
    if ( !this.state.showNotificationBlock && this.props.loginCheckNotification === true) {
      if (this.props.notificationList && this.props.notificationList.size > 0) {
        this.setState({ showNotificationBlock: true })
      }
    }
    if ( this.state.showNotificationBlock && this.props.loginCheckNotification === true ) {
      this.props.onUpdateLoginCheckNotification(false)
    }
  }
  
  render() {
    const {
      currentNotification, showNotificationBlock, showNotificationDetail,
      showHistory, showAccountTool
    } = this.state;
    const { showRWDNavbar } = this.props;
    return (
      <div className={`topBar-container ${showNotificationBlock && "open"}`} id="topBar">
        <div className="topBar-wrapper">
          <div className="logo-wrapper">
            <button className="rwd-nav" onClick={e => this.props.onUpdateRWDNavbar(!showRWDNavbar)}><FontAwesomeIcon className="toolBar-icon" icon={fas.faBars} /></button>
            <Link to="/cases"><img className="logo" alt="" src={dnaLogoIcon} /></Link>
          </div>
          <div className="top-tool-wrapper">
            <div className={`notification-wrapper ${this.props.notificationList && this.props.notificationList.size > 0 && "hasData"}`}
                onClick={() => this.setState({ showNotificationBlock: !showNotificationBlock })}
            >
              <FontAwesomeIcon className="tool-icon" icon={fas.faBell} />
              <span className="notification-tip" />
            </div>
            <div className={`account-wrapper`} onClick={() => this.setState({ showAccountTool: !showAccountTool })}>
              <FontAwesomeIcon className="tool-icon" icon={fas.faUser} />
              {
                showAccountTool &&
                <div className="account-toolBar-popup">
                  <p className="toolBar-wrapper"
                    onClick={() => this.props.history.push(`/profile`)}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faUserCog} />
                    <span className="toolBar-label">帳戶設定</span>
                  </p>
                  <p className="toolBar-wrapper"
                    onClick={() => this.props.history.push(`/plan`)}>
                    <span className="toolBar-label">方案內容</span>
                  </p>
                  {/* <p className="toolBar-wrapper"
                    onClick={() => this.props.history.push(`/payment`)}>
                    <span className="toolBar-label">付款紀錄</span>
                  </p> */}
                  <p className="toolBar-wrapper"
                    onClick={() => this.props.onClickLogout()}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faSignOutAlt} />
                    <span className="toolBar-label">登出</span>
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="notification-container message-item-wrapper">
          {
            (this.props.notificationList && this.props.notificationList.size > 0) ?
              this.props.notificationList.map((notification, notificationIdx) =>
                <div className={`message-item ${this.getNotificationStyle(notification.get("type"), "className")}`}
                  onClick={() => this.onClickNotificationDetail(notification)}
                  key={notificationIdx}>
                  <p className="message-item-icon">
                    <FontAwesomeIcon icon={fas[this.getNotificationStyle(notification.get("type"), "iconClass")]} />
                  </p>
                  <div className="message-item-message">
                    <p className="message-title">{this.getNotificationStyle(notification.get("type"), "title")}</p>
                    <p className="message-content">{notification.get("message")}</p>
                  </div>
                  <p className="message-item-close" onClick={(event) => {
                    event.stopPropagation();
                    this.props.onUpdateNotification(notification.get("messageId"));
                  }}>
                    <FontAwesomeIcon icon={fas.faBookOpen} />
                    <span className="message-close-label">已讀</span>
                  </p>
                </div>
              )
              :
              <div className="notification-empty">
                <FontAwesomeIcon className="empty-icon" icon={fas.faBell} />
                <p className="empty-content">訊息皆已閱讀完畢</p>
              </div>
          }
          <div className="notification-manager">
            <button className="btn btn-outline-primary" onClick={() => this.onDisplayHistory()}>歷史訊息管理</button>
          </div>
        </div>
        <div className="topBar-collapse-wrapper"
          onClick={() => this.setState({ showNotificationBlock: !showNotificationBlock })}>
          <div className="collapse-inner-circle">
            <FontAwesomeIcon icon={fas.faChevronUp} />
          </div>
        </div>
        {
          (currentNotification && showNotificationDetail)
          &&
          <div className={`alert-container ${showNotificationDetail && "open"}`}>
            <div className={`alert-wrapper ${this.getNotificationStyle(currentNotification.get("type"), "className")}`}>
              <p className="alert-icon">
                <FontAwesomeIcon icon={fas[this.getNotificationStyle(currentNotification.get("type"), "iconClass")]} />
              </p>
              <p className="alert-title">{this.getNotificationStyle(currentNotification.get("type"), "title")}</p>
              <p className="alert-content">{currentNotification.get("message")}</p>
              <p className="alert-btn">
                {
                  currentNotification.get("category") === "receives" ?
                    <button className="btn btn-outline-primary"
                      onClick={() => {
                        this.setState({
                          showNotificationBlock: false,
                          showHistory: false,
                          currentNotification: null,
                          showNotificationDetail: false
                        });
                        this.props.onUpdateCachedReceivedId(currentNotification.getIn(['relativeID']))
                        this.props.history.push(`/mailroom`)
                      }}>前往收發管理
                    </button>
                  : currentNotification.get("category") === "case" ?
                    <button className="btn btn-outline-primary"
                      onClick={() => {
                        this.setState({
                          showNotificationBlock: false,
                          showHistory: false,
                          currentNotification: null,
                          showNotificationDetail: false
                        });
                        this.props.onUpdateCachedAppointmentId(currentNotification.getIn(['relativeID']))
                        this.props.history.push(`/cases`)
                      }}>前往委任列表
                    </button>
                  :
                    null
                }
                <button className="btn btn-outline-info"
                  onClick={() => this.setState({ currentNotification: null, showNotificationDetail: false })}>關閉
                </button>
              </p>
            </div>
          </div>
        }
        {
          showHistory
          &&
          <div className="history-popup">
            <div className="history-container message-item-wrapper">
              <div className="history-top">
                <p className="history-title">歷史訊息</p>
                <p className="history-close-wrapper" onClick={() => this.setState({ showHistory: false })}>
                  <FontAwesomeIcon className="history-close" icon={fas.faTimes} />
                </p>
              </div>
              <div className="history-content">
                {
                  (this.props.historyList && this.props.historyList.size > 0) ?
                    this.props.historyList.map((history, historyIdx) => {
                      return (
                        <div className={`message-item ${this.getNotificationStyle(history.get("type"), "className")}`}
                          onClick={() => this.onClickNotificationDetail(history)}
                          key={historyIdx}>
                          <p className="message-item-icon">
                            <FontAwesomeIcon icon={fas[this.getNotificationStyle(history.get("type"), "iconClass")]} />
                          </p>
                          <div className="message-item-message">
                            <p className="message-title">{this.getNotificationStyle(history.get("type"), "title")}</p>
                            <p className="message-content">{history.get("message")}</p>
                          </div>
                        </div>
                      )
                    }
                    )
                    :
                    <div className="history-empty">
                      <FontAwesomeIcon className="empty-icon" icon={fas.faBell} />
                      <p className="empty-content">無歷史訊息</p>
                    </div>
                }
              </div>
              <div className="history-footer">
                {
                  this.props.historyList.size > 0
                  &&
                  <Pagination defaultCurrent={1}
                    showTotal={total => `共 ${total} 筆訊息`}
                    total={this.props.historyCount}
                    defaultPageSize={25} onChange={this.onChangePage} />
                }
              </div>
            </div>
          </div>
        }
        {
          this.props.isLoading
          &&
          <FullSpinner />
        }
      </div>
    )
  }
}

export default withRouter(TopBarComponent);