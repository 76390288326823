import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import SearchExtendComponent from './component';

const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),
  totalHits: selectors.getTotalHits(state),
  hitList: selectors.getHitList(state),
  lawData: selectors.getLawData(state),
  caseList: selectors.getCaseList(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadCaseNames: () => {
    dispatch(actions.doLoadCaseNames());
  },
  onSubmitAddJudgment: (caseId, judicialId, esId) => {
    dispatch(actions.doSubmitAddJudgment(caseId, judicialId, esId));
  },
  onLoadReference: (law, no) => {
    dispatch(actions.doLoadReference(law, no));
  },  
  onSubmitSearch: (yearRange, case_, noRange, content, summary, judge, text, referenceList) => {
    dispatch(actions.doSubmitSearch(yearRange, case_, noRange, content, summary, judge, text, referenceList));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchExtendComponent);