import { connect } from 'react-redux';
import * as selectors from './selectors';
import AppointmentNotesComponent from './component';

const mapStateToProps = (state) => ({
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentNotesComponent);