import React, { Component } from 'react';
import plan from '../modules/plan';


class PlanPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-plan">
        <plan.container />
	    </div>
	  )
  }
}

export default PlanPageComponent;
