import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import dnaLogoIcon from '../../assets/img/dna-logo.svg';
import { Form, Button, Pagination, Table } from 'react-bootstrap';
import { Modal, message, Tabs, Tooltip } from 'antd';
import Spinner from '../../utils/Spinner';
import { MAX_SEARCH_RESULT, SEARCH_RESULT_PAGE_SIZE, CATEGORIES, COURTS, SUMMARY_COURTS } from '../../config';

const FJUD_HIGH_COURT_ID_LIST = Object.entries(COURTS).filter(([courtId, court]) => court.isHighCourt).map(([courtId, court]) => courtId);
const FJUD_DISTRICT_COURT_ID_LIST = Object.entries(COURTS).filter(([courtId, court]) => court.isDistrictCourt).map(([courtId, court]) => courtId);
const FJUD_SUPREME_COURT_ID_LIST = Object.entries(COURTS).filter(([courtId, court]) => court.isSupremeCourt).map(([courtId, court]) => courtId);
const FJUD_OTHER_COURT_ID_LIST = Object.entries(COURTS).filter(([courtId, court]) => !(court.isHighCourt || court.isDistrictCourt || court.isSupremeCourt)).map(([courtId, court]) => courtId);
const FJUD_ALL_COURT_ID_LIST = Object.keys(COURTS);
const FJUDE_ALL_COURT_ID_LIST = Object.keys(SUMMARY_COURTS);

const FJUD_CATEGORY_ID_LIST = Object.entries(CATEGORIES).filter(([categoryId, category]) => category.isHigh).map(([categoryId, category]) => categoryId);
const FJUDE_CATEGORY_ID_LIST = Object.entries(CATEGORIES).filter(([categoryId, category]) => category.isSummary).map(([categoryId, category]) => categoryId);

const MAX_PAGE = Math.ceil(MAX_SEARCH_RESULT / SEARCH_RESULT_PAGE_SIZE);

Array.range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { TabPane } = Tabs;


class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 'all',
      currentPage: -1,
      lastPage: -1,
      showCaseSelector: false,
      importPdf: {
        caseId: '',
        esId: ''
      },
      // 全域
      text: '',
      content: '',
      summary: '',
      judge: '',
      fromYear: '',
      toYear: '',
      case_: '',
      fromNo: '',
      toNo: '',
      // 法規
      referenceName: '',
      referenceNo: '條',
      // 行政函釋
      isExecuteChecked: false,
      // 司法判解
      isInterpretationChecked: false,
      // 裁判書isSupremeCourt
      fjudSupremeCourtSet: new Set(),
      fjudHighCourtSet: new Set(),
      fjudDistrictCourtSet: new Set(),
      fjudOtherCourtSet: new Set(),
      fjudCategorySet: new Set(),
      // 簡易案件
      fjudeCourtSet: new Set(),
      fjudeCategorySet: new Set(),
      // highlight
      keywordList: [],
    };
  }
  componentDidMount() {
    this.props.onLoadCaseNames();
    if (localStorage.hasOwnProperty('searchCriteria') === true) {
      const searchCriteria = JSON.parse(localStorage.getItem('searchCriteria'));
      const searchPage = JSON.parse(localStorage.getItem('searchPage'));
      let keywordSet = new Set();
      searchCriteria.content.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(c => c ? keywordSet.add(c) : null);
      searchCriteria.summary.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(s => s ? keywordSet.add(s) : null);
      searchCriteria.judge.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(j => j ? keywordSet.add(j) : null);
      searchCriteria.text.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(t => t ? keywordSet.add(t) : null);
      let keywordList = Array.from(keywordSet).sort((a, b) => b.length - a.length)
      this.setState({
        // pages
        currentIndex: searchPage.currentIndex,
        currentPage: searchPage.currentPage,
        lastPage: searchPage.lastPage,
        // search criteria
        fromYear: searchCriteria.fromYear,
        toYear: searchCriteria.toYear,
        case_: searchCriteria['case'],
        fromNo: searchCriteria.fromNo,
        toNo: searchCriteria.toNo,
        content: searchCriteria.content,
        summary: searchCriteria.summary,
        judge: searchCriteria.judge,
        text: searchCriteria.text,
        referenceName: searchCriteria.referenceName,
        referenceNo: searchCriteria.referenceNo,
        isExecuteChecked: searchCriteria.isExecuteChecked,
        isInterpretationChecked: searchCriteria.isInterpretationChecked,
        fjudSupremeCourtSet: new Set(searchCriteria.fjudSupremeCourtList),
        fjudHighCourtSet: new Set(searchCriteria.fjudHighCourtList),
        fjudDistrictCourtSet: new Set(searchCriteria.fjudDistrictCourtList),
        fjudOtherCourtSet: new Set(searchCriteria.fjudOtherCourtList),
        fjudCategorySet: new Set(searchCriteria.fjudCategoryList),
        fjudeCourtSet: new Set(searchCriteria.fjudeCourtList),
        fjudeCategorySet: new Set(searchCriteria.fjudeCategoryList),
        // highlight
        keywordList
      }, () => {
        this.props.onGetSearch(searchPage.currentIndex, searchPage.currentPage, searchCriteria.cacheId);
      });
    } else {
      this.props.onClearSearchResult();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isSearching === true && this.props.isSearching === false) {
      if (this.props.searchErrMsg === null) {
        const lastPage = this.props.totalHits[this.state.currentIndex] % SEARCH_RESULT_PAGE_SIZE === 0 ? this.props.totalHits[this.state.currentIndex] / SEARCH_RESULT_PAGE_SIZE : Math.ceil(this.props.totalHits[this.state.currentIndex] / SEARCH_RESULT_PAGE_SIZE);
        if (lastPage > MAX_PAGE) {
          this.setState({ lastPage: MAX_PAGE });
        } else {
          this.setState({ lastPage });
        }
        localStorage.setItem('searchPage', JSON.stringify({ currentIndex: this.state.currentIndex, currentPage: this.state.currentPage, lastPage }));
      }
    }
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        if (this.state.showCaseSelector === true) {
          this.setState({ showCaseSelector: false });
          message.success('Imported successfully');
        }
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  onChangeReferenceLaw = (e) => {
    this.setState({ referenceNo: e.target.value });
    if (this.state.referenceNo !== '') {
      if (this.state.referenceName !== '') {
        return;
      } else {
        this.setState({ referenceName: '中華民國刑法' })
      }
    }
  }
  onChangeReferenceName = referenceName => {
    if (referenceName === this.state.referenceName) {
      this.setState({ referenceName: '' });
    } else {
      this.setState({ referenceName });
    }
  }
  onChangeFjudCategory = checkCategoryId => {
    const { fjudCategorySet, fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet } = this.state;

    if (checkCategoryId === 'ALL') {
      if (fjudCategorySet.size === FJUD_CATEGORY_ID_LIST.length) {
        fjudCategorySet.clear();
      } else {
        FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
      }
    } else {
      if (fjudCategorySet.has(checkCategoryId)) {
        fjudCategorySet.delete(checkCategoryId);
        if (fjudCategorySet.size < 0) {
          fjudSupremeCourtSet.clear();
          fjudHighCourtSet.clear();
          fjudDistrictCourtSet.clear();
          fjudOtherCourtSet.clear();
        }
      } else {
        fjudCategorySet.add(checkCategoryId);
        if (fjudSupremeCourtSet.size + fjudHighCourtSet.size + fjudDistrictCourtSet.size + fjudOtherCourtSet.size === 0) {
          FJUD_SUPREME_COURT_ID_LIST.forEach(courtId => fjudSupremeCourtSet.add(courtId));
          FJUD_HIGH_COURT_ID_LIST.forEach(courtId => fjudHighCourtSet.add(courtId));
          FJUD_DISTRICT_COURT_ID_LIST.forEach(courtId => fjudDistrictCourtSet.add(courtId));
          FJUD_OTHER_COURT_ID_LIST.forEach(courtId => fjudOtherCourtSet.add(courtId));
        }
      }
    }

    this.setState({ fjudCategorySet });
  }
  onChangeFjudCourt = (type, checkCourtId) => {
    console.log(type, checkCourtId)
    if (type === 'ALL') {
      const { fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudCategorySet } = this.state;

      if (fjudSupremeCourtSet.size + fjudHighCourtSet.size + fjudDistrictCourtSet.size + fjudOtherCourtSet.size === FJUD_ALL_COURT_ID_LIST.length) {
        fjudSupremeCourtSet.clear();
        fjudHighCourtSet.clear();
        fjudDistrictCourtSet.clear();
        fjudOtherCourtSet.clear();
      } else {
        FJUD_SUPREME_COURT_ID_LIST.forEach(courtId => fjudSupremeCourtSet.add(courtId));
        FJUD_HIGH_COURT_ID_LIST.forEach(courtId => fjudHighCourtSet.add(courtId));
        FJUD_DISTRICT_COURT_ID_LIST.forEach(courtId => fjudDistrictCourtSet.add(courtId));
        FJUD_OTHER_COURT_ID_LIST.forEach(courtId => fjudOtherCourtSet.add(courtId));
        FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
      }
      this.setState({ fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet })
    } else if (type === 'ALLSHC') {
      const { fjudSupremeCourtSet, fjudCategorySet } = this.state;
      if (fjudSupremeCourtSet.has(checkCourtId)) {
        fjudSupremeCourtSet.delete(checkCourtId)
      } else {
        fjudSupremeCourtSet.add(checkCourtId);
        if (fjudCategorySet.size === 0) {
          FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
        }
      }
      this.setState({ fjudSupremeCourtSet })
    } else if (type === 'ALLHC') {
      const { fjudCategorySet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet } = this.state;
      if (checkCourtId === 'ALLHC') {
        if (fjudHighCourtSet.size === FJUD_HIGH_COURT_ID_LIST.length) {
          fjudHighCourtSet.clear();
          if (fjudHighCourtSet.size + fjudDistrictCourtSet.size + fjudOtherCourtSet.size < 0) {
            fjudCategorySet.clear();
          }
        } else {
          FJUD_HIGH_COURT_ID_LIST.forEach(courtId => fjudHighCourtSet.add(courtId));
          if (fjudCategorySet.size === 0) {
            FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
          }
        }
      } else {
        if (fjudHighCourtSet.has(checkCourtId)) {
          fjudHighCourtSet.delete(checkCourtId);
        } else {
          fjudHighCourtSet.add(checkCourtId);
          if (fjudCategorySet.size === 0) {
            FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
          }
        }
      }
      this.setState({ fjudHighCourtSet })
    } else if (type === 'ALLDC') {
      const { fjudCategorySet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet } = this.state;
      if (checkCourtId === 'ALLDC') {
        if (fjudDistrictCourtSet.size === FJUD_DISTRICT_COURT_ID_LIST.length) {
          fjudDistrictCourtSet.clear();
          if (fjudHighCourtSet.size + fjudDistrictCourtSet.size + fjudOtherCourtSet.size < 0) {
            fjudCategorySet.clear();
          }
        } else {
          FJUD_DISTRICT_COURT_ID_LIST.forEach(courtId => fjudDistrictCourtSet.add(courtId));
          if (fjudCategorySet.size === 0) {
            FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
          }
        }
      } else {
        if (fjudDistrictCourtSet.has(checkCourtId)) {
          fjudDistrictCourtSet.delete(checkCourtId);
        } else {
          fjudDistrictCourtSet.add(checkCourtId);
          if (fjudCategorySet.size === 0) {
            FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
          }
        }
      }
      this.setState({ fjudDistrictCourtSet })
    } else if (type === 'ALL_OTHER') {
      const { fjudCategorySet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet } = this.state;

      if (checkCourtId === 'ALL_OTHER') {
        if (fjudOtherCourtSet.size === FJUD_OTHER_COURT_ID_LIST.length) {
          fjudOtherCourtSet.clear();
          if (fjudHighCourtSet.size + fjudDistrictCourtSet.size + fjudOtherCourtSet.size < 0) {
            fjudCategorySet.clear();
          }
        } else {
          FJUD_OTHER_COURT_ID_LIST.forEach(courtId => fjudOtherCourtSet.add(courtId));
          if (fjudCategorySet.size === 0) {
            FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
          }
        }
      } else {
        if (fjudOtherCourtSet.has(checkCourtId)) {
          fjudOtherCourtSet.delete(checkCourtId);
        } else {
          fjudOtherCourtSet.add(checkCourtId);
          if (fjudCategorySet.size === 0) {
            FJUD_CATEGORY_ID_LIST.forEach(categoryId => fjudCategorySet.add(categoryId));
          }
        }
      }
      this.setState({ fjudOtherCourtSet })
    }
  }
  onChangeFjudeCategory = checkCategoryId => {
    const { fjudeCategorySet, fjudeCourtSet } = this.state;

    if (checkCategoryId === 'ALL') {
      if (fjudeCategorySet.size === FJUDE_CATEGORY_ID_LIST.length) {
        fjudeCategorySet.clear();
      } else {
        FJUDE_CATEGORY_ID_LIST.forEach(categoryId => fjudeCategorySet.add(categoryId));
      }
    } else {
      if (fjudeCategorySet.has(checkCategoryId)) {
        fjudeCategorySet.delete(checkCategoryId);
        if (fjudeCategorySet.size < 0) {
          fjudeCourtSet.clear();
        }
      } else {
        fjudeCategorySet.add(checkCategoryId);
        if (fjudeCourtSet.size === 0) {
          FJUDE_ALL_COURT_ID_LIST.forEach(courtId => fjudeCourtSet.add(courtId));
        }
      }
    }

    this.setState({ fjudeCategorySet });
  }
  onChangeFjudeCourt = checkCourtId => {
    const { fjudeCourtSet, fjudeCategorySet } = this.state;
    if (checkCourtId === 'ALL') {
      if (fjudeCourtSet.size === FJUDE_ALL_COURT_ID_LIST.length) {
        fjudeCourtSet.clear();
      } else {
        FJUDE_ALL_COURT_ID_LIST.forEach(courtId => fjudeCourtSet.add(courtId));
        if (fjudeCategorySet.size === 0) {
          FJUDE_CATEGORY_ID_LIST.forEach(categoryId => fjudeCategorySet.add(categoryId));
        }
      }
    } else {
      if (fjudeCourtSet.has(checkCourtId)) {
        fjudeCourtSet.delete(checkCourtId);
        if (fjudeCourtSet.size === 0) {
          fjudeCategorySet.clear();
        }
      } else {
        fjudeCourtSet.add(checkCourtId);
        if (fjudeCategorySet.size === 0) {
          FJUDE_CATEGORY_ID_LIST.forEach(categoryId => fjudeCategorySet.add(categoryId));
        }
      }
    }

    this.setState({ fjudeCourtSet });
  }
  onSubmitSearch = () => {
    var {
      fromYear, toYear, case_, fromNo, toNo,
      content, summary, judge, text,
      referenceName, referenceNo,
      isExecuteChecked, isInterpretationChecked,
      fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudCategorySet,
      fjudeCourtSet, fjudeCategorySet
    } = this.state;
    fromYear = fromYear.trim();
    toYear = toYear.trim();
    case_ = case_.trim();
    fromNo = fromNo.trim();
    toNo = toNo.trim();
    content = content.trim();
    summary = summary.trim();
    judge = judge.trim();
    text = text.trim();
    referenceName = referenceName.trim();
    referenceNo = referenceNo.trim();
    if (
      fromYear || toYear || case_ || fromNo || toNo || content || summary || judge || text || referenceName || referenceNo ||
      fjudSupremeCourtSet.size || fjudHighCourtSet.size || fjudDistrictCourtSet.size || fjudOtherCourtSet.size || fjudCategorySet.size ||
      fjudeCourtSet.size || fjudeCategorySet.size
    ) {
      let keywordSet = new Set();
      content.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(c => c ? keywordSet.add(c) : null);
      summary.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(s => s ? keywordSet.add(s) : null);
      judge.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(j => j ? keywordSet.add(j) : null);
      text.replace(/[&|+|\-|(|)]/g, ' ').split(' ').forEach(t => t ? keywordSet.add(t) : null);
      let keywordList = Array.from(keywordSet).sort((a, b) => b.length - a.length)
      this.setState({ keywordList, currentIndex: 'all' }, () => {
        this.props.onSubmitSearch(fromYear, toYear, case_, fromNo, toNo, content, summary, judge, text, referenceName, referenceNo, isExecuteChecked, isInterpretationChecked, fjudCategorySet, fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudeCategorySet, fjudeCourtSet);
      });
    } else {
      message.error('請輸入搜尋條件');
    }
  }
  replaceHighlightHtml = (description) => {
    const { keywordList } = this.state;
    keywordList.forEach(keyword => description = description.replace(new RegExp(keyword, 'g'), `<mark>${keyword}</mark>`));
    return description;
  }
  onChangeTab = (currentIndex) => {
    this.setState({ currentIndex, currentPage: 1, lastPage: -1 }, () => this.props.onGetSearch(currentIndex, 1));
  }
  onClickInfo() {
    Modal.info({
      title: '資料庫來源',
      width: '50%',
      content: (
        <div>
          <p>
            資料提示：因應目前司法院法學資料檢索系統改版，我司也正同步定期更新資料內容。目前收錄內容僅 85 年 1 月 ~ 110 年 4 月期間上傳的法學資料。
          </p>
          <p>資料來源：司法院判決書開放資料下載</p>
          <p>資料收錄：民國 85 年度 1 月至民國 110 年度 4 月期間上傳</p>
          <p>最後更新：110 年 5 月 20 日</p>
        </div>
      ),
      onOk() { },
    });
  }
  onClickManual() {
    Modal.info({
      title: '使用說明',
      content: (
        <div>
          <p>年度字號之年度、號；搜尋法規之法條，僅能輸入數字</p>
        </div>
      ),
      onOk() { },
    });
  }
  onClickToPage(page) {
    const { currentPage, lastPage, currentIndex } = this.state;
    if (page === currentPage || page < 1 || page > lastPage) {
    } else {
      this.props.onGetSearch(currentIndex, page);
      this.setState({ currentPage: page });
    }
  }
  onClickReference = (law, no) => {
    const reference = new Buffer(JSON.stringify({ law, no })).toString('base64');
    this.props.history.push(`search/${reference}`);
  }
  onResetSearch() {
    const { fjudCategorySet, fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudeCourtSet, fjudeCategorySet } = this.state;
    this.setState({
      currentIndex: 'all',
      currentPage: -1,
      lastPage: -1,
      fromYear: '',
      toYear: '',
      case_: '',
      fromNo: '',
      toNo: '',
      content: '',
      summary: '',
      judge: '',
      text: '',
      referenceName: '',
      referenceNo: '',
      isExecuteChecked: '',
      isInterpretationChecked: '',
    });
    fjudCategorySet.clear();
    fjudSupremeCourtSet.clear();
    fjudHighCourtSet.clear();
    fjudDistrictCourtSet.clear();
    fjudOtherCourtSet.clear();
    fjudeCategorySet.clear();
    fjudeCourtSet.clear();
    this.props.onClearSearchResult()
  }
  render() {
    const {
      currentIndex, currentPage, lastPage, showCaseSelector, importPdf,
      text, content, summary, judge, fromYear, toYear, case_, fromNo, toNo,
      referenceName, referenceNo,
      isExecuteChecked,
      isInterpretationChecked,
      fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudCategorySet,
      fjudeCourtSet, fjudeCategorySet
    } = this.state;
    return (
      <div className="search_wrap">
        <nav className="p-3 d-flex justify-content-between">
          <div>
            <Tooltip title="回到委任列表"><img className="logo mr-3" src={dnaLogoIcon} onClick={e => this.props.history.push(`/cases`)} /></Tooltip>
            <h4 className="search_title">判決搜尋</h4>
          </div>
          <div>
            <div className="col">
              <button className="btn btn-outline-primary btn-sm mr-3"
                type="button"
                onClick={e => { e.stopPropagation(); this.onClickManual() }}
              >
                使用說明
              </button>
              <button className="btn btn-outline-info btn-sm"
                type="button"
                onClick={e => { e.stopPropagation(); this.onClickInfo() }}
              >
                資料庫來源
              </button>
            </div>
          </div>
        </nav>
        <div className="search_wrap">
          <div className="search_filter">
            <Form onSubmit={e => {
              e.preventDefault();
              this.onSubmitSearch();
              this.setState({ currentPage: 1 })
            }}>
              <div className="search_section">
                <div className="search_row">
                  <div>
                    <label className="search_item" htmlFor="">年度字號：</label>
                    <div className="input_lengh_M">
                      <input className="search_input w-100"
                        type="number"
                        value={fromYear}
                        onChange={e => this.setState({ fromYear: e.target.value })}
                      />
                    </div>
                    <span className="input_text">～</span>
                    <div className="input_lengh_M">
                      <input className="search_input w-100"
                        type="number"
                        value={toYear}
                        onChange={e => this.setState({ toYear: e.target.value })}
                      />
                    </div>
                    <span className="input_text">年</span>
                    <div className="input_lengh_M">
                      <input className="search_input w-100"
                        type="text"
                        value={case_}
                        onChange={e => this.setState({ case_: e.target.value })}
                      />
                    </div>
                    <span className="input_text">字</span>
                    <label className="input_text" htmlFor="">第</label>
                    <div className="input_lengh_L">
                      <input className="search_input w-100"
                        type="number"
                        value={fromNo}
                        onChange={e => this.setState({ fromNo: e.target.value })}
                      />
                    </div>
                    <span className="input_text">～</span>
                    <div className="input_lengh_L">
                      <input className="search_input w-100"
                        type="number"
                        value={toNo}
                        onChange={e => this.setState({ toNo: e.target.value })}
                      />
                    </div>
                    <span className="input_text">號</span>
                  </div>
                </div>
                <div className="search_row">
                  <div className="search_col">
                    <label className="search_item" htmlFor="content">主文檢索：</label>
                    <input className="search_input"
                      type="text"
                      id="content"
                      placeholder="主文"
                      value={content}
                      onChange={e => this.setState({ content: e.target.value })}
                    />
                  </div>
                  <div className="search_col">
                    <label className="search_item" htmlFor="summary">案由：</label>
                    <input className="search_input"
                      type="text"
                      id="summary"
                      placeholder="案由"
                      value={summary}
                      onChange={e => this.setState({ summary: e.target.value })}
                    />
                  </div>
                  <div className="search_col">
                    <label className="search_item" htmlFor="judge">法官：</label>
                    <input className="search_input"
                      type="text"
                      id="judge"
                      value={judge}
                      onChange={e => this.setState({ judge: e.target.value })}
                    />
                  </div>
                </div>
                <div className="search_row">
                  <div>
                    <label className="search_item" htmlFor="text">全文檢索：</label>
                    <input className="search_input font-weight-light text-secondary"
                      type="text"
                      id="text"
                      placeholder="例：返還借款&傷害（表示欲搜尋內文同時含有返還借款及傷害）"
                      value={text}
                      onChange={e => this.setState({ text: e.target.value })}
                    />
                    <p className="text-secondary search_tooltip">
                      半形符號：& 及空白鍵表示「且」；+ 表示「或」；- 表示「不含」；() 表示「組合」/ 可利用上述五種符號，組合檢索字詞。檢索字詞之查詢順序為由左至右，依序比對。
                    </p>
                  </div>
                </div>
              </div>
              <input type="checkbox" id="adv-search-section-visible" className="adv-search-section-visible" />
              <label for="adv-search-section-visible">
                進階搜尋
              </label>
              <div className="search_section adv-search-section">
                <div className="search_row">
                  <div className="search_col">
                    <div className="search_item">搜尋法規：</div>
                    <div className="search_content">
                      <div className="select_item">
                        <input
                          type="radio"
                          name="referenceName"
                          id="criminal"
                          value="中華民國刑法"
                          checked={referenceName === '中華民國刑法' ? '中華民國刑法' : null}
                          onChange={e => this.onChangeReferenceName('中華民國刑法')}
                        />
                        <label htmlFor="criminal">刑法</label>
                      </div>
                      <div className="select_item">
                        <input
                          type="radio"
                          name="referenceName"
                          id="civil"
                          value="民法"
                          checked={referenceName === '民法' ? '民法' : null}
                          onChange={e => this.onChangeReferenceName('民法')}
                        />
                        <label htmlFor="civil">民法</label>
                      </div>
                      <div className="select_item search_article">
                        <input className="search_input"
                          type="number"
                          id="law_article"
                          placeholder="條"
                          value={referenceNo}
                          onChange={this.onChangeReferenceLaw.bind()}
                        />
                        <label htmlFor="">條</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="search_row">
                  <div className="search_item">行政函釋：</div>
                  <div className="search_content">
                    <div className="select_item">
                      <input
                        type="checkbox"
                        id="is-execute-checked"
                        checked={isExecuteChecked}
                        onChange={e => this.setState({ isExecuteChecked: !this.state.isExecuteChecked })}
                      />
                      <label htmlFor="is-execute-checked">行政函釋</label>
                    </div>
                  </div>
                </div>
                <div className="search_row">
                  <div className="search_item">司法判解：</div>
                  <div className="search_content">
                    <div className="select_item">
                      <input
                        type="checkbox"
                        id="is-interpretation-checked"
                        checked={isInterpretationChecked}
                        onChange={e => this.setState({ isInterpretationChecked: !this.state.isInterpretationChecked })}
                      />
                      <label htmlFor="is-interpretation-checked">司法判解</label>
                    </div>
                  </div>
                </div> */}
                <div className="search_row">
                  <div className="search_item">裁判書：</div>
                  <div className="search_content">
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALL_CATEGORY"
                          onChange={e => this.onChangeFjudCategory('ALL')}
                          checked={fjudCategorySet.size === FJUD_CATEGORY_ID_LIST.length}
                        />
                        <label htmlFor="ALL_CATEGORY">所有類別</label>
                      </div>
                      {
                        FJUD_CATEGORY_ID_LIST.map(categoryId =>
                          <div className="select_item" key={`fjud-category${categoryId}`}>
                            <input
                              type="checkbox"
                              id={`fjud-category-${categoryId}`}
                              value={categoryId}
                              onChange={e => this.onChangeFjudCategory(categoryId)}
                              checked={fjudCategorySet.has(categoryId)}
                            />
                            <label htmlFor={`fjud-category-${categoryId}`}>{CATEGORIES[categoryId].name}</label>
                          </div>
                        )
                      }
                    </div>
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALL_FJUD"
                          checked={fjudSupremeCourtSet.size + fjudHighCourtSet.size + fjudDistrictCourtSet.size + fjudOtherCourtSet.size === FJUD_ALL_COURT_ID_LIST.length}
                          onChange={e => this.onChangeFjudCourt('ALL', 'ALL')}
                        />
                        <label htmlFor="ALL_FJUD">所有法院</label>
                      </div>
                    </div>
                    {console.log(FJUD_OTHER_COURT_ID_LIST)}
                    <div className="search_select">
                      {
                        FJUD_SUPREME_COURT_ID_LIST.map(courtId =>
                          <div key={`${courtId}`} className="select_item">
                            <input
                              type="checkbox"
                              id={`fjud-court-${courtId}`}
                              value={courtId}
                              checked={fjudSupremeCourtSet.has(courtId)}
                              onChange={e => this.onChangeFjudCourt('ALLSHC', courtId)}
                            />
                            <label htmlFor={`fjud-court-${courtId}`}>{COURTS[courtId].name}</label>
                          </div>
                        )
                      }
                    </div>
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALLHC"
                          checked={fjudHighCourtSet.size === FJUD_HIGH_COURT_ID_LIST.length}
                          onChange={e => this.onChangeFjudCourt('ALLHC', 'ALLHC')}
                        />
                        <label htmlFor="ALLHC">所有高等法院</label>
                      </div>
                      {
                        FJUD_HIGH_COURT_ID_LIST.map(courtId =>
                          <div key={`${courtId}`} className="select_item">
                            <input
                              type="checkbox"
                              id={`fjud-court-${courtId}`}
                              value={courtId}
                              checked={fjudHighCourtSet.has(courtId)}
                              onChange={e => this.onChangeFjudCourt('ALLHC', courtId)}
                            />
                            <label htmlFor={`fjud-court-${courtId}`}>{COURTS[courtId].name.replace('臺灣高等法院 ', '')}</label>
                          </div>
                        )
                      }
                    </div>
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALLDC"
                          checked={fjudDistrictCourtSet.size === FJUD_DISTRICT_COURT_ID_LIST.length}
                          onChange={e => this.onChangeFjudCourt('ALLDC', 'ALLDC')}
                        />
                        <label htmlFor="ALLDC">所有地方法院</label>
                      </div>
                      {
                        FJUD_DISTRICT_COURT_ID_LIST.map(courtId =>
                          <div key={`${courtId}`} className="select_item">
                            <input
                              type="checkbox"
                              id={`fjud-court-${courtId}`}
                              value={courtId}
                              checked={fjudDistrictCourtSet.has(courtId)}
                              onChange={e => this.onChangeFjudCourt('ALLDC', courtId)}
                            />
                            <label htmlFor={`fjud-court-${courtId}`}>{COURTS[courtId].name.replace('地方法院', '').replace('臺灣', '')}</label>
                          </div>
                        )
                      }
                    </div>
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALL_OTHER"
                          checked={fjudOtherCourtSet.size === FJUD_OTHER_COURT_ID_LIST.length}
                          onChange={e => this.onChangeFjudCourt('ALL_OTHER', 'ALL_OTHER')}
                        />
                        <label htmlFor="ALL_OTHER">其他</label>
                      </div>
                      {
                        FJUD_OTHER_COURT_ID_LIST.map(courtId =>
                          <div key={`${courtId}`} className="select_item">
                            <input
                              type="checkbox"
                              id={`fjud-court-${courtId}`}
                              value={courtId}
                              checked={fjudOtherCourtSet.has(courtId)}
                              onChange={e => this.onChangeFjudCourt('ALL_OTHER', courtId)}
                            />
                            <label htmlFor={`fjud-court-${courtId}`}>{COURTS[courtId].name}</label>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="search_row">
                  <div className="search_item">簡易案件：</div>
                  <div className="search_content">
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALL_FJUDE_CATEGORY"
                          checked={fjudeCategorySet.size === FJUDE_CATEGORY_ID_LIST.length}
                          onChange={e => this.onChangeFjudeCategory('ALL')}
                        />
                        <label htmlFor="ALL_FJUDE_CATEGORY">所有類別</label>
                      </div>
                      {
                        FJUDE_CATEGORY_ID_LIST.map(categoryId =>
                          <div key={`${categoryId}`} className="select_item">
                            <input
                              type="checkbox"
                              id={`fjude-category-${categoryId}`}
                              value={categoryId}
                              checked={fjudeCategorySet.has(categoryId)}
                              onChange={e => this.onChangeFjudeCategory(categoryId)}
                            />
                            <label htmlFor={`fjude-category-${categoryId}`}>{CATEGORIES[categoryId].name}</label>
                          </div>
                        )
                      }
                    </div>
                    <div className="search_select">
                      <div className="select_item">
                        <input
                          type="checkbox"
                          id="ALL_FJUDE"
                          checked={fjudeCourtSet.size === FJUDE_ALL_COURT_ID_LIST.length}
                          onChange={e => this.onChangeFjudeCourt('ALL')}
                        />
                        <label htmlFor="ALL_FJUDE">所有簡易庭</label>
                      </div>
                      {
                        Object.entries(SUMMARY_COURTS).map(([courtId, courtName]) =>
                          <div key={`${courtId}`} className="select_item">
                            <input
                              type="checkbox"
                              id={`fjude-court-${courtId}`}
                              value={courtId}
                              checked={fjudeCourtSet.has(courtId)}
                              onChange={e => this.onChangeFjudeCourt(courtId)}
                            />
                            <label htmlFor={`fjude-court-${courtId}`}>{courtName}</label>
                          </div>
                        )
                      }
                    </div>
                  </div>

                </div>
              </div>

              <div className="search_section row pl-4 pr-4 mt-5">
                <div className="col">
                  <button className="btn btn-outline-info w-50"
                    type="button"
                    onClick={e => { e.stopPropagation(); this.onResetSearch() }}
                  >
                    清空條件重新搜尋
                  </button>
                </div>
                <div className="col">
                  <Button variant="primary" type="submit" className="w-100">
                    搜尋
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {
          currentPage !== -1
          &&
          <div className="search_result">
            {
              this.props.isSearching ?
              <Spinner />
              : this.props.searchErrMsg ?
                <div className="d-flex justify-content-between">
                  <div className="text-primary">系統忙錄中，請重新搜尋。</div>
                </div>
              :
              <>
                <div className="result">
                  <div className="d-flex result_nav">
                    <div className="pt-2 mr-3 text-primary text-sm">搜尋總筆數：{this.props.totalHits[currentIndex]} 筆</div>
                    <Pagination>
                      <Pagination.First onClick={e => this.onClickToPage(1)} />
                      <Pagination.Prev onClick={e => this.onClickToPage(currentPage - 1)} />
                      {
                        (currentPage > 3 && lastPage > 5)
                        &&
                        <Pagination.Ellipsis />
                      }
                      {
                        currentPage < 4
                          ?
                          lastPage > 5
                            ?
                            Array.range(1, 6).map(p =>
                              <Pagination.Item disabled={currentPage === p} onClick={e => this.onClickToPage(p)}>{p}</Pagination.Item>
                            )
                            :
                            Array.range(1, lastPage + 1).map(p =>
                              <Pagination.Item disabled={currentPage === p} onClick={e => this.onClickToPage(p)}>{p}</Pagination.Item>
                            )
                          :
                          lastPage - currentPage < 3
                            ?
                            Array.range(lastPage - 5, lastPage + 1).map(p =>
                              <Pagination.Item disabled={currentPage === p} onClick={e => this.onClickToPage(p)}>{p}</Pagination.Item>
                            )
                            :
                            Array.range(currentPage - 2, currentPage + 3).map(p =>
                              <Pagination.Item disabled={currentPage === p} onClick={e => this.onClickToPage(p)}>{p}</Pagination.Item>
                            )
                      }
                      {
                        (lastPage - currentPage > 2 && lastPage > 5)
                        &&
                        <Pagination.Ellipsis />
                      }
                      <Pagination.Next onClick={e => this.onClickToPage(currentPage + 1)} />
                      <Pagination.Last onClick={e => this.onClickToPage(lastPage)} />
                    </Pagination>
                  </div>
                  <Tabs onChange={e => this.onChangeTab(e)} activeKey={currentIndex} type="card">
                    <TabPane tab="所有結果" key="all"></TabPane>
                    {
                      this.props.totalHits.hasOwnProperty('fjud')
                      &&
                      <TabPane tab="裁判書" key="fjud"></TabPane>
                    }
                    {
                      this.props.totalHits.hasOwnProperty('fjude')
                      &&
                      <TabPane tab="簡易案件" key="fjude"></TabPane>
                    }
                    {/* {
                  this.props.totalHits.hasOwnProperty('fint_execute')
                  &&
                  <TabPane tab="行政函釋" key="fint_execute">
                  </TabPane>
                }
                {
                  this.props.totalHits.hasOwnProperty('fint_court')
                  &&
                  <TabPane tab="司法判解" key="fint_court">
                  </TabPane>
                } */}
                  </Tabs>
                  <div className="search-result">
                    <div className="item-head">
                      <ul className="list-head">
                        <li className="add-case">參考</li>
                        <li className="title">裁判字號</li>
                        <li className="date">裁判日期</li>
                        <li className="summary">裁判案由</li>
                      </ul>
                      <div className="law">相關法條</div>
                    </div>
                    {
                      this.props.hitList && this.props.hitList.map(hit =>
                        <div className="item" key={`${hit.getIn(['esId'])}`}>
                          <div className="content">
                            <ul className="list-body">
                              <li className="add-case">
                                {
                                  hit.getIn(['pdfLink'])
                                  &&
                                  <button onClick={e => this.setState({ showCaseSelector: true, importPdf: { caseId: '', judicialId: hit.getIn(['judicialId']), esId: hit.getIn(['esId']) } })}>
                                    <FontAwesomeIcon icon={fas.faFolderPlus} />
                                  </button>
                                }
                              </li>
                              <li className="title">
                                <a target="_blank" rel="noopener noreferrer" href={hit.getIn(['link'])} onClick={e => this.props.onClickSearchResult(hit.getIn(['esId']), hit.getIn(['link']))}>
                                  {hit.getIn(['title'])}
                                </a>
                              </li>
                              <li className="date">{hit.getIn(['date'])}</li>
                              <li className="summary">{hit.getIn(['summary'])}</li>
                            </ul>
                            <div className="description" dangerouslySetInnerHTML={{ __html: this.replaceHighlightHtml(hit.getIn(['description'])) }}>
                            </div>
                          </div>
                          <div className="law">
                            {
                              hit.getIn(['referenceList']) && hit.getIn(['referenceList']).map(reference =>
                                <div key={`${reference}`}>
                                  <Link to={`/search/${encodeURIComponent(new Buffer(JSON.stringify({ fromYear, toYear, 'case': case_, fromNo, toNo, content, summary, judge, text, 'referenceName': reference.getIn(['law']), 'referenceNo': reference.getIn(['no']) })).toString('base64'))}`} target="_blank">
                                    {`${reference.getIn(['law'])} 第 ${reference.getIn(['no'])} 條 (${reference.getIn(['date'])})`}
                                  </Link>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </>
        }
          </div>
        }
        {
          showCaseSelector
          &&
          <div className={'case-form modalbox ' + (showCaseSelector ? 'show' : '')}>
            {
              this.props.isPosting
              ?
              <Spinner />
              :
              <form className="modalbox-content" onSubmit={(e) => { e.preventDefault(); this.props.onSubmitAddJudgment(importPdf.caseId, importPdf.judicialId, importPdf.esId) }}>
                <div>
                  <label htmlFor="pages-label-selector">加入案件：</label>
                  <select
                    id="pages-label-selector"
                    className="modalbox-content-input fix_focus_shadow"
                    value={importPdf.caseId}
                    onChange={e => this.setState({ importPdf: { caseId: e.target.value, judicialId: importPdf.judicialId, esId: importPdf.esId } })}
                    required
                  >
                    {
                      importPdf.caseId === ''
                      &&
                      <option value="" disabled>請選擇</option>
                    }
                    {
                      this.props.caseList && this.props.caseList.map(case_ =>
                        <option key={`searches-case-${case_.getIn(['id'])}`} value={case_.getIn(['id'])}>{case_.getIn(['name'])}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-item-tool">
                  <button className="btn-import" type="submit" disabled={this.props.isPosting}>匯入</button>
                  <button className="btn-cancel" type="button" onClick={e => this.setState({ showCaseSelector: false })}>關閉</button>
                </div>
              </form>
            }
          </div>
        }
      </div>
    )
  }
}

export default withRouter(SearchComponent);
