import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';

const Reducer = handleActions({
  // common
  'appointmentList/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentList/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentList/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentList/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentList/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentList/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentList/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentList/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentList/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentList/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentList/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentList/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentList/GETTING_APPOINTMENT_LIST_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingAppointmentList'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentList/GETTING_APPOINTMENT_LIST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingAppointmentList'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentList/GETTING_APPOINTMENT_LIST_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingAppointmentList'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentList/GETTING_APPOINTMENT_DETAIL_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingAppointmentDetail'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentList/GETTING_APPOINTMENT_DETAIL_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingAppointmentDetail'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentList/GETTING_APPOINTMENT_DETAIL_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingAppointmentDetail'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentList/UPDATE_CURRENT_APPOINTMENT_ID': (state, { payload }) => {
    return state.setIn(['currentAppointmentId'], payload);
  },
  'appointmentList/UPDATE_CURRENT_DETAIL_TAB': (state, { payload }) => {
    return state.setIn(['currentDetailTab'], payload);
  },
  'appointmentList/UPDATE_APPOINTMENT_LIST': (state, { payload }) => {
    return state.setIn(['appointmentIdList'], fromJS(payload.appointmentIdList))
                .setIn(['appointments'], fromJS(payload.appointments));
  },
  'appointmentList/UPDATE_APPOINTMENT_DETAIL': (state, { payload }) => {
    return state.setIn(['appointmentDetail'], fromJS(payload.appointmentDetail))
                .setIn(['currentAppointmentId'], payload.appointmentId);
  },
  'appointmentList/UPDATE_CACHED_APPOINTMENT_ID': (state, { payload }) => {
    return state.setIn(['cachedAppointmentId'], payload)
                .setIn(['currentAppointmentId'], null);
  },
}, State);

export default Reducer;