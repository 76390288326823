import { fromJS  } from 'immutable';

export const State = fromJS({
  isDownloading: false,
  isPosting: false,
  isGetting: false,
  isPutting: false,
  isDeleting: false,
  isBackuping: false,
  errMsg: '',
  isExceededQuota: false
});