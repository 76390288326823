// common
export const POST_REQUEST = 'casesReceipt/POST_REQUEST'
export const POST_SUCCESS = 'casesReceipt/POST_SUCCESS'
export const POST_FAILURE = 'casesReceipt/POST_FAILURE'
export const GET_REQUEST = 'casesReceipt/GET_REQUEST'
export const GET_SUCCESS = 'casesReceipt/GET_SUCCESS'
export const GET_FAILURE = 'casesReceipt/GET_FAILURE'
export const PUT_REQUEST = 'casesReceipt/PUT_REQUEST'
export const PUT_SUCCESS = 'casesReceipt/PUT_SUCCESS'
export const PUT_FAILURE = 'casesReceipt/PUT_FAILURE'

export const UPDATE_EXPORT_RECEIPT = 'casesReceipt/UPDATE_EXPORT_RECEIPT'
export const UPDATE_LAWER = 'casesReceipt/UPDATE_LAWER'