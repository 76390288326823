import React, { Component } from 'react';
import exportConsultations from '../modules/exportConsultations';


class ExportConsultationsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
  	return (
	    <div id="page-exportConsultations">
        <exportConsultations.container />
	    </div>
	  )
  }
}

export default ExportConsultationsPageComponent;
