import { handleActions } from 'redux-actions';
import { State } from './model';

const Reducer = handleActions({
  // common
  'appointmentFee/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentFee/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentFee/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
}, State);

export default Reducer;