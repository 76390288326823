// common
export const POST_REQUEST = 'doc/POST_REQUEST'
export const POST_SUCCESS = 'doc/POST_SUCCESS'
export const POST_FAILURE = 'doc/POST_FAILURE'
export const GET_REQUEST = 'doc/GET_REQUEST'
export const GET_SUCCESS = 'doc/GET_SUCCESS'
export const GET_FAILURE = 'doc/GET_FAILURE'
export const PUT_REQUEST = 'doc/PUT_REQUEST'
export const PUT_SUCCESS = 'doc/PUT_SUCCESS'
export const PUT_FAILURE = 'doc/PUT_FAILURE'
export const DELETE_REQUEST = 'doc/DELETE_REQUEST'
export const DELETE_SUCCESS = 'doc/DELETE_SUCCESS'
export const DELETE_FAILURE = 'doc/DELETE_FAILURE'

export const DOWNLOAD_REQUEST = 'doc/DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'doc/DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAILURE = 'doc/DOWNLOAD_FAILURE'

export const UPDATE_IS_EXCEEDED_QUOTA = 'doc/UPDATE_IS_EXCEEDED_QUOTA'
export const UPDATE_ENCRYPTED_PDF_NAME_LIST = 'doc/UPDATE_ENCRYPTED_PDF_NAME_LIST'

// pageDuplicates
export const UPDATE_DUPLICATES = 'doc/UPDATE_DUPLICATES'

export const UPDATE_IS_LOADING = 'doc/UPDATE_IS_LOADING'
// category
export const UPDATE_CASE_NAME = 'doc/UPDATE_CASE_NAME'
export const UPDATE_CATEGORY_AND_PAGEURLS = 'doc/UPDATE_CATEGORY_AND_PAGEURLS'
export const GET_CASE_CATEGORY_REQUEST = 'doc/GET_CASE_CATEGORY_REQUEST'
export const GET_CASE_CATEGORY_SUCCESS = 'doc/GET_CASE_CATEGORY_SUCCESS'
export const GET_CASE_CATEGORY_FAILURE = 'doc/GET_CASE_CATEGORY_FAILURE'

// pdf
export const UPDATE_PDF_SCALE = 'doc/UPDATE_PDF_SCALE'
export const UPDATE_PDF_PAGE = 'doc/UPDATE_PDF_PAGE'
export const UPDATE_PDF_PAGE_INDEX = 'doc/UPDATE_PDF_PAGE_INDEX'

// label
export const UPDATE_CASE_LIST = 'doc/UPDATE_CASE_LIST'
export const UPDATE_USER_FOLDER_LIST = 'doc/UPDATE_USER_FOLDER_LIST'
export const UPDATE_LABELS = 'doc/UPDATE_LABELS'
export const UPDATE_LABEL = 'doc/UPDATE_LABEL'

// search
export const UPDATE_HIT_LIST = 'doc/UPDATE_HIT_LIST'

//HighLight
export const UPDATE_ANNOTATION = 'doc/UPDATE_ANNOTATION';



