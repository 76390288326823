import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import dnaBgIcon from '../../assets/img/dna-bg.png'
import { message, Checkbox, Upload, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class PaymentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const { } = this.state;
    return (
      <div id="page-payment" className="container">
        <nav className="container p-3 mb-5">
          <Link to={`/cases`}>
            <img className="logo mr-3" alt="" src={dnaLogoIcon} />
          </Link>
          <h4 className="text-black-50">付款紀錄</h4>
        </nav>
        <div className="mb-5 payment_info">
          <div className="alert alert-light pl-5 pr-5" role="alert">
            <ul>
              <li>個人付款紀錄</li>
            </ul>
          </div>
        </div>
        <div className="payment-content">
          <ul className="list-head">
            <li className="head-payDate">日期</li>
            <li className="head-fee">金額</li>
            <li className="head-plan">方案</li>
          </ul>
          <ul className="list-body">
            <li className="body-payDate" data-th="日期：">2020/12/12</li>
            <li className="body-fee" data-th="金額：">$ 1000</li>
            <li className="body-plan" data-th="方案：">月費 1T 方案</li>
          </ul>
          <ul className="list-body">
            <li className="body-payDate" data-th="日期：">2021/1/12</li>
            <li className="body-fee" data-th="金額：">$ 9000</li>
            <li className="body-plan" data-th="方案：">年費 1T 方案</li>
          </ul>
        </div>
        <img className="login-logo-bg" alt="" src={dnaBgIcon} />
        <div className="text-center mt-5 border-top">
          <Link to={`/cases`}>
            <button type="button" className="btn btn-outline-info w-50 mt-5">返回案件列表</button>
          </Link>
        </div>
      </div>
    )
  }
}

export default withRouter(PaymentComponent);