import { fromJS  } from 'immutable';

export const State = fromJS({
  isGetting: false,
  isPutting: false,
  isPosting: false,
  errMsg: '',
  firstName: '',
  lastName: '',
  address: '',
  phoneNo: '',
  email: '',
  mobile: '',
  twoFactorAuthenticationId: 1,
  lawFirmLogo: '',
  lawFirmName: '',
  pinCode: ''
});
