import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  updateAppointmentCase,
  casesRelated,
  deleteDocket
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)
export const getCaseRelatedRequest = createAction(types.GET_CASE_RELATED_REQUEST)
export const getCaseRelatedSuccess = createAction(types.GET_CASE_RELATED_SUCCESS)
export const getCaseRelatedFailure = createAction(types.GET_CASE_RELATED_FAILURE)

export function doUpdateCase(appointmentId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote) {
  return dispatch => {
    dispatch(putRequest());
    updateAppointmentCase(appointmentId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export const updateCaseRelateList = createAction(types.UPDATE_CASE_RELATE_LIST)
export function doLoadRelatedList(contain, size) {
  return dispatch => {
    dispatch(getCaseRelatedRequest());
    casesRelated(contain, size).then(data => {
      dispatch(updateCaseRelateList(data));
      dispatch(getCaseRelatedSuccess());
    }).catch((errMsg) => {
      dispatch(getCaseRelatedFailure({ errMsg }));
    })
  }
}

export function doDeleteDocket(docketId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteDocket(docketId).then(data => {
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}