import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentAppointComponent from './component';


const mapStateToProps = (state) => ({
  isPutting: selectors.getIsPutting(state),
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateAppointor: (appointmentId, appointDate, name, address, phone, email, relationship, ssid, birthday) => {
    dispatch(actions.doUpdateAppointor(appointmentId, appointDate, name, address, phone, email, relationship, ssid, birthday));
  },
   onUpdateCompany: (appointmentId, appointDate, name, address, phone, email, ban, representative, contactPerson) => {
    dispatch(actions.doUpdateCompany(appointmentId, appointDate, name, address, phone, email, ban, representative, contactPerson));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentAppointComponent);