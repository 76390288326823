import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { 
	getPlan,
  getUpdatablePlan,
  postUpgrade
} from '../../utils/api';
import * as config from '../../config'

export const getRequest = createAction(types.GET_REQUEST);
export const getSuccess = createAction(types.GET_SUCCESS);
export const getFailure = createAction(types.GET_FAILURE);
export const postRequest = createAction(types.POST_REQUEST);
export const postSuccess = createAction(types.POST_SUCCESS);
export const postFailure = createAction(types.POST_FAILURE);

export const doUpdatePlan = createAction(types.UPDATE_PLAN);
export function doLoadPlan(userId) {
  return dispatch => {
    dispatch(getRequest());
    getPlan(userId).then(data => {
      dispatch(doUpdatePlan(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export const doUpgradeList = createAction(types.UPDATE_UPGRADE_LIST);
export function doLoadUpdatable(userId) {
  return dispatch => {
    dispatch(getRequest());
    getUpdatablePlan(userId).then(data => {
      dispatch(doUpgradeList(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doUpgrade(userId, planId) {
  return dispatch => {
    dispatch(postRequest());
    postUpgrade(userId, planId).then(data => {
      dispatch(postSuccess());
      dispatch(doLoadPlan(userId));
      dispatch(doLoadUpdatable(userId));
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}