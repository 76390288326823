import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getConsultationList,
  getConsultationDetail,
  deleteConsultation,
  appointConsultation
} from '../../utils/api';

// common
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getConsultationListRequest = createAction(types.GETTING_CONSULTATION_LIST_REQUEST)
export const getConsultationListSuccess = createAction(types.GETTING_CONSULTATION_LIST_SUCCESS)
export const getConsultationListFailure = createAction(types.GETTING_CONSULTATION_LIST_FAILURE)
export const getConsultationDetailRequest = createAction(types.GETTING_CONSULTATION_DETAIL_REQUEST)
export const getConsultationDetailSuccess = createAction(types.GETTING_CONSULTATION_DETAIL_SUCCESS)
export const getConsultationDetailFailure = createAction(types.GETTING_CONSULTATION_DETAIL_FAILURE)

export const doChangeCurrentConsultationId = createAction(types.UPDATE_CURRENT_CONSULTATION_ID)
export const doUpdateCurrentDetailTab = createAction(types.UPDATE_CURRENT_DETAIL_TAB)

export const updateConsultationList = createAction(types.UPDATE_CONSULTATION_LIST)
export function doLoadConsultationList(name, startDay, endDay) {
  return (dispatch, getState) => {
    const state = getState();
    let currentConsultationId = state.getIn(['consultations', 'currentConsultationId']);
    dispatch(getConsultationListRequest());
    getConsultationList(name, startDay, endDay).then(consultationList => {
      const consultationIdList = [];
      const consultations = {};
      consultationList.forEach(consultation => {
        consultationIdList.push(`${consultation.id}`);
        consultations[`${consultation.id}`] = consultation;
      })
      if (consultationList.length === 0) {
        currentConsultationId = null;
      } else if (currentConsultationId === null || consultationIdList.indexOf(currentConsultationId) === -1) {
        currentConsultationId = consultationList[0].id;
      }
      dispatch(updateConsultationList({ consultationIdList, consultations }));
      dispatch(doLoadConsultationDetail(currentConsultationId));
      dispatch(getConsultationListSuccess());
    }).catch((errMsg) => {
      dispatch(getConsultationListFailure({ errMsg }));
    })
  }
}

export const updateConsultationDetail = createAction(types.UPDATE_CONSULTATION_DETAIL)
export function doLoadConsultationDetail(consultationId) {
  return dispatch => {
    if (!consultationId) {
      const consultationDetail = {
        appoint: {},
        case: {},
        fee: {},
        files: {}
      };
      dispatch(updateConsultationDetail({ consultationDetail, consultationId }));
    } else {
      dispatch(getConsultationDetailRequest());
      getConsultationDetail(consultationId).then(consultationDetail => {
        dispatch(updateConsultationDetail({ consultationDetail, consultationId }));
        dispatch(getConsultationDetailSuccess());
      }).catch((errMsg) => {
        dispatch(getConsultationDetailFailure({ errMsg }));
      })
    }
  }
}

export function doDeleteConsultation(consultationId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteConsultation(consultationId).then(data => {
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doAppointConsultation(consultationId, appointName, appointNoId) {
  return dispatch => {
    dispatch(postRequest());
    appointConsultation(consultationId, appointName, appointNoId).then(data => {
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}