import { message } from "antd";
import { createAction } from 'redux-actions';
import {
  createCaseFileFolder,
  deleteCaseFile,
  downloadCaseFile,
  getCaseFilesById,
  getCaseFileText,
  getFolderList,
  getCaseFileBase64,
  moveUserFile,
  searchCaseFiles,
  isExceededUploadQuota,
  uploadCaseFile
} from '../../utils/api';
import * as types from './actionTypes';

// common
export const httpRequest = createAction(types.HTTP_REQUEST);
export const httpSuccess = createAction(types.HTTP_SUCCESS);
export const httpFailure = createAction(types.HTTP_FAILURE);
export const uploadRequest = createAction(types.UPLOAD_REQUEST);
export const uploadSuccess = createAction(types.UPLOAD_SUCCESS);
export const uploadFailure = createAction(types.UPLOAD_FAILURE);

// attachment
export const updateAttachmentDict = createAction(types.UPDATE_ATTACHMENT_DICT);

export function doLoadAttachments(caseId) {
  return (dispatch) => {
    dispatch(updateAttachmentDict({}));
    dispatch(httpRequest());
    const mockData = {
      1: [{ id: 7650, name: "資料科學家的日常.pdf", statusId: 4 },
      { id: 7655, name: "站在浪尖的數位廣告產業.pdf", statusId: 4 }],
      2: [{ id: 7656, name: "數位廣告產業.pdf", statusId: 1 }],
      3: [{ id: 7657, name: "landing-slider1.jpg", statusId: 3 }],
      26: [{ id: 7658, name: "landing-slider2.jpg", statusId: 2 }]
    };
    getCaseFilesById(caseId).then((res) => {
      dispatch(updateAttachmentDict(res));
      dispatch(httpSuccess());
    }).catch((errMsg) => {
      dispatch(updateAttachmentDict(mockData)); // todo
      message.error(`讀取委任檔案失敗，請稍後再試 [${errMsg.toString()}]`);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
    })
  }
}

export const updateIsExceededQuota = createAction(types.UPDATE_IS_EXCEEDED_QUOTA)
export function doUploadAttachments(caseId, folderId, fileList) {
  return dispatch => {
    dispatch(uploadRequest());
    const totalFileSize = fileList.map(f => f.size).reduce((a, b) => a + b, 0)
    const userId = localStorage.getItem('userId');
    isExceededUploadQuota(userId, totalFileSize).then(data => {
      if (data.isExceeded === false) {
        uploadCaseFile(caseId, folderId, fileList).then(() => {
          dispatch(doLoadAttachments(caseId));
          dispatch(updateIsExceededQuota(false));
          dispatch(uploadSuccess());
        }).catch((errMsg) => {
          console.log("doUploadAttachments:", errMsg);
          dispatch(uploadFailure({ errMsg }));
        })
      } else {
        dispatch(updateIsExceededQuota(true));
        dispatch(uploadSuccess());
      }
    }).catch((errMsg) => {
      console.log(errMsg);
      dispatch(uploadFailure({ errMsg }));
    })
  }
}

export function doDownloadAttachment(fileId, fileName) {
  return dispatch => {
    dispatch(httpRequest());
    return downloadCaseFile(fileId, fileName).then(() => {
      dispatch(httpSuccess());
      return true;
    }).catch((errMsg) => {
      console.log("doDownloadAttachment:", errMsg);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
      return false;
    })
  }
}

export function doMoveAttachment(caseId, folderId, fileId) {
  return dispatch => {
    dispatch(httpRequest());
    return moveUserFile(folderId, fileId).then(() => {
      dispatch(doLoadAttachments(caseId));
      return true
    }).catch((errMsg) => {
      console.log("doMoveAttachment:", errMsg);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
      return false
    })
  }
}

export function doDeleteAttachment(caseId, fileId) {
  return dispatch => {
    dispatch(httpRequest());
    return deleteCaseFile(fileId).then(() => {
      dispatch(doLoadAttachments(caseId));
      return true
    }).catch((errMsg) => {
      console.log("doDeleteAttachment:", errMsg);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
      return false
    })
  }
}

export const updateHitList = createAction(types.UPDATE_HIT_LIST);

export function doSearchAttachment(caseId, searchCriteria) {
  return (dispatch) => {
    dispatch(updateHitList(null));
    dispatch(httpRequest());
    searchCaseFiles(caseId, searchCriteria).then(res => {
      if (res.data.length !== 0) {
        dispatch(updateHitList(res.data));
      }
      dispatch(httpSuccess());
    }).catch((errMsg) => {
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
    })
  }
}

export function doLoadAttachmentUrl(pageId) {
  return (dispatch) => {
    if (pageId === undefined) {
      message.error(`無法取得檔案ID，請稍後再試`);
      return false;
    }
    dispatch(httpRequest());
    return getCaseFileBase64(pageId).then(data => {
      dispatch(httpSuccess());
      // return convertBase64ToBinary(data.b64);
      return data.b64;
    }).catch((errMsg) => {
      console.log("getPageBase64:", errMsg);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
      return false
    })
  }
}

export function doLoadAttachmentText(pageId) {
  return (dispatch) => {
    if (pageId === undefined) {
      message.error(`無法取得檔案ID，請稍後再試`);
      return "";
    }
    dispatch(httpRequest());
    return getCaseFileText(pageId).then(res => {
      return res.text ? res.text.toString() : res.text;
    }).catch((errMsg) => {
      console.log("doLoadAttachmentText:", errMsg);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
      return false;
    });
  }
}

export const updateFolderList = createAction(types.UPDATE_FOLDER_LIST);

export function doLoadFolderList() {
  return dispatch => {
    dispatch(httpRequest());
    getFolderList().then(folderList => {
      const folderIdList = [];
      const folders = {};
      folderList.forEach(folder => {
        folderIdList.push(`${folder.id}`);
        folders[folder.id] = folder.name;
      });
      dispatch(updateFolderList({ folderIdList, folders }));
      dispatch(httpSuccess());
    }).catch((errMsg) => {
      dispatch(httpFailure({ errMsg }));
    })
  }
}

export function doCreateFolder(folderName) {
  return dispatch => {
    dispatch(httpRequest());
    return createCaseFileFolder(folderName).then(() => {
      dispatch(doLoadFolderList());
      return true
    }).catch((errMsg) => {
      console.log("doCreateFolder:", errMsg);
      dispatch(httpFailure({ errMsg: errMsg.toString() }));
      return false
    })
  }
}
