// import { createSelector } from 'reselect';

export const getIsDeleting = state => state.getIn(['appointmentList', 'isDeleting']);
export const getErrMsg = state => state.getIn(['appointmentList', 'errMsg']);

export const getIsGettingAppointmentList = state => state.getIn(['appointmentList', 'isGettingAppointmentList']);
export const getIsGettingAppointmentDetail = state => state.getIn(['appointmentList', 'isGettingAppointmentDetail']);
export const getCurrentAppointmentId = state => state.getIn(['appointmentList', 'currentAppointmentId']);
export const getCurrentDetailTab = state => state.getIn(['appointmentList', 'currentDetailTab']);
export const getAppointmentIdList = state => state.getIn(['appointmentList', 'appointmentIdList']);
export const getAppointments = state => state.getIn(['appointmentList', 'appointments']);
export const getAppointmentDetail = state => state.getIn(['appointmentList', 'appointmentDetail']);
export const getCachedAppointmentId = state => state.getIn(['appointmentList', 'cachedAppointmentId']);