import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  isPutting: false,
  isDeleting: false,
  sentList: [],
  errMsg: '',
  dockets: {},
  docketNames: {},
  docketList: [],
  csvRowList: []
});