import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getAppointmentList,
  getAppointmentDetail,
  deleteAppointment,
  creatDocket,
  updateDocket,
  delDocket
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)
export const getAppointmentListRequest = createAction(types.GETTING_APPOINTMENT_LIST_REQUEST)
export const getAppointmentListSuccess = createAction(types.GETTING_APPOINTMENT_LIST_SUCCESS)
export const getAppointmentListFailure = createAction(types.GETTING_APPOINTMENT_LIST_FAILURE)
export const getAppointmentDetailRequest = createAction(types.GETTING_APPOINTMENT_DETAIL_REQUEST)
export const getAppointmentDetailSuccess = createAction(types.GETTING_APPOINTMENT_DETAIL_SUCCESS)
export const getAppointmentDetailFailure = createAction(types.GETTING_APPOINTMENT_DETAIL_FAILURE)

export const doChangeCurrentAppointmentId = createAction(types.UPDATE_CURRENT_APPOINTMENT_ID)
export const doUpdateCurrentDetailTab = createAction(types.UPDATE_CURRENT_DETAIL_TAB)
export const doUpdateCachedAppointmentId = createAction(types.UPDATE_CACHED_APPOINTMENT_ID)

export const updateAppointmentList = createAction(types.UPDATE_APPOINTMENT_LIST)
export function doLoadAppointmentList(name, text, currentAppointmentId) {
  return (dispatch, getState) => {
    const state = getState();
    if (currentAppointmentId) {
      dispatch(doChangeCurrentAppointmentId(currentAppointmentId))
    } else {
      currentAppointmentId = state.getIn(['appointmentList', 'currentAppointmentId']);
    }
    dispatch(getAppointmentListRequest());
    getAppointmentList(name, text).then(appointmentList => {
      const appointmentIdList = [];
      const appointments = {};
      appointmentList.forEach(appointment => {
        appointmentIdList.push(`${appointment.id}`);
        appointments[`${appointment.id}`] = appointment;
      })
      if (appointmentList.length === 0) {
        currentAppointmentId = null;
      } else if (currentAppointmentId === null || appointmentIdList.indexOf(currentAppointmentId) === -1) {
        currentAppointmentId = appointmentList[0].id;
      }
      dispatch(updateAppointmentList({ appointmentIdList, appointments }));
      dispatch(doLoadAppointmentDetail(currentAppointmentId));
      dispatch(getAppointmentListSuccess());
    }).catch((errMsg) => {
      dispatch(getAppointmentListFailure({ errMsg }));
    })
  }
}

export const updateAppointmentDetail = createAction(types.UPDATE_APPOINTMENT_DETAIL)
export function doLoadAppointmentDetail(appointmentId) {
  return dispatch => {
    dispatch(getAppointmentDetailRequest());
    getAppointmentDetail(appointmentId).then(appointmentDetail => {
      dispatch(updateAppointmentDetail({ appointmentDetail, appointmentId }));
      dispatch(getAppointmentDetailSuccess());
    }).catch((errMsg) => {
      dispatch(getAppointmentDetailFailure({ errMsg }));
    })
  }
}

export function doDeleteAppointment(appointmentId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteAppointment(appointmentId).then(data => {
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doCreatDocket(caseId, docket) {
  return dispatch => {
    let word = docket.word ? docket.word : '';
    let courts = docket.courts ? docket.courts : '';
    let ext = docket.ext ? docket.ext : '';
    let level = docket.level ? docket.level : '';
    let no = docket.no ? docket.no : '';
    let trial = docket.trial ? docket.trial : '';
    let unit = docket.unit ? docket.unit : '';
    let year = docket.year ? docket.year : '';
    dispatch(postRequest());
    creatDocket(caseId, word, courts, ext, level, no, trial, unit, year).then(data => {
      dispatch(doLoadAppointmentList());
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}
export function doUpdateDocket(caseId, docket) {
  return dispatch => {
    let id = docket.docketId ? docket.docketId : '';
    let word = docket.word ? docket.word : '';
    let courts = docket.courts ? docket.courts : '';
    let ext = docket.ext ? docket.ext : '';
    let level = docket.level ? docket.level : '';
    let no = docket.no ? Number(docket.no) : '';
    let trial = docket.trial ? docket.trial : '';
    let unit = docket.unit ? docket.unit : '';
    let year = docket.year ? Number(docket.year) : '';
    dispatch(putRequest());
    updateDocket(caseId, id, word, courts, ext, level, no, trial, unit, year).then(data => {
      dispatch(doLoadAppointmentList());
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}
export function doDeleteDocket(caseId, docketId) {
  return dispatch => {
    dispatch(deleteRequest());
    delDocket(caseId, docketId).then(data => {
      dispatch(doLoadAppointmentList());
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}