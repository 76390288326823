import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {State} from './model';

const Reducer = handleActions({
  // common
  "attachment/HTTP_REQUEST": (state, {}) => {
    return state.setIn(['isLoading'], true)
      .setIn(["errMsg"], null);
  },
  "attachment/HTTP_SUCCESS": (state, {}) => {
    return state.setIn(['isLoading'], false)
      .setIn(["errMsg"], null);
  },
  "attachment/HTTP_FAILURE": (state, { payload }) => {
    return state.setIn(['isLoading'], false)
      .setIn(['errMsg'], payload.errMsg);
  },
  "attachment/UPLOAD_REQUEST": (state, {}) => {
    return state.setIn(['isUploading'], true)
                .setIn(["errMsg"], null);
  },
  "attachment/UPLOAD_SUCCESS": (state, {}) => {
    return state.setIn(['isUploading'], false)
                .setIn(["errMsg"], null);
  },
  "attachment/UPLOAD_FAILURE": (state, { payload }) => {
    return state.setIn(['isUploading'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'attachment/UPDATE_FOLDER_LIST': (state, { payload }) => {
    return state.setIn(['folderIdList'], fromJS(payload.folderIdList))
                .setIn(['folders'], fromJS(payload.folders));
  },
  // attachment
  "attachment/UPDATE_ATTACHMENT_DICT": (state, { payload }) => {
    if (payload) {
      return state
        .setIn(["attachmentDict"], fromJS(payload))
    } else {
      return state
        .setIn(["attachmentDict"], fromJS({}))
    }
  },
  "attachment/UPDATE_HIT_LIST": (state, { payload }) => {
    if (payload) {
      return state
        .setIn(["hitList"], fromJS(payload))
    } else {
      return state
        .setIn(["hitList"], null)
    }
  },
  'attachment/UPDATE_IS_EXCEEDED_QUOTA': (state, { payload }) => {
    return state.setIn(['isExceededQuota'], payload);
  },
}, State);

export default Reducer;
