import React, { Component } from 'react';
import index from '../modules/index';


class IndexPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-index">
        <index.container />
	    </div>
	  )
  }
}

export default IndexPageComponent;
