import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Select, message, Input } from 'antd';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

class PreviewNotesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageRefs: null,
      currentScale: 1,
    };
    this.onDocumentLoad = this.onDocumentLoad.bind(this);
  }

  componentDidMount() {
    const previewNotesId = localStorage.getItem('previewNotesId');
    this.props.onLoadNotes(previewNotesId);
  }
  componentDidUpdate(prevProps, prevState) {
  }
  onDocumentLoad({ numPages }) {
    const pageRefs = Array.from(Array(numPages)).map(() => React.createRef());
    this.setState({ numPages, pageRefs });
  }

  render() {
    const { numPages, pageRefs, currentScale } = this.state;
    return (
      <div className="previewNotes-section section-col-item">
        <div className="previewNotes-wrap">
          <div className="previewNotes-title noPrint">
            <button><Link to={`/cases`}>返回委任管理列表</Link></button>
            <div className="title">法律卷證筆記-預覽列印</div>
          </div>
          <div className="note-wrap">
            <div className="image">
              {
                this.props.notes.getIn(['image'])
                &&
                <Document
                  file={`data:application/pdf;base64,${this.props.notes.getIn(['image'])}`}
                  onLoadSuccess={this.onDocumentLoad}
                >
                  {
                    Array.apply(null, Array(numPages)).map((x, i)=>i+1).map((page, i) => 
                      <div id={`page-${page}`}
                        ref={pageRefs && pageRefs[i]}
                        key={`page-${page}`}
                      >
                        <Page
                          loading={'Loading pdf page..'}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          pageNumber={page}
                          scale={currentScale}
                        />
                      </div>
                    )
                  }
                </Document>
              }
            </div>
            <div className="notes">
              <div className="note">
                <div className="note-item note-dates">
                  <div className="note-item-title">日期：</div>
                  <div>{this.props.notes.getIn(['date'])}</div>
                </div>
                <div className="note-item note-fileName">
                  <div className="note-item-title">檔案名：</div>
                  <div>{this.props.notes.getIn(['title'])}</div>
                </div>
                <div className="note-item note-filePage">
                  <div className="note-item-title">頁碼：</div>
                  <div>{this.props.notes.getIn(['pageNo'])}</div>
                </div>
                {
                  this.props.notes.getIn(['notes']) && this.props.notes.getIn(['notes']).map(note => 
                    <div className="note-notes">
                      <div>標題：{note.getIn(['title'])}</div>
                      <div>筆記：{note.getIn(['content'])}</div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(PreviewNotesComponent);