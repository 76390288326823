// common
export const POST_REQUEST = 'mailroomReceivedList/POST_REQUEST'
export const POST_SUCCESS = 'mailroomReceivedList/POST_SUCCESS'
export const POST_FAILURE = 'mailroomReceivedList/POST_FAILURE'
export const GET_REQUEST = 'mailroomReceivedList/GET_REQUEST'
export const GET_SUCCESS = 'mailroomReceivedList/GET_SUCCESS'
export const GET_FAILURE = 'mailroomReceivedList/GET_FAILURE'
export const PUT_REQUEST = 'mailroomReceivedList/PUT_REQUEST'
export const PUT_SUCCESS = 'mailroomReceivedList/PUT_SUCCESS'
export const PUT_FAILURE = 'mailroomReceivedList/PUT_FAILURE'
export const DELETE_REQUEST = 'mailroomReceivedList/DELETE_REQUEST'
export const DELETE_SUCCESS = 'mailroomReceivedList/DELETE_SUCCESS'
export const DELETE_FAILURE = 'mailroomReceivedList/DELETE_FAILURE'

export const UPDATE_RECEIVED_LIST = 'mailroomReceivedList/UPDATE_RECEIVED_LIST'
export const UPDATE_DOCKET_LIST = 'mailroomReceivedList/UPDATE_DOCKET_LIST'
export const UPDATE_CACHED_RECEIVED_ID = 'mailroomReceivedList/UPDATE_CACHED_RECEIVED_ID'
export const UPDATE_CSV_ROW_LIST = 'mailroomReceivedList/UPDATE_CSV_ROW_LIST'