import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { message, Checkbox, InputNumber, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import { Form } from 'react-bootstrap';
import mailroomReceivedList from '../mailroomReceivedList';
import mailroomSentList from '../mailroomSentList';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class ReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxYear: null,
      taxable: false,
      nontaxable: false
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {

  }
  onSubmitSearch = () => {

  }
  onChangeTaxYear = (taxYear) => {
    this.setState({ taxYear });
  }
  onChangeTaxable = () => {
    this.setState({ taxable: !this.state.taxable });
  }
  onChangeNontaxable = () => {
    this.setState({ nontaxable: !this.state.nontaxable });
  }
  onClickReset = () => {
    this.setState({ taxYear: null, taxable: false, nontaxable: false });
  }
  onSubmitSearch = () => {
    const { taxYear, taxable, nontaxable } = this.state;
    this.props.onSearch(taxYear, taxable, nontaxable);
  }
  onDownload = () => {
    const { taxYear, taxable, nontaxable } = this.state;
    this.props.onDownload(taxYear, taxable, nontaxable);
  }
  render() {
    const { taxYear, taxable, nontaxable } = this.state;
    return (
      <div className="report">
        <div className="tool-bar">
          <Form className="search"
            onSubmit={e => {e.preventDefault(); this.onSubmitSearch()}}
          >
            <div className="search-col">
              <label htmlFor="searchName">年度選擇：</label>
              <InputNumber className="col-edit searchYear"
                size="small"
                allowClear
                type="text"
                min={0}
                value={taxYear}
                onChange={value => this.onChangeTaxYear(value)}
              />
            </div>
            <div className="search-col">
              <input type="checkbox" id="taxable" className="taxable-"
                checked={taxable === true}
                onChange={e => this.onChangeTaxable()}
              />
              <label htmlFor="taxable" className="taxable-">應稅</label>
            </div>
            <div className="search-col">
              <input type="checkbox" id="nontaxable" className="taxable-"
                checked={nontaxable === true}
                onChange={e => this.onChangeNontaxable()}
              />
              <label htmlFor="nontaxable" className="taxable-">未稅</label>
            </div>
            <button data-txt="搜尋案件" className="btn-search btn-empty" variant="primary" type="submit">
              <i><FontAwesomeIcon icon={fas.faSearch} /></i>搜尋案件
            </button>
            <Tooltip title="重新搜尋">
              <button className="btn-empty" data-txt="重新搜尋"
                type="button"
                onClick={e => {e.stopPropagation(); this.onClickReset() }}
              ><FontAwesomeIcon icon={fas.faRedoAlt} /></button>
            </Tooltip>
          </Form>
          {
            this.props.rowList && this.props.rowList.size > 1  // 標題列
            &&
            <div className="btn-tool">
              <CSVLink className="btn-case" filename={`${this.props.reportName}`} data={this.props.rowList.toJS()}>
                <i><FontAwesomeIcon icon={fas.faDownload} /></i>下載報表
              </CSVLink>
            </div>
          }
        </div>
        <div className="section-list">
          {
            this.props.rowList && this.props.rowList.map((row, idx) =>
              idx === 0
              ?
                <ul className="case-list-head">
                  <li className="head-taxYear">報稅年度</li>
                  <li className="head-taxabled">應稅/未稅</li>
                  <li className="head-docketKeyword">案號</li>
                  <li className="head-caseStatus">案件狀態</li>
                  <li className="head-closeDate">結案日期</li>
                  <li className="head-appointorType">自然人/法人</li>
                  <li className="head-cname">名稱{/* (公司名稱/委任人名稱) */}</li>
                  <li className="head-cname">當事人{/* (公司名稱/委任人名稱) */}</li>
                  <li className="head-companyBan">統編</li>
                  <li className="head-companyAddress">住址</li>
                  <li className="head-payDate">收款日期</li>
                  <li className="head-taxAmount">報稅金額</li>
                  <li className="head-withholdingAmount">扣繳金額</li>
                  <li className="head-actualAmount">實收金額</li>
                </ul>
              :
                <div key={`report-row-${idx}`} className="case-item">
                  <ul className="case-list-body">
                    <li className="body-taxYear" data-th="報稅年度：">{ row.getIn([0]) }</li>
                    <li className="body-taxabled" data-th="應稅/未稅">{ row.getIn([1]) }</li>
                    <li className="body-docketKeyword" data-th="案號">{ row.getIn([2]) }</li>
                    <li className="body-caseStatus" data-th="案件狀態">{ row.getIn([3]) }</li>
                    <li className="body-closeDate" data-th="結案日期">{ row.getIn([4]) }</li>
                    <li className="body-appointorType" data-th="自然人/法人：">{ row.getIn([5]) }</li>
                    <li className="body-cname" data-th="名稱(公司名稱/委任人名稱)：">{ row.getIn([6])}</li>
                    <li className="body-cname" data-th="當事人:">{ row.getIn([7])}</li>
                    <li className="body-companyBan" data-th="統編：">{ row.getIn([8]) }</li>
                    <li className="body-companyAddress" data-th="住址：">{ row.getIn([9]) }</li>
                    <li className="body-payDate" data-th="收款日期：">{ row.getIn([10]) }</li>
                    <li className="body-taxAmount" data-th="報稅金額：">{ row.getIn([11]) }</li>
                    <li className="body-withholdingAmount" data-th="扣繳金額：">{ row.getIn([12]) }</li>
                    <li className="body-actualAmount" data-th="實收金額：">{ row.getIn([13]) }</li>
                  </ul>
                </div>
            )
          }
        </div>
      </div>
    )
  }
}
export default withRouter(ReportComponent);
