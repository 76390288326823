import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentCustodianComponent from './component';
import * as appointmentCaseActions from '../appointmentCase/actions';


const mapStateToProps = (state) => ({
  isPutting: selectors.getIsPutting(state),
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateCustodian: (appointmentId, name, address, phone, email, relationship, ssid, birthday, no) => {
    dispatch(actions.doUpdateCustodian(appointmentId, name, address, phone, email, relationship, ssid, birthday, no));
  },
  onUpdateCustodianCompany: (appointmentId, name, ban, representative, contactPerson, address, phone, email, relationship) => {
    dispatch(actions.doUpdateCustodianCompany(appointmentId, name, ban, representative, contactPerson, address, phone, email, relationship));
  },
  onUpdateCustodianRelationship: (appointmentId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote) => {
    dispatch(appointmentCaseActions.doUpdateCase(appointmentId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentCustodianComponent);