import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Input, message } from 'antd';


class PdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchView: 'pdf',
      cachePageUrl: null,
      cachePageText: "",
      numPages: null,
      currentPageNumber: 1,
      jumpToPageNumber: 1,
      currentScale: 1.3,
      pageRefs: null
    };
    this.onDocumentLoad = this.onDocumentLoad.bind(this);
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentPage) {
      if (!prevProps.currentPage || (this.props.currentPage.get("id") !== prevProps.currentPage.get("id"))) {
        this.setState({ cachePageUrl: null, cachePageText: "", currentPageNumber: 1, jumpToPageNumber: 1 });
        this.props.onLoadAttachmentUrl(this.props.currentPage.get("id")).then((pageUrl) => {
          if (pageUrl) {
            this.setState({ cachePageUrl: pageUrl });
          } else {
            message.info('檔案處理中');
          }
          // this.setState({ cachePageUrl: "https://arxiv.org/pdf/quant-ph/0410100.pdf" });
        });
        this.props.onLoadAttachmentText(this.props.currentPage.get("id")).then((text) => {
          if (text !== false) {
            this.setState({ cachePageText: text });
          } else {
            message.error(`取得檔案文字檔失敗 [${this.props.errMsg}]`);
          }
        });
      }
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onDocumentLoad({ numPages }) {
    const pageRefs = Array.from(Array(numPages)).map(() => React.createRef());
    this.setState({ numPages, pageRefs });
  }
  onChangePageScale(scareAction) {
    const { currentScale } = this.state;
    if (scareAction === 'plus' && currentScale + 0.1 < 3) {
      this.setState({ currentScale: currentScale + 0.1 });
    } else if (scareAction === 'minus' && currentScale - 0.1 > 0) {
      this.setState({ currentScale: currentScale - 0.1 });
    }
  }
  onChangePage(currentPageNumber) {
    if (currentPageNumber < 1 || currentPageNumber > this.state.numPages) {
      return;
    }
    this.state.pageRefs[currentPageNumber - 1].current.scrollIntoView();
    this.setState({ currentPageNumber, jumpToPageNumber: currentPageNumber });
  }
  render() {
    const { numPages, pageRefs, currentPageNumber, jumpToPageNumber, switchView, currentScale } = this.state;
    return (
      <div className="view_block section" id="top_bar">
        {
          this.props.currentPage
          &&
          <div className="view_tool">
            <div className="view-tool-left">
              <p className="page-detail-title">{this.props.currentPage.get("name")}</p>
            </div>
            <div className="view-tool-right">
              {
                switchView === "pdf"
                &&
                <React.Fragment>
                  <p className="view-tool-wrapper" onClick={() => this.onChangePageScale('plus')}>
                    <FontAwesomeIcon className="view-tool-icon" icon={fas.faSearchPlus} />
                    <span className="view-tool-label">放大</span>
                  </p>
                  <p className="view-tool-wrapper" onClick={() => this.onChangePageScale('minus')}>
                    <FontAwesomeIcon className="view-tool-icon" icon={fas.faSearchMinus} />
                    <span className="view-tool-label">縮小</span>
                  </p>
                </React.Fragment>
              }
              <p className="view-tool-wrapper"
                onClick={() => this.setState({ switchView: switchView === 'pdf' ? 'text' : 'pdf' })}>
                <FontAwesomeIcon className="view-tool-icon" icon={fas.faToggleOn} />
                <span className="view-tool-label">切換:</span>
                <span>{switchView.toUpperCase()}</span>
              </p>
            </div>
          </div>
        }
        <div className="pdf-view-container">
          <div className="pdf-viewer">
            {
              (switchView === "pdf" && this.state.cachePageUrl)
              &&
              <Document
                file={`data:application/pdf;base64,${this.state.cachePageUrl}`}
                onLoadSuccess={this.onDocumentLoad}
              >
                {
                  Array.apply(null, Array(numPages)).map((x, i) => i + 1).map((page, i) =>
                    <div id={`page-${page}`}
                      ref={pageRefs && pageRefs[i]}
                      key={`page-${page}`}
                    >
                      <Page
                        loading={'Loading pdf page..'}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        pageNumber={page}
                        scale={currentScale}
                      />
                    </div>
                  )
                }
              </Document>
            }
            {
              (switchView === 'text' && this.props.currentPage.get('statusId') === 4)
              &&
              <div className="text-view-container">
                {this.state.cachePageText}
              </div>
            }
          </div>
        </div>
        <div className="view-bottom">
          {
            this.props.currentPage
            &&
            <div className="pages-wrapper">
              <i className="pages-up" onClick={() => this.onChangePage(currentPageNumber - 1)}>
                <FontAwesomeIcon icon={fas.faAngleLeft} />
              </i>
              <div className="page-count-wrapper">
                <form onSubmit={e => { e.preventDefault(); this.onChangePage(jumpToPageNumber) }}>
                  <Input
                    className="pages-input-value"
                    type="number"
                    value={jumpToPageNumber}
                    onChange={e => this.onChangeState('jumpToPageNumber', parseInt(e.target.value))}
                  />
                </form>
                <span className="pages-input-count">{numPages}</span>
              </div>
              <i className="pages-down" onClick={() => this.onChangePage(currentPageNumber + 1)}>
                <FontAwesomeIcon icon={fas.faAngleRight} />
              </i>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(PdfViewer)