import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getCourtList,
  getMailroomTypeList,
  getMailroomDeliveryTypeList,
  getDocketList,
  createMailroomReceived,
  createMailroomSent,
  getSearchMailroom
} from '../../utils/api';
import * as config from '../../config'

// common
export const searchRequest = createAction(types.SEARCH_REQUEST)
export const searchSuccess = createAction(types.SEARCH_SUCCESS)
export const searchFailure = createAction(types.SEARCH_FAILURE)
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)


export const updateCourtList = createAction(types.UPDATE_COURT_LIST)
export function doLoadCourtList() {
  return dispatch => {
    dispatch(getRequest());
    getCourtList().then(courtList => {
      const courtIdList = [];
      const courts = {};
      courtList.forEach(court => {courtIdList.push(`${court.id}`); courts[court.id] = court.name});
      dispatch(updateCourtList({ courtIdList, courts }));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}


export const updateTypeList = createAction(types.UPDATE_TYPE_LIST)
export function doLoadTypeList() {
  return dispatch => {
    dispatch(getRequest());
    getMailroomTypeList().then(typeList => {
      const typeIdList = [];
      const types = {};
      typeList.forEach(type_ => {typeIdList.push(`${type_.id}`); types[type_.id] = type_.name});
      dispatch(updateTypeList({ typeIdList, types }));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export const updateDocketList = createAction(types.UPDATE_DOCKET_LIST)
export function doLoadDocketList(contain, size) {
  return dispatch => {
    getDocketList(contain, size).then(data => {
      dispatch(updateDocketList(data));
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export const updateDeliveryTypeList = createAction(types.UPDATE_DELIVERY_TYPE_LIST)
export function doLoadDeliveryTypeList() {
  return dispatch => {
    dispatch(getRequest());
    getMailroomDeliveryTypeList().then(data => {
      const deliveryTypeIdList = [];
      const deliveryTypes = {};
      data.forEach(deliveryType_ => {deliveryTypeIdList.push(`${deliveryType_.id}`); deliveryTypes[deliveryType_.id] = deliveryType_.name});
      dispatch(updateDeliveryTypeList({ deliveryTypeIdList, deliveryTypes }));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}


export function doCreateReceived(name, typeId, receivedDate, sender, custodianName, sentDate, docketId, expiredDays, fileList) {
  return dispatch => {
    dispatch(postRequest());
    createMailroomReceived(name, typeId, receivedDate, sender, custodianName, sentDate, docketId, expiredDays, fileList).then(data => {
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}


export function doCreateSent(name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId, fileList) {
  return dispatch => {
    dispatch(postRequest());
    createMailroomSent(name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId, fileList).then(data => {
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export const updateSearchMailroom = createAction(types.UPDATE_SEARCH_MAILROOM)
export function doSearchMailroom(keyword) {
  return dispatch => {
    dispatch(searchRequest());
    getSearchMailroom(keyword).then(data => {
      dispatch(updateSearchMailroom(data))
      dispatch(searchSuccess());
    }).catch((errMsg) => {
      dispatch(searchFailure({ errMsg }));
    })
  }
}