// common
export const POST_REQUEST = 'appointmentCase/POST_REQUEST'
export const POST_SUCCESS = 'appointmentCase/POST_SUCCESS'
export const POST_FAILURE = 'appointmentCase/POST_FAILURE'
export const PUT_REQUEST = 'appointmentCase/PUT_REQUEST'
export const PUT_SUCCESS = 'appointmentCase/PUT_SUCCESS'
export const PUT_FAILURE = 'appointmentCase/PUT_FAILURE'
export const DELETE_REQUEST = 'appointmentCase/DELETE_REQUEST'
export const DELETE_SUCCESS = 'appointmentCase/DELETE_SUCCESS'
export const DELETE_FAILURE = 'appointmentCase/DELETE_FAILURE'
export const GET_CASE_RELATED_REQUEST = 'appointmentCase/GET_CASE_RELATED_REQUEST'
export const GET_CASE_RELATED_SUCCESS = 'appointmentCase/GET_CASE_RELATED_SUCCESS'
export const GET_CASE_RELATED_FAILURE = 'appointmentCase/GET_CASE_RELATED_FAILURE'

export const UPDATE_CASE_RELATE_LIST = 'appointmentCase/UPDATE_CASE_RELATE_LIST'