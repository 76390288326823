import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getPageNoteList
} from '../../utils/api';
import * as config from '../../config'


// common
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)

export const updateNotes = createAction(types.UPDATE_NOTES)
export function doUpdateNoteVisible(noteId, visible) {
  return (dispatch, getState) => {
    const state = getState();
    let storeNotes = state.getIn(['reviewNotes', 'notes']);
    storeNotes = storeNotes.setIn([`${noteId}`, 'visible'], visible);
    dispatch(updateNotes(storeNotes));
  }
}

export const updatePageNoteList = createAction(types.UPDATE_PAGE_NOTE_LIST)
export function doLoadPageNoteList(pageId) {
  return (dispatch, getState) => {
    const state = getState();
    const storeNotes = state.getIn(['reviewNotes', 'notes']);
    dispatch(getRequest());
    const cachedNoteId = localStorage.getItem('cachedNoteId');  // 點委任管理頁、筆記子分頁中的這筆筆記, 因而轉跳到卷證頁
    getPageNoteList(pageId).then(noteList => {
      const noteIdList = [];
      const notes = {};
      noteList.forEach(note => {
        noteIdList.push(`${note.id}`);
        if (cachedNoteId === `${note.id}`) {
          note['visible'] = true;
          note['isEdit'] = false;
        } else if (storeNotes.hasIn([`${note.id}`])) {
          note['visible'] = storeNotes.getIn([`${note.id}`, 'visible']);
          note['isEdit'] = storeNotes.getIn([`${note.id}`, 'isEdit']);
        } else {
          note['visible'] = false;
          note['isEdit'] = false;
        }
        notes[`${note.id}`] = note;
      });
      localStorage.removeItem('cachedNoteId');
      dispatch(updatePageNoteList({ noteIdList, notes }));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}