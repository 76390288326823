import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  updateAppointmentCustodian,
  updateCompanyCustodian
} from '../../utils/api';

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)

export function doUpdateCustodian(appointmentId, name, address, phone, email, relationship, ssid, birthday, no) {
  return dispatch => {
    dispatch(putRequest());
    updateAppointmentCustodian(appointmentId, name, address, phone, email, relationship, ssid, birthday, no).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}
export function doUpdateCustodianCompany(appointmentId, name, ban, representative, contactPerson, address, phone, email, relationship) {
  return dispatch => {
    dispatch(putRequest());
    updateCompanyCustodian(appointmentId, name, ban, representative, contactPerson, address, phone, email, relationship).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}