import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  getMailroomReceivedList,
  createMailroomReceived,
  deleteMailroomReceivedFile,
  uploadMailroomReceivedFile,
  downloadMailroomReceivedFile,
  getDocketList,
  updateReceived,
  deleteReceived
} from '../../utils/api';
import * as config from '../../config'

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)

export const doUpdateCachedReceivedId = createAction(types.UPDATE_CACHED_RECEIVED_ID)

export const updateReceivedList = createAction(types.UPDATE_RECEIVED_LIST)
export const updateCsvRowList = createAction(types.UPDATE_CSV_ROW_LIST)
export function doLoadReceivedList(action) {
  return (dispatch, getState) => {
    const state = getState();
    let receives = state.getIn(['mailroom', 'searchMailroom', 'receives']);
    if(action === 'search') {
      dispatch(updateReceivedList(receives.toJS()));
      const csvRowList = [['收文日', '當事人', '案號', '來文者', '期限日', '類型', '函文名稱']];
        receives.toJS().forEach(received => {
          csvRowList.push([
            received.receivedDate,
            received.custodianName,
            received.docketName,
            received.sender,
            received.expiredDate,
            received.typeName,
            received.name,
          ])
        })
        dispatch(updateCsvRowList(csvRowList));
    } else {
      dispatch(getRequest());
      getMailroomReceivedList().then(receivedList => {
        dispatch(updateReceivedList(receivedList));
        const csvRowList = [['收文日', '當事人', '案號', '來文者', '期限日', '類型', '函文名稱']];
        receivedList.forEach(received => {
          csvRowList.push([
            received.receivedDate,
            received.custodianName,
            received.docketName,
            received.sender,
            received.expiredDate,
            received.typeName,
            received.name,
          ])
        })
        dispatch(updateCsvRowList(csvRowList));
        dispatch(getSuccess());
      }).catch((errMsg) => {
        dispatch(getFailure({ errMsg }));
      })
    }
  }
}

export function doDeleteReceivedFile(fileId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteMailroomReceivedFile(fileId).then(data => {
      dispatch(doLoadReceivedList());
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doUploadReceivedFile(receivedId, docketId, fileList) {
  return dispatch => {
    dispatch(postRequest());
    uploadMailroomReceivedFile(receivedId, docketId, fileList).then(data => {
      dispatch(doLoadReceivedList());
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doDownloadReceivedFile(fileId, fileName) {
  return dispatch => {
    downloadMailroomReceivedFile(fileId, fileName).then(data => {
    }).catch((errMsg) => {
    })
  }
}

export const updateDocketList = createAction(types.UPDATE_DOCKET_LIST)
export function doLoadDocketList(contain, size) {
  return dispatch => {
    getDocketList(contain, size).then(data => {
      dispatch(updateDocketList(data));
      return;
    }).catch((errMsg) => {
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export function doUpdateReceived(receivedId, sender, docketId, expiredDays, sentDate, receivedDate, custodianName, typeId, name) {
  return dispatch => {
    dispatch(putRequest());
    updateReceived(receivedId, sender, docketId, expiredDays, sentDate, receivedDate, custodianName, typeId, name).then(data => {
      dispatch(doLoadReceivedList());
      dispatch(putSuccess());
      return;
    }).catch((errMsg) => {
      return dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDeleteReceived(receivedId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteReceived(receivedId).then(data => {
      dispatch(doLoadReceivedList());
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}