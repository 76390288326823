// common
export const PUT_REQUEST = 'folder/PUT_REQUEST'
export const PUT_SUCCESS = 'folder/PUT_SUCCESS'
export const PUT_FAILURE = 'folder/PUT_FAILURE'
export const POST_REQUEST = 'folder/POST_REQUEST'
export const POST_SUCCESS = 'folder/POST_SUCCESS'
export const POST_FAILURE = 'folder/POST_FAILURE'
export const GET_REQUEST = 'folder/GET_REQUEST'
export const GET_SUCCESS = 'folder/GET_SUCCESS'
export const GET_FAILURE = 'folder/GET_FAILURE'

export const UPDATE_FOLDER_LIST = 'folder/UPDATE_FOLDER_LIST'