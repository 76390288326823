// common
export const DELETE_REQUEST = 'consultations/DELETE_REQUEST'
export const DELETE_SUCCESS = 'consultations/DELETE_SUCCESS'
export const DELETE_FAILURE = 'consultations/DELETE_FAILURE'
export const POST_REQUEST = 'consultations/POST_REQUEST'
export const POST_SUCCESS = 'consultations/POST_SUCCESS'
export const POST_FAILURE = 'consultations/POST_FAILURE'
// consultations
export const GETTING_CONSULTATION_LIST_REQUEST = 'consultations/GETTING_CONSULTATION_LIST_REQUEST'
export const GETTING_CONSULTATION_LIST_SUCCESS = 'consultations/GETTING_CONSULTATION_LIST_SUCCESS'
export const GETTING_CONSULTATION_LIST_FAILURE = 'consultations/GETTING_CONSULTATION_LIST_FAILURE'
export const GETTING_CONSULTATION_DETAIL_REQUEST = 'consultations/GETTING_CONSULTATION_DETAIL_REQUEST'
export const GETTING_CONSULTATION_DETAIL_SUCCESS = 'consultations/GETTING_CONSULTATION_DETAIL_SUCCESS'
export const GETTING_CONSULTATION_DETAIL_FAILURE = 'consultations/GETTING_CONSULTATION_DETAIL_FAILURE'
export const UPDATE_CURRENT_CONSULTATION_ID = 'consultations/UPDATE_CURRENT_CONSULTATION_ID'
export const UPDATE_CURRENT_DETAIL_TAB = 'consultations/UPDATE_CURRENT_DETAIL_TAB'
export const UPDATE_CONSULTATION_LIST = 'consultations/UPDATE_CONSULTATION_LIST'
export const UPDATE_CONSULTATION_DETAIL = 'consultations/UPDATE_CONSULTATION_DETAIL'