import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { 
  getProfile ,
  updateProfile,
  updateLogo
} from '../../utils/api';

export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)

export const doUpdateProfile = createAction(types.UPDATE_PROFILE);
export function doLoadProfile(userId) {
  return dispatch => {
    dispatch(getRequest());
    getProfile(userId).then(data => {
      dispatch(doUpdateProfile(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doSubmitProfile(userId, firstName, lastName, address, phoneNo, mobile, bank, bankAccount, twoFactorAuthenticationId) {
  return dispatch => {
    dispatch(putRequest());
    updateProfile(userId, firstName, lastName, address, phoneNo, mobile, bank, bankAccount, twoFactorAuthenticationId).then(data => {
      dispatch(doLoadProfile(userId));
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doSubmitLogo(userId, uploadFileList) {
  return dispatch => {
    dispatch(postRequest());
    updateLogo(userId, uploadFileList).then(data => {
      dispatch(doLoadProfile(userId));
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}