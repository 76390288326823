import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ConsultationsFormComponent from './component';


const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isGetting: selectors.getIsGetting(state),
  errMsg: selectors.getErrMsg(state),
  lawyer: selectors.getLawyer(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadConsultationsForm: (pincode) => {
    dispatch(actions.doLoadConsultationsForm(pincode));
  },
  onSubmitConsultationsForm: (pincode, appointorType, typeId, plaintiffList, defendantList, appointor, company, consultationContent) => {
    dispatch(actions.doSubmitConsultationsForm(pincode, appointorType, typeId, plaintiffList, defendantList, appointor, company, consultationContent));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationsFormComponent);
