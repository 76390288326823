import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  isChecking: false,
  errMsg: '',
  isFetching: true,
  isGettingCaseRelated: false,
  caseRelateList: [],
  custodianConflict: false,
});