import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Select, message, Input, Spin, DatePicker } from 'antd';
import { CASE_TYPE, CASE_STATEID, APPOINTOR_TYPE, TAX_VOUCHER, TAXABLE } from '../../config';

let CASE_TYPE_ID = {};
CASE_TYPE.forEach(type => { CASE_TYPE_ID[type.id] = type.name });

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

class CreatConsultationsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultationDate: '',
      appointorType: '1',
      appointor: {
        ssid: '',
        address: '',
        birthday: '',
        email: '',
        name: '',
        phone: '',
        relationship: '',
      },
      company: {
        ban: '',
        address: '',
        contactPerson: '',
        name: '',
        representative: '',
        email: '',
        phone: '',
      },
      receipt: {
        isHolding: false,
        category: '',
        cost: '',
        date: '',
        name: '',
        note: '',
        reason: '',
      },
      consultFee: '',
      estimatedFee: '',
      isTaxabled: '0',
      taxYear: '',
      taxAmount: '',
      payDate: '',
      taxVoucher: '2', //扣繳憑證 default
      caseStatus: '1', //default
      plaintiffList: [],
      defendantList: [],
      typeId: '1', //default
      relatedList: [],
      keywordList: [],
      consultationContent: '',
      consultationNote: '',
      expandConsultationsSet: new Set(),
    };
  }

  componentDidMount() {
    this.onInitCreatConsultations();
    this.onCreatconsultationDate();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        message.success('新增諮詢紀錄成功');
        this.props.history.push(`/consultations`);
      } else {
        message.error(this.props.errMsg);
      }
    }
  }

  onInitCreatConsultations = () => {
    this.setState({
      consultationDate: '',
      appointorType: '1',
      appointor: {
        ssid: '',
        address: '',
        birthday: '',
        email: '',
        name: '',
        phone: '',
        relationship: '',
      },
      company: {
        ban: '',
        address: '',
        contactPerson: '',
        name: '',
        representative: '',
        email: '',
        phone: '',
      },
      receipt: {
        isHolding: false,
        category: '',
        cost: '',
        date: '',
        name: '',
        note: '',
        reason: '',
      },
      consultFee: '',
      estimatedFee: '',
      isTaxabled: '0',
      taxYear: '',
      taxAmount: '',
      payDate: '',
      taxVoucher: '2', //扣繳憑證 default
      caseStatus: '1', //default
      plaintiffList: [],
      defendantList: [],
      typeId: '1', //default
      relatedList: [],
      keywordList: [],
      consultationContent: '',
      consultationNote: '',
      expandConsultationsSet: new Set(),
    })
  }
  onCreatconsultationDate() {
    var today = new Date();
    var DD = today.getDate();
    var MM = today.getMonth() + 1;
    var YYYY = today.getFullYear();
    if (DD < 10) { DD = '0' + DD };
    if (MM < 10) { MM = '0' + MM };
    today = YYYY + '/' + MM + '/' + DD;
    let ROCyear = YYYY - 1911;
    this.setState({ consultationDate: today, taxYear: ROCyear })
  }
  onClickSubmitCreateConsultations() {
    let { consultationDate, appointorType, appointor, company, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote } = this.state;
    let appointorData = {};
    consultationDate = consultationDate ? moment(consultationDate).format('YYYY/MM/DD') : '';
    if ( appointorType === '1') {
      appointor['ssid'] = appointor['ssid'] ? appointor['ssid'].trim() : '';
      appointor['address'] = appointor['address'] ? appointor['address'].trim() : '';
      appointor['birthday'] = appointor['birthday'] ? moment(appointor['birthday']).format('YYYY/MM/DD') : '';
      appointor['email'] = appointor['email'] ? appointor['email'].trim() : '';
      appointor['name'] = appointor['name'] ? appointor['name'].trim() : '';
      appointor['phone'] = appointor['phone'] ? appointor['phone'] : '';
      appointor['relationship'] = appointor['relationship'] ? appointor['relationship'] : '';
      appointorData = appointor;
    }
    if ( appointorType === '2') {
      company['ban'] = company['ban'] ? company['ban'] : '';
      company['address'] = company['address'] ? company['address'].trim() : '';
      company['contactPerson'] = company['contactPerson'] ? company['contactPerson'].trim() : '';
      company['name'] = company['name'] ? company['name'].trim() : '';
      company['representative'] = company['representative'] ? company['representative'].trim() : '';
      company['email'] = company['email'] ? company['email'].trim() : '';
      company['phone'] = company['phone'] ? company['phone'] : '';
      appointorData = company;
    }
    if (receipt['isHolding'] === true) {
      if (receipt['category'] === '' && receipt['cost'] === '' && receipt['date'] === '' && receipt['name'] === '' && receipt['note'] === '' && receipt['reason'] === '') {
        receipt = null
      } else {
        receipt = {
          category: receipt['category'] ? receipt['category'].trim() : '',
          cost: receipt['cost'] ? receipt['cost'] : '',
          date: receipt['date'] ? moment(receipt['date']).format('YYYY/MM/DD') : '',
          name: receipt['name'] ? receipt['name'].trim() : '',
          note: receipt['note'] ? receipt['note'].trim() : '',
          reason: receipt['reason'] ? receipt['reason'].trim() : '',
        };
      }
    } else {
      receipt = null
    }
    consultFee = consultFee ? consultFee : '';
    estimatedFee = estimatedFee ? estimatedFee : '';
    isTaxabled = parseInt(isTaxabled, 10);
    taxYear = taxYear ? taxYear : '';
    taxAmount = taxAmount ? taxAmount : 0;
    payDate = payDate ? moment(payDate).format('YYYY/MM/DD') : '';
    taxVoucher = parseInt(taxVoucher, 10);
    caseStatus = parseInt(caseStatus, 10);
    plaintiffList = plaintiffList.toString();
    defendantList = defendantList.toString();
    typeId = parseInt(typeId, 10);
    relatedList = relatedList.toString();
    keywordList = keywordList.toString();
    consultationContent = consultationContent ? consultationContent.trim() : '';
    consultationNote = consultationNote ? consultationNote.trim() : '';

    if (consultationDate === '') { return message.error('請輸入必填欄位，「諮詢日期」') };
    if (appointorType === '1') { 
      if (appointor['name'] === '') {
        return message.error('請輸入必填欄位，「諮詢人」')
      }
    }
    if (appointorType === '2') { 
      if (company['name'] === '') {
        return message.error('請輸入必填欄位，「公司名稱」')
      }
    }
    this.props.onSubmitCreateConsultations(consultationDate, appointorType, appointorData, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote);
  }

  onChangeAppointor(key, value) {
    let { appointor } = this.state;
    appointor = JSON.parse(JSON.stringify(appointor));
    appointor[key] = value;
    if( key === 'phone' && appointor['phone'].length > 20) {
      appointor['phone'] = appointor['phone'].slice(0, 20)
    }
    this.setState({ appointor })
  }
  onChangeCompany(key, value) {
    let { company } = this.state;
    company = JSON.parse(JSON.stringify(company));
    company[key] = value;
    if( key === 'phone' && company['phone'].length > 20) {
      company['phone'] = company['phone'].slice(0, 20)
    }
    this.setState({ company })
  }
  onChangeReceipt(key, value) {
    let { receipt } = this.state;
    receipt = JSON.parse(JSON.stringify(receipt));
    receipt[key] = value;
    if( key === 'cost' && receipt['cost'].length > 10 ) {
      receipt['cost'] = receipt['cost'].slice(0, 10)
    }
    this.setState({ receipt });
  }
  onChangeFee(key, value) {
    let { consultFee, estimatedFee, taxYear, taxAmount } = this.state;
    if ( key === 'consultFee' ) {
      if(consultFee.length > 10) { this.setState({ consultFee: value.slice(0, 10) })
      } else { this.setState({ consultFee: value }) }
    } else if ( key === 'estimatedFee' ) {
      if(estimatedFee.length > 10) { this.setState({ estimatedFee: value.slice(0, 10) })
      } else { this.setState({ estimatedFee: value }) }
    } else if ( key === 'taxYear' ) {
      if(taxYear.length > 4) { this.setState({ taxYear: value.slice(0, 4) })
      } else { this.setState({ taxYear: value }) }
    } else if ( key === 'taxAmount' ) {
      if(taxAmount.length > 10) { this.setState({ taxAmount: value.slice(0, 10) })
      } else { this.setState({ taxAmount: value }) }
    }
  }

  render() {
    const { consultationDate, appointorType, appointor, company, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote, expandConsultationsSet } = this.state;
    return (
      <div className="creatConsultations-section section-col-item creatConsultations">
        <div className="content-section">
          <form className="creat-consultations-from"
            onSubmit={(e) => { e.preventDefault(); this.onClickSubmitCreateConsultations() }}
          >
            <div className="creat-case-head">
              <div className="creat-title">
                新增諮詢紀錄
                <span className="tip">* 字為必填欄位</span>
                <div className="col-item">
                  <div className="col-title">案件種類：</div>
                  <Select className="col-edit"
                    size="small"
                    showSearch
                    value={typeId}
                    onChange={value => this.setState({ typeId: value })}
                  >
                    {
                      Object.entries(CASE_TYPE_ID).map(([key, value]) => 
                        <Option key={key} value={key}>{value}</Option>
                      )
                    }
                  </Select>
                </div>
              </div>
            </div>
            <div className="creatCase-btn">
            </div>
            <div className="creat-consultations">
              <div className="col-item-column">
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>諮詢人資訊</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">諮詢身份：</div>
                      <div className="col-edit">
                        {
                          Object.entries(APPOINTOR_TYPE).map(([key, value]) =>
                            <span key={`appointorType-${key}`}>
                              <input type="radio" id={`appointorType-${key}`}
                                value={key}
                                checked={appointorType === key}
                                onChange={e => this.setState({ appointorType: e.target.value })}
                              />
                              <label htmlFor={`appointorType-${key}`} className="edit-appointorType">{value}</label>
                            </span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title required">諮詢日期：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={consultationDate ? moment(consultationDate, dateFormat) : null}
                        onChange={value => this.setState({ consultationDate: value }) }
                      />
                    </div>
                    {
                      appointorType === '1'
                      &&
                      <>
                        <div className="col-item">
                          <div className="col-title required">諮詢人：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={appointor['name']}
                            onChange={e => this.onChangeAppointor('name', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">委任關係：</div>
                          <Input className="col-edit"
                            size="small"
                            placeholder="與當事人關係"
                            type="text"
                            value={appointor['relationship']}
                            onChange={e => this.onChangeAppointor('relationship', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">身分證字號：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={appointor['ssid']}
                            onChange={e => this.onChangeAppointor('ssid', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">生日：</div>
                          <DatePicker className="col-edit"
                            size="small"
                            placeholder="請選擇日期"
                            inputReadOnly={true}
                            showToday={false}
                            format={dateFormat}
                            value={appointor['birthday'] ? moment(appointor['birthday'], dateFormat) : null}
                            onChange={value => this.onChangeAppointor('birthday', value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">住址：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={appointor['address']}
                            onChange={e => this.onChangeAppointor('address', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">聯絡電話：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={appointor['phone']}
                            onChange={e => this.onChangeAppointor('phone', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">Email：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={appointor['email']}
                            onChange={e => this.onChangeAppointor('email', e.target.value)}
                          />
                        </div>
                      </>
                    }
                    {
                      appointorType === '2'
                      &&
                      <>
                        <div className="col-item">
                          <div className="col-title required">公司名稱：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={company['name']}
                            onChange={e => this.onChangeCompany('name', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">統一編號：</div>
                          <Input className="col-edit"
                            size="small"
                            type="number"
                            value={company['ban']}
                            onChange={e => this.onChangeCompany('ban', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">代表人：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={company['representative']}
                            onChange={e => this.onChangeCompany('representative', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">聯絡人：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={company['contactPerson']}
                            onChange={e => this.onChangeCompany('contactPerson', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">住址：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={company['address']}
                            onChange={e => this.onChangeCompany('address', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">聯絡電話：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={company['phone']}
                            onChange={e => this.onChangeCompany('phone', e.target.value)}
                          />
                        </div>
                        <div className="col-item">
                          <div className="col-title">Email：</div>
                          <Input className="col-edit"
                            size="small"
                            type="text"
                            value={company['email']}
                            onChange={e => this.onChangeCompany('email', e.target.value)}
                          />
                        </div>
                      </>
                    }
                  </div>
                </div>
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>案件相關</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">案件狀態：</div>
                      <Select className="col-edit"
                        size="small"
                        defaultValue={caseStatus}
                        onChange={value => this.setState({ caseStatus: value })}
                      >
                        {
                          Object.entries(CASE_STATEID).map(([key, value]) => 
                            <Option key={key} value={key}>{value}</Option>
                          )
                        }
                      </Select>
                    </div>
                    {
                      typeId !== '6'
                      &&
                      <>
                        <div className="col-item">
                          <div className="col-title">
                            {
                              CASE_TYPE.map(type => {
                                if (type['id'].toString() === typeId) {
                                  return type['plaintiff']
                                }
                              })
                            }：
                          </div>
                          <Select className="col-edit"
                            size="small"
                            mode="tags"
                            dropdownStyle={{ display: 'none' }}
                            defaultValue={plaintiffList}
                            onChange={value => this.setState({ plaintiffList: value })}
                            tokenSeparators={[',']}>
                          </Select>
                        </div>
                        <div className="col-item">
                          <div className="col-title">
                            {
                              CASE_TYPE.map(type => {
                                if (type['id'].toString() === typeId) {
                                  return type['defendant']
                                }
                              })
                            }：
                          </div>
                          <Select className="col-edit"
                            size="small"
                            mode="tags" dropdownStyle={{ display: 'none' }}
                            defaultValue={defendantList}
                            onChange={value => this.setState({ defendantList: value })}
                            tokenSeparators={[',']}>
                          </Select>
                        </div>
                      </>
                    }
                    <div className="col-item">
                      <div className="col-title">案件相關：</div>
                      <Select className="col-edit"
                        size="small"
                        placeholder="請輸入關鍵字。例：損害賠償"
                        mode="multiple"
                        value={relatedList}
                        notFoundContent={this.props.isGettingCaseRelated ? <Spin size="small" /> : '無相關資料'}
                        filterOption={false}
                        onSearch={value => this.props.onLoadRelatedList(value, 10)}
                        onChange={value => this.setState({ relatedList: value })}
                      >
                        {
                          this.props.caseRelateList && this.props.caseRelateList.map(relate =>
                            <Option key={`caserelated-${relate}`} value={relate}>{`${relate}`}</Option>
                          )
                        }
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title">諮詢內容：</div>
                      <Input className="col-edit"
                        size="small"
                        type="text"
                        value={consultationContent}
                        onChange={e => { this.setState({ consultationContent: e.target.value }) }}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">備註：</div>
                      <Input className="col-edit"
                        size="small"
                        type="text"
                        value={consultationNote}
                        onChange={e => { this.setState({ consultationNote: e.target.value }) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-item-column">
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>費用</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">實收金額：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="元"
                        value={consultFee}
                        onChange={e => this.onChangeFee('consultFee', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">委任報價金額：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="元"
                        value={estimatedFee}
                        onChange={e => this.onChangeFee('estimatedFee', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">應／未稅：</div>
                      <div className="col-edit">
                        {
                          Object.entries(TAXABLE).map(([key, value]) =>
                            <span key={`isTaxabled-${key}`}>
                              <input type="radio" id={`isTaxabled-${key}`}
                                value={key}
                                checked={isTaxabled === key}
                                onChange={e => this.setState({ isTaxabled: e.target.value })}
                              />
                              <label htmlFor={`isTaxabled-${key}`} className="edit-isTaxabled">{value}</label>
                            </span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">報稅年度：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="年度"
                        value={taxYear}
                        onChange={e => this.onChangeFee('taxYear', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">報稅金額：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="元"
                        value={taxAmount}
                        onChange={e => this.onChangeFee('taxAmount', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">付款日：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={payDate ? moment(payDate, dateFormat) : null}
                        onChange={value => { this.setState({ payDate: value }) }}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">扣繳憑單：</div>
                      <div className="col-edit">
                        {
                          Object.entries(TAX_VOUCHER).map(([key, value]) =>
                            <span key={`taxVoucher-${key}`}>
                              <input type="radio" id={`taxVoucher-${key}`}
                                value={key}
                                checked={taxVoucher === key}
                                onChange={e => this.setState({ taxVoucher: e.target.value })}
                              />
                              <label htmlFor={`taxVoucher-${key}`} className="edit-taxVoucher">{value}</label>
                            </span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">收據：</div>
                      <div className="col-edit">
                        <input type="radio" name="receipt" id="receipt-2" checked={receipt['isHolding'] === false}
                          onChange={e => this.setState({ receipt: { ...receipt, isHolding: false } })}
                        />
                        <label htmlFor="receipt-2" className="edit-receipt">無</label>
                        <input type="radio" name="receipt" id="receipt-1" checked={receipt['isHolding'] === true}
                          onChange={e => this.setState({ receipt: { ...receipt, isHolding: true } })}
                        />
                        <label htmlFor="receipt-1" className="edit-receipt">有</label>
                      </div>
                    </div>  
                  </div>
                </div>
                {
                  receipt['isHolding'] === true
                  &&
                  <div className="col-item-wrap">
                    <div className="col-item-group">
                      <div className="col-item-title">
                        <div>開立收據</div>
                      </div>
                      <div className="col-item">
                        <div className="col-title">開立日期：</div>
                        <DatePicker className="col-edit"
                          size="small"
                          placeholder="請選擇日期"
                          inputReadOnly={true}
                          showToday={false}
                          format={dateFormat}
                          value={receipt['date'] ? moment(receipt['date'], dateFormat) : null}
                          onChange={value => this.onChangeReceipt('date', value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">收據人：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={receipt['name']}
                          onChange={e => this.onChangeReceipt('name', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">請款類別：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={receipt['category']}
                          onChange={e => this.onChangeReceipt('category', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">事由：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={receipt['reason']}
                          onChange={e => this.onChangeReceipt('reason', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">金額：</div>
                        <Input className="col-edit"
                          size="small"
                          type="number"
                          suffix="元"
                          value={receipt['cost']}
                          onChange={e => this.onChangeReceipt('cost', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">備註：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={receipt['note']}
                          onChange={e => this.onChangeReceipt('note', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="col-item-wrap save">
                  <button className="btn-submit" type="submit" disabled={this.props.isPosting}>儲存</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
export default withRouter(CreatConsultationsComponent);
