import React, { Component } from 'react';
import casesReceipt from '../modules/casesReceipt';

class CasesReceiptPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
  	return (
	    <div id="page-casesReceipt">
        <casesReceipt.container caseId={this.props.match.params.caseId} />
	    </div>
	  )
  }
}

export default CasesReceiptPageComponent;
