import React, {Component} from 'react';
import attachment from '../modules/attachment';


class AttachmentPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <div id="page-review">
        <attachment.container
          caseId={this.props.match.params.caseId}/>
      </div>
    )
  }
}

export default AttachmentPageComponent;
