import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'searchExtend/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'searchExtend/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'searchExtend/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg)
                .setIn(['totalHits'], 0)
                .setIn(['hitList'], fromJS([]));
  },
  'searchExtend/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'searchExtend/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'searchExtend/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'searchExtend/UPDATE_CASE_LIST': (state, { payload }) => {
    return state.setIn(['caseList'], fromJS(payload));
  },
  'searchExtend/UPDATE_SEARCH_RESULT': (state, { payload }) => {
    return state.setIn(['totalHits'], payload.totalHits)
                .setIn(['hitList'], fromJS(payload.hitList));
  },
  'searchExtend/UPDATE_LAW_DATA': (state, { payload }) => {
    return state.setIn(['lawData'], fromJS(payload));
  },
}, State);

export default Reducer;