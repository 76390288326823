import React, {Component} from 'react';
import doc from '../modules/doc';


class ReviewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlights: []
    };
  }

  updateHighLights = (highlights) => {
    this.setState({ highlights });
  };

  componentDidMount() {
  }

  render() {
    return (
      <div id="page-review">
        <doc.container
          caseId={this.props.match.params.caseId}
          highlights={this.state.highlights}
          updateHighLights={this.updateHighLights}
        />
      </div>
    )
  }
}

export default ReviewPageComponent;
