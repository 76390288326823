import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {State} from './model';


const Reducer = handleActions({
// common
  "topBar/HTTP_REQUEST": (state, {}) => {
    return state.setIn(['isLoading'], true)
      .setIn(["errMsg"], null);
  },
  "topBar/HTTP_SUCCESS": (state, {}) => {
    return state.setIn(['isLoading'], false)
      .setIn(["errMsg"], null);
  },
  "topBar/HTTP_FAILURE": (state, { payload }) => {
    return state.setIn(['isLoading'], false)
      .setIn(['errMsg'], payload.errMsg);
  },
  'topBar/UPDATE_NOTIFICATION': (state, { payload }) => {
    if (payload) {
      return state.setIn(['notificationList'], fromJS(payload));
    } else {
      return state.setIn(['notificationList'], fromJS([]));
    }
  },
  'topBar/UPDATE_HISTORY': (state, { payload }) => {
    if (payload) {
      return state.setIn(['historyList'], fromJS(payload));
    } else {
      return state.setIn(['historyList'], fromJS([]));
    }
  },
  'topBar/UPDATE_HISTORY_COUNT': (state, { payload }) => {
    if (payload) {
      return state.setIn(['historyCount'], payload);
    } else {
      return state.setIn(['historyCount'], 0);
    }
  },
  "topBar/UPDATE_RWD_NAVBAR": (state, { payload }) => {
    return state.setIn(['showRWDNavbar'], payload);
  },
}, State);

export default Reducer;
