export const AttachmentPopupString = {
  createFolder: {
    className: "modal-createFolder",
    title: "新增資料夾",
    okText: "新增",
    cancelText: "取消",
    okType: "primary",
  },
  uploadFile: {
    className: "modal-uploadFile",
    title: "上傳委任檔案",
    okText: "上傳",
    cancelText: "取消",
    okType: "primary",
  },
  OCRFile: {
    className: "modal-OCRFile",
    title: "辨識委任檔案內容 (OCR)",
    okText: "執行",
    cancelText: "取消",
    okType: "primary",
  },
  OCRFiles: {
    className: "modal-OCRFile",
    title: "辨識多個委任檔案內容 (OCR)",
    okText: "執行",
    cancelText: "取消",
    okType: "primary",
  },
  downloadFile: {
    className: "modal-downloadFile",
    title: "下載委任檔案",
    okText: "下載",
    cancelText: "取消",
    okType: "primary",
  },
  moveFile: {
    className: "modal-moveFile",
    title: "移動檔案至其他資料夾",
    okText: "移動",
    cancelText: "取消",
    okType: "primary",
  },
  deleteFile: {
    className: "modal-deleteFile",
    title: "刪除委任檔案",
    okText: "刪除",
    cancelText: "取消",
    okType: "danger",
  },
};
