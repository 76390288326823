import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'mailroomSentList/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroomSentList/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroomSentList/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroomSentList/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'mailroomSentList/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroomSentList/UPDATE_RECEIVED_LIST': (state, { payload }) => {
    return state.setIn(['sentList'], fromJS(payload));
  },
  'mailroomSentList/UPDATE_DOCKET_LIST': (state, { payload }) => {
    if (payload.length === 0 ) {
      return state.setIn(['isFetching'], false);
    } else {
      const dockets = {};
      const docketNames ={};
      const docketList = [];
      payload.forEach(docket => {
        dockets[docket.id] = docket.name;
        docketNames[docket.id] = docket.custodianName;
        docketList.push(docket.name);
      })
      return state.setIn(['dockets'],  fromJS(dockets))
                  .setIn(['docketNames'],  fromJS(docketNames))
                  .setIn(['docketList'],  fromJS(docketList));
    }
  },
  'mailroomSentList/UPDATE_CSV_ROW_LIST': (state, { payload }) => {
    return state.setIn(['csvRowList'], fromJS(payload));
  },
}, State);

export default Reducer;