import { createSelector } from 'reselect';

export const getPdfPageList = state => state.getIn(['doc', 'pdfPageList']);
export const getPdfPageIndex = state => state.getIn(['doc', 'pdfPageIndex']);
export const getPdfScale = state => state.getIn(['doc', 'pdfScale']);
export const getPageId = createSelector(
  [getPdfPageList, getPdfPageIndex],
  (pdfPageList, pdfPageIndex) => pdfPageList.getIn([pdfPageIndex, 'pageId'])
)
export const getPageUrl = createSelector(
  [getPdfPageList, getPdfPageIndex],
  (pdfPageList, pdfPageIndex) => pdfPageList.getIn([pdfPageIndex, 'pageUrl'])
)
export const getPageText = state => state.getIn(['pdf', 'PageText']);
export const getPageBase64s = state => state.getIn(['pdf', 'pageBase64s']);
export const getCachePageUrl = createSelector(
  [getPdfPageList, getPdfPageIndex, getPageBase64s],
  (pdfPageList, pdfPageIndex, pageBase64s) => {
    if (pdfPageList.size === 0) {
      return null;
    }
    const cachePageUrl = pageBase64s.getIn([pdfPageList.getIn([pdfPageIndex, 'pageId'])])
    if (!cachePageUrl) {
      return ''
    }
    return cachePageUrl
  }
)