import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { message, Input, DatePicker } from 'antd';
import { DATE_FORMAT, APPOINTOR_TYPE } from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const DEFAULT_APPOINTOR = {
  name: '',
  address: '',
  phone: '',
  email: '',
  relationship: '',
  ssid: '',
  birthday: null
}
const DEFAULT_COMPANY = {
  name: '',
  address: '',
  phone: '',
  email: '',
  ban: '',
  representative: '',
  contactPerson: ''
}

class ConsultationAppointComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      appointTypeId: 1,
      consultationDate: moment(new Date(), DATE_FORMAT),
      appointor: DEFAULT_APPOINTOR,
      company: DEFAULT_COMPANY
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleEditMode(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.consultationId !== this.props.consultationId && this.state.editMode === true) {
      this.onToggleEditMode(false);
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value });
  }
  onToggleEditMode(editMode) {
    if (editMode === true) {
      const appointTypeId = this.props.appoint.getIn(['appointTypeId']);
      if (appointTypeId === 1) {
        this.setState({
          editMode,
          appointTypeId,
          consultationDate: this.props.appoint.getIn(['consultationDate']),
          appointor: this.props.appoint.getIn(['appointor']).toJS(),
          company: DEFAULT_COMPANY
        });
      } else {
        this.setState({
          editMode,
          appointTypeId,
          consultationDate: this.props.appoint.getIn(['consultationDate']),
          appointor: DEFAULT_APPOINTOR,
          company: this.props.appoint.getIn(['company']).toJS()
        });
      }
    } else {
      this.setState({ editMode });
    }
    this.props.onChangeIsEditing(editMode);
  }
  onChangeAppointor(key, value) {
    let { appointor } = this.state;
    appointor = JSON.parse(JSON.stringify(appointor));
    appointor[key] = value;
    if( key === 'phone' && appointor['phone'].length > 20) {
      appointor['phone'] = appointor['phone'].slice(0, 20)
    }
    this.setState({ appointor })
  }
  onChangeCompany(key, value) {
    let { company } = this.state;
    company = JSON.parse(JSON.stringify(company));
    company[key] = value;
    this.setState({ company })
  }
  onSubmitAppointor() {
    const { consultationDate, appointor } = this.state;
    if (!consultationDate || !appointor.name) {
      return message.error('請輸入必填欄位，「諮詢日期」、「諮詢人」');
    }
    const birthday = appointor.birthday ? moment(appointor.birthday).format(DATE_FORMAT) : null;
    this.props.onUpdateAppointor(this.props.consultationId, moment(consultationDate).format(DATE_FORMAT), appointor.name, appointor.address, appointor.phone, appointor.email, appointor.relationship, appointor.ssid, birthday);
  }
  onSubmitCompany() {
    const { consultationDate, company } = this.state;
    if (!consultationDate || !company.name) {
      return message.error('請輸入必填欄位，「諮詢日期」、「公司名稱」');
    }
    this.props.onUpdateCompany(this.props.consultationId, moment(consultationDate).format(DATE_FORMAT), company.name, company.address, company.phone, company.email, company.ban, company.representative, company.contactPerson);
  }
  render() {
    const { editMode, appointTypeId, consultationDate, appointor, company } = this.state;
    if (!this.props.appoint || this.props.appoint.size === 0) {
      return null;
    }
    return (
      <div className="consultationappoint-section section-col-item">
        {
          editMode && appointTypeId === 1 ?
            <form onSubmit={e => { e.preventDefault(); this.onSubmitAppointor() }}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>關閉</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>儲存</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">諮詢身份：</div>
                  <div className="col-edit">
                    {
                      Object.entries(APPOINTOR_TYPE).map(([key, value]) =>
                        <span key={`appointtype-${key}`}>
                          <input type="radio" id={`appointtype-${key}`}
                            value={key}
                            checked={`${appointTypeId}` === key}
                            onChange={e => this.onChangeState('appointTypeId', parseInt(e.target.value))}
                          />
                          <label htmlFor={`appointtype-${key}`} className="edit-appointorType">{value}</label>
                        </span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title required">諮詢日期：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={consultationDate ? moment(consultationDate, DATE_FORMAT) : null}
                    onChange={value => this.onChangeState('consultationDate', value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title required">諮詢人：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={appointor.name}
                    onChange={e => this.onChangeAppointor('name', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">委任關係：</div>
                  <Input className="col-edit"
                    size="small"
                    placeholder="與當事人關係"
                    type="text"
                    value={appointor.relationship}
                    onChange={e => this.onChangeAppointor('relationship', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">身分證字號：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={appointor.ssid}
                    onChange={e => this.onChangeAppointor('ssid', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">生日：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={appointor.birthday ? moment(appointor.birthday, DATE_FORMAT) : null}
                    onChange={value => this.onChangeAppointor('birthday', value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={appointor.address}
                    onChange={e => this.onChangeAppointor('address', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={appointor.phone}
                    onChange={e => this.onChangeAppointor('phone', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={appointor.email}
                    onChange={e => this.onChangeAppointor('email', e.target.value)}
                  />
                </div>
              </div>
            </form>
          : editMode && appointTypeId === 2 ?
            <form onSubmit={e => { e.preventDefault(); this.onSubmitCompany() }}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>取消</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>更新</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">諮詢身份：</div>
                  <div className="col-edit">
                    {
                      Object.entries(APPOINTOR_TYPE).map(([key, value]) =>
                        <span key={`appointtype-${key}`}>
                          <input type="radio" id={`appointtype-${key}`}
                            value={key}
                            checked={`${appointTypeId}` === key}
                            onChange={e => this.onChangeState('appointTypeId', parseInt(e.target.value))}
                          />
                          <label htmlFor={`appointtype-${key}`} className="edit-appointorType">{value}</label>
                        </span>
                      )
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title required">諮詢日期：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={consultationDate ? moment(consultationDate, DATE_FORMAT) : null}
                    onChange={value => this.setState({ consultationDate: value })}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title required">公司名稱：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={company.name}
                    onChange={e => this.onChangeCompany('name', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">統一編號：</div>
                  <Input className="col-edit"
                    size="small"
                    type="number"
                    value={company.ban}
                    onChange={e => this.onChangeCompany('ban', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">代表人：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={company.representative}
                    onChange={e => this.onChangeCompany('representative', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡人：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={company.contactPerson}
                    onChange={e => this.onChangeCompany('contactPerson', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={company.address}
                    onChange={e => this.onChangeCompany('address', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={company.phone}
                    onChange={e => this.onChangeCompany('phone', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <Input className="col-edit"
                    size="small"
                    type="text"
                    value={company.email}
                    onChange={e => this.onChangeCompany('email', e.target.value)}
                  />
                </div>
              </div>
            </form>
          : this.props.appoint.getIn(['appointTypeId']) === 1 ?
            <>
              <div className="col-item-tool">
                <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">諮詢身份：</div>
                  <div className="col-text">自然人</div>
                </div>
                <div className="col-item">
                  <div className="col-title">諮詢日期：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['consultationDate'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">諮詢人：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'name'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">委任關係：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'relationship'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">身分證字號：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'ssid'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">生日：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'birthday'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'address'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'phone'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['appointor', 'email'])}
                  </div>
                </div>
              </div>
            </>
          : this.props.appoint.getIn(['appointTypeId']) === 2 ?
            <>
              <div className="col-item-tool">
                <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
              </div>
              <div className="col-item-group">
                <div className="col-item">
                  <div className="col-title">諮詢身份：</div>
                  <div className="col-text">法人</div>
                </div>
                <div className="col-item">
                  <div className="col-title">諮詢日期：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['consultationDate'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">公司名稱：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'name'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">統一編號：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'ban'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">代表人：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'representative'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡人：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'contactPerson'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">住址：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'address'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">聯絡電話：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'phone'])}
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">Email：</div>
                  <div className="col-text">
                    {this.props.appoint.getIn(['company', 'email'])}
                  </div>
                </div>
              </div>
            </>
          :
            null
        }
      </div>
    )
  }
}
export default withRouter(ConsultationAppointComponent);