export const GET_REQUEST = 'pdf/GET_REQUEST'
export const GET_SUCCESS = 'pdf/GET_SUCCESS'
export const GET_FAILURE = 'pdf/GET_FAILURE'

export const PUT_REQUEST = 'pdf/PUT_REQUEST'
export const PUT_SUCCESS = 'pdf/PUT_SUCCESS'
export const PUT_FAILURE = 'pdf/PUT_FAILURE'

export const DELETE_REQUEST = 'pdf/DELETE_REQUEST'
export const DELETE_SUCCESS = 'pdf/DELETE_SUCCESS'
export const DELETE_FAILURE = 'pdf/DELETE_FAILURE'

export const UPDATE_CACHE_PAGE_URL = 'pdf/UPDATE_CACHE_PAGE_URL'
export const UPDATE_CACHE_PAGES = 'pdf/UPDATE_CACHE_PAGES'
export const UPDATE_PAGE_TEXT = 'pdf/UPDATE_PAGE_TEXT'
