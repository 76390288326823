import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, Input } from 'antd';
import { CASE_TYPE } from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { TextArea } = Input;

const APPOINTOR = {
  ssid: '',
  address: '',
  birthday: '',
  email: '',
  name: '',
  phone: '',
  relationship: '',
}
const COMPANY = {
  ban: '',
  address: '',
  contactPerson: '',
  email: '',
  name: '',
  phone: '',
  representative: '',
}
const ITEM = {
  moneyTransfer: '請於後 3 日內匯入本所帳戶（匯款並註明委任人名稱）。本所將以付款紀錄作為同意上開案件之意思表示，並開始為委任案件上開作業程序。',
  item1: '一、',
  item2: '二、',
  item3: '三、本所不留置委任人任何正本文件書面資料。另同意本所代刻私章，用於委任狀及相關書狀。',
  item4: '四、委任人所為陳述及所提證據確係真正，如有虛構，致損害本所權益，聽憑受任人解約，並不退還已付之委任費用；如委任中途片面中止或解除委任，亦同。',
  item5: '五、如非本所律師執業登錄之範圍（台中、台南、高雄、屏東），則律師登錄費用由委任人承擔。',
  item6: '六、其他未盡事宜，依誠信原則履行契約。',
}

class CasesContractComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHolding: false,
      isEditing: false,
      appointorType: '',
      typeId: '',
      appointor: APPOINTOR,
      company: COMPANY,
      lawer: {
        address: '',
        faxNo: '',
        lawFirmName: '',
        lawFirmAccountName: '',
        lawFirmAccountNo: '',
        name: '',
        phoneNo: '',
      },
      item: ITEM,
      date: {
        year: '',
        month: '',
        day: '',
      }
    };
  }

  componentDidMount() {
    this.props.onLoadCasesContract(this.props.caseId);
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.appointorType !== '' && prevProps.isGeting === true && this.props.isGeting === false){
      this.onClickUpdateReceipt('init', this.props.appointorType);
    }
  }
  onClickUpdateReceipt(action, value) {
    if(action === 'init') {
      if(value === null){
        this.setState({ isHolding: false })
      }else{
        let appointor = JSON.parse(JSON.stringify(this.props.appointor));
        let company = JSON.parse(JSON.stringify(this.props.company));
        let lawer = JSON.parse(JSON.stringify(this.props.lawer));
        this.setState({
          isHolding: true,
          appointorType: value,
          typeId: this.props.typeId,
          lawer,
        })
        if (value === 1) {
          this.setState({
            appointor,
            company,
          })
        }
      }
    }
    if(action === 'edit') {
      this.setState({
        isEditing: false,
      })
    }
    if(action === 'save') {
      this.setState({
        isEditing: true,
      })
    }
  }

  onChangeAppointor(key, value){
    let { appointor } = this.state;
    appointor = JSON.parse(JSON.stringify(appointor));
    appointor[key] = value;
    this.setState({ appointor })
  }
  onChangeCompany(key, value) {
    let { company } = this.state;
    company = JSON.parse(JSON.stringify(company));
    company[key] = value;
    this.setState({ company })
  }
  onChangeLawer(key, value){
    let { lawer } = this.state;
    lawer = JSON.parse(JSON.stringify(lawer));
    lawer[key] = value;
    this.setState({ lawer })
  }
  onChangeItem(key, value) {
    let { item } = this.state;
    item = JSON.parse(JSON.stringify(item));
    item[key] = value;
    this.setState({ item })
  }

  render() {
    const { isHolding, isEditing, item, date, appointor, company, currentDate, lawer } = this.state;
    const { appointorType, typeId } = this.props;
  	return (
      <div className="casesContract-section casesContract">
        <div className="casesContract-report">
          <div className="navBar noPrint">
            <button><Link to={`/cases`}>返回委任管理列表</Link></button>
            <div className="title">委任契約書預覽列印</div>
          </div>
          {
            isHolding
            ?
            <div className="case-receipt-wrap">
              <div className="tool noPrint">
                {
                  isEditing
                  ?
                  <button onClick={e => this.onClickUpdateReceipt('edit', '')}>編輯</button>
                  :
                  <button onClick={e => this.onClickUpdateReceipt('save', '')}>預覽列印</button>
                }
              </div>
              {
                isEditing
                ?
                <div className="case-receipt">
                  <div className="case-receipt-title">
                    {
                      CASE_TYPE && CASE_TYPE.map(type => {
                        if(type['id'] === typeId){ return type['name'] }
                      })
                    }
                    委任契約書
                  </div>
                  <div className="case-receipt-row">
                    {
                      appointorType === 1
                      &&
                      <div className="item-col">
                        <div className="item">
                          <div className="title">當事人：</div>
                          <div className="content">{appointor.name}</div>
                        </div>
                        <div className="item">
                          <div className="title">委任人：</div>
                          <div className="content">{appointor.name}</div>
                        </div>
                      </div>
                    }
                    {
                      appointorType === 2
                      &&
                      <div className="item-col">
                        <div className="item">
                          <div className="title">當事人：</div>
                          <div className="content">
                            {company.name}<br />
                            {company.representative}
                          </div>
                        </div>
                        <div className="item">
                          <div className="title">委任人：</div>
                          <div className="content">{company.contactPerson}</div>
                        </div>
                      </div>
                    }
                    <div className="item-col">
                      <div className="item">
                        <div className="title">日期：</div>
                        <div className="content">{currentDate}</div>
                      </div>
                      <div className="item">
                        <div className="title">委任電話：</div>
                        <div className="content">
                          {
                            appointorType === 1 ? 
                              appointor.phone
                            : appointorType === 2 ?
                              company.phone
                            :
                              null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-address">
                    <div className="item">
                      <div className="title">收件地址：</div>
                      <div className="content">
                        { appointorType === 1 ? appointor.address : null }
                        { appointorType === 2 ? company.address : null }
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-content">
                    <div className="textArea">
                      {/* <div className="itemNo">一、</div> */}
                      <div className="itemTxt">{item.item1}</div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">二、</div> */}
                      <div className="itemTxt">
                        {item.item2}
                        <div className="txt">
                          {item.moneyTransfer}<br />
                          {lawer.lawFirmAccountName}<br />
                          {lawer.lawFirmAccountNo}<br />
                        </div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">三、</div> */}
                      <div className="itemTxt">
                        <div className="txt">{item.item3}</div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">四、</div> */}
                      <div className="itemTxt">
                        <div className="txt">{item.item4}</div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">五、</div> */}
                      <div className="itemTxt">
                        <div className="txt">{item.item5}</div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">六、</div> */}
                      <div className="itemTxt">
                        <div className="txt">{item.item6}</div>
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-sign">
                    <div className="item-col">
                      <div className="item">
                        <div className="title">受任人：</div>
                        <div className="content">{lawer.name}  律師</div>
                      </div>
                    </div>
                    <div className="item-col">
                      <div className="item">
                        <div className="title">委任人：</div>
                        <div className="content">{appointor.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-date">
                    <span>中華民國</span>
                    <span>{date['year']}</span>
                    <span>年</span>
                    <span>{date['month']}</span>
                    <span>月</span>
                    <span>{date['day']}</span>
                    <span>日</span>
                  </div>
                </div>
                :
                <div className="case-receipt">
                  <div className="case-receipt-title">
                    {
                      CASE_TYPE && CASE_TYPE.map(type => {
                        if(type['id'] === typeId){ return type['name'] }
                      })
                    }
                    委任契約書
                  </div>
                  <div className="case-receipt-row">
                    {
                      appointorType === 1
                      &&
                      <div className="item-col">
                        <div className="item">
                          <div className="title">當事人：</div>
                          <Input className="content"
                            placeholder="當事人"
                            allowClear
                            type="text"
                            value={appointor.name}
                            onChange={e => this.onChangeAppointor('name', e.target.value)}
                          />
                        </div>
                        <div className="item">
                          <div className="title">委任人：</div>
                          <Input className="content"
                            placeholder="委任人"
                            allowClear
                            type="text"
                            value={appointor.name}
                            onChange={e => this.onChangeAppointor('name', e.target.value)}
                          />
                        </div>
                      </div>
                    }
                    {
                      appointorType === 2
                      &&
                      <div className="item-col">
                        <div className="item">
                          <div className="title">當事人：</div>
                          <div className="content">
                            <Input className="content"
                              placeholder="當事人"
                              allowClear
                              type="text"
                              value={company.name}
                              onChange={e => this.onChangeCompany('name', e.target.value)}
                            /><br />
                            <Input className="content"
                              placeholder="代表人"
                              allowClear
                              type="text"
                              value={company.representative}
                              onChange={e => this.onChangeCompany('representative', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="item">
                          <div className="title">委任人：</div>
                          <div className="content">
                            <Input className="content"
                              placeholder="委任人"
                              allowClear
                              type="text"
                              value={company.contactPerson}
                              onChange={e => this.onChangeCompany('contactPerson', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    <div className="item-col">
                      <div className="item">
                        <div className="title">日期：</div>
                        <Input className="input-"
                          placeholder="日期"
                          allowClear
                          type="text"
                          value={currentDate}
                          onChange={e => this.setState({ currentDate: e.target.value })}
                        />
                      </div>
                      <div className="item">
                        <div className="title">委任電話：</div>
                        <div className="content">
                          {
                            appointorType === 1 ?
                              <Input className="input-"
                                placeholder="收件電話"
                                allowClear
                                type="number"
                                value={appointor.phone}
                                onChange={e => this.onChangeAppointor('phone', e.target.value)}
                              />
                            : appointorType === 2 ?
                              <Input className="input-"
                                placeholder="收件電話"
                                allowClear
                                type="number"
                                value={company.phone}
                                onChange={e => this.onChangeCompany('phone', e.target.value)}
                              />
                            :
                              null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-address">
                    <div className="item">
                      <div className="title">收件地址：</div>
                      <div className="content">
                        {
                          appointorType === 1 ?
                            <Input className="content"
                              placeholder="收件地址"
                              allowClear
                              type="text"
                              value={appointor.address}
                              onChange={e => this.onChangeAppointor('address', e.target.value)}
                            />
                          : appointorType === 2 ?
                            <Input className="content"
                              placeholder="收件地址"
                              allowClear
                              type="text"
                              value={appointor.address}
                              onChange={e => this.onChangeCompany('address', e.target.value)}
                            />
                          : 
                            null
                        }
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-content">
                    <div className="textArea">
                      {/* <div className="itemNo">一、</div> */}
                      <div className="itemTxt">
                        <TextArea rows={4}
                          value={item.item1}
                          onChange={e => this.onChangeItem('item1', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">二、</div> */}
                      <div className="itemTxt">
                        <TextArea rows={6}
                          value={item.item2}
                          onChange={e => this.onChangeItem('item2', e.target.value)}
                        />
                        <div className="txt">
                          <TextArea rows={2}
                            value={item.moneyTransfer}
                            onChange={e => this.onChangeItem('moneyTransfer', e.target.value)}
                          /><br />
                          <TextArea rows={1}
                            value={lawer.lawFirmAccountName}
                            placeholder="戶名"
                            onChange={e => this.onChangeLawer('lawFirmAccountName', e.target.value)}
                          /><br />
                          <TextArea rows={1}
                            placeholder="帳號"
                            value={lawer.lawFirmAccountNo}
                            onChange={e => this.onChangeLawer('lawFirmAccountNo', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">三、</div> */}
                      <div className="itemTxt">
                        <div className="txt">
                          <TextArea rows={2}
                            value={item.item3}
                            onChange={e => this.onChangeItem('item3', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">四、</div> */}
                      <div className="itemTxt">
                        <div className="txt">
                          <TextArea rows={2}
                            value={item.item4}
                            onChange={e => this.onChangeItem('item4', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">五、</div> */}
                      <div className="itemTxt">
                        <div className="txt">
                          <TextArea rows={2}
                            value={item.item5}
                            onChange={e => this.onChangeItem('item5', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textArea">
                      {/* <div className="itemNo">六、</div> */}
                      <div className="itemTxt">
                        <div className="txt">
                          <TextArea rows={2}
                            value={item.item6}
                            onChange={e => this.onChangeItem('item6', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-sign">
                    <div className="item-col">
                      <div className="item">
                        <div className="title">受任人：</div>
                        <div className="content">
                          <Input className="input-"
                            allowClear
                            type="text"
                            value={lawer.name}
                            suffix="律師"
                            onChange={e => this.setState({ lawer: { name: e.target.value, ...lawer } })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item-col">
                      <div className="item">
                        <div className="title">委任人：</div>
                        <Input className="content"
                          placeholder="委任人"
                          allowClear
                          type="text"
                          value={appointor.name}
                          onChange={e => this.onChangeAppointor('name', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-date">
                    <span className="text">中華民國</span>
                    <span className="input">
                      <Input className="input"
                        placeholder="年度"
                        allowClear
                        type="number"
                        value={date['year']}
                        onChange={e => this.setState({ date: { ...date, year: e.target.value} })}
                      />
                    </span>
                    <span className="text">年</span>
                    <span className="input">
                      <Input className="input"
                        placeholder="月份"
                        allowClear
                        type="number"
                        value={date['month']}
                        onChange={e => this.setState({ date: { ...date, month: e.target.value} })}
                      />
                    </span>
                    <span className="text">月</span>
                    <span className="input">
                      <Input className="input"
                        placeholder="日"
                        allowClear
                        type="number"
                        value={date['day']}
                        onChange={e => this.setState({ date: { ...date, day: e.target.value} })}
                      />
                    </span>
                    <span className="text">日</span>
                  </div>
                </div>
              }
            </div>
            :
            <div className="case-receipt-tip">此筆委任尚無委任契約書</div>
          }
        </div>
      </div>
	  )
  }
}
export default withRouter(CasesContractComponent);