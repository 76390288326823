import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import * as docActions from '../doc/actions';
import AppointmentFilesComponent from './component';
import * as docSelectors from '../doc/selectors';

const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  isPutting: selectors.getIsPutting(state),
  isDeleting: selectors.getIsDeleting(state),
  errMsg: selectors.getErrMsg(state),
  docIsPosting: docSelectors.getIsPosting(state),
  docErrMsg: docSelectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onUploadUserFile: (appointmentId, userFolderId, fileList) => {
    dispatch(actions.doUploadUserFile(appointmentId, userFolderId, fileList));
  },
  onMoveUserFile: (userFolderId, userFileId) => {
    dispatch(actions.doMoveUserFile(userFolderId, userFileId));
  },
  onDownloadFile: (fileId, fileName) => {
    dispatch(actions.doDownloadFile(fileId, fileName));
  },
  onDeleteFile: (fileId, appointmentId) => {
    dispatch(actions.doDeleteFile(fileId, appointmentId));
  },
  onSubmitUpdateFileName: (fileId, name) => {
    dispatch(actions.doSubmitUpdateFileName(fileId, name));
  },
  onSubmitUploadFiles: (caseId, uploadFileList) => {
    dispatch(docActions.doSubmitUploadFiles(caseId, uploadFileList));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentFilesComponent);