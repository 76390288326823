import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ExportConsultationsComponent from './component';


const mapStateToProps = (state) => ({
  // common
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),  
  caseRelateList: selectors.getCaseRelateList(state),
  isFetching: selectors.getIsFetching(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadRelatedList: (contain, size) => {
    dispatch(actions.doLoadRelatedList(contain, size));
  },
  onSubmitCreateCase: (caseName, relatedList, plaintiffList, defendantList, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, instance, custodian, typeId, fileTypeId, uploadFileList) => {
    dispatch(actions.doSubmitCreateCase(caseName, relatedList, plaintiffList, defendantList, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, instance, custodian, typeId, fileTypeId, uploadFileList));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportConsultationsComponent);