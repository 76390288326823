import fetch from 'isomorphic-fetch';
import * as config from '../config'
import { Base64 } from 'js-base64';
let fileDownload = require('js-file-download');


export function request(method, url, data={}) {
  method = method.toUpperCase();
  let body;
  if (method === 'GET') {
      body = undefined;
  } else {
      body = data && JSON.stringify(data);
  }
  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
  const token = localStorage.getItem('token');
  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }
  return (
    fetch(url, {
      method,
      headers,
      body
    })
    .then((response) => {
      return response.json()
        .then((json) => {
          return ({ response, json });
        });

    })
    .then(({ response, json }) => {
      if (response.ok) {
        return json;
      } else {
        if (response.status === 401) {
          if(method !== 'POST' && url !== `${config.API_ENDPOINT}/rest/auth/token` ) {
            return window.location.href = `${config.logoutPage}`;
          }
        }
        if (json.hasOwnProperty('msg')) {
          throw json.msg;
        } else {
          throw config.DEFAULT_ERROR_MSG;
        }
      }
    })
    .catch((err) => {
      throw err;
    })
  )
}

export function requestUpload(method, url, data, fileList=null) {
  method = method.toUpperCase();
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach(value => formData.append(key, value));
    } else {
      formData.append(key, data[key]);
    }
  })
  if (fileList) {
    fileList.forEach(file_ => {
      if (file_.hasOwnProperty('apiKey')) {
        formData.append(file_.apiKey, file_.file);
      } else {
        formData.append('files[]', file_);
      }
    });
  }
  let headers = {
    // 'Content-Type': 'multipart/form-data', // 加了這行反而 Django 收不到...
    'Accept': 'application/json'
  }
  const token = localStorage.getItem('token');
  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }
  return (
    fetch(url, {
      method,
      headers,
      body: formData
    })
    .then((response) => {
      return response.json()
        .then((json) => {
          return ({ response, json });
        });
    })
    .then(({ response, json }) => {
      if (response.ok) {
        return json;
      } else {
        if (json.hasOwnProperty('msg')) {
          throw json.msg;
        } else {
          throw config.DEFAULT_ERROR_MSG;
        }
      }
    })
    .catch((err) => {
      throw err;
    })
  )
}

export function requestDownload(method, url, fileName, data={}) {
  method = method.toUpperCase();
  let body;
  if (method === 'GET') {
      body = undefined;
  } else {
      body = data && JSON.stringify(data);
  }
  let headers = {
    'Content-Type': 'application/json'
  }
  const token = localStorage.getItem('token');
  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }
  return (
    fetch(url, {
      method,
      headers,
      body
    })
    .then((response) => {
      if (response.ok) {
        var contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition) {
          console.log(contentDisposition)
          if (contentDisposition.startsWith('=?utf-8?b?')) {
            contentDisposition = contentDisposition.substring(10, contentDisposition.length-2);
            console.log(contentDisposition)
            contentDisposition = Base64.decode(contentDisposition);
            console.log(contentDisposition)
          }
          console.log(contentDisposition)
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
          }
          return response.blob().then((blob_) => {
            fileDownload(blob_, fileName);
            return ({ response, json: {} });
          });
        }
      }
      return response.json().then((json) => {
        return ({ response, json });
      });
    })
    .then(({ response, json }) => {
      if (response.ok) {
        return;
      } else {
        if (json.hasOwnProperty('msg')) {
          throw json.msg;
        } else {
          throw config.DEFAULT_ERROR_MSG;
        }
      }
    })
    .catch((err) => {
      console.log(err)
      throw err;
    })
  )
}


// registration
export const submitApply = (email, password, lastName, firstName, lawOffice, phoneNo, mobile) => request('POST', `${config.API_ENDPOINT}/rest/users/register`, { email, password, lastName, firstName, lawOffice, phoneNo, mobile });
// auth
export const createToken = (account, password) => request('POST', `${config.API_ENDPOINT}/rest/auth/token`, { email: account, password });
export const checkToken = () => request('GET', `${config.API_ENDPOINT}/rest/auth/token`);
export const snedSmsToken = () => request('GET', `${config.API_ENDPOINT}/rest/auth/sms`);
export const checkSmsToken = (smsToken) => request('POST', `${config.API_ENDPOINT}/rest/auth/sms`, { smsToken });
export const agreeTermsOfUse = (userId) => request('POST', `${config.API_ENDPOINT}/rest/users/${userId}/terms-of-use`, {});
// passwordReset
export const sendForgotPasswordEmail = (email) => request('POST', `${config.API_ENDPOINT}/rest/users/forgot-password`, { email });
export const passwordReset = (userId, newPassword) => request('PUT', `${config.API_ENDPOINT}/rest/users/${userId}/password`, { newPassword });
// profile
export const getProfile = (userId) => request('GET', `${config.API_ENDPOINT}/rest/users/${userId}`);
export const updateProfile = (userId, firstName, lastName, address, phoneNo, mobile, lawFirmAccountName, lawFirmAccountNo, twoFactorAuthenticationId, oldPassword, newPassword) => request('PUT', `${config.API_ENDPOINT}/rest/users/${userId}`, { firstName, lastName, address, phoneNo, mobile, lawFirmAccountName, lawFirmAccountNo, twoFactorAuthenticationId, password: oldPassword, newPassword });
export const updateLogo = (userId, file) => requestUpload('POST', `${config.API_ENDPOINT}/rest/users/${userId}/logo`, {}, file);
export const updatePassword = (userId, oldPassword, newPassword) => request('PUT', `${config.API_ENDPOINT}/rest/users/${userId}`, { password: oldPassword, newPassword });
// consultationsForm
export const getLawyer = (pincode) => request('GET', `${config.API_ENDPOINT}/rest/consultations/reserve/${pincode}`);
export const consultationsForm_a = (pincode, appointorType, typeId, plaintiffList, defendantList, appointor, consultationContent) => request('POST', `${config.API_ENDPOINT}/rest/consultations/reserve/${pincode}`, { appointorType, typeId, plaintiffList, defendantList, appointor, consultationContent });
export const consultationsForm_c = (pincode, appointorType, typeId, plaintiffList, defendantList, company, consultationContent) => request('POST', `${config.API_ENDPOINT}/rest/consultations/reserve/${pincode}`, { appointorType, typeId, plaintiffList, defendantList, company, consultationContent });
// pageDuplicates
export const getCategories = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/categories`);
export const getDuplicates = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/duplicates`);
// appointment
export const getAppointmentList = (name, text) => request('POST', `${config.API_ENDPOINT}/rest/cases/search`, { name, text });
export const deleteAppointment = (caseId) => request('DELETE', `${config.API_ENDPOINT}/rest/cases/${caseId}`);
export const getAppointmentDetail = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}`);
export const updateAppointmentAppointor = (caseId, appointDate, name, address, phone, email, relationship, ssid, birthday) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/appointor`, { appointDate, name, address, phone, email, relationship, ssid, birthday });
export const updateAppointmentCompany = (caseId, appointDate, name, address, phone, email, ban, representative, contactPerson) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/company`, { appointDate, name, address, phone, email, ban, representative, contactPerson });
export const updateAppointmentCustodian = (caseId, name, address, phone, email, relationship, ssid, birthday, no) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/custodian`, { name, address, phone, email, relationship, ssid, birthday, no });
export const updateCompanyCustodian = (caseId, name, ban, representative, contactPerson, address, phone, email, relationship) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/companyCustodian`, { name, ban, representative, contactPerson, address, phone, email, relationship });
export const updateAppointmentCase = (caseId, name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/case`, { name, appointNoId, typeId, statusId, relatedList, keywordList, plaintiffList, defendantList, lawNo, appointContent, appointNote });
export const updateAppointmentFee = (caseId, isTaxabled, taxAmount, appointFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/fee`, { isTaxabled, taxAmount, appointFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason });
export const updateAppointmentDates = (caseId, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/dates`, { courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate });
export const uploadUserFile = (caseId, userFolderId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/user-files/cases/files`, { caseId, userFolderId }, fileList);
export const moveUserFile = (userFolderId, userFileId) => request('PUT', `${config.API_ENDPOINT}/rest/user-files/cases/files/${userFileId}/move`, { userFolderId });
export const downloadUserFile = (userFileId, userFileName) => requestDownload('GET', `${config.API_ENDPOINT}/rest/user-files/cases/files/${userFileId}`, userFileName);
export const downloadUserAllFile = (appointmentId) => requestDownload('POST', `${config.API_ENDPOINT}/rest/cases/${appointmentId}/export/user-files`);
export const deleteDocket = (docketId) => request('DELETE', `${config.API_ENDPOINT}/rest/dockets/${docketId}`);
export const deleteUserFile = (userFileId) => request('DELETE', `${config.API_ENDPOINT}/rest/user-files/cases/files/${userFileId}`);
export const updateUserFileName = (fileId, name) => request('PUT', `${config.API_ENDPOINT}/rest/user-files/cases/files/${fileId}/rename`, { name });
export const getOauthUrl = (caseId) => request('POST', `${config.API_ENDPOINT}/rest/auth/googleDrive`, { caseId });
export const postBackupUserFile = (caseId, code) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/export/google`, { code });
// case
export const casesSearches = (name, text) => request('POST', `${config.API_ENDPOINT}/rest/cases/search`, { name, text });
export const editCase_a = (caseId, appointNoId, name, appointDate, appointorType, appointor, custodian, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}`, { appointNoId, name, appointDate, appointorType, appointor, custodian, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList });
export const editCase_c = (caseId, appointNoId, name, appointDate, appointorType, company, custodian, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}`, {appointNoId, name, appointDate, appointorType, company, custodian, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList });
export const createCase_a = (appointNoId, name, appointDate, appointorType, appointor, custodianAppointor, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseNoId, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/cases`, { appointNoId, name, appointDate, appointorType, appointor: JSON.stringify(appointor), custodian: JSON.stringify(custodianAppointor), receipt: JSON.stringify(receipt), appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseNoId, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList: JSON.stringify(docketList) });
export const createCase_c = (appointNoId, name, appointDate, appointorType, company, custodianCompany, receipt, appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseNoId, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/cases`, { appointNoId, name, appointDate, appointorType, company: JSON.stringify(company), companyCustodian: JSON.stringify(custodianCompany), receipt: JSON.stringify(receipt), appointFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseNoId, statusId, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, closeDate, appointContent, appointNote, docketList: JSON.stringify(docketList) });
export const casesRelated = (contain, size) => request('POST', `${config.API_ENDPOINT}/rest/cases/related`, { contain, size });
export const getCase = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}`);
export const getCaseList = () => request('GET', `${config.API_ENDPOINT}/rest/cases`);
export const checkCustodianName = (name) => request('POST', `${config.API_ENDPOINT}/rest/cases/custodianConflict`, { name });
export const isExceededUploadQuota = (userId, fileSize) => request('POST', `${config.API_ENDPOINT}/rest/users/${userId}/quotas/upload`, { fileSize });
export const createCaseFiles = (caseId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/files`, {}, fileList);
export const changeSequence =(caseId, fileId, newSerialNumber) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/files/${fileId}/sequence`, { sequence: newSerialNumber });
export const getFolderList = () => request('GET', `${config.API_ENDPOINT}/rest/user-files/folders`);
export const getCaseFiles = () => request('GET', `${config.API_ENDPOINT}/rest/user-files/cases/files`);
export const uploadCaseFile = (caseId, userFolderId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/user-files/cases/files`, { caseId, userFolderId }, fileList);
export const downloadCaseFile = (fileId, fileName) => requestDownload('GET', `${config.API_ENDPOINT}/rest/user-files/cases/files/${fileId}`, fileName);
export const deleteCaseFile = (fileId) => request('DELETE', `${config.API_ENDPOINT}/rest/user-files/cases/files/${fileId}`);
export const creatDocket = (caseId, word, courts, ext, level, no, trial, unit, year) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/docket`, { word, courts, ext, level, no, trial, unit, year });
export const updateDocket = (caseId, id, word, courts, ext, level, no, trial, unit, year) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/docket`, { id, word, courts, ext, level, no, trial, unit, year });
export const delDocket = (caseId, id) => request('DELETE', `${config.API_ENDPOINT}/rest/cases/${caseId}/docket`, {id});
// attachment
export const getCaseFilesById = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/user-files/cases/${caseId}/categories`);
export const createCaseFileFolder = (name) => request('POST', `${config.API_ENDPOINT}/rest/user-files/folders`, {name});
export const searchCaseFiles = (caseId, searchCriteria) => request('POST', `${config.API_ENDPOINT}/rest/user-files/cases/files/${caseId}/search`, { searchCriteria });
export const getCaseFileUrl = (fileId) => request('GET', `${config.API_ENDPOINT}/rest/user-files/cases/${fileId}/url`);
export const getCaseFileText = (fileId) => request('GET', `${config.API_ENDPOINT}/rest/user-files/cases/${fileId}/text`);
export const getCaseFileBase64 = (fileId) => request('GET', `${config.API_ENDPOINT}/rest/user-files/cases/${fileId}/b64`);
// notification
export const getNotification = () => request('GET', `${config.API_ENDPOINT}/rest/notification`);
export const getNotificationHistory = (page) => request('GET', `${config.API_ENDPOINT}/rest/notification/history/${page}`);
export const updateNotificationStatus = (messageId) => request('POST', `${config.API_ENDPOINT}/rest/notification`, {messageId});
// caseEdit
export const caseEdit = (caseId, name, type_, statusId) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}`, { name, 'type': type_, statusId });
export const caseDelete = (caseId) => request('DELETE', `${config.API_ENDPOINT}/rest/cases/${caseId}`);
// exportReceipt
export const updateReceipt = (caseId, category, cost, date, name, note, reason) => request('PUT', `${config.API_ENDPOINT}/rest/cases/${caseId}/receipt`, { category, cost, date, name, note, reason });
export const getReceipt = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/receipt`);
// powerOfAttorney委任狀
export const getPowerOfAttorney = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/contract`);
// consultations
export const consultRecordSearch = (name, startDay, endDay) => request('POST', `${config.API_ENDPOINT}/rest/consultations/search`, { name, startDay, endDay });
export const getConsultRecordList = () => request('GET', `${config.API_ENDPOINT}/rest/consultations`);
export const getConsultRecordUrl = (fileId) => request('GET', `${config.API_ENDPOINT}/rest/consultations/files/${fileId}`);
export const editConsultRecord_a = (consultationsId, consultationDate, appointorType, appointor, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, consultationContent, consultationNote) => request('PUT', `${config.API_ENDPOINT}/rest/consultations/${consultationsId}`, {consultationDate, appointorType, appointor, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, consultationContent, consultationNote});
export const editConsultRecord_c = (consultationsId, consultationDate, appointorType, company, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, consultationContent, consultationNote) => request('PUT', `${config.API_ENDPOINT}/rest/consultations/${consultationsId}`, {consultationDate, appointorType, company, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, consultationContent, consultationNote});
export const appointConsultation = (consultationId, appointName, appointNoId) => request('POST', `${config.API_ENDPOINT}/rest/consultations/${consultationId}/appoint`, { caseName: appointName, appointNoId });
// createConsultations
export const createConsultations_a = (consultationDate, appointorType, appointor, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote) => request('POST', `${config.API_ENDPOINT}/rest/consultations`, { consultationDate, appointorType, appointor: JSON.stringify(appointor), receipt: JSON.stringify(receipt), consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote });
export const createConsultations_c = (consultationDate, appointorType, company, receipt, consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote) => request('POST', `${config.API_ENDPOINT}/rest/consultations`, { consultationDate, appointorType, company: JSON.stringify(company), receipt: JSON.stringify(receipt), consultFee, estimatedFee, isTaxabled, taxYear, taxAmount, payDate, taxVoucher, caseStatus, plaintiffList, defendantList, typeId, relatedList, keywordList, consultationContent, consultationNote });
// consultations
export const getConsultationList = (name, startDay, endDay) => request('POST', `${config.API_ENDPOINT}/rest/consultations/search`, { name, startDay, endDay });
export const getConsultationDetail = (consultationId) => request('GET', `${config.API_ENDPOINT}/rest/consultations/${consultationId}`);
export const deleteConsultation = (consultationId) => request('DELETE', `${config.API_ENDPOINT}/rest/consultations/${consultationId}`);
export const updateConsultationAppointor = (consultationId, consultationDate, name, address, phone, email, relationship, ssid, birthday) => request('PUT', `${config.API_ENDPOINT}/rest/consultations/${consultationId}/appointor`, { consultationDate, name, address, phone, email, relationship, ssid, birthday });
export const updateConsultationCompany = (consultationId, consultationDate, name, address, phone, email, ban, representative, contactPerson) => request('PUT', `${config.API_ENDPOINT}/rest/consultations/${consultationId}/company`, { consultationDate, name, address, phone, email, ban, representative, contactPerson });
export const updateConsultationCase = (consultationId, typeId, statusId, relatedList, plaintiffList, defendantList, consultationContent, consultationNote) => request('PUT', `${config.API_ENDPOINT}/rest/consultations/${consultationId}/case`, { typeId, statusId, relatedList, plaintiffList, defendantList, consultationContent, consultationNote });
export const updateConsultationFee = (consultationId, isTaxabled, taxAmount, consultFee, estimatedFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason) => request('PUT', `${config.API_ENDPOINT}/rest/consultations/${consultationId}/fee`, { isTaxabled, taxAmount, consultFee, estimatedFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason });
export const uploadConsultationFile = (consultationId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/user-files/consultations/files`, { consultationId }, fileList);
export const deleteConsultationFile = (consultationFileId) => request('DELETE', `${config.API_ENDPOINT}/rest/user-files/consultations/${consultationFileId}`);
export const downloadConsultationFile = (consultationFileId, consultationFileName) => requestDownload('GET', `${config.API_ENDPOINT}/rest/user-files/consultations/${consultationFileId}`, consultationFileName);
export const updateConsultationFileName = (fileId, name) => request('PUT', `${config.API_ENDPOINT}/rest/user-files/consultations/files/${fileId}/rename`, { name });
// appoint
export const getConsultationHistory = (appointId) => request('GET', `${config.API_ENDPOINT}/rest/appointments/${appointId}/consultations`);
export const appointSearch = (name, startDay, endDay) => request('POST', `${config.API_ENDPOINT}/rest/appointments/search`, { name, startDay, endDay });
export const createAppoint = (appointNoId, name, appointedDate, appointor, custodian, receipt, appointFee, taxYear, payDate, taxVoucher, caseNoId, caseStatus, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, consultationContent, consultationNote, instance, uploadFileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/appointments`, { appointNoId, name, appointedDate, appointor: JSON.stringify(appointor), custodian: JSON.stringify(custodian), receipt: JSON.stringify(receipt), appointFee, taxYear, payDate, taxVoucher, caseNoId, caseStatus, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, consultationContent, consultationNote, instance: JSON.stringify(instance) }, uploadFileList);
export const getAppointList = () => request('GET', `${config.API_ENDPOINT}/rest/appointments`);
export const editAppoint = (appointId, appointNoId, name, appointedDate, appointor, custodian, receipt, appointFee, taxYear, payDate, taxVoucher, caseNoId, caseStatus, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, consultationContent, consultationNote, instance) => request('PUT', `${config.API_ENDPOINT}/rest/appointments/${appointId}`, { appointNoId, name, appointedDate, appointor, custodian, receipt, appointFee, taxYear, payDate, taxVoucher, caseNoId, caseStatus, plaintiffList, defendantList, typeId, relatedList, lawNo, keywordList, courtDay, appealLastDay, lastDayFirst, lastDaySecond, consultationContent, consultationNote, instance});
export const getAppointUrl = (fileId) => request('GET', `${config.API_ENDPOINT}/rest/appointments/files/${fileId}`);
// notes 委任筆記預覽列印
export const getNotes = (previewNotesId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${previewNotesId}/previewNote`);
// review
export const getCaseCategories = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/categories`);
export const exportCase = (caseId, fileName) => requestDownload('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/export/files`, fileName);
export const exportFile = (fileId, catalog, fileName) => requestDownload('POST', `${config.API_ENDPOINT}/rest/files/${fileId}/export`, fileName, { catalog });
export const exportTypes = (caseId, fileName) => requestDownload('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/export/page-types`, fileName);
export const exportSingleTypes = (pageTypeIdList, fileName) => requestDownload('POST', `${config.API_ENDPOINT}/rest/page-types/export`, fileName, { pageTypeIdList });
export const exportTags = (caseId, fileName) => requestDownload('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/export/tags`, fileName);
export const createDocSearch = (caseId, searchWord) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/search`, { caseId, searchWord });
// fileEdit
export const fileEdit = (fileId, fileTitle) => request('PUT', `${config.API_ENDPOINT}/rest/files/${fileId}`, { fileTitle });
export const fileDelete = (fileId) => request('DELETE', `${config.API_ENDPOINT}/rest/files/${fileId}`);
export const pageTypeEdit = (pageTypeIdList, newPageType) => request('PUT', `${config.API_ENDPOINT}/rest/page-types`, { pageTypeIdList, pageTypeName: newPageType });
export const tagAdd = (pageTypeId, addTag) => request('POST', `${config.API_ENDPOINT}/rest/page-types/${pageTypeId}/tags`, { tagName: addTag });
export const tagEditTab = (tagId, newTag) => request('PUT', `${config.API_ENDPOINT}/rest/tags/${tagId}`, { tagName: newTag });
export const tagEdit = (tagId, newTag, pageTypeId) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/${pageTypeId}/tags`, { tagId, tagName: newTag });
export const tagMigrateEdit = (pageTypeId, fromTagId, toTagName) => request('POST', `${config.API_ENDPOINT}/rest/page-types/${pageTypeId}/tags/migrate`, { fromTagId, toTagName });
export const addKeyword = (caseId, keyword) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/keywords`, { keyword });
export const breakPageType = (pageTypeId, pageTypeList) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/${pageTypeId}/break`, { pageTypeList });
export const mergePageType = (pageTypeId) => request('POST', `${config.API_ENDPOINT}/rest/page-types/${pageTypeId}/merge`);
// pdf
// export const getPageAnnotation = (pageId) => request('GET', `${config.API_ENDPOINT}/rest/pages/${pageId}/annotations`);
export const getCaseAnnotation = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/annotations`);
export const createHighlight = (pageId, highlight) => request('POST', `${config.API_ENDPOINT}/rest/pages/${pageId}/highlights`, { highlight });
export const deleteHighlights = (pageId) => request('DELETE', `${config.API_ENDPOINT}/rest/pages/${pageId}/highlights`);
export const getPageUrl = (pageId) => request('GET', `${config.API_ENDPOINT}/rest/pages/${pageId}/url`);
export const getPageText = (pageId) => request('GET', `${config.API_ENDPOINT}/rest/pages/${pageId}/text`);
export const getPageBase64 = (pageId) => request('GET', `${config.API_ENDPOINT}/rest/pages/${pageId}/b64`);
// export const getPageBase64 = (url) => request('GET', url);
// export const getPageTypePageIdList = (caseId, pageTypeId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/page-types/${pageTypeId}/page-ids`);
// search
export const createSearches = (yearRange, case_, noRange, content, summary, judge, text, referenceList, searchFintExecute, searchFintCourt, fjudCategoryList, fjudCourtList, fjudeCategoryList, fjudeCourtList) => request('POST', `${config.API_ENDPOINT}/rest/searches`, { yearRange, 'case': case_, noRange, content, summary, judge, text, referenceList, searchFintExecute, searchFintCourt, fjudCategoryList, fjudCourtList, fjudeCategoryList, fjudeCourtList });
export const createClickSearchResultActivity = (esId, link) => request('POST', `${config.API_ENDPOINT}/rest/searches/results/activities`, { esId, link });
export const getSearches = (cacheId, index, page) => request('GET', `${config.API_ENDPOINT}/rest/searches/${cacheId}?page=${page}&index=${index}`);
export const createSearchsLaws = (law, no) => request('POST', `${config.API_ENDPOINT}/rest/searches/laws`, { law, no });
export const createJudgmentFiles = (caseId, judicialId, esId) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/files/import`, { judicialId, esId });
export const getCaseNames = () => request('GET', `${config.API_ENDPOINT}/rest/cases/names`);
// label
export const getLabels = (caseId) => request('GET', `${config.API_ENDPOINT}/rest/cases/${caseId}/labels`);
export const createLabel = (caseId, labelName) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/labels`, { labelName });
export const deleteLabel = (labelId) => request('DELETE', `${config.API_ENDPOINT}/rest/labels/${labelId}`);
export const updateLabelNote = (labelId, note) => request('PUT', `${config.API_ENDPOINT}/rest/labels/${labelId}/notes`, { note });
export const createLabelPages = (labelId, pageIdList) => request('POST', `${config.API_ENDPOINT}/rest/labels/${labelId}/pages`, { pageIdList });
export const getLabelPages = (labelId) => request('GET', `${config.API_ENDPOINT}/rest/labels/${labelId}/pages`);
export const deleteLabelPages = (labelId, pageIdList) => request('DELETE', `${config.API_ENDPOINT}/rest/labels/${labelId}/pages`, { pageIdList });
export const exportLabel = (labelId, fileName) => requestDownload('POST', `${config.API_ENDPOINT}/rest/labels/${labelId}/export`, fileName);
export const copyLabelToFolder = (userFolderId, labelId) => request('POST', `${config.API_ENDPOINT}/rest/labels/${labelId}/user-folders`, { userFolderId });
// page notes
export const createPageNote = (pageId, title, content, position) => request('POST', `${config.API_ENDPOINT}/rest/pages/${pageId}/notes`, { title, content, position });
export const getPageNoteList = (pageId) => request('GET', `${config.API_ENDPOINT}/rest/pages/${pageId}/notes`);
export const updatePageNote = (pageId, pageNoteId, title, content, position) => request('PUT', `${config.API_ENDPOINT}/rest/pages/${pageId}/notes/${pageNoteId}`, { title, content, position });
export const updatePageNotePosition = (pageId, pageNoteId, position) => request('PUT', `${config.API_ENDPOINT}/rest/pages/${pageId}/notes/${pageNoteId}/position`, { position });
// folder
export const createFilesFolder = (caseId, folderName) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/files/folders`, { folderName });
export const modifyFilesFolder = (folderId, folderName) => request('PUT', `${config.API_ENDPOINT}/rest/files/folders/${folderId}`, { folderName });
export const deleteFileFolder = (folderId) => request('DELETE', `${config.API_ENDPOINT}/rest/files/folders/${folderId}`);
export const movingFileToFolder = (fileId, toFolderId) => request('PUT', `${config.API_ENDPOINT}/rest/files/${fileId}/migrate`, { toFolderId });
export const createPageTypesFolder = (caseId, folderName) => request('POST', `${config.API_ENDPOINT}/rest/cases/${caseId}/page-types/folders`, { folderName });
export const modifyFolder = (folderId, folderName) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/folders/${folderId}`, { folderName });
export const deleteFolder = (folderId) => request('DELETE', `${config.API_ENDPOINT}/rest/page-types/folders/${folderId}`);
export const movingToFolder = (pageTypeIdList, toFolderId) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/migrate`, { pageTypeIdList, toFolderId });
export const movingToPageTypeInFolder = (pageTypeIdList, toType, toFolderId) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/migrate`, { pageTypeIdList, toFolderId, toType });
export const movingToPageType = (pageTypeIdList, toType) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/migrate`, { pageTypeIdList, toType });
export const combineToPages = (pageTypeIdList, toPageTypeId) => request('PUT', `${config.API_ENDPOINT}/rest/page-types/migrate`, { pageTypeIdList, toPageTypeId });
// mailroom
export const getSearchMailroom = (keyword) => request('POST', `${config.API_ENDPOINT}/rest/mailrooms/search`, { keyword });

export const getCourtList = () => request('GET', `${config.API_ENDPOINT}/rest/courts`);
export const getMailroomDeliveryTypeList = () => request('GET', `${config.API_ENDPOINT}/rest/mailrooms/delivery-types`);
export const getMailroomTypeList = () => request('GET', `${config.API_ENDPOINT}/rest/mailrooms/types`);
export const getMailroomReceivedList = () => request('GET', `${config.API_ENDPOINT}/rest/mailrooms/receives`);
export const getDocketList = (contain, size) => request('POST', `${config.API_ENDPOINT}/rest/mailrooms/related`, { contain, size });
export const createMailroomReceived = (name, typeId, receivedDate, sender, custodianName, sentDate, docketId, expiredDays, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/mailrooms/receives`, { name, typeId, receivedDate, sender, custodianName, sentDate, docketId, expiredDays }, fileList);
export const deleteMailroomReceivedFile = (fileId) => request('DELETE', `${config.API_ENDPOINT}/rest/mailrooms/receives/files/${fileId}`);
export const uploadMailroomReceivedFile = (receivedId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/mailrooms/receives/${receivedId}/files`, {}, fileList);
export const downloadMailroomReceivedFile = (fileId, fileName) => requestDownload('GET', `${config.API_ENDPOINT}/rest/mailrooms/receives/files/${fileId}`, fileName);

export const getMailroomSentList = () => request('GET', `${config.API_ENDPOINT}/rest/mailrooms/sends`);
export const createMailroomSent = (name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/mailrooms/sends`, { name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId }, fileList);
export const deleteMailroomSentFile = (fileId) => request('DELETE', `${config.API_ENDPOINT}/rest/mailrooms/sends/files/${fileId}`);
export const uploadMailroomSentFile = (sentId, fileList) => requestUpload('POST', `${config.API_ENDPOINT}/rest/mailrooms/sends/${sentId}/files`, {}, fileList);
export const downloadMailroomSentFile = (fileId, fileName) => requestDownload('GET', `${config.API_ENDPOINT}/rest/mailrooms/sends/files/${fileId}`, fileName);
export const updateSent = (sentId, name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId) => request('PUT', `${config.API_ENDPOINT}/rest/mailrooms/sends/${sentId}`, { sentName: name, typeId, receivedDate, recipient, custodianName, sentDate, docketId, deliveryTypeId });
export const deleteSent = (sentId) => request('DELETE', `${config.API_ENDPOINT}/rest/mailrooms/sends/${sentId}`);
export const updateReceived = (receivedId, sender, docketId, expiredDays, sentDate, receivedDate, custodianName, typeId, name) => request('PUT', `${config.API_ENDPOINT}/rest/mailrooms/receives/${receivedId}`, { sender, docketId, expiredDays, sentDate, receivedDate, custodianName, typeId, receiveName: name });
export const deleteReceived = (receivedId) => request('DELETE', `${config.API_ENDPOINT}/rest/mailrooms/receives/${receivedId}`);
// report
export const getReport = (taxYear, taxable, nontaxable) => request('POST', `${config.API_ENDPOINT}/rest/cases/reports`, { taxYear, taxable, nontaxable });
// plan
export const getPlan = (userId) => request('GET', `${config.API_ENDPOINT}/rest/users/${userId}/plan`);
export const getUpdatablePlan = (userId) => request('GET', `${config.API_ENDPOINT}/rest/users/${userId}/plan/upgrade`);
export const postUpgrade = (userId, planId) => request('POST', `${config.API_ENDPOINT}/rest/users/${userId}/plan/upgrade`, { planId });
//folder
export const getFolder = () => request('GET', `${config.API_ENDPOINT}/rest/user-files/folders`);
export const creatFolder = (name) => request('POST', `${config.API_ENDPOINT}/rest/user-files/folders`, { name });
export const updateFolder = (folderId, name) => request('PUT', `${config.API_ENDPOINT}/rest/user-files/folders/${folderId}`, { name });
//logout
export const cLogout = () => request('DELETE', `${config.API_ENDPOINT}/rest/auth/token`);