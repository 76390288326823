import { combineReducers } from 'redux-immutable';
import attachment from './modules/attachment';
import index from './modules/index';
import registration from './modules/registration';
import login from './modules/login';
import forgotPassword from './modules/forgotPassword';
import resetPassword from './modules/resetPassword';
import resetPasswordWithOldPassword from './modules/resetPasswordWithOldPassword';
import profile from './modules/profile';
import consultationsForm from './modules/consultationsForm';
import topBar from './modules/topBar';
import mailroom from './modules/mailroom';
import mailroomReceivedList from './modules/mailroomReceivedList';
import mailroomSentList from './modules/mailroomSentList';
import appointmentList from './modules/appointmentList';
import appointmentAppoint from './modules/appointmentAppoint';
import appointmentCustodian from './modules/appointmentCustodian';
import appointmentCase from './modules/appointmentCase';
import appointmentFee from './modules/appointmentFee';
import appointmentDates from './modules/appointmentDates';
import appointmentUserFiles from './modules/appointmentUserFiles';
import appointmentFiles from './modules/appointmentFiles';
import appointmentNotes from './modules/appointmentNotes';
import previewNotes from './modules/previewNotes';
import creatCase from './modules/creatCase';
import casesReceipt from './modules/casesReceipt';
import powerOfAttorney from './modules/powerOfAttorney';
import casesContract from './modules/casesContract';
import creatConsultations from './modules/creatConsultations';
import consultations from './modules/consultations';
import consultationAppoint from './modules/consultationAppoint';
import consultationCase from './modules/consultationCase';
import consultationFee from './modules/consultationFee';
import consultationFiles from './modules/consultationFiles';
import exportConsultations from './modules/exportConsultations';
import searchExtend from './modules/searchExtend';
import search from './modules/search';
import plan from './modules/plan';
import payment from './modules/payment';
import folder from './modules/folder';
import doc from './modules/doc';
import reviewNotes from './modules/reviewNotes';
import reviewNoteDraggable from './modules/reviewNoteDraggable';
import pdf from './modules/pdf';
import report from './modules/report';

const rootReducer = combineReducers({
  [attachment.constants.NAME]: attachment.reducer,
  [index.constants.NAME]: index.reducer,
  [registration.constants.NAME]: registration.reducer,
  [login.constants.NAME]: login.reducer,
  [forgotPassword.constants.NAME]: forgotPassword.reducer,
  [resetPassword.constants.NAME]: resetPassword.reducer,
  [resetPasswordWithOldPassword.constants.NAME]: resetPasswordWithOldPassword.reducer,
  [profile.constants.NAME]: profile.reducer,
  [consultationsForm.constants.NAME]: consultationsForm.reducer,
  [topBar.constants.NAME]: topBar.reducer,
  [mailroom.constants.NAME]: mailroom.reducer,
  [mailroomReceivedList.constants.NAME]: mailroomReceivedList.reducer,
  [mailroomSentList.constants.NAME]: mailroomSentList.reducer,
  [appointmentList.constants.NAME]: appointmentList.reducer,
  [appointmentAppoint.constants.NAME]: appointmentAppoint.reducer,
  [appointmentCustodian.constants.NAME]: appointmentCustodian.reducer,
  [appointmentCase.constants.NAME]: appointmentCase.reducer,
  [appointmentFee.constants.NAME]: appointmentFee.reducer,
  [appointmentDates.constants.NAME]: appointmentDates.reducer,
  [appointmentUserFiles.constants.NAME]: appointmentUserFiles.reducer,
  [appointmentFiles.constants.NAME]: appointmentFiles.reducer,
  [appointmentNotes.constants.NAME]: appointmentNotes.reducer,
  [previewNotes.constants.NAME]: previewNotes.reducer,
  [powerOfAttorney.constants.NAME]: powerOfAttorney.reducer,
  [creatCase.constants.NAME]: creatCase.reducer,
  [casesReceipt.constants.NAME]: casesReceipt.reducer,
  [casesContract.constants.NAME]: casesContract.reducer,
  [creatConsultations.constants.NAME]: creatConsultations.reducer,
  [consultations.constants.NAME]: consultations.reducer,
  [consultationAppoint.constants.NAME]: consultationAppoint.reducer,
  [consultationCase.constants.NAME]: consultationCase.reducer,
  [consultationFee.constants.NAME]: consultationFee.reducer,
  [consultationFiles.constants.NAME]: consultationFiles.reducer,
  [exportConsultations.constants.NAME]: exportConsultations.reducer,
  [searchExtend.constants.NAME]: searchExtend.reducer,
  [search.constants.NAME]: search.reducer,
  [plan.constants.NAME]: plan.reducer,
  [payment.constants.NAME]: payment.reducer,
  [folder.constants.NAME]: folder.reducer,
  [doc.constants.NAME]: doc.reducer,
  [reviewNotes.constants.NAME]: reviewNotes.reducer,
  [reviewNoteDraggable.constants.NAME]: reviewNoteDraggable.reducer,
  [pdf.constants.NAME]: pdf.reducer,
  [report.constants.NAME]: report.reducer,
});

export default rootReducer

