import {message} from 'antd';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Spinner from '../../utils/Spinner';
import {AreaHighlight, Highlight, PdfHighlighter, PdfLoader, Popup, Tip} from './react-pdf-highlighter/src';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 3,
});

const getNextId = () => String(Math.random()).slice(2);
const parseIdFromHash = () => window.location.hash.slice("#highlight-".length);
const resetHash = () => window.location.hash = "";
const HighlightPopup = ({ comment }) => {
  if (comment.text) {
    return (
      <div className="Highlight__popup">
        {comment.emoji} {comment.text}
      </div>
    )
  } else {
    return null;
  }
}


class PdfComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isGetting === true && this.props.isGetting === false && this.props.errMsg !== null) {
      message.error(this.props.errMsg);
    }
    if (prevProps.pageId !== this.props.pageId) {
      const highlightId = window.location.hash;
      this.props.onChangePdf(0);
      this.props.onChangePdf(this.props.pageId);
      this.props.onChangeText();
      this.props.onChangeText(this.props.pageId);
      resetHash();
      window.location.hash = highlightId.indexOf("highlight-goto-") > -1 ? highlightId.replace("highlight-goto-", "highlight-") : "";
      const currentHighLight = this.props.highlightList.find((highlight) => highlight.get("pageId") === this.props.pageId);
      this.props.updateHighLights(currentHighLight ? currentHighLight.get("highlight").toJS() : []);
    }
  }

  scrollViewerTo = (highlight) => {
  };
  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());
    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  getHighlightById(id) {
    const { highlights } = this.props;
    return highlights.find(highlight => highlight.id === id);
  };

  addHighlight(highlight) {
    const { highlights, caseId } = this.props;
    console.log("Saving highlight:", JSON.stringify(highlight));
    // const pageId = this.props.pageIdList.getIn([highlight['position']['pageNumber']-1]);
    const pageId = this.props.pageId;
    highlight['pageId'] = pageId;
    highlight['id'] = getNextId();
    this.props.updateHighLights([highlight, ...highlights]);
    this.props.onAddAnnotation(caseId, pageId, 'highlight', highlight);
  };

  updateHighlight(highlightId, position, content) {
    console.log("Updating highlight", highlightId, position, content);
    this.props.updateHighLights(
      this.props.highlights.map(h => {
        return h.id === highlightId
          ? {
            ...h,
            position: { ...h.position, ...position },
            content: { ...h.content, ...content }
          }
          : h;
      })
    );
  };

  render() {
    const { highlights, switchView, PageText } = this.props;
    return (
      <div className="pdf-viewer">
        {
          this.props.cachePageUrl
          ?
            <div className="viewer">
              {
                switchView === "pdf"
                &&
                <PdfLoader key={`pdf-loader-${this.props.pageId}`} url={this.props.cachePageUrl} beforeLoad={<Spinner/>}>
                  {pdfDocument =>
                    <PdfHighlighter
                      pdfDocument={pdfDocument}
                      pdfScale={this.props.pdfScale}
                      enableAreaSelection={event => event.altKey}
                      onScrollChange={resetHash}
                      scrollRef={scrollTo => {
                        this.scrollViewerTo = scrollTo;
                        this.scrollToHighlightFromHash();
                      }}
                      onSelectionFinished={(
                        position,
                        content,
                        hideTipAndSelection,
                        transformSelection
                      ) => (
                        <Tip
                          onOpen={transformSelection}
                          onConfirm={comment => {
                            this.addHighlight({ content, position, comment });

                            hideTipAndSelection();
                          }}
                        />
                      )}
                      highlightTransform={(
                        highlight,
                        index,
                        setTip,
                        hideTip,
                        viewportToScaled,
                        screenshot,
                        isScrolledTo
                      ) => {
                        const isTextHighlight = !Boolean(
                          highlight.content && highlight.content.image
                        );
                        const component = isTextHighlight ? (
                          <Highlight
                            isScrolledTo={isScrolledTo}
                            position={highlight.position}
                            comment={highlight.comment}
                          />
                        ) : (
                          <AreaHighlight
                            isScrolledTo={isScrolledTo}
                            highlight={highlight}
                            onChange={boundingRect => {
                              this.updateHighlight(
                                highlight.id,
                                { boundingRect: viewportToScaled(boundingRect) },
                                { image: screenshot(boundingRect) }
                              );
                            }}
                          />
                        );
                        return (
                          <Popup
                            popupContent={<HighlightPopup {...highlight} />}
                            onMouseOver={popupContent =>
                              setTip(highlight, highlight => popupContent)
                            }
                            onMouseOut={hideTip}
                            key={index}
                            children={component}
                          />
                        );
                      }}
                      highlights={highlights}
                    />
                  }
                </PdfLoader>
              }
              {
                switchView === "text"
                &&
                <div className="text-view-container">
                  {PageText}
                </div>
              }
            </div>
          : this.props.cachePageUrl === '' ?
            <div className="viewer">
              <Spinner/>
            </div>
          :
            null
        }
      </div>
    )
  }
}

export default withRouter(PdfComponent);
