import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  uploadUserFile,
  moveUserFile,
  exportFile,
  fileDelete,
  fileEdit
} from '../../utils/api';

// common
export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)
export const deleteRequest = createAction(types.DELETE_REQUEST)
export const deleteSuccess = createAction(types.DELETE_SUCCESS)
export const deleteFailure = createAction(types.DELETE_FAILURE)

export function doUploadUserFile(appointmentId, userFolderId, fileList) {
  return dispatch => {
    dispatch(postRequest());
    uploadUserFile(appointmentId, userFolderId, fileList).then(data => {
      dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doMoveUserFile(userFolderId, userFileId) {
  return dispatch => {
    dispatch(putRequest());
    moveUserFile(userFolderId, userFileId).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDownloadFile(fileId, fileName) {
  return dispatch => {
    dispatch(getRequest());
    exportFile(fileId, false, `${fileName}.pdf`).then(data => {
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doDeleteFile(fileId, appointmentId) {
  return dispatch => {
    dispatch(deleteRequest());
    fileDelete(fileId, appointmentId).then(() => {
      dispatch(deleteSuccess());
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doSubmitUpdateFileName(fileId, name) {
  return dispatch => {
    dispatch(putRequest());
    fileEdit(fileId, name).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}