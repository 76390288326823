import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Select, message, Input, Spin, DatePicker, Modal } from 'antd';
import { CASE_INSTANCE_LEVEL } from '../../config';
import { CASE_TYPE, CASE_STATEID, APPOINTOR_TYPE, TAX_VOUCHER, TAXABLE } from '../../config';
import TextArea from 'antd/lib/input/TextArea';

let CASE_TYPE_ID = {};
CASE_TYPE.forEach(type => { CASE_TYPE_ID[type.id] = type.name });

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const DEFAULT_DOCKET = {
  level: '請選擇',
  courts: '',
  trial: '',
  year: '',
  word: '',
  no: '',
  unit: '',
  ext: ''
}
const DEFAULT_CASE = {
  name: '',
  appointNoId: '',
  typeId: 1,
  statusId: 1,
  relatedList: [],
  keywordList: [],
  plaintiffList: [],
  defendantList: [],
  lawNo: [],
  appointContent: '',
  appointNote: '',
}
const DEFAULT_APPOINTOR = {
  name: '',
  relationship: '', // 委任人-委任關係
  ssid: '',
  birthday: null,
  address: '',
  phone: '',
  email: ''
}
const DEFAULT_COMPANY = {
  name: '',
  ban: '',
  representative: '',
  contactPerson: '',
  address: '',
  phone: '',
  email: ''
}
const DEFAULT_CUSTODIAN_APPOINTOR = {
  name: '',
  ssid: '',
  birthday: null,
  address: '',
  phone: '',
  email: '',
  no: '',
  relationship: '', // 自然人-當事人身份
}
const DEFAULT_CUSTODIAN_COMPANY = {
  name: '',
  ban: '',
  representative: '',
  contactPerson: '',
  address: '',
  phone: '',
  email: '',
  relationship: '', // 法人-當事人身份
}
const DEFAULT_DATE = {
  courtDay: null,
  appealLastDay: null,
  lastDayFirst: null,
  lastDaySecond: null,
  closeDate: null
}
const DEFAULT_FEE = {
  isTaxabled: 0,
  taxAmount: '',
  appointFee: '',
  payDate: null,
  taxYear: '',
  taxVoucher: 2,
  receipt: null
}
const DEFAULT_RECEIPT = {
  name: '',
  category: '',
  note: '',
  date: null,
  cost: '',
  reason: ''
}

class CreatCaseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docketList: [DEFAULT_DOCKET],
      requiredTip: '',
      appointDate: '',
      appointorType: 1,
      caseDetail: DEFAULT_CASE,
      appointor: DEFAULT_APPOINTOR,
      company: DEFAULT_COMPANY,
      copyEditAppointor: false, //同委任人
      copyEditCompany: false,
      custodianAppointor: DEFAULT_CUSTODIAN_APPOINTOR,
      custodianCompany: DEFAULT_CUSTODIAN_COMPANY,
      fee: DEFAULT_FEE,
      caseDate: DEFAULT_DATE,
    };
  }

  componentDidMount() {
    this.onCreatAppointDate();
    this.props.resetErrMsg();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        message.success('新增委任案件成功');
        this.props.history.push(`/cases`);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevState.exceedsUploadLimit === false && this.state.exceedsUploadLimit === true) {
      message.error('基於您所在的網路速度不同，系統建議一次上傳10個以內卷宗至案件。其他卷證可以至案件內再持續上傳。');
    };
    if (prevState.copyEditAppointor === true && (prevState.appointor['name'] !== this.state.custodianAppointor['name'] || prevState.appointor['ssid'] !== this.state.custodianAppointor['ssid'] || prevState.appointor['birthday'] !== this.state.custodianAppointor['birthday'] || prevState.appointor['address'] !== this.state.custodianAppointor['address'] || prevState.appointor['phone'] !== this.state.custodianAppointor['phone'] || prevState.appointor['email'] !== this.state.custodianAppointor['email'])) {
      this.setState({ copyEditAppointor: false })
    }
    if (prevState.copyEditCompany === true && (prevState.company['name'] !== this.state.custodianCompany['name'] || prevState.company['ban'] !== this.state.custodianCompany['ban'] || prevState.company['representative'] !== this.state.custodianCompany['representative'] || prevState.company['contactPerson'] !== this.state.custodianCompany['contactPerson'] || prevState.company['address'] !== this.state.custodianCompany['address'] || prevState.company['phone'] !== this.state.custodianCompany['phone'] || prevState.company['email'] !== this.state.custodianCompany['email'])) {
      this.setState({ copyEditCompany: false })
    }
    if ( prevProps.isChecking === true && this.props.isChecking === false ) {
      if (this.props.custodianConflict) {
        this.showCustodianConflict()
      }
    }
  }
  showCustodianConflict() {
    Modal.warning({
      title: '提醒您，這個當事人可能有利衝之疑慮',
      okText: '了解',
      onOk: () => {},
    });
  }
  onCreatAppointDate() {
    var today = new Date();
    var DD = today.getDate();
    var MM = today.getMonth() + 1;
    var YYYY = today.getFullYear();
    if (DD < 10) { DD = '0' + DD };
    if (MM < 10) { MM = '0' + MM };
    today = YYYY + '/' + MM + '/' + DD;
    let ROCyear = YYYY - 1911;
    this.setState({ appointDate: today, taxYear: ROCyear })
  }
  onClickSubmitCreateCase() {
    let { docketList, appointDate, appointorType, caseDetail, appointor, company, custodianAppointor, custodianCompany, fee, caseDate } = this.state;
    if (docketList[0].level > 0 || docketList[0].courts !== '' || docketList[0].trial !== '' || docketList[0].year !== '' || docketList[0].word !== '' || docketList[0].no !== '' || docketList[0].unit !== '' || docketList[0].ext !== '') {
      docketList.map(docket => {
        docket.level = docket.level === '請選擇' ? 0 : Number(docket.level)
        docket.no = docket.no ? Number(docket.no) : ''
        docket.year = docket.year ? Number(docket.year) : ''
      })
    } else {
      docketList = []
    }
    caseDetail = {
      name: caseDetail.name ? caseDetail.name.trim() : '',
      appointNoId: caseDetail.appointNoId ? caseDetail.appointNoId.trim() : '',
      typeId: caseDetail.typeId,
      statusId: caseDetail.statusId,
      relatedList: caseDetail.relatedList,
      keywordList: caseDetail.keywordList,
      plaintiffList: caseDetail.plaintiffList,
      defendantList: caseDetail.defendantList,
      lawNo: caseDetail.lawNo,
      appointContent: caseDetail.appointContent ? caseDetail.appointContent.trim() : '',
      appointNote: caseDetail.appointNote ? caseDetail.appointNote.trim() : '',
    }
    if (appointorType === 1) {
      appointor = {
        name: appointor.name ? appointor.name.trim() : '',
        relationship: appointor.relationship ? appointor.relationship.trim() : '',
        ssid: appointor.ssid ? appointor.ssid.trim() : '',
        birthday: appointor.birthday ? moment(appointor.birthday).format('YYYY/MM/DD') : '',
        address: appointor.address ? appointor.address.trim() : '',
        phone: appointor.phone ? appointor.phone : '',
        email: appointor.email ? appointor.email.trim() : '',
      };
      custodianAppointor = {
        name: custodianAppointor.name ? custodianAppointor.name.trim() : '',
        ssid: custodianAppointor.ssid ? custodianAppointor.ssid.trim() : '',
        birthday: custodianAppointor.birthday ? moment(custodianAppointor.birthday).format('YYYY/MM/DD') : '',
        address: custodianAppointor.address ? custodianAppointor.address.trim() : '',
        phone: custodianAppointor.phone ? custodianAppointor.phone.trim() : '',
        email: custodianAppointor.email ? custodianAppointor.email.trim() : '',
        no: custodianAppointor.no ? custodianAppointor.no : '',
        relationship: custodianAppointor.relationship ? custodianAppointor.relationship.trim() : '',
      };
      company = DEFAULT_COMPANY;
      custodianCompany = DEFAULT_CUSTODIAN_COMPANY;
    };
    if (appointorType === 2) {
      company = {
        name: company.name ? company.name.trim() : '',
        ban: company.ban ? company.ban : '',
        representative: company.representative ? company.representative.trim() : '',
        contactPerson: company.contactPerson ? company.contactPerson.trim() : '',
        address: company.address ? company.address.trim() : '',
        phone: company.phone ? company.phone : '',
        email: company.email ? company.email.trim() : '',
      };
      custodianCompany = {
        name: custodianCompany.name ? custodianCompany.name.trim() : '',
        ban: custodianCompany.ban ? custodianCompany.ban : '',
        representative: custodianCompany.representative ? custodianCompany.representative.trim() : '',
        contactPerson: custodianCompany.contactPerson ? custodianCompany.contactPerson.trim() : '',
        address: custodianCompany.address ? custodianCompany.address.trim() : '',
        phone: custodianCompany.phone ? custodianCompany.phone : '',
        email: custodianCompany.email ? custodianCompany.email.trim() : '',
        relationship: custodianCompany.relationship ? custodianCompany.relationship.trim() : '',
      };
      appointor = DEFAULT_APPOINTOR;
      custodianAppointor = DEFAULT_CUSTODIAN_APPOINTOR;
    }
    if (fee.receipt !== null) {
      fee.receipt = {
        name: fee.receipt.name ? fee.receipt.name.trim() : '',
        category: fee.receipt.category ? fee.receipt.category.trim() : '',
        note: fee.receipt.note ? fee.receipt.note.trim() : '',
        date: fee.receipt.date ? moment(fee.receipt.date).format('YYYY/MM/DD') : '',
        cost: fee.receipt.cost ? fee.receipt.cost : '',
        reason: fee.receipt.reason ? fee.receipt.reason.trim() : '',
      }
    }
    fee = {
      isTaxabled: fee.isTaxabled,
      taxAmount: fee.taxAmount ? fee.taxAmount.trim() : '',
      appointFee: fee.appointFee ? fee.appointFee.trim() : '',
      payDate: fee.payDate ? moment(fee.payDate).format('YYYY/MM/DD') : '',
      taxYear: fee.taxYear ? fee.taxYear.trim() : '',
      taxVoucher: fee.taxVoucher,
      receipt: fee.receipt
    }
    caseDate = {
      courtDay: caseDate.courtDay ? moment(caseDate.courtDay).format('YYYY/MM/DD') : '',
      appealLastDay: caseDate.appealLastDay ? moment(caseDate.appealLastDay).format('YYYY/MM/DD') : '',
      lastDayFirst: caseDate.lastDayFirst ? moment(caseDate.lastDayFirst).format('YYYY/MM/DD') : '',
      lastDaySecond: caseDate.lastDaySecond ? moment(caseDate.lastDaySecond).format('YYYY/MM/DD') : '',
      closeDate: caseDate.closeDate ? moment(caseDate.closeDate).format('YYYY/MM/DD') : ''
    }

    if (appointDate === '') { return message.error('請輸入必填欄位，「委任日期」') }
    if (caseDetail.typeId === 0) { return message.error('請輸入必填欄位，「案件種類」') }
    if (caseDetail.appointNoId === '') { return message.error('請輸入必填欄位，「所內編號」') }
    if (caseDetail.name === '') { return message.error('請輸入必填欄位，「委任案由」') }
    if (appointorType === 1 && appointor['name'] === '') { return message.error('請輸入必填欄位，「委任人」') }
    if (appointorType === 2 && company['name'] === '') { return message.error('請輸入必填欄位，「公司名稱」') }
    this.props.onSubmitCreateCase(docketList, moment(appointDate).format('YYYY/MM/DD'), appointorType, caseDetail, appointor, company, custodianAppointor, custodianCompany, fee, caseDate)
  }

  onChangeCopyData(action, attr, value) {
    const { copyEditAppointor, copyEditCompany, appointor, company, custodianAppointor } = this.state;
    let { caseDetail } = this.state;
    value = (value === null || value === '') ? null : value.trim();
    if (action === 'editAppointor') {
      copyEditAppointor ?
        this.setState({ copyEditAppointor: false })
      :
        this.setState({
          copyEditAppointor: true,
          custodianAppointor: {
            name: appointor['name'],
            ssid: appointor['ssid'],
            birthday: appointor['birthday'],
            address: appointor['address'],
            phone: appointor['phone'],
            email: appointor['email'],
            no: custodianAppointor['no']
          }
        }, () => this.onClickCheckConflictOfInterest(appointor['name']));
    }
    if (action === 'editCompany') {
      copyEditCompany ?
        this.setState({ copyEditCompany: false })
      :
        this.setState({
          copyEditCompany: true,
          custodianCompany: company
        }, () => this.onClickCheckConflictOfInterest(company['name']))
    }
    if (action === 'appointor' && attr === 'plaintiff') {
      if (value !== null && value !== '' && caseDetail.plaintiffList.indexOf(value) === -1) {
        this.onChangeCase('plaintiffList', [...caseDetail.plaintiffList, value])
      }
    }
    if (action === 'appointor' && attr === 'defendant') {
      if (value !== null && value !== '' && caseDetail.defendantList.indexOf(value) === -1) {
        this.onChangeCase('defendantList', [...caseDetail.defendantList, value])
      }
    }
    if (action === 'company' && attr === 'plaintiff') {
      if (value !== null && value !== '' && caseDetail.plaintiffList.indexOf(value) === -1) {
        this.onChangeCase('plaintiffList', [...caseDetail.plaintiffList, value])
      }
    }
    if (action === 'company' && attr === 'defendant') {
      if (value !== null && value !== '' && caseDetail.defendantList.indexOf(value) === -1) {
        this.onChangeCase('defendantList', [...caseDetail.defendantList, value])
      }
    }
  }
  onChangeDocket(idx, item, value) {
    const { docketList } = this.state;
    let newDocketList = docketList;
    newDocketList[idx] = {
      ...newDocketList[idx],
      [`${item}`]: value
    }
    this.setState({ docketList: newDocketList })
  }
  onChangeCustodian(type, key, value) {
    let { custodianAppointor, custodianCompany } = this.state;
    if (type === 'appointor') {
      custodianAppointor = JSON.parse(JSON.stringify(custodianAppointor));
      custodianAppointor[key] = value;
      if (key === 'no' || key === 'ssid' || key === 'phone') {
        if (key === 'no') { custodianAppointor['no'] = custodianAppointor['no'].slice(0, 10) }
        if (key === 'ssid') { custodianAppointor['ssid'] = custodianAppointor['ssid'].slice(0, 10) }
        if (key === 'phone') { custodianAppointor['phone'] = custodianAppointor['phone'].slice(0, 10) }
      }
      this.setState({ custodianAppointor });
    }
    if (type === 'company') {
      custodianCompany = JSON.parse(JSON.stringify(custodianCompany));
      custodianCompany[key] = value;
      if (key === 'ban' || key === 'phone') {
        if (key === 'ban') { custodianCompany['ban'] = custodianCompany['ban'].slice(0, 8) }
        if (key === 'phone') { custodianCompany['phone'] = custodianCompany['phone'].slice(0, 10) }
      }
      this.setState({ custodianCompany });
    }
  }
  onChangeCase(key, value) {
    let { caseDetail } = this.state;
    caseDetail = JSON.parse(JSON.stringify(caseDetail));
    caseDetail[key] = value;
    this.setState({ caseDetail })
  }
  onChangeAppointor(key, value) {
    let { appointor } = this.state;
    appointor = JSON.parse(JSON.stringify(appointor));
    appointor[key] = value;
    if (key === 'phone' || key === 'ssid') {
      if (key === 'phone') { appointor['phone'] = appointor['phone'].slice(0, 20) }
      if (key === 'ssid') { appointor['ssid'] = appointor['ssid'].slice(0, 10) }
    }
    this.setState({ appointor })
  }
  onChangeCompany(key, value) {
    let { company, custodian } = this.state;
    company = JSON.parse(JSON.stringify(company));
    company[key] = value;
    if (key === 'phone' || key === 'ban') {
      if (key === 'phone') { company['phone'] = company['phone'].slice(0, 20) }
      if (key === 'ban') { company['ban'] = company['ban'].slice(0, 8) }
    }
    if (key === 'name') {
      this.setState({ custodian: { ...custodian, name: value } })
    }
    this.setState({ company })
  }
  onChangeFee(key, value) {
    let { fee } = this.state;
    fee = JSON.parse(JSON.stringify(fee));
    fee[key] = value;
    if (key === 'taxYear' || key === 'appointFee' || key === 'taxAmount' || key === 'cost') {
      if (key === 'taxYear') { fee['taxYear'] = fee['taxYear'].slice(0, 4) }
      if (key === 'appointFee') { fee['appointFee'] = fee['appointFee'].slice(0, 10) }
      if (key === 'taxAmount') { fee['taxAmount'] = fee['taxAmount'].slice(0, 10) }
      if (key === 'cost') { fee['cost'] = fee['cost'].slice(0, 10) }
    }
    this.setState({ fee });
  }
  onChangeReceipt(key, value) {
    let { fee } = this.state;
    fee = JSON.parse(JSON.stringify(fee));
    fee.receipt[key] = value;
    if (key === 'cost') {
      fee.receipt['cost'] = fee.receipt['cost'].slice(0, 10)
    }
    this.setState({ fee });
  }
  onChangeCaseDates(key, value) {
    let { caseDate } = this.state;
    caseDate = JSON.parse(JSON.stringify(caseDate));
    caseDate[key] = value;
    this.setState({ caseDate });
  }
  onClickCheckConflictOfInterest(custodianName) {
    if (custodianName !== ''){
      this.props.onCheckConflictOfInterest(custodianName)
    }
  }

  render() {
    const { docketList, requiredTip, appointDate, appointorType, copyEditAppointor, copyEditCompany } = this.state;
    const { caseDetail, appointor, company, custodianAppointor, custodianCompany, fee, caseDate } = this.state;
    return (
      <div className="creatCase-section section-col-item creatCase">
        <div className="content-section">
          <form className="creat-case-from"
            onSubmit={(e) => { e.preventDefault(); this.onClickSubmitCreateCase() }}
          >
            <div className="creat-case-head">
              <div className="creat-title">新增案件<span className="tip">* 字為必填欄位</span></div>
            </div>
            <div className="creatCase-btn"></div>
            {
              this.props.errMsg
              &&
              <p className="err-msg">{this.props.errMsg}</p>
            }
            {
              (requiredTip !== '')
              &&
              <p className="err-msg">{requiredTip}</p>
            }
            <div className="creat-case">
              <div>
                <div className="col-item">
                  <div className="col-title required">所內編號：</div>
                  <Input className="col-edit"
                    allowClear
                    size="small"
                    type="text"
                    value={caseDetail.appointNoId}
                    onChange={e => this.onChangeCase('appointNoId', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title required">委任案由：</div>
                  <Input className="col-edit"
                    allowClear
                    size="small"
                    type="text"
                    value={caseDetail.name}
                    onChange={e => this.onChangeCase('name', e.target.value)}
                  />
                </div>
                <div className="col-item">
                  <div className="col-title required">案件種類：</div>
                  <Select className="col-edit"
                    size="small"
                    showSearch
                    value={caseDetail.typeId}
                    onChange={value => this.onChangeCase('typeId', value)}
                  >
                    <Option value="請選擇" disabled>請選擇</Option>
                    {
                      CASE_TYPE.map(caseType =>
                        <Option key={`casetypeedit-${caseType.id}`} value={caseType.id}>{caseType.name}</Option>
                      )
                    }
                  </Select>
                </div>
                <div className="col-item creat-appointment">
                  <div className="col-title">案號：</div>
                  <div className="col-edit">
                    {
                      docketList && docketList.map((docket, idx) =>
                        <div className="docketList" key={`docket-${idx}`}>
                          <div className="docket-col">
                            <Select size="small" className="docket-col-txt" placeholder="請選擇審級"
                              value={`${docket.level}`}
                              onChange={value => this.onChangeDocket(idx, 'level', value)}
                            >
                              {
                                Object.entries(CASE_INSTANCE_LEVEL).map(([key, value]) =>
                                  <Option key={`dockerlevel-${key}`} value={key}>{value}</Option>
                                )
                              }
                            </Select>
                          </div>
                          <div className="docket-col courts">
                            <Input className="docket-col-txt"
                              size="small"
                              type="text"
                              placeholder="法院/地檢"
                              value={docket.courts}
                              onChange={e => this.onChangeDocket(idx, 'courts', e.target.value)}
                            />
                          </div>
                          <div className="docket-col">
                            <Input className="docket-col-txt"
                              size="small"
                              type="text"
                              suffix="庭"
                              value={docket.trial}
                              onChange={e => this.onChangeDocket(idx, 'trial', e.target.value)}
                            />
                          </div>
                          <div className="docket-col">
                            <Input className="docket-col-txt"
                              size="small"
                              type="number"
                              suffix="年度"
                              value={docket.year}
                              onChange={e => this.onChangeDocket(idx, 'year', e.target.value)}
                            />
                          </div>
                          <div className="docket-col">
                            <Input className="docket-col-txt"
                              size="small"
                              type="text"
                              suffix="字"
                              value={docket.word}
                              onChange={e => this.onChangeDocket(idx, 'word', e.target.value)}
                            />
                          </div>
                          <div className="docket-col">
                            <Input className="docket-col-txt"
                              size="small"
                              type="number"
                              suffix="號"
                              value={docket.no}
                              onChange={e => this.onChangeDocket(idx, 'no', e.target.value)}
                            />
                          </div>
                          <div className="docket-col">
                            <Input className="docket-col-txt"
                              size="small"
                              type="text"
                              suffix="股"
                              value={docket.unit}
                              onChange={e => this.onChangeDocket(idx, 'unit', e.target.value)}
                            />
                          </div>
                          <div className="docket-col">
                            <Input className="docket-col-txt"
                              size="small"
                              type="text"
                              suffix="分機"
                              value={docket.ext}
                              onChange={e => this.onChangeDocket(idx, 'ext', e.target.value)}
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-item-column">
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>委任人資訊</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">委任身份：</div>
                      <div className="col-edit">
                        {
                          Object.entries(APPOINTOR_TYPE).map(([key, value]) =>
                            <span key={`appointorType-${key}`}>
                              <input type="radio" id={`appointorType-${key}`}
                                value={key}
                                checked={appointorType === parseInt(key)}
                                onChange={e => this.setState({ appointorType: parseInt(e.target.value) })}
                              />
                              <label htmlFor={`appointorType-${key}`} className="edit-appointorType">{value}</label>
                            </span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title required">委任日期：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={appointDate ? moment(appointDate, dateFormat) : null}
                        onChange={value => this.setState({ appointDate: value })}
                      />
                    </div>
                    {
                      appointorType === 1 ?
                        <>
                          <div className="col-item">
                            <div className="col-title required">委任人：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={appointor.name}
                              onChange={e => this.onChangeAppointor('name', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">委任關係：</div>
                            <Input className="col-edit"
                              size="small"
                              placeholder="與當事人關係"
                              type="text"
                              value={appointor.relationship}
                              onChange={e => this.onChangeAppointor('relationship', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">身分證字號：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={appointor.ssid}
                              onChange={e => this.onChangeAppointor('ssid', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">生日：</div>
                            <DatePicker className="col-edit"
                              size="small"
                              placeholder="請選擇日期"
                              inputReadOnly={true}
                              showToday={false}
                              format={dateFormat}
                              value={appointor.birthday ? moment(appointor.birthday, dateFormat) : null}
                              onChange={value => this.onChangeAppointor('birthday', value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">住址：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={appointor.address}
                              onChange={e => this.onChangeAppointor('address', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">聯絡電話：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={appointor.phone}
                              onChange={e => this.onChangeAppointor('phone', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">Email：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={appointor.email}
                              onChange={e => this.onChangeAppointor('email', e.target.value)}
                            />
                          </div>
                        </>
                      : appointorType === 2 ?
                        <>
                          <div className="col-item">
                            <div className="col-title required">公司名稱：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={company.name}
                              onChange={e => this.onChangeCompany('name', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">統一編號：</div>
                            <Input className="col-edit"
                              size="small"
                              type="number"
                              value={company.ban}
                              onChange={e => this.onChangeCompany('ban', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">代表人：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={company.representative}
                              onChange={e => this.onChangeCompany('representative', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">聯絡人：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={company.contactPerson}
                              onChange={e => this.onChangeCompany('contactPerson', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">住址：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={company.address}
                              onChange={e => this.onChangeCompany('address', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">聯絡電話：</div>
                            <Input className="col-edit"
                              size="small"
                              type="number"
                              value={company.phone}
                              onChange={e => this.onChangeCompany('phone', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">Email：</div>
                            <Input className="col-edit"
                              size="small"
                              type="email"
                              value={company.email}
                              onChange={e => this.onChangeCompany('email', e.target.value)}
                            />
                          </div>
                        </>
                      :
                      null
                    }
                  </div>
                </div>
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>案件相關</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">委任狀態：</div>
                      <Select className="col-edit"
                        size="small"
                        value={CASE_STATEID[caseDetail.statusId]}
                        onChange={value => this.onChangeCase('statusId', value)}
                      >
                        {
                          Object.entries(CASE_STATEID).map(([key, value]) =>
                            <Option key={`casestatus-${key}`} value={key}>{value}</Option>
                          )
                        }
                      </Select>
                    </div>
                    {
                      caseDetail.typeId !== 6
                      &&
                      <>
                        <div className="col-item">
                          <div className="col-title">
                            {
                              CASE_TYPE.map(type => {
                                if (type['id'] === caseDetail.typeId) {
                                  return type['plaintiff']
                                }
                              })
                            }：
                          </div>
                          {
                            CASE_TYPE.map(type => {
                              if (type['id'] === caseDetail.typeId) {
                                return <Select key={`plaintiff-${type['id']}`} className="col-edit"
                                  size="small"
                                  mode="tags"
                                  dropdownStyle={{ display: 'none' }}
                                  value={caseDetail.plaintiffList}
                                  onChange={value => this.onChangeCase('plaintiffList', value)}
                                  tokenSeparators={[',']}>
                                </Select>
                              }
                            })
                          }
                        </div>
                        <div className="col-item">
                          <div className="col-title">
                            {
                              CASE_TYPE.map(type => {
                                if (type['id'] === caseDetail.typeId) {
                                  return type['defendant']
                                }
                              })
                            }：
                          </div>
                          {
                            CASE_TYPE.map(type => {
                              if (type['id'] === caseDetail.typeId) {
                                return <Select key={`defendant-${type['id']}`}  className="col-edit"
                                  size="small"
                                  mode="tags"
                                  dropdownStyle={{ display: 'none' }}
                                  value={caseDetail.defendantList}
                                  onChange={value => this.onChangeCase('defendantList', value)}
                                  tokenSeparators={[',']}>
                                </Select>
                              }
                            })
                          }
                        </div>
                      </>
                    }
                    <div className="col-item">
                      <div className="col-title">案件相關：</div>
                      <Select className="col-edit"
                        size="small"
                        placeholder="請輸入關鍵字。例：損害賠償"
                        mode="multiple"
                        value={caseDetail.relatedList}
                        notFoundContent={this.props.isGettingCaseRelated ? <Spin size="small" /> : '無相關資料'}
                        filterOption={false}
                        onSearch={value => this.props.onLoadRelatedList(value, 10)}
                        onChange={value => this.onChangeCase('relatedList', value)}
                      >
                        {
                          this.props.caseRelateList && this.props.caseRelateList.map(relate =>
                            <Option key={`caserelated-${relate}`} value={relate}>{`${relate}`}</Option>
                          )
                        }
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title">法條相關：</div>
                      <Select className="col-edit"
                        size="small"
                        mode="tags" dropdownStyle={{ display: 'none' }}
                        defaultValue={caseDetail.lawNo}
                        onChange={value => this.onChangeCase('lawNo', value)}
                        tokenSeparators={[',']}>
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title">關鍵字：</div>
                      <Select className="col-edit"
                        size="small"
                        mode="tags" dropdownStyle={{ display: 'none' }}
                        defaultValue={caseDetail.keywordList}
                        onChange={value => this.onChangeCase('keywordList', value)}
                        tokenSeparators={[',']}>
                      </Select>
                    </div>
                    <div className="col-item">
                      <div className="col-title">委任內容：</div>
                      <TextArea className="col-edit"
                        rows="3"
                        size="small"
                        type="text"
                        value={caseDetail.appointContent}
                        onChange={e => this.onChangeCase('appointContent', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">備註：</div>
                      <TextArea className="col-edit"
                        rows="3"
                        size="small"
                        type="text"
                        value={caseDetail.appointNote}
                        onChange={e => this.onChangeCase('appointNote', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>庭期</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">開庭日期：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={caseDate.courtDay ? moment(caseDate.courtDay, dateFormat) : null}
                        onChange={value => this.onChangeCaseDates('courtDay', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">上訴末日：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={caseDate.appealLastDay ? moment(caseDate.appealLastDay, dateFormat) : null}
                        onChange={value => this.onChangeCaseDates('appealLastDay', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">合意停止末日(一)：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={caseDate.lastDayFirst ? moment(caseDate.lastDayFirst, dateFormat) : null}
                        onChange={value => this.onChangeCaseDates('lastDayFirst', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">合意停止末日(二)：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={caseDate.lastDaySecond ? moment(caseDate.lastDaySecond, dateFormat) : null}
                        onChange={value => this.onChangeCaseDates('lastDaySecond', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">結案日期：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={caseDate.closeDate ? moment(caseDate.closeDate, dateFormat) : null}
                        onChange={value => this.onChangeCaseDates('closeDate', value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-item-column">
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>當事人資訊</div>
                      {
                        caseDetail.typeId !== 6 && appointorType === 1 ?
                          <div className="col-item">
                            <div className="col-title">／當事人身份：</div>
                            <div className="col-edit">
                              <span>
                                <input type="radio" name="custodianName" id="plaintiff"
                                  checked={custodianAppointor.relationship === CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff}
                                  onChange={e => {this.setState({ custodianAppointor: { ...custodianAppointor, relationship: CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff } }); this.onChangeCopyData('appointor', 'plaintiff', custodianAppointor['name']) }}
                                />
                                <label className={`edit-custodianName`} htmlFor="plaintiff">{CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff}</label>
                              </span>
                              <span>
                                <input type="radio" name="custodianName" id="defendant"
                                  checked={custodianAppointor.relationship === CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant}
                                  onChange={e => {this.setState({ custodianAppointor: { ...custodianAppointor, relationship: CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant } }); this.onChangeCopyData('appointor', 'defendant', custodianAppointor['name']) }}
                                />
                                <label className={`edit-custodianName`} htmlFor="defendant">{CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant}</label>
                              </span>
                            </div>
                          </div>
                        : caseDetail.typeId !== 6 && appointorType === 2 ?
                          <div className="col-item">
                            <div className="col-title">／當事人身份：</div>
                            <div className="col-edit">
                              <span>
                                <input type="radio" name="custodianName" id="plaintiff"
                                  checked={custodianCompany.relationship === CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff}
                                  onChange={e => {this.setState({ custodianCompany: { ...custodianCompany, relationship: CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff } }); this.onChangeCopyData('company', 'plaintiff', custodianCompany['name']) }}
                                />
                                <label className={`edit-custodianName`} htmlFor="plaintiff">{CASE_TYPE.find(type => type['id'] === caseDetail.typeId).plaintiff}</label>
                              </span>
                              <span>
                                <input type="radio" name="custodianName" id="defendant"
                                  checked={custodianCompany.relationship === CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant}
                                  onChange={e => {this.setState({ custodianCompany: { ...custodianCompany, relationship: CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant } }); this.onChangeCopyData('company', 'defendant', custodianCompany['name']) }}
                                />
                                <label className={`edit-custodianName`} htmlFor="defendant">{CASE_TYPE.find(type => type['id'] === caseDetail.typeId).defendant}</label>
                              </span>
                            </div>
                          </div>
                        :
                          null
                      }
                    </div>
                    {
                      appointorType === 1 ?
                        <>
                          <div className="col-item">
                            <div className="col-title">同委任人：</div>
                            <div className="col-edit">
                              <input type="checkbox" name="copyEditAppointor" id="copyEditAppointor" value={copyEditAppointor}
                                checked={copyEditAppointor === true}
                                onChange={e => this.onChangeCopyData('editAppointor', '', '')}
                              />
                              <label className="edit-copyEditAppointor" htmlFor="copyEditAppointor">同委任人</label>
                            </div>
                          </div>
                          <div className="col-item">
                            <div className="col-title">編號：</div>
                            <Input className="col-edit"
                              size="small"
                              type="number"
                              value={custodianAppointor['no']}
                              onChange={e => this.onChangeCustodian('appointor', 'no', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">當事人：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianAppointor['name']}
                              onChange={e => this.onChangeCustodian('appointor', 'name', e.target.value)}
                              onBlur={e => this.onClickCheckConflictOfInterest(custodianAppointor['name'])}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">身分證字號：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianAppointor['ssid']}
                              onChange={e => this.onChangeCustodian('appointor', 'ssid', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">生日：</div>
                            <DatePicker className="col-edit"
                              size="small"
                              placeholder="請選擇日期"
                              inputReadOnly={true}
                              showToday={false}
                              format={dateFormat}
                              value={custodianAppointor['birthday'] ? moment(custodianAppointor['birthday'], dateFormat) : null}
                              onChange={value => this.onChangeCustodian('appointor', 'birthday', value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">住址：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianAppointor['address']}
                              onChange={e => this.onChangeCustodian('appointor', 'address', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">聯絡電話：</div>
                            <Input className="col-edit"
                              size="small"
                              type="number"
                              value={custodianAppointor['phone']}
                              onChange={e => this.onChangeCustodian('appointor', 'phone', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">Email：</div>
                            <Input className="col-edit"
                              size="small"
                              type="email"
                              value={custodianAppointor['email']}
                              onChange={e => this.onChangeCustodian('appointor', 'email', e.target.value)}
                            />
                          </div>
                        </>
                      : appointorType === 2 ?
                        <>
                          <div className="col-item">
                            <div className="col-title">同委任人：</div>
                            <div className="col-edit">
                              <input type="checkbox" name="copyEditCompany" id="copyEditCompany" value={copyEditCompany}
                                checked={copyEditCompany === true}
                                onChange={e => this.onChangeCopyData('editCompany', '', '')}
                              />
                              <label className="edit-copyEditCompany" htmlFor="copyEditCompany">同委任人</label>
                            </div>
                          </div>
                          <div className="col-item">
                            <div className="col-title">公司名稱：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianCompany['name']}
                              onChange={e => this.onChangeCustodian('company', 'name', e.target.value)}
                              onBlur={e => this.onClickCheckConflictOfInterest(custodianCompany['name'])}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">統一編號：</div>
                            <Input className="col-edit"
                              size="small"
                              type="number"
                              value={custodianCompany['ban']}
                              onChange={e => this.onChangeCustodian('company', 'ban', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">代表人：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianCompany['representative']}
                              onChange={e => this.onChangeCustodian('company', 'representative', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">聯絡人：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianCompany['contactPerson']}
                              onChange={e => this.onChangeCustodian('company', 'contactPerson', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">住址：</div>
                            <Input className="col-edit"
                              size="small"
                              type="text"
                              value={custodianCompany['address']}
                              onChange={e => this.onChangeCustodian('company', 'address', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">聯絡電話：</div>
                            <Input className="col-edit"
                              size="small"
                              type="number"
                              value={custodianCompany['phone']}
                              onChange={e => this.onChangeCustodian('company', 'phone', e.target.value)}
                            />
                          </div>
                          <div className="col-item">
                            <div className="col-title">Email：</div>
                            <Input className="col-edit"
                              size="small"
                              type="email"
                              value={custodianCompany['email']}
                              onChange={e => this.onChangeCustodian('company', 'email', e.target.value)}
                            />
                          </div>
                        </>
                      :
                      null
                    }
                  </div>
                </div>
                <div className="col-item-wrap">
                  <div className="col-item-group">
                    <div className="col-item-title">
                      <div>費用</div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">實收金額：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="元"
                        value={fee.appointFee}
                        onChange={e => this.onChangeFee('appointFee', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">應／未稅：</div>
                      <div className="col-edit">
                        {
                          Object.entries(TAXABLE).map(([key, value]) =>
                            <span key={`isTaxabled-${key}`}>
                              <input type="radio" id={`isTaxabled-${key}`}
                                value={key}
                                checked={fee.isTaxabled === parseInt(key)}
                                onChange={e => this.onChangeFee('isTaxabled', parseInt(e.target.value))}
                              />
                              <label htmlFor={`isTaxabled-${key}`} className="edit-isTaxabled">{value}</label>
                            </span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">報稅年度：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="年度"
                        value={fee.taxYear}
                        onChange={e => this.onChangeFee('taxYear', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">報稅金額：</div>
                      <Input className="col-edit"
                        size="small"
                        type="number"
                        suffix="元"
                        value={fee.taxAmount}
                        onChange={e => this.onChangeFee('taxAmount', e.target.value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">付款日：</div>
                      <DatePicker className="col-edit"
                        size="small"
                        placeholder="請選擇日期"
                        inputReadOnly={true}
                        showToday={false}
                        format={dateFormat}
                        value={fee.payDate ? moment(fee.payDate, dateFormat) : null}
                        onChange={value => this.onChangeFee('payDate', value)}
                      />
                    </div>
                    <div className="col-item">
                      <div className="col-title">扣繳憑單：</div>
                      <div className="col-edit">
                        {
                          Object.entries(TAX_VOUCHER).map(([key, value]) =>
                            <span key={`taxVoucher-${key}`}>
                              <input type="radio" id={`taxVoucher-${key}`}
                                value={key}
                                checked={fee.taxVoucher === parseInt(key)}
                                onChange={e => this.onChangeFee('taxVoucher', parseInt(e.target.value))}
                              />
                              <label htmlFor={`taxVoucher-${key}`} className="edit-taxVoucher">{value}</label>
                            </span>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-item">
                      <div className="col-title">收據：</div>
                      <div className="col-edit">
                        <input type="radio" name="receipt" id="receipt-2" checked={fee.receipt === null}
                          onChange={e => this.onChangeFee('receipt', null)}
                        />
                        <label htmlFor="receipt-2" className="edit-receipt">無</label>
                        <input type="radio" name="receipt" id="receipt-1" checked={fee.receipt !== null}
                          onChange={e => this.onChangeFee('receipt', DEFAULT_RECEIPT)}
                        />
                        <label htmlFor="receipt-1" className="edit-receipt">有</label>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  fee.receipt
                  &&
                  <div className="col-item-wrap">
                    <div className="col-item-group">
                      <div className="col-item-title">
                        <div>開立收據</div>
                      </div>
                      <div className="col-item">
                        <div className="col-title">開立日期：</div>
                        <DatePicker className="col-edit"
                          size="small"
                          placeholder="請選擇日期"
                          inputReadOnly={true}
                          showToday={false}
                          format={dateFormat}
                          value={fee.receipt.date ? moment(fee.receipt.date, dateFormat) : null}
                          onChange={value => this.onChangeReceipt('date', value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">收據人：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={fee.receipt.name}
                          onChange={e => this.onChangeReceipt('name', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">請款類別：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={fee.receipt.category}
                          onChange={e => this.onChangeReceipt('category', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">金額：</div>
                        <Input className="col-edit"
                          size="small"
                          type="number"
                          suffix="元"
                          value={fee.receipt.cost}
                          onChange={e => this.onChangeReceipt('cost', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">事由：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={fee.receipt.reason}
                          onChange={e => this.onChangeReceipt('reason', e.target.value)}
                        />
                      </div>
                      <div className="col-item">
                        <div className="col-title">備註：</div>
                        <Input className="col-edit"
                          size="small"
                          type="text"
                          value={fee.receipt.note}
                          onChange={e => this.onChangeReceipt('note', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="col-item-wrap save">
                  <button className="btn-submit" type="submit" disabled={this.props.isPosting}>儲存</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
export default withRouter(CreatCaseComponent);
