import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Select, Upload, message, Modal, Input, Tooltip, DatePicker, Spin } from 'antd';
import { Form, Button } from 'react-bootstrap';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const emptyEditingSent = {
  name: '',
  docketId: '',
  typeId: '',
  recipient: '',
  custodianName: '',
  receivedDate: null,
  sentDate: null,
  deliveryTypeId: ''
}

class MailroomSentListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSentId: null,
      expandSendSet: new Set(),
      editingFileLists: {},
      showEditSent: false,
      editSentId: '',
      newSent: JSON.parse(JSON.stringify(emptyEditingSent)),
    };
  }
  componentDidMount() {
    if(!this.props.isSearch) {
      this.props.onLoadSentList()
    } else {
      this.props.onLoadSentList('search')
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        this.onCleanFiles();
        message.success('上傳成功');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isDeleting === true && this.props.isDeleting === false) {
      if (this.props.errMsg === null) {
        message.success('刪除成功');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if ((prevProps.reloadList === true && this.props.reloadList === false && this.props.isSearch === false) ||
        (prevProps.isSearch === true && this.props.isSearch === false)) {
      this.props.onLoadSentList();
    }
    if (prevProps.isSearch === false && this.props.isSearch === true) {
      this.props.onLoadSentList('search')
    }
  }
  onCleanFiles = () => {
    const { currentSentId, editingFileLists } = this.state;
    const newEditingFileLists = Object.assign({}, editingFileLists);
    newEditingFileLists[currentSentId] = [];
    this.setState({ editingFileLists: newEditingFileLists })
  }
  onCheckSentFileStatus = (info) => {
    if (info.file.status === 'done') {
      this.props.onLoadSentList();
      message.success('上傳成功');
    }
  }
  onSelectSentFile = (sentId, file_) => {
    const { editingFileLists } = this.state;
    let newEditingFileLists = Object.assign({}, editingFileLists);
    if (!newEditingFileLists.hasOwnProperty(sentId)) {
      newEditingFileLists[sentId] = Object.assign([], file_);
    } else if (!Array.isArray(file_)) {
      newEditingFileLists[sentId].splice(newEditingFileLists[sentId].indexOf(file_), 1);
    } else {
      newEditingFileLists[sentId] = newEditingFileLists[sentId].concat(file_);
    }

    this.setState({ editingFileLists: newEditingFileLists, currentSentId: sentId });
  }
  onChangeCurrentSentId = (currentSentId) => {
    this.setState({ currentSentId })
  }
  onSubmitSentFile = (sentId) => {
    this.setState({ currentSentId: sentId }, () => this.props.onUploadSentFile(sentId, this.state.editingFileLists[sentId]))
  }
  onToggleFileList = (sentId) => {
    const { expandSendSet } = this.state;
    if (expandSendSet.has(sentId)) {
      expandSendSet.delete(sentId);
    } else {
      expandSendSet.add(sentId);
    }
    this.setState({ expandSendSet })
  }
  onDeleteSentFile = (fileId, fileName) => {
    Modal.confirm({
      title: `確認刪除： ${fileName}`,
      okText: '刪除',
      cancelText: '取消',
      onOk: () => {
        this.props.onDeleteSentFile(fileId);
      },
    });
  }
  onDownloadSentFile = (fileId, fileName) => {
    this.props.onDownloadSentFile(fileId, fileName);
  }
  onClickHandleSent = (id, recipient, docketId, docketName, deliveryTypeId, receivedDate, sentDate, custodianName, typeId, name) => {
    this.setState({
      showEditSent: true,
      editSentId: id,
      docketName,
      newSent: {
        recipient,
        docketId: `${docketId}`,
        deliveryTypeId: `${deliveryTypeId}`,
        receivedDate,
        sentDate,
        custodianName,
        typeId: `${typeId}`,
        name,
      }
    })
  }
  onUpdateEditingSent = (key, value) => {
    let { newSent } = this.state;
    newSent[key] = value;
    this.setState({ newSent });
  }
  onUpdateDocketId = (type, docketValue) => {
    let docketId = '';
    Object.entries(this.props.dockets.toJS()).map(([key, value]) => {
      if( docketValue === value ) {
        console.log(key, value)
        docketId = key;
        this.setState({ docketName: value })
      }
    })
    if(type === 'editingSent'){
      this.onUpdateEditingSent('docketId', docketId)
    };
  }

  onSubmitUpdateSent = () => {
    const { editSentId, newSent } = this.state;
    if(newSent['name'] === '' && newSent['typeId'] === '' && newSent['receivedDate'] === '' && newSent['recipient'] === '' && newSent['sentDate'] === '' && newSent['deliveryTypeId']){
      return message.error('請輸入發文欄位')
    } else {
      this.props.onUpdateSent(
        editSentId,
        newSent['name'],
        Number(newSent['typeId']),
        moment(newSent['receivedDate']).format('YYYY/MM/DD'),
        newSent['recipient'],
        newSent['custodianName'],
        moment(newSent['sentDate']).format('YYYY/MM/DD'),
        Number(newSent['docketId']),
        Number(newSent['deliveryTypeId']),
      )
      this.setState({ showEditSent: false, editSentId: '', newSent: JSON.parse(JSON.stringify(emptyEditingSent)) })
    }
  }
  onClickToReview(caseId, folderId) {
    if(caseId === null) {
      message.warning('尚未建立委任案件，請建立委任案件後檢閱檔案')
    } else {
      console.log(caseId, folderId)
      this.props.history.push(`attachment/${caseId}`);
      localStorage.setItem("attachmentSelectedFileId", folderId);
      localStorage.setItem("attachmentSelectedFileFolderId", folderId);
    }
  }
  onClickDeleteSent(sentId) {
    Modal.confirm({
      title: '刪除發文',
      content: '按下"確認"按鈕將刪除此發文。',
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteSent(sentId);
      },
    });
  };
  onClickToAppointment(appointmentId) {
    if(!appointmentId){
      return
    } else {
      this.props.onUpdateCachedAppointmentId(parseInt(appointmentId));
      this.props.history.push(`/cases`)
    }
  }
  onCopyDocketIdToCustodianName(docketName) {
    const { newSent } = this.state;
    const custodianNames = this.props.docketNames.toJS();
    Object.entries(this.props.dockets.toJS()).map(([key, value]) => {
      if(docketName === value) {
        this.setState({
          newSent: {
            ...newSent,
            custodianName: custodianNames[key],
          }
        })
      }
    })
  }

  render() {
    const { expandSendSet, editingFileLists, showEditSent, newSent, docketName } = this.state;
    return (
      <div className="section-list mailroomsentlist">
        <ul className="case-list-head">
          <li className="head-name">函文名稱</li>
          <li className="head-docketName">案號</li>
          <li className="head-custodianName">當事人</li>
          <li className="head-type">類型</li>
          <li className="head-deliveryType">遞送方式</li>
          <li className="head-sentDate">發送日</li>
          <li className="head-recipient">收受者</li>
          <li className="head-receivedDate">收受日</li>
          <li className="head-files">檔案</li>
          <li className="head-action">操作</li>
        </ul>
        {
          this.props.sentList && this.props.sentList.map(sent =>
            <div key={`mailroom-sent-${sent.getIn(['id'])}`} className="case-item" onClick={e => this.onChangeCurrentSentId(sent.getIn(['id']))}>
              <ul className="case-list-body">
                <li className="body-name" data-th="函文名稱：">{sent.getIn(['name'])}</li>
                <li className="body-docketName" data-th="案號：" onClick={e => this.onClickToAppointment(sent.getIn(['caseId']))}>{sent.getIn(['docketName'])}</li>
                <li className="body-custodianName" data-th="當事人：">{sent.getIn(['custodianName'])}</li>
                <li className="body-type" data-th="類型：">{this.props.types && this.props.types.getIn([`${sent.getIn(['typeId'])}`])}</li>
                <li className="body-deliveryType" data-th="遞送方式：">{this.props.deliveryTypes && this.props.deliveryTypes.getIn([`${sent.getIn(['deliveryTypeId'])}`])}</li>
                <li className="body-sentDate" data-th="發送日：">{sent.getIn(['sentDate'])}</li>
                <li className="body-recipient" data-th="收受者：">{sent.getIn(['recipient'])}</li>
                <li className="body-receivedDate" data-th="收受日：">{sent.getIn(['receivedDate'])}</li>
                <li className="body-files" data-th="檔案：">
                  <Tooltip title={expandSendSet.has(sent.getIn(['id'])) ? '收起檔案' : '展開檔案'}>
                    <button className="btn-empty"
                      onClick={e => this.onToggleFileList(sent.getIn(['id']))}
                    ><FontAwesomeIcon icon={fas.faPaperclip} /></button>
                    {
                      sent.getIn(['fileList']).size > 0
                      &&
                      `${sent.getIn(['fileList']).size}`
                    }
                  </Tooltip>
                </li>
                <li className="head-action" data-th="操作：">
                  <Tooltip title="編輯">
                    <button 
                      onClick={e => this.onClickHandleSent(
                        sent.getIn(['id']),
                        sent.getIn(['recipient']),
                        sent.getIn(['docketId']),
                        sent.getIn(['docketName']),
                        sent.getIn(['deliveryTypeId']),
                        sent.getIn(['receivedDate']),
                        sent.getIn(['sentDate']),
                        sent.getIn(['custodianName']),
                        sent.getIn(['typeId']),
                        sent.getIn(['name']),
                      )} 
                    ><FontAwesomeIcon icon={fas.faPencilAlt} /></button>
                  </Tooltip>
                  <Tooltip title="刪除發文">
                    <button className="btn-warn" onClick={e => this.onClickDeleteSent(sent.getIn(['id']))}>
                      <i><FontAwesomeIcon icon={fas.faMinus} /></i>
                    </button>
                  </Tooltip>
                </li>
              </ul>
              {
                expandSendSet.has(sent.getIn(['id']))
                &&
                <div className="case-file-extend">
                  <div className="case-file-extend-head">
                    <div className="case-file-extend-title">
                      <div>檔案列表</div>
                      <div className="case-file-list">
                        {
                          sent.getIn(['fileList']).size > 0
                          ?
                          <div className="case-file-group">
                            {
                              sent.getIn(['fileList']).map(file_ =>
                                <div key={`mailroom-file-${file_.getIn(['id'])}`} className="case-file">
                                  <div className="case-file-title" onClick={e => this.onClickToReview(sent.getIn(['caseId']), file_.getIn(['folderId']))}>{file_.getIn(['name'])}</div>
                                  <div className="case-file-tool">
                                    <button
                                      onClick={e => this.onDownloadSentFile(file_.getIn(['id']), file_.getIn(['name']))}
                                    ><FontAwesomeIcon icon={fas.faDownload} /></button>
                                    <button
                                      onClick={e => this.onDeleteSentFile(file_.getIn(['id']), file_.getIn(['name']))}
                                    ><FontAwesomeIcon icon={fas.faTrash} /></button>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                          :
                          <div className="case-file-group">
                            <div className="case-file-group-title">尚無檔案</div>
                          </div>
                        }
                      </div>
                    </div>
                    <Form onSubmit={e => { e.preventDefault(); this.onSubmitSentFile(sent.getIn(['id'])) }}>
                      <Upload multiple={true}
                        fileList={editingFileLists[sent.getIn(['id'])]}
                        onRemove={(file_, fileList) => this.onSelectSentFile(sent.getIn(['id']), file_)}
                        beforeUpload={(file_, fileList) => { this.onSelectSentFile(sent.getIn(['id']), fileList); return false; }}
                      >
                        <Button className="btn-upload">
                          <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>選擇上傳檔案
                        </Button>
                      </Upload>
                      {
                        editingFileLists[sent.getIn(['id'])]
                        &&
                        <button className="upload"><FontAwesomeIcon icon={fas.faPlus} />上傳</button>
                      }
                    </Form>
                  </div>
                </div>
              }
            </div>
          )
        }
        <Modal className="edit-sent"
          title="編輯發文"
          visible={showEditSent}
          okText="儲存發文"
          cancelText="取消"
          onOk={() => this.onSubmitUpdateSent()}
          onCancel={() => this.setState({showEditSent: !showEditSent, editSentId: ''})}
        >
          <div className="col-item">
            <div className="col-title">收受者：</div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="text"
              value={newSent['recipient']}
              onChange={e => this.onUpdateEditingSent('recipient', e.target.value)}
              required
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span className="required">發文日：</span></div>
            <DatePicker className="col-edit"
              size="small"
              placeholder="請選擇日期"
              inputReadOnly={true}
              showToday={false}
              format={dateFormat}
              value={newSent['sentDate'] ? moment(newSent['sentDate'], dateFormat) : null}
              onChange={value => this.onUpdateEditingSent('sentDate', value)}
              required
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span>案號：</span></div>
            <Select className="col-edit"
              size="small"
              placeholder="請輸入關鍵字"
              notFoundContent={this.props.isFetching ? <Spin size="small" /> : '無相關資料'}
              showSearch
              value={ docketName }
              onSearch={value => this.props.onLoadDocketList(value, 10)}
              onChange={value => {this.onUpdateDocketId('editingSent', value); this.onCopyDocketIdToCustodianName(value)}}
            >
              {
                this.props.docketList && this.props.docketList.toJS().map((docket, idx) =>
                  <Option key={idx} value={docket}>{`${docket}`}</Option>
                )
              }
            </Select>
          </div>
          <div className="col-item">
            <div className="col-title"><span>當事人：</span></div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="text"
              value={newSent['custodianName']}
              onChange={e => this.onUpdateEditingSent('custodianName', e.target.value)}
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span className="required">遞送方式：</span></div>
            <Select className="col-edit"
              size="small"
              showSearch
              value={newSent['deliveryTypeId']}
              onChange={value => this.onUpdateEditingSent('deliveryTypeId', value)}
              required
            >

              {
                this.props.deliveryTypeIdList && this.props.deliveryTypeIdList.map(deliveryTypeId =>
                  <Option key={`mailroom-sent-deliverytype-${deliveryTypeId}`} value={deliveryTypeId}>{this.props.deliveryTypes.getIn([deliveryTypeId])}</Option>
                )
              }
            </Select>
          </div>
          <div className="col-item">
            <div className="col-title"><span className="required">類型：</span></div>
            <Select className="col-edit"
              size="small"
              showSearch
              value={newSent['typeId']}
              onChange={value => this.onUpdateEditingSent('typeId', value)}
              required
            >
              {
                this.props.typeIdList && this.props.typeIdList.map(typeId =>
                  <Option key={`mailroom-sent-type-${typeId}`} value={typeId}>{this.props.types.getIn([typeId])}</Option>
                )
              }
            </Select>
          </div>
          <div className="col-item">
            <div className="col-title"><span>收受日：</span></div>
            <DatePicker className="col-edit"
              size="small"
              placeholder="請選擇日期"
              inputReadOnly={true}
              showToday={false}
              format={dateFormat}
              value={newSent['receivedDate'] ? moment(newSent['receivedDate'], dateFormat) : null}
              onChange={value => this.onUpdateEditingSent('receivedDate', value)}
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span className="required">函文名稱：</span></div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="text"
              value={newSent['name']}
              onChange={e => this.onUpdateEditingSent('name', e.target.value)}
              required
            />
          </div>
        </Modal>
      </div>
    )
  }
}
export default withRouter(MailroomSentListComponent);
