// import { createSelector } from 'reselect';

export const getIsPosting = state => state.getIn(['search', 'isPosting']);
export const getErrMsg = state => state.getIn(['search', 'errMsg']);

export const getIsSearching = state => state.getIn(['search', 'isSearching']);
export const getSearchErrMsg = state => state.getIn(['search', 'searchErrMsg']);
export const getTotalHits = state => state.getIn(['search', 'totalHits']);
export const getHitList = state => state.getIn(['search', 'hitList']);

export const getCaseList = state => state.getIn(['search', 'caseList']);
