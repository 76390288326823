import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import NavbarComponent from './component';


const mapStateToProps = (state) => ({
  showRWDNavbar: selectors.getShowRWDNavbar(state),
});

const mapDispatchToProps = dispatch => ({  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarComponent);