import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { checkToken } from './api';


export default function WithAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentDidMount() {
      checkToken().then(data => {
        this.setState({ loading: false });
      }).catch((errMsg) => {
        this.setState({ loading: false, redirect: true });
      })        
    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  }
}