import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import CasesReceiptComponent from './component';

const mapStateToProps = (state) => ({
  // common
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),  
  casesReceipt: selectors.getExportReceipt(state),
  lawer: selectors.getLawer(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadProfile: (userId) => {
    dispatch(actions.doLoadProfile(userId));
  },
  onLoadExportReceipt: (caseId) => {
    dispatch(actions.doLoadExportReceipt(caseId));
  },
  onUpdateReceipt: (caseId, category, cost, date, name, note, reason) => {
    dispatch(actions.doUpdateReceipt(caseId, category, cost, date, name, note, reason));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasesReceiptComponent);