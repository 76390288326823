import { fromJS  } from 'immutable';

export const State = fromJS({
  isGetting: false,
  isDeleting: false,
  isGettingAppointmentList: false,
  isGettingAppointmentDetail: false,
  currentAppointmentId: null,
  currentDetailTab: 'case',
  errMsg: '',
  appointmentIdList: [],
  appointments: {},
  appointmentDetail: {
    appoint: {},
    custodian: {},
    case: {},
    notes: {},
    fee: {},
    files: {},
    dates: {}
  },
  cachedAppointmentId: null
});