import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  updateAppointmentAppointor,
  updateAppointmentCompany
} from '../../utils/api';

// common
export const putRequest = createAction(types.PUT_REQUEST)
export const putSuccess = createAction(types.PUT_SUCCESS)
export const putFailure = createAction(types.PUT_FAILURE)

export function doUpdateAppointor(appointmentId, appointDate, name, address, phone, email, relationship, ssid, birthday) {
  return dispatch => {
    dispatch(putRequest());
    updateAppointmentAppointor(appointmentId, appointDate, name, address, phone, email, relationship, ssid, birthday).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doUpdateCompany(appointmentId, appointDate, name, address, phone, email, ban, representative, contactPerson) {
  return dispatch => {
    dispatch(putRequest());
    updateAppointmentCompany(appointmentId, appointDate, name, address, phone, email, ban, representative, contactPerson).then(data => {
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

