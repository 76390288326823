import Password from 'antd/lib/input/Password';
import React, { Component } from 'react';
import registration from '../modules/registration';


class RegistrationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-registration">
        <registration.container />
	    </div>
	  )
  }
}

export default RegistrationPageComponent;