// common
export const getErrMsg = state => state.getIn(['attachment', 'errMsg']);
// case
export const getIsLoading = state => state.getIn(['attachment', 'isLoading']);
export const getIsUploading = state => state.getIn(['attachment', 'isUploading']);
export const getFolders = state => state.getIn(['attachment', 'folders']);
// attachment
export const getCaseAttachment = state => state.getIn(['attachment', 'attachmentDict']);
export const getIsExceededQuota = state => state.getIn(['attachment', 'isExceededQuota']);
// pdf
export const getHitList = state => state.getIn(['attachment', 'hitList']);
