import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  isPutting: false,
  isDeleting: false,
  receivedList: [],
  errMsg: '',
  dockets: {},
  docketNames: {},
  docketList: [],
  cachedReceivedId: null,
  csvRowList: [['函文名稱', '案號', '當事人', '類型', '期限日', '收文日', '來文者']]
});