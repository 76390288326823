import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'profile/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'profile/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'profile/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'profile/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'profile/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'profile/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'profile/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'profile/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'profile/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'profile/UPDATE_PROFILE': (state, { payload }) => {
    return state.setIn(['firstName'], payload.firstName)
                .setIn(['lastName'], payload.lastName)
                .setIn(['address'], payload.address)
                .setIn(['phoneNo'], payload.phoneNo)
                .setIn(['email'], payload.email)
                .setIn(['mobile'], payload.mobile)
                .setIn(['bank'], payload.lawFirmAccountName)
                .setIn(['bankAccount'], payload.lawFirmAccountNo)
                .setIn(['twoFactorAuthenticationId'], payload.twoFactorAuthenticationId)
                .setIn(['lawFirmLogo'], payload.lawFirmLogo)
                .setIn(['lawFirmName'], payload.lawFirmName)
                .setIn(['pinCode'], payload.pinCode);
  },
}, State);

export default Reducer;