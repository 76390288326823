import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { deleteAllCookies } from '../../utils/cookie';
import {
  createToken,
  snedSmsToken,
  checkSmsToken,
  cLogout
} from '../../utils/api';


export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)
export const getRequest = createAction(types.GET_REQUEST)
export const getSuccess = createAction(types.GET_SUCCESS)
export const getFailure = createAction(types.GET_FAILURE)

export const updateRedirectTo = createAction(types.UPDATE_REDIRECT_TO)
export const updateTwoFactorAuthenticationId = createAction(types.UPDATE_TWO_FACTOR_AUTHENTICATION_ID)
export const updateLoginCheckNotification = createAction(types.UPDATE_LOGIN_CHECK_NOTIFICATION)
export function doSubmitLogin(account, password) {
  return dispatch => {
    dispatch(postRequest());
    dispatch(updateLoginCheckNotification(false));
    return createToken(account, password).then(data => {
      localStorage.setItem('token', data.lawDnaAccessToken);
      localStorage.setItem('userId', data.userId);
      if (data.twoFactorAuthenticationId === 2) {
        dispatch(updateTwoFactorAuthenticationId(2));
      } else {
        dispatch(updateLoginCheckNotification(true));
        if ( data.confirm === false ) {
          dispatch(updateRedirectTo('/contract'));
        } else {
          dispatch(updateRedirectTo('/cases'));
        }
      }
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doSendSms() {
  return dispatch => {
    dispatch(getRequest());
    return snedSmsToken().then(data => {
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export function doCheckSms(smsToken) {
  return dispatch => {
    dispatch(postRequest(smsToken));
    return checkSmsToken(smsToken).then(data => {
      dispatch(updateRedirectTo('/cases'));
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}