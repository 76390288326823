// common
export const POST_REQUEST = 'appointmentList/POST_REQUEST'
export const POST_SUCCESS = 'appointmentList/POST_SUCCESS'
export const POST_FAILURE = 'appointmentList/POST_FAILURE'
export const PUT_REQUEST = 'appointmentList/PUT_REQUEST'
export const PUT_SUCCESS = 'appointmentList/PUT_SUCCESS'
export const PUT_FAILURE = 'appointmentList/PUT_FAILURE'
export const DELETE_REQUEST = 'appointmentList/DELETE_REQUEST'
export const DELETE_SUCCESS = 'appointmentList/DELETE_SUCCESS'
export const DELETE_FAILURE = 'appointmentList/DELETE_FAILURE'
// appointmentList
export const GETTING_APPOINTMENT_LIST_REQUEST = 'appointmentList/GETTING_APPOINTMENT_LIST_REQUEST'
export const GETTING_APPOINTMENT_LIST_SUCCESS = 'appointmentList/GETTING_APPOINTMENT_LIST_SUCCESS'
export const GETTING_APPOINTMENT_LIST_FAILURE = 'appointmentList/GETTING_APPOINTMENT_LIST_FAILURE'
export const GETTING_APPOINTMENT_DETAIL_REQUEST = 'appointmentList/GETTING_APPOINTMENT_DETAIL_REQUEST'
export const GETTING_APPOINTMENT_DETAIL_SUCCESS = 'appointmentList/GETTING_APPOINTMENT_DETAIL_SUCCESS'
export const GETTING_APPOINTMENT_DETAIL_FAILURE = 'appointmentList/GETTING_APPOINTMENT_DETAIL_FAILURE'
export const UPDATE_CURRENT_APPOINTMENT_ID = 'appointmentList/UPDATE_CURRENT_APPOINTMENT_ID'
export const UPDATE_CURRENT_DETAIL_TAB = 'appointmentList/UPDATE_CURRENT_DETAIL_TAB'
export const UPDATE_APPOINTMENT_LIST = 'appointmentList/UPDATE_APPOINTMENT_LIST'
export const UPDATE_APPOINTMENT_DETAIL = 'appointmentList/UPDATE_APPOINTMENT_DETAIL'
export const UPDATE_CACHED_APPOINTMENT_ID = 'appointmentList/UPDATE_CACHED_APPOINTMENT_ID'