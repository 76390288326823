import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'appointmentCase/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentCase/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentCase/DELETE_REQUEST': (state, { payload }) => {
    return state.setIn(['isDeleting'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/DELETE_SUCCESS': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/DELETE_FAILURE': (state, { payload }) => {
    return state.setIn(['isDeleting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentCase/GET_CASE_RELATED_REQUEST': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], true)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/GET_CASE_RELATED_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], false)
                .setIn(['errMsg'], null);
  },
  'appointmentCase/GET_CASE_RELATEDT_FAILURE': (state, { payload }) => {
    return state.setIn(['isGettingCaseRelated'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'appointmentCase/UPDATE_CASE_RELATE_LIST': (state, { payload }) => {
    return state.setIn(['caseRelateList'],  fromJS(payload));
  },
}, State);

export default Reducer;