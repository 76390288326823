import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'reviewNoteDraggable/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'reviewNoteDraggable/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'reviewNoteDraggable/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'reviewNoteDraggable/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'reviewNoteDraggable/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'reviewNoteDraggable/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'reviewNoteDraggable/PUT_POSITION_REQUEST': (state, { payload }) => {
    return state.setIn(['isPuttingPosition'], true)
                .setIn(['errMsg'], null);
  },
  'reviewNoteDraggable/PUT_POSITION_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPuttingPosition'], false)
                .setIn(['errMsg'], null);
  },
  'reviewNoteDraggable/PUT_POSITION_FAILURE': (state, { payload }) => {
    return state.setIn(['isPuttingPosition'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'reviewNoteDraggable/PUT_CURRENT_NOTE_ID': (state, { payload }) => {
    return state.setIn(['currentNoteId'], payload);
  },
}, State);

export default Reducer;