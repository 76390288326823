// common
export const SEARCH_REQUEST = 'mailroom/SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'mailroom/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'mailroom/SEARCH_FAILURE'
export const POST_REQUEST = 'mailroom/POST_REQUEST'
export const POST_SUCCESS = 'mailroom/POST_SUCCESS'
export const POST_FAILURE = 'mailroom/POST_FAILURE'
export const GET_REQUEST = 'mailroom/GET_REQUEST'
export const GET_SUCCESS = 'mailroom/GET_SUCCESS'
export const GET_FAILURE = 'mailroom/GET_FAILURE'

export const UPDATE_COURT_LIST = 'mailroom/UPDATE_COURT_LIST'
export const UPDATE_TYPE_LIST = 'mailroom/UPDATE_TYPE_LIST'
export const UPDATE_DOCKET_LIST = 'mailroom/UPDATE_DOCKET_LIST'
export const UPDATE_DELIVERY_TYPE_LIST = 'mailroom/UPDATE_DELIVERY_TYPE_LIST'
export const UPDATE_SEARCH_MAILROOM = 'mailroom/UPDATE_SEARCH_MAILROOM'