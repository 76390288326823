// common
export const POST_REQUEST = 'mailroomSentList/POST_REQUEST'
export const POST_SUCCESS = 'mailroomSentList/POST_SUCCESS'
export const POST_FAILURE = 'mailroomSentList/POST_FAILURE'
export const GET_REQUEST = 'mailroomSentList/GET_REQUEST'
export const GET_SUCCESS = 'mailroomSentList/GET_SUCCESS'
export const GET_FAILURE = 'mailroomSentList/GET_FAILURE'
export const PUT_REQUEST = 'mailroomSentList/PUT_REQUEST'
export const PUT_SUCCESS = 'mailroomSentList/PUT_SUCCESS'
export const PUT_FAILURE = 'mailroomSentList/PUT_FAILURE'
export const DELETE_REQUEST = 'mailroomSentList/DELETE_REQUEST'
export const DELETE_SUCCESS = 'mailroomSentList/DELETE_SUCCESS'
export const DELETE_FAILURE = 'mailroomSentList/DELETE_FAILURE'

export const UPDATE_RECEIVED_LIST = 'mailroomSentList/UPDATE_RECEIVED_LIST'
export const UPDATE_DOCKET_LIST = 'mailroomSentList/UPDATE_DOCKET_LIST'
export const UPDATE_CSV_ROW_LIST = 'mailroomSentList/UPDATE_CSV_ROW_LIST'