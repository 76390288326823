import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { Select, Upload, Icon, message, Modal, Input, Spin, Tooltip, DatePicker, Checkbox } from 'antd';
import { Form } from 'react-bootstrap';
import { DATE_FORMAT, TAX_VOUCHER, TAXABLE } from '../../config';
import reviewNoteDraggable from '../reviewNoteDraggable';

const windowHeight = window.innerHeight || document.body.clientHeight;
const windowWidth = window.innerWidth || document.body.clientWidth;
message.config({
  top: windowHeight / 2,
});
const DEFAULT_NOTE = {
  id: 0,
  title: '',
  content: '',
  position: {x: 0, y: 0},
  visible: false,
  isEdit: false
}


class ReviewNotesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newNote: JSON.parse(JSON.stringify(DEFAULT_NOTE))
    };
    this.onToggleNewNoteDraggable = this.onToggleNewNoteDraggable.bind(this);
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.pageId && prevProps.pageId !== this.props.pageId) {
      this.props.onLoadPageNoteList(this.props.pageId);
    }
  }
  onToggleNewNoteDraggable(noteId) {
    const newNote = JSON.parse(JSON.stringify(DEFAULT_NOTE));
    if (noteId === '0') {
      newNote.visible = false;
      newNote.isEdit = false;
    } else {
      newNote.visible = true;
      newNote.isEdit = true;
    }
    this.setState({ newNote });
  }
  render() {
    const { newNote } = this.state;
    return (
      <div className={this.props.visible ? "right-item-wrapper sidebar open" : "right-item-wrapper sidebar"} id="notes-wrapper">
        <div className="right-item-title" onClick={e => this.props.onToggleNotes(!this.props.visible)}>
          <div className="title-wrapper">
            <FontAwesomeIcon className="title-active-mark" icon={this.props.visible ? fas.faAngleDown : fas.faAngleRight}/>
            <span className="title-name">筆記</span>
          </div>
          {
            this.props.pageId
            &&
            <div className="tool-wrapper">
              <button type="button"
                className="btn btn-outline-info"
                onClick={e => {e.stopPropagation();this.onToggleNewNoteDraggable('new')}}>新增筆記
              </button>
            </div>
          }
        </div>
        <ul className="sidebar-notes right-item-content">
          {
            this.props.noteIdList.map(noteId =>
              <li className="sidebar-notes-item" key={`pagenotelist-${noteId}`} onClick={e => this.props.onUpdateNoteVisible(noteId, true)}>
                <i>{this.props.currentNoteId === noteId ? <FontAwesomeIcon icon={fas.faCommentAlt}/> : <FontAwesomeIcon icon={far.faCommentAlt}/>}</i>
                <div className="item-date">{ this.props.notes.getIn([noteId, 'lastModifiedDate']) }</div>
                <div className="item-title">{ this.props.notes.getIn([noteId, 'title']) }</div>
                <div className="item-content">{ this.props.notes.getIn([noteId, 'content']) }</div>
              </li>
            )
          }
        </ul>
        {
          this.props.noteIdList.map(noteId =>
            <reviewNoteDraggable.container 
              key={`notedraggable-${noteId}`}
              note={this.props.notes.getIn([noteId]).toJS()}
              pageId={this.props.pageId}
              onToggleNoteDraggable={this.props.onUpdateNoteVisible}
              onLoadPageNoteList={this.props.onLoadPageNoteList}
            />
          )
        }
        <reviewNoteDraggable.container 
          key={`notedraggable-0`}
          note={newNote}
          pageId={this.props.pageId}
          onToggleNoteDraggable={this.onToggleNewNoteDraggable}
          onLoadPageNoteList={this.props.onLoadPageNoteList}
        />
      </div>
    )
  }
}
export default withRouter(ReviewNotesComponent);