import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import SearchComponent from './component';

// 如何轉換目前 Redux store state 成為你想要傳到正在包裝的 presentational component 的 props
const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),
  // searches
  isSearching: selectors.getIsSearching(state),
  searchErrMsg: selectors.getSearchErrMsg(state),
  totalHits: selectors.getTotalHits(state),
  hitList: selectors.getHitList(state),
  // 
  caseList: selectors.getCaseList(state),
});

// 接收 dispatch() method 並回傳你想要注入 presentational component 的 callback props
const mapDispatchToProps = dispatch => ({
  onLoadCaseNames: () => {
    dispatch(actions.doLoadCaseNames());
  },
  onClearSearchResult: () => {
    dispatch(actions.doClearSearchResult());
  },
  onSubmitSearch: (fromYear, toYear, case_, fromNo, toNo, content, summary, judge, text, referenceName, referenceNo, isExecuteChecked, isInterpretationChecked, fjudCategorySet, fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudeCategorySet, fjudeCourtSet) => {
    dispatch(actions.doSubmitSearch(fromYear, toYear, case_, fromNo, toNo, content, summary, judge, text, referenceName, referenceNo, isExecuteChecked, isInterpretationChecked, fjudCategorySet, fjudSupremeCourtSet, fjudHighCourtSet, fjudDistrictCourtSet, fjudOtherCourtSet, fjudeCategorySet, fjudeCourtSet));
  },
  onGetSearch: (index, page, cacheId) => {
    dispatch(actions.doGetSearch(index, page, cacheId));
  },
  onClickSearchResult: (esId, link) => {
    dispatch(actions.doClickSearchResult(esId, link));
  },
  onSubmitAddJudgment: (caseId, judicialId, esId) => {
    dispatch(actions.doSubmitAddJudgment(caseId, judicialId, esId));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent);