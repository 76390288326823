// common
export const PUT_REQUEST = 'consultationFiles/PUT_REQUEST'
export const PUT_SUCCESS = 'consultationFiles/PUT_SUCCESS'
export const PUT_FAILURE = 'consultationFiles/PUT_FAILURE'
export const POST_REQUEST = 'consultationFiles/POST_REQUEST'
export const POST_SUCCESS = 'consultationFiles/POST_SUCCESS'
export const POST_FAILURE = 'consultationFiles/POST_FAILURE'
export const GET_REQUEST = 'consultationFiles/GET_REQUEST'
export const GET_SUCCESS = 'consultationFiles/GET_SUCCESS'
export const GET_FAILURE = 'consultationFiles/GET_FAILURE'
export const DELETE_REQUEST = 'consultationFiles/DELETE_REQUEST'
export const DELETE_SUCCESS = 'consultationFiles/DELETE_SUCCESS'
export const DELETE_FAILURE = 'consultationFiles/DELETE_FAILURE'