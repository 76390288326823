import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import dnaBgIcon from '../../assets/img/dna-bg.png'


class ResetPasswordWithOldPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
  }
  render() {
    const {
      oldPassword, newPassword, confirmPassword, errMessage,
      onUpdateOldPassword, onUpdateNewPassword, onUpdateConfirmPassword, onSubmitResetPassword
    } = this.props;
  	return (
      <div className="container">
        <nav className="container p-3 mb-5">
          <img className="logo mr-3" alt="" src={dnaLogoIcon} onClick={e => this.props.history.push(`/cases`)}/>
          <h4 className="text-black-50">設定個人資料</h4>
        </nav>
        <div className="mb-5 profile_info">
          <div className="alert alert-light pl-5 pr-5" role="alert">
            <ul>
              <li>更改密碼注意事項</li>
              <li>更改密碼至少要 8 個字元以上且含有字母、數字、符號</li>
            </ul>
          </div>
        </div>
        <img className="login-logo-bg" alt="" src={dnaBgIcon}/>
        <div className="profile_wrap">
          <div className="row">
            <div className="col-4 profile_disable border-right">
              <h4 className="text-black-50">更改密碼</h4>
            </div>
            <div className="col-8">
              <form onSubmit={(event) => {event.preventDefault();onSubmitResetPassword(this.props.history)}}>
                <div className="form-group row">
                  <label htmlFor="old_password" className="col-sm-3 col-form-label">舊密碼</label>
                  <div className="col-sm-9 d-flex justify-content-between">
                    <input className="form-control"
                      value={oldPassword}
                      onChange={(event) => onUpdateOldPassword(event.target.value)}
                      type="password"
                      id="old_password"
                      placeholder="舊密碼"
                      required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="new_password" className="col-sm-3 col-form-label">新密碼</label>
                  <div className="col-sm-9">
                    <input className="form-control"
                      value={newPassword}
                      onChange={(event) => onUpdateNewPassword(event.target.value)}
                      type="password"
                      id="new_password"
                      placeholder="新密碼"
                      required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="check_new_password" className="col-sm-3 col-form-label">再次輸入新密碼</label>
                  <div className="col-sm-9">
                    <input className="form-control"
                      value={confirmPassword}
                      onChange={(event) => onUpdateConfirmPassword(event.target.value)}
                      type="password"
                      id="check_new_password"
                      placeholder="再次輸入新密碼"
                      required/>
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="btn btn-outline-success w-25 mb-3">
                    更改密碼
                  </button>
                </div>
                {
                  errMessage
                  ?
                  <div className="alert alert-danger" role="alert">
                    {errMessage}
                  </div>
                  :
                  ''
                }
              </form>
            </div>
          </div>
        </div>
        <div className="text-center mt-5 border-top">
          <button type="button" className="btn btn-outline-info w-50 mt-5" onClick={e => this.props.history.push(`/profile`)}>返回設定</button>
        </div>
	    </div>
	  )
  }
}

export default withRouter(ResetPasswordWithOldPasswordComponent);
