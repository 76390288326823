import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Select, message, Modal, Input, DatePicker } from 'antd';
import moment from 'moment';
import { CASE_TYPE, APPOINTOR_TYPE, DATE_FORMAT } from '../../config'
import TextArea from 'antd/lib/input/TextArea';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;
// const costMaxLength = /d(8,8)/;

const APPOINTOR = {
  ssid: '',
  address: '',
  birthday: '',
  email: '',
  name: '',
  phone: '',
  relationship: '',
}
const COMPANY = {
  ban: '',
  address: '',
  contactPerson: '',
  email: '',
  name: '',
  phone: '',
  representative: '',
}
const LAWER = {
  address: '',
  faxNo: '',
  lawFirmName: '',
  name: '',
  phoneNo: '',
}
const RELATED = {
  v: '為事件，委任人茲委任受任人為代理人，就本事件為一切訴訟行為之權限，並有民事訴訟法第七十條第一項但書第二項所列各行為之特別代理權，爰依同法第六十九條上半段規定及司法院三十二年院字第二四七八號解釋合提出委任狀如上。',
  m: '案件，委任人謹依刑事訴訟法第二十七條第一項及第三十條等規定，選任受任人為辯護人合提出委任狀如上'
}

class PowerOfAttorneyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHolding: false,
      isEditing: false,
      appointorType: '',
      typeId: '',
      appointor: APPOINTOR,
      company: COMPANY,
      lawer: LAWER,
      docket: '',
      related_v: RELATED.v,
      related_m: RELATED.m,
      courts: '',
      date: {
        year: '',
        month: '',
        day: '',
      },
      agents: ''
    };
  }

  componentDidMount() {
    this.props.onLoadPowerOfAttorney(this.props.caseId);
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.appointorType !== '' && prevProps.isGeting === true && this.props.isGeting === false){
      this.onClickUpdateReceipt('init', this.props.appointorType)
    }
  }
  onClickUpdateReceipt(action, value) {
    console.log(action, value)
    const { newReceipt, appointor, lawer } = this.state;
    if(action === 'init') {
      if(value === null){
        this.setState({ isHolding: false })
      }else{
        let appointor = JSON.parse(JSON.stringify(this.props.appointor));
        let company = JSON.parse(JSON.stringify(this.props.company));
        let lawer = JSON.parse(JSON.stringify(this.props.lawer));
        this.setState({
          isHolding: true,
          appointorType: value,
          typeId: this.props.typeId,
          // lawer: {
          //   address: this.props.lawer['address'],
          //   faxNo: this.props.lawer['faxNo'],
          //   lawFirmName: this.props.lawer['lawFirmName'],
          //   name: this.props.lawer['name'],
          //   phoneNo: this.props.lawer['phoneNo'],
          // },
          lawer,
        })
        if (value === 1) {
          this.setState({
            appointor,
            company,
          })
        }
      }
    }
    if(action === 'edit') {
      this.setState({
        isEditing: false,
      })
    }
    if(action === 'save') {
      let { appointor } = this.state;
      this.setState({
        isEditing: true,
        appointor,
        lawer,
      })
    }
  }
  onChangeAppointor(key, value){
    console.log(key, value)
    let { appointor } = this.state;
    appointor = JSON.parse(JSON.stringify(appointor));
    appointor[key] = value;
    this.setState({ appointor })
    console.log(this.state.appointor)
  }
  onChangeCompany(key, value) {
    let { company } = this.state;
    company = JSON.parse(JSON.stringify(company));
    company[key] = value;
    this.setState({ company })
  }
  onChangeLawer(key, value){
    let { lawer } = this.state;
    lawer = JSON.parse(JSON.stringify(lawer));
    lawer[key] = value;
    this.setState({ lawer })
  }

  render() {
    const { isHolding, isEditing, receipt, newReceipt, docket, related_v, related_m, courts, date, appointor, company, lawer, agents } = this.state;
    const { appointorType, typeId } = this.props;
  	return (
      <div className="powerOfAttorney-section powerOfAttorney">
        <div className="powerOfAttorney-report">
          <div className="navBar noPrint">
            <button><Link to={`/cases`}>返回委任管理列表</Link></button>
            <div className="title">委任狀預覽列印</div>
          </div>
          {
            isHolding
            ?
            <div className="case-receipt-wrap">
              <div className="tool noPrint">
                {
                  isEditing
                  ?
                  <button onClick={e => this.onClickUpdateReceipt('edit', '')}>編輯</button>
                  :
                  <button onClick={e => this.onClickUpdateReceipt('save', '')}>預覽列印</button>
                }
              </div>
              {
                isEditing
                ?
                <div className="case-receipt">
                  <div className="case-receipt-title">
                    {
                      CASE_TYPE && CASE_TYPE.map(type => {
                        if(type['id'] === typeId){ return type['name'] }
                      })
                    }
                    委任狀
                  </div>
                  <div className="case-receipt-row item-caseDocket">
                    <div className="item">
                      <div className="title">案號：</div>
                      <div className="txt">{docket}</div>
                    </div>
                  </div>
                  <div className="case-receipt-row">
                    {
                      appointorType === 1
                      &&
                      <div className="item-col">
                        <div className="item-col-title">委任人</div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">委任人：</div>
                            <div className="content">{appointor.name}</div>
                          </div>
                          <div className="item">
                            <div className="title">身分證號：</div>
                            <div className="content">{appointor.ssid}</div>
                          </div>
                          <div className="item">
                            <div className="title">出生日期：</div>
                            <div className="content">{appointor.birthday}</div>
                          </div>
                        </div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">聯絡電話：</div>
                            <div className="content">{appointor.phone}</div>
                          </div>
                          <div className="item">
                            <div className="title">住址：</div>
                            <div className="content">{appointor.address}</div>
                          </div>
                          <div className="item">
                            <div className="title">Email：</div>
                            <div className="content">{appointor.email}</div>
                          </div>
                          <div className="item"></div>
                        </div>
                      </div>
                    }
                    {
                      appointorType === 2
                      &&
                      <div className="item-col">
                        <div className="item-col-title">委任人</div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">公司名稱：</div>
                            <div className="content">{company.name}</div>
                          </div>
                          <div className="item">
                            <div className="title">法定代理人：</div>
                            <div className="content">{company.representative}</div>
                          </div>
                          <div className="item"></div>
                        </div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">聯絡電話：</div>
                            <div className="content">{company.phone}</div>
                          </div>
                          <div className="item">
                            <div className="title">住址：</div>
                            <div className="content">{company.address}</div>
                          </div>
                          <div className="item">
                            <div className="title">Email：</div>
                            <div className="content">{company.email}</div>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="item-col">
                      <div className="item-col-title">受任人</div>
                      <div className="item-group">
                        <div className="item">
                          <div className="content content-center">{lawer.lawFirmName}</div>
                        </div>
                        <div className="item">
                          <div className="content content-center">{lawer.name}律師</div>
                        </div>
                        <div className="item"></div>
                      </div>
                      <div className="item-group">
                        <div className="item">
                          <div className="title">住址：</div>
                          <div className="content">{lawer.address}</div>
                        </div>
                        <div className="item">
                          <div className="title">聯絡電話：</div>
                          <div className="content">{lawer.phoneNo}</div>
                        </div>
                        <div className="item">
                          <div className="title">聯絡手機：</div>
                          <div className="content">{lawer.mobile}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-content">
                    <div>{related_v}</div>
                    <span>謹呈</span><br />
                    <span>{courts}</span>  <span>公鑒</span>
                  </div>
                  <div className="case-receipt-row item-signature">
                    <div className="item">
                      <div className="title">委任人：</div>
                      <div className="content">
                        {
                          appointorType === 1 ?
                            appointor.name
                          : appointorType === 2 ?
                            company.name
                          :
                            null
                        }
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">法定代理人：</div>
                      <div className="content">
                        {
                          appointorType === 1 ?
                            appointor.representative
                          : appointorType === 2 ?
                            company.representative
                          :
                            null
                        }
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">受任人：</div>
                      <div className="content">{lawer.name}  律師</div>
                    </div>
                  </div>
                  <div className="case-receipt-row item-date">
                    <span className="text">中華民國</span>
                    <span>{date['year']}</span>
                    <span className="text">年</span>
                    <span>{date['month']}</span>
                    <span className="text">月</span>
                    <span>{date['day']}</span>
                    <span className="text">日</span>
                  </div>
                </div>
                :
                <div className="case-receipt">
                  <div className="case-receipt-title">
                    {
                      CASE_TYPE && CASE_TYPE.map(type => {
                        if(type['id'] === typeId){ return type['name'] }
                      })
                    }
                    委任狀
                  </div>
                  <div className="case-receipt-row item-caseDocket">
                    <div className="title">案號：</div>
                    <Input className="input-docket"
                      placeholder="請輸入案號"
                      allowClear
                      type="text"
                      value={docket}
                      onChange={e => this.setState({ docket: e.target.value })}
                    />
                  </div>
                  <div className="case-receipt-row">
                    {
                      appointorType === 1
                      &&
                      <div className="item-col">
                        <div className="item-col-title">委任人</div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">委任人：</div>
                            <Input className="input-"
                              placeholder="委任人"
                              allowClear
                              type="text"
                              value={appointor.name}
                              onChange={e => this.onChangeAppointor('name', e.target.value)}
                            />
                          </div>
                          <div className="item">
                            <div className="title">身分證號：</div>
                            <Input className="input-"
                              placeholder="身分證字號"
                              allowClear
                              type="text"
                              value={appointor.ssid}
                              onChange={e => this.onChangeAppointor('ssid', e.target.value)}
                            />
                          </div>
                          <div className="item">
                            <div className="title">出生日期：</div>
                            <Input className="input-"
                              placeholder="出生日期"
                              allowClear
                              type="text"
                              value={appointor.birthday}
                              onChange={e => this.onChangeAppointor('birthday', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">聯絡電話：</div>
                            <Input className="input-"
                              placeholder="聯絡電話"
                              allowClear
                              type="text"
                              value={appointor.phone}
                              onChange={e => this.onChangeAppointor('phone', e.target.value)}
                            />
                          </div>
                          <div className="item">
                            <div className="title">住址：</div>
                            <Input className="input-"
                              placeholder="住址"
                              allowClear
                              type="text"
                              value={appointor.address}
                              onChange={e => this.onChangeAppointor('address', e.target.value)}
                            />
                          </div>
                          <div className="item">
                            <div className="title">Email：</div>
                            <Input className="input-"
                              placeholder="Email"
                              allowClear
                              type="text"
                              value={appointor.email}
                              onChange={e => this.onChangeAppointor('email', e.target.value)}
                            />
                          </div>
                          <div className="item"></div>
                        </div>
                      </div>
                    }
                    {
                      appointorType === 2
                      &&
                      <div className="item-col">
                        <div className="item-col-title">委任人</div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">公司名稱：</div>
                            <div className="content">{company.name}</div>
                          </div>
                          <div className="item">
                            <div className="title">法定代理人：</div>
                            <div className="content">{company.representative}</div>
                          </div>
                          <div className="item"></div>
                        </div>
                        <div className="item-group">
                          <div className="item">
                            <div className="title">聯絡電話：</div>
                            <div className="content">{company.phone}</div>
                          </div>
                          <div className="item">
                            <div className="title">住址：</div>
                            <div className="content">{company.address}</div>
                          </div>
                          <div className="item">
                            <div className="title">Email：</div>
                            <div className="content">{company.email}</div>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="item-col">
                      <div className="item-col-title">受任人</div>
                      <div className="item-group">
                        <div className="item">
                          <Input className="input-"
                            placeholder="Email"
                            allowClear
                            type="text"
                            value={lawer.lawFirmName}
                            onChange={e => this.onChangeLawer('lawFirmName', e.target.value)}
                          />
                        </div>
                        <div className="item">
                          <Input className="input-"
                            placeholder="Email"
                            allowClear
                            suffix="律師"
                            type="text"
                            value={lawer.name}
                            onChange={e => this.onChangeLawer('name', e.target.value)}
                          />
                        </div>
                        <div className="item"></div>
                      </div>
                      <div className="item-group">
                        <div className="item">
                          <div className="title">住址：</div>
                          <Input className="input-"
                            placeholder="Email"
                            allowClear
                            type="text"
                            value={lawer.address}
                            onChange={e => this.onChangeLawer('address', e.target.value)}
                          />
                        </div>
                        <div className="item">
                          <div className="title">聯絡電話：</div>
                          <Input className="input-"
                            placeholder="Email"
                            allowClear
                            type="text"
                            value={lawer.phoneNo}
                            onChange={e => this.onChangeLawer('phoneNo', e.target.value)}
                          />
                        </div>
                        <div className="item">
                          <div className="title">聯絡手機：</div>
                          <Input className="input-"
                            placeholder="Email"
                            allowClear
                            type="text"
                            value={lawer.mobile}
                            onChange={e => this.onChangeLawer('mobile', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    (typeId === 1 || typeId === 3 || typeId === 4 || typeId === 5)
                    &&
                    <div className="item-textArea">
                      <TextArea className="input"
                        placeholder="請輸入事件。e.g. 損害賠償"
                        type="text"
                        rows="5"
                        value={related_v}
                        onChange={e => this.setState({ related_v: e.target.value })}
                      />
                      <span>謹呈</span><br />
                      <Input className="input"
                        placeholder="請輸入法院。"
                        allowClear
                        type="text"
                        value={courts}
                        onChange={e => this.setState({ courts: e.target.value })}
                      />  <span>公鑒</span>
                    </div>
                  }
                  {
                    typeId === 2
                    &&
                    <div className="case-receipt-row item-content">
                      <TextArea className="input"
                        placeholder="請輸入事件。e.g. 損害賠償"
                        type="text"
                        rows="5"
                        value={related_m}
                        onChange={e => this.setState({ related_m: e.target.value })}
                      />
                      <span>謹呈</span><br />
                      <Input className="input"
                        placeholder="請輸入法院。"
                        type="text"
                        value={courts}
                        onChange={e => this.setState({ courts: e.target.value })}
                      />  <span>公鑒</span>
                    </div>
                  }
                  <div className="case-receipt-row item-signature">
                    <div className="item">
                      <div className="title">委任人：</div>
                      <div className="content">
                        <Input className="input-"
                          placeholder="委任人"
                          allowClear
                          type="text"
                          value={appointor.name}
                          onChange={e => this.onChangeAppointor('name', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">法定代理人：</div>
                      <div className="content">
                        <Input className="input-"
                          placeholder="法定代理人"
                          allowClear
                          type="text"
                          value={appointor.representative}
                          onChange={e => this.onChangeAppointor('representative', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">受任人：</div>
                      {/* <div className="content">{this.props.lawer.getIn(['name'])}  律師</div> */}
                      <Input className="input-"
                        placeholder="Email"
                        allowClear
                        suffix="律師"
                        type="text"
                        value={lawer.name}
                        onChange={e => this.onChangeLawer('name', e.target.value)}
                      />
                    </div>
                    <div className="item"></div>
                  </div>
                  <div className="case-receipt-row item-date">
                    <span className="text">中華民國</span>
                    <span className="input">
                      <Input className="input"
                        placeholder="年度"
                        allowClear
                        type="number"
                        value={date['year']}
                        onChange={e => this.setState({ date: { ...date, year: e.target.value} })}
                      />
                    </span>
                    <span className="text">年</span>
                    <span className="input">
                      <Input className="input"
                        placeholder="月份"
                        allowClear
                        type="number"
                        value={date['month']}
                        onChange={e => this.setState({ date: { ...date, month: e.target.value} })}
                      />
                    </span>
                    <span className="text">月</span>
                    <span className="input">
                      <Input className="input"
                        placeholder="日"
                        allowClear
                        allowClear
                        type="number"
                        value={date['day']}
                        onChange={e => this.setState({ date: { ...date, day: e.target.value} })}
                      />
                    </span>
                    <span className="text">日</span>
                  </div>
                </div>
              }
            </div>
            :
            <div className="case-receipt-tip">此筆委任尚無委任狀</div>
          }
        </div>
      </div>
	  )
  }
}
export default withRouter(PowerOfAttorneyComponent);