import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  errMsg: '',
  appointorType: '',
  typeId: '',
  appointor: {
    ssid: '',
    address: '',
    birthday: '',
    email: '',
    name: '',
    phone: '',
    relationship: '',
  },
  company: {
    ban: '',
    address: '',
    contactPerson: '',
    email: '',
    name: '',
    phone: '',
    representative: '',
  },
  lawer: {
    address: '',
    faxNo: '',
    lawFirmName: '',
    name: '',
    phoneNo: '',
  },
});