import React, { Component } from 'react';
import searchExtend from '../modules/searchExtend';

class SearchExtendPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }  
  render() {
  	return (
	    <div id="page-searchExtend">
        <searchExtend.container 
        />
	    </div>
	  )
  }
}

export default SearchExtendPageComponent;
