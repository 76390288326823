import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, Checkbox, Upload, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import dnaLogoIcon from '../../assets/img/dna-logo.svg'
import dnaBgIcon from '../../assets/img/dna-bg.png'
import * as config from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const QRCode = require('qrcode.react');

class ProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      firstName: '',
      lastName: '',
      address: '',
      phoneNo: '',
      mobile: '',
      bank: '',
      bankAccount: '',
      twoFactorAuthenticationId: 1,
      uploadFileList: [],
      pinCode: '',
    };
  }
  componentDidMount() {
    const userId = localStorage.getItem('userId');
    this.props.onLoadProfile(userId);
    this.setState({ userId });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isGetting === true && this.props.isGetting === false) {
      if (this.props.errMsg === null) {
        this.setState({
          firstName: this.props.firstName,
          lastName: this.props.lastName,
          address: this.props.address,
          phoneNo: this.props.phoneNo,
          mobile: this.props.mobile,
          bank: this.props.bank,
          bankAccount: this.props.bankAccount,
          twoFactorAuthenticationId: this.props.twoFactorAuthenticationId,
          lawFirmLogo: this.props.lawFirmLogo,
          pinCode: this.props.pinCode,
        })
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  checkMobileFormat = (mobile) => {
    if (!mobile || !/09\d{8}$/.test(mobile)) {
      return false;
    } else {
      return true;
    }
  }
  onChangeMobile = (mobile) => {
    let twoFactorAuthenticationId = this.state.twoFactorAuthenticationId;
    if (!this.checkMobileFormat(mobile)) {
      twoFactorAuthenticationId = 1;
    }
    this.setState({ mobile, twoFactorAuthenticationId })
  }
  onChangeTwoFactorAuthenticationId = () => {
    this.setState({ twoFactorAuthenticationId: this.state.twoFactorAuthenticationId === 2 ? 1 : 2 });
  }
  render() {
    const { userId, firstName, lastName, address, phoneNo, mobile, bank, bankAccount, twoFactorAuthenticationId, uploadFileList, pinCode } = this.state;
    const UploadProps = {
      accept: ".doc,.docx,.jpg,.jpeg,.png,.pdf,.heic,.heif,.hevc",
      multiple: true,
      fileList: uploadFileList,
      onRemove: file => {
        this.setState(state => {
          const index = state.uploadFileList.indexOf(file);
          const newFileList = state.uploadFileList.slice();
          newFileList.splice(index, 1);
          return {
            uploadFileList: newFileList,
          };
        });
      },
      beforeUpload: (file, fileList) => {
        console.log(file, fileList)
        if (fileList.length <= 1 && this.state.uploadFileList <= 1) {
          this.setState(state => ({
            uploadFileList: [...state.uploadFileList, file]
          }));
          return false;
        } else {
          return message.warning('僅可選擇一個檔案')
        }
      }
    };
    return (
      <div className="container">
        <nav className="container p-3 mb-5">
          <Link to={`/cases`}>
            <img className="logo mr-3" alt="" src={dnaLogoIcon} />
          </Link>
          <h4 className="text-black-50">設定個人資料</h4>
        </nav>
        <div className="mb-5 profile_info">
          <div className="alert alert-light pl-5 pr-5" role="alert">
            <ul>
              <li>設定個人資料注意事項</li>
              <li>左方欄位為不可更改內容；右方欄位可提供修改資料。</li>
            </ul>
          </div>
        </div>
        <img className="login-logo-bg" alt="" src={dnaBgIcon} />
        <div className="profile_wrap">
          <div className="mb-3 profile_title">
          </div>
          <div className="row">
            <div className="col-md-4 col-12 profile_disable">
              <ul>
                <li className="profile-item">
                  <div>帳號：</div>
                  <div>{this.props.email}</div>
                </li>
                <li className="profile-item">
                  <div>事務所：</div>
                  <div>{this.props.lawFirmName}</div>
                </li>
                <li className="profile-logo">
                  <img src={this.props.lawFirmLogo} alt="" />
                </li>
                {
                  pinCode &&
                  <li className="qrcode">
                    <Link to={`/consultationsForm/${pinCode}`} target="_blank"><QRCode value={`${config.API_ENDPOINT}/consultationsForm/${pinCode}`} /></Link>
                  </li>
                }
              </ul>
            </div>
            <div className="col-md-8 col-12">
              <form onSubmit={e => { e.preventDefault(); this.props.onSubmitProfile(userId, firstName, lastName, address, phoneNo, mobile, bank, bankAccount, twoFactorAuthenticationId) }}>
                <div className="form-group row">
                  <label htmlFor="user_password" className="col-sm-3 col-form-label">密碼</label>
                  <div className="col-sm-9">
                    <Link to={`/password/reset`}>
                      <button type="button" className="btn btn-primary w-100">更改密碼</button>
                    </Link>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="user_name" className="col-sm-3 col-form-label">姓名</label>
                  <div className="col-sm-9 d-flex justify-content-between">
                    <input className="form-control col-4"
                      value={lastName}
                      onChange={e => this.setState({ lastName: e.target.value })}
                      type="text"
                      placeholder="姓氏"
                      required
                    />
                    <input className="form-control col-7"
                      value={firstName}
                      onChange={e => this.setState({ firstName: e.target.value })}
                      type="text"
                      id="user_name"
                      placeholder="名字"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="user_address" className="col-sm-3 col-form-label">公司住址</label>
                  <div className="col-sm-9">
                    <input className="form-control"
                      value={address}
                      onChange={e => this.setState({ address: e.target.value })}
                      type="text"
                      id="user_address"
                      placeholder="公司住址"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="user_tel" className="col-sm-3 col-form-label">公司電話</label>
                  <div className="col-sm-9">
                    <input className="form-control"
                      value={phoneNo}
                      onChange={e => this.setState({ phoneNo: e.target.value })}
                      type="tel"
                      id="user_tel"
                      placeholder="公司電話"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="user_tel" className="col-sm-3 col-form-label">手機號碼</label>
                  <div className="col-sm-4">
                    <input className="form-control"
                      value={mobile}
                      onChange={e => this.onChangeMobile(e.target.value)}
                      type="tel"
                      id="user_mobile"
                      placeholder="手機號碼"
                      pattern="09\d{8}$"
                    />
                  </div>
                  <div className="col-sm-5">
                    <Checkbox
                      checked={twoFactorAuthenticationId === 2}
                      onChange={e => this.onChangeTwoFactorAuthenticationId()}
                      disabled={!this.checkMobileFormat(mobile)}
                    >
                      啟用二次驗證
                    </Checkbox>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="user_bank" className="col-sm-3 col-form-label">匯款銀行</label>
                  <div className="col-sm-9">
                    <input className="form-control"
                      value={bank || ''}
                      onChange={e => this.setState({ bank: e.target.value })}
                      type="tel"
                      id="user_bank"
                      placeholder="匯款銀行"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="user_bankAccount" className="col-sm-3 col-form-label">匯款帳號</label>
                  <div className="col-sm-9">
                    <input className="form-control"
                      value={bankAccount || ''}
                      onChange={e => this.setState({ bankAccount: e.target.value })}
                      type="tel"
                      id="user_bankAccount"
                      placeholder="銀行帳號"
                    />
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="btn btn-outline-success w-25" disabled={this.props.isPosting}>
                    儲存
                  </button>
                </div>
                {
                  this.props.errMsg
                  &&
                  <div className="alert alert-danger" role="alert">
                    {this.props.errMsg}
                  </div>
                }
              </form>
              <form onSubmit={e => { e.preventDefault(); this.props.onSubmitLogo(userId, uploadFileList) }} className="form-group mt-5 row upload-item">
                <label className="col-sm-3 col-form-label">公司 LOGO</label>
                <div className="col-sm-9 upload-logo">
                  <Upload className="case-file-upload-select" {...UploadProps}>
                    <Button>
                      <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
                      選擇圖片
                    </Button>
                  </Upload>
                  {
                    uploadFileList.length > 0
                    &&
                    <div className="text-right mt-3"><button type="submit" className="btn btn-outline-success" disabled={this.props.isPosting}>上傳公司圖片</button></div>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="text-center mt-5 pb-5 border-top">
          <Link to={`/cases`}>
            <button type="button" className="btn btn-outline-info w-50 mt-5">返回案件列表</button>
          </Link>
        </div>
      </div>
    )
  }
}

export default withRouter(ProfileComponent);