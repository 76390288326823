import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // common
  'mailroom/SEARCH_REQUEST': (state, { payload }) => {
    return state.setIn(['isSearching'], true)
                .setIn(['errMsg'], null);;
  },
  'mailroom/SEARCH_SUCCESS': (state, { payload }) => {
    return state.setIn(['isSearching'], false)
                .setIn(['errMsg'], null);
  },
  'mailroom/SEARCH_FAILURE': (state, { payload }) => {
    return state.setIn(['isSearching'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroom/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'mailroom/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'mailroom/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroom/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'mailroom/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'mailroom/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'mailroom/UPDATE_COURT_LIST': (state, { payload }) => {
    return state.setIn(['courtIdList'], fromJS(payload.courtIdList))
                .setIn(['courts'], fromJS(payload.courts));
  },
  'mailroom/UPDATE_TYPE_LIST': (state, { payload }) => {
    return state.setIn(['typeIdList'], fromJS(payload.typeIdList))
                .setIn(['types'], fromJS(payload.types));
  },
  'mailroom/UPDATE_DOCKET_LIST': (state, { payload }) => {
    if (payload.length === 0 ) {
      return state.setIn(['isFetching'], false);
    } else {
      const dockets = {};
      const docketNames ={};
      const docketList = [];
      payload.forEach(docket => {
        dockets[docket.id] = docket.name;
        docketNames[docket.id] = docket.custodianName;
        docketList.push(docket.name);
      })
      return state.setIn(['dockets'],  fromJS(dockets))
                  .setIn(['docketNames'],  fromJS(docketNames))
                  .setIn(['docketList'],  fromJS(docketList));
    }
  },
  'mailroom/UPDATE_DELIVERY_TYPE_LIST': (state, { payload }) => {
    return state.setIn(['deliveryTypeIdList'], fromJS(payload.deliveryTypeIdList))
                .setIn(['deliveryTypes'], fromJS(payload.deliveryTypes));
  },
  'mailroom/UPDATE_SEARCH_MAILROOM': (state, { payload }) => {
    console.log(payload)
    return state.setIn(['searchMailroom', 'sends'], fromJS(payload.sends))
                .setIn(['searchMailroom', 'receives'], fromJS(payload.receives));
  },
}, State);

export default Reducer;