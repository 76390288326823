import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ResetPasswordWithOldPasswordComponent from './component';


const mapStateToProps = (state) => ({
  oldPassword: selectors.getOldPassword(state),
  newPassword: selectors.getNewPassword(state),
  confirmPassword: selectors.getConfirmPassword(state),
  isReset: selectors.getIsReset(state),
  errMessage: selectors.getErrMessage(state)    
});

const mapDispatchToProps = dispatch => ({
  onSubmitResetPassword: (history) => {
    dispatch(actions.doSubmitResetPassword(history));
  },
  onUpdateOldPassword: (oldPassword) => {
    dispatch(actions.doUpdateOldPassword({ oldPassword }));
  },
  onUpdateNewPassword: (newPassword) => {
    dispatch(actions.doUpdateNewPassword({ newPassword }));
  },
  onUpdateConfirmPassword: (confirmPassword) => {
    dispatch(actions.doUpdateConfirmPassword({ confirmPassword }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordWithOldPasswordComponent);