import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {  message, DatePicker } from 'antd';
import { DATE_FORMAT, DATE_TIME_FORMAT, CASE_TYPE } from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const DEFAULT_FEE = {
  isTaxabled: 0,
  taxAmount: '',
  appointFee: '',
  payDate: null,
  taxYear: '',
  taxVoucher: 0,
  receipt: {}
}
// const DEFAULT_RECEIPT = {
//   name: '',
//   category: '',
//   note: '',
//   date: null,
//   cost: '',
//   reason: ''
// }
const GOOGLE_CALENDAR_URL = 'https://calendar.google.com/calendar/u/0/r/eventedit?'


class AppointmentDatesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      showReceiptEditor: false,
      fee: DEFAULT_FEE,
      caseTitle: ''
    };
  }
  componentDidMount() {
    this.setState({ caseTitle: `${CASE_TYPE.find(type_ => type_['id'] === this.props.caseDetail.getIn(['typeId'])).name} ${this.props.caseDetail.getIn(['appointNoId'])} ${this.props.caseDetail.getIn(['name'])}` });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPutting === true && this.props.isPutting === false) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleEditMode(false);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.appointmentId !== this.props.appointmentId && this.state.editMode === true) {
      this.onToggleEditMode(false);
    }
  }
  convertGoogleCalendarLink(dateType, dateStr) {
    const { caseTitle } = this.state;
    return encodeURI(`${GOOGLE_CALENDAR_URL}text=${caseTitle} ${dateType}&dates=${dateStr.replaceAll('/', '')}/${moment(dateStr, DATE_FORMAT).add(1, 'days').format('YYYYMMDD')}&trp=true&sprop=https://www.lawdna.com.tw`)
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onToggleEditMode(editMode) {
    if (editMode === true) {
      this.setState({ 
        editMode, 
        dates: this.props.dates.toJS()
      });
    } else {
      this.setState({ editMode });
    }
    this.props.onChangeIsEditing(editMode);
  }
  onChangeDates(key, value) {
    let { dates } = this.state;
    dates = JSON.parse(JSON.stringify(dates));
    dates[key] = value;
    this.setState({ dates });
  }
  onSubmitDates() {
    const { dates } = this.state;
    this.props.onUpdateDates(
      this.props.appointmentId, 
      dates.courtDay ? moment(dates.courtDay).format(DATE_TIME_FORMAT) : null,
      dates.appealLastDay ? moment(dates.appealLastDay).format(DATE_FORMAT) : null,
      dates.lastDayFirst ? moment(dates.lastDayFirst).format(DATE_FORMAT) : null,
      dates.lastDaySecond ? moment(dates.lastDaySecond).format(DATE_FORMAT) : null,
      dates.closeDate ? moment(dates.closeDate).format(DATE_FORMAT) : null
    );
  }
  render() {
    const { editMode, dates } = this.state;
    if (!this.props.dates || this.props.dates.size === 0) {
      return null;
    }
    return (
      <div className="appointmentdates-section section-col-item">
        {
          editMode
          ?
            <form onSubmit={e => {e.preventDefault();this.onSubmitDates()}}>
              <div className="col-item-tool">
                <button className="btn-cancel" type="button" onClick={e => this.onToggleEditMode(false)}>取消</button>
                <button className="btn-update" type="submit" disabled={this.props.isPutting}>更新</button>
              </div>
              <div className="col-item-group">
                <div className="col-item date courtDayOpenData">
                  <div className="col-title">{window.screen.width < 768 ? '司法院公開庭期：（open data)' : '司法院 OPEN DATE 開庭日期：'}</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['courtDayOpenData']) }
                  </div>
                </div>
                <div className="col-item date">
                  <div className="col-title">開庭日期：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    showTime={{ format: 'HH:mm' }}
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_TIME_FORMAT}
                    value={dates.courtDay ? moment(dates.courtDay, DATE_TIME_FORMAT) : null}
                    onChange={value => this.onChangeDates('courtDay', value)}
                  />
                </div>
                <div className="col-item date">
                  <div className="col-title">上訴末日：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={dates.appealLastDay ? moment(dates.appealLastDay, DATE_FORMAT) : null}
                    onChange={value => this.onChangeDates('appealLastDay', value)}
                  />
                </div>
                <div className="col-item date">
                  <div className="col-title">合意停止末日(一)：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={dates.lastDayFirst ? moment(dates.lastDayFirst, DATE_FORMAT) : null}
                    onChange={value => this.onChangeDates('lastDayFirst', value)}
                  />
                </div>
                <div className="col-item date">
                  <div className="col-title">合意停止末日(二)：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={dates.lastDaySecond ? moment(dates.lastDaySecond, DATE_FORMAT) : null}
                    onChange={value => this.onChangeDates('lastDaySecond', value)}
                  />
                </div>
                <div className="col-item date">
                  <div className="col-title">結案日期：</div>
                  <DatePicker className="col-edit"
                    size="small"
                    placeholder="請選擇日期"
                    inputReadOnly={true}
                    showToday={false}
                    format={DATE_FORMAT}
                    value={dates.closeDate ? moment(dates.closeDate, DATE_FORMAT) : null}
                    onChange={value => this.onChangeDates('closeDate', value)}
                  />
                </div>
              </div>
            </form>
          :
            <>
              <div className="col-item-tool">
                <button className="btn-edit" onClick={e => this.onToggleEditMode(true)}>編輯</button>
              </div>
              <div className="col-item-group">
                <div className="col-item courtDayOpenData">
                  <div className="col-title">{window.screen.width < 768 ? '司法院公開庭期：（open data)' : '司法院 OPEN DATE 開庭日期：'}</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['courtDayOpenData']) }
                    {
                      this.props.dates.getIn(['courtDayOpenData'])
                      &&
                      <a href={this.convertGoogleCalendarLink('司法院 OPEN DATE 開庭日期', this.props.dates.getIn(['courtDayOpenData']))} target="_blank" rel="noreferrer noopener">
                        <button className="btn-add-to-google-calendar">加到 Google 行事曆</button>                          
                      </a>
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">開庭日期：</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['courtDay']) }
                    {
                      this.props.dates.getIn(['courtDay'])
                      &&
                      <a href={this.convertGoogleCalendarLink('開庭日', this.props.dates.getIn(['courtDay']))} target="_blank" rel="noreferrer noopener">
                        <button className="btn-add-to-google-calendar">加到 Google 行事曆</button>                          
                      </a>
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">上訴末日：</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['appealLastDay']) }
                    {
                      this.props.dates.getIn(['appealLastDay'])
                      &&
                      <a href={this.convertGoogleCalendarLink('上訴末日', this.props.dates.getIn(['appealLastDay']))} target="_blank" rel="noreferrer noopener">
                        <button className="btn-add-to-google-calendar">加到 Google 行事曆</button>                          
                      </a>
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">合意停止末日(一)：</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['lastDayFirst']) }
                    {
                      this.props.dates.getIn(['lastDayFirst'])
                      &&
                      <a href={this.convertGoogleCalendarLink('合意停止末日(一)', this.props.dates.getIn(['lastDayFirst']))} target="_blank" rel="noreferrer noopener">
                        <button className="btn-add-to-google-calendar">加到 Google 行事曆</button>                          
                      </a>
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">合意停止末日(二)：</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['lastDaySecond']) }
                    {
                      this.props.dates.getIn(['lastDaySecond'])
                      &&
                      <a href={this.convertGoogleCalendarLink('合意停止末日(二)', this.props.dates.getIn(['lastDaySecond']))} target="_blank" rel="noreferrer noopener">
                        <button className="btn-add-to-google-calendar">加到 Google 行事曆</button>                          
                      </a>
                    }
                  </div>
                </div>
                <div className="col-item">
                  <div className="col-title">結案日期：</div>
                  <div className="col-text">
                    { this.props.dates.getIn(['closeDate']) }
                    {
                      this.props.dates.getIn(['closeDate'])
                      &&
                      <a href={this.convertGoogleCalendarLink('結案日', this.props.dates.getIn(['closeDate']))} target="_blank" rel="noreferrer noopener">
                        <button className="btn-add-to-google-calendar">加到 Google 行事曆</button>                          
                      </a>
                    }
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    )
  }
}
export default withRouter(AppointmentDatesComponent);