// common
export const POST_REQUEST = 'creatCase/POST_REQUEST'
export const POST_SUCCESS = 'creatCase/POST_SUCCESS'
export const POST_FAILURE = 'creatCase/POST_FAILURE'
export const POST_CHECK_REQUEST = 'creatCase/POST_CHECK_REQUEST'
export const POST_CHECK_SUCCESS = 'creatCase/POST_CHECK_SUCCESS'
export const POST_CHECK_FAILURE = 'creatCase/POST_CHECK_FAILURE'
export const GET_REQUEST = 'creatCase/GET_REQUEST'
export const GET_SUCCESS = 'creatCase/GET_SUCCESS'
export const GET_FAILURE = 'creatCase/GET_FAILURE'
// cases
export const UPDATE_ERR_MSG = 'creatCase/UPDATE_ERR_MSG'
export const UPDATE_APPOINTED_HISTORY_LIST = 'creatCase/UPDATE_APPOINTED_HISTORY_LIST'
export const UPDATE_CASE_LIST = 'creatCase/UPDATE_CASE_LIST'
export const UPDATE_CASE_RELATE_LIST = 'creatCase/UPDATE_CASE_RELATE_LIST'
export const GET_CASE_RELATED_REQUEST = 'creatCase/GET_CASE_RELATED_REQUEST'
export const GET_CASE_RELATED_SUCCESS = 'creatCase/GET_CASE_RELATED_SUCCESS'
export const GET_CASE_RELATED_FAILURE = 'creatCase/GET_CASE_RELATED_FAILURE'
export const UPDATE_CUSTODIAN_CONFLICT = 'creatCase/UPDATE_CUSTODIAN_CONFLICT'