// common
export const HTTP_REQUEST = "attachment/HTTP_REQUEST";
export const HTTP_SUCCESS = "attachment/HTTP_SUCCESS";
export const HTTP_FAILURE = "attachment/HTTP_FAILURE";
export const UPLOAD_REQUEST = "attachment/UPLOAD_REQUEST";
export const UPLOAD_SUCCESS = "attachment/UPLOAD_SUCCESS";
export const UPLOAD_FAILURE = "attachment/UPLOAD_FAILURE";

// attachment
export const UPDATE_ATTACHMENT_DICT = "attachment/UPDATE_ATTACHMENT_DICT";
export const UPDATE_HIT_LIST = "attachment/UPDATE_HIT_LIST";
export const UPDATE_FOLDER_LIST = "attachment/UPDATE_FOLDER_LIST";

export const UPDATE_IS_EXCEEDED_QUOTA = 'attachment/UPDATE_IS_EXCEEDED_QUOTA'
