import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Select, Upload, message, Modal, Input, Tooltip, DatePicker, Spin } from 'antd';
import { Form, Button } from 'react-bootstrap';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const emptyEditingReceived = {
  name: '',
  docketId: '',
  typeId: '',
  sender: '',
  custodianName: '',
  receivedDate: null,
  sentDate: null,
  expiredDays: 0
}

class MailroomReceivedListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentReceivedId: null,
      expandReceivedSet: new Set(),
      editingFileLists: {},
      showEditReceived: false,
      editReceivedId: '',
      newReceived: JSON.parse(JSON.stringify(emptyEditingReceived)),
    };
  }
  componentDidMount() {
    if(!this.props.isSearch) {
      this.props.onLoadReceivedList();
    } else {
      this.props.onLoadReceivedList('search');
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        this.onCleanFiles();
        message.success('上傳成功');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isDeleting === true && this.props.isDeleting === false) {
      if (this.props.errMsg === null) {
        message.success('刪除成功');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if ((prevProps.reloadList === true && this.props.reloadList === false && this.props.isSearch === false) ||
        (prevProps.isSearch === true && this.props.isSearch === false)) {
      this.props.onLoadReceivedList();
    }
    if (prevProps.isSearch === false && this.props.isSearch === true) {
      this.props.onLoadReceivedList('search')
    }
    if (this.props.cachedReceivedId !== null) {
      this.onChangeCurrentReceivedId(this.props.cachedReceivedId);
      this.props.onUpdateCachedReceivedId(null);
    }
  }
  componentWillUnmount() {
    this.props.onUpdateCachedReceivedId(null);
  }
  onCleanFiles = () => {
    const { currentReceivedId, editingFileLists } = this.state;
    const newEditingFileLists = Object.assign({}, editingFileLists);
    newEditingFileLists[currentReceivedId] = [];
    this.setState({ editingFileLists: newEditingFileLists })
  }
  onCheckReceivedFileStatus = (info) => {
    if (info.file.status === 'done') {
      this.props.onLoadReceivedList();
      message.success('上傳成功');
    }
  }
  onSelectReceivedFile = (receivedId, file_) => {
    const { editingFileLists } = this.state;
    let newEditingFileLists = Object.assign({}, editingFileLists);
    if (!newEditingFileLists.hasOwnProperty(receivedId)) {
      newEditingFileLists[receivedId] = Object.assign([], file_);
    } else if (!Array.isArray(file_)) {
      newEditingFileLists[receivedId].splice(newEditingFileLists[receivedId].indexOf(file_), 1);
    } else {
      newEditingFileLists[receivedId] = newEditingFileLists[receivedId].concat(file_);
    }

    this.setState({ editingFileLists: newEditingFileLists, currentReceivedId: receivedId });
  }
  onChangeCurrentReceivedId = (currentReceivedId) => {
    this.setState({ currentReceivedId })
  }
  onSubmitReceivedFile = (receivedId) => {
    this.setState({ currentReceivedId: receivedId }, () => this.props.onUploadReceivedFile(receivedId, this.state.editingFileLists[receivedId]))
  }
  onToggleFileList = (receivedId) => {
    const { expandReceivedSet } = this.state;
    if (expandReceivedSet.has(receivedId)) {
      expandReceivedSet.delete(receivedId);
    } else {
      expandReceivedSet.add(receivedId);
    }
    this.setState({ expandReceivedSet })
  }
  onDeleteReceivedFile = (fileId, fileName) => {
    Modal.confirm({
      title: `確認刪除： ${fileName}`,
      okText: '刪除',
      cancelText: '取消',
      onOk: () => {
        this.props.onDeleteReceivedFile(fileId);
      },
    });
  }
  onDownloadReceivedFile = (fileId, fileName) => {
    this.props.onDownloadReceivedFile(fileId, fileName);
  }
  onClickHandleReceived = (id, sender, docketId, docketName, expiredDays, sentDate, receivedDate, custodianName, typeId, name) => {
    this.setState({
      showEditReceived: true,
      editReceivedId: id,
      docketName,
      newReceived: {
        sender,
        docketId: docketId ? docketId.toString() : null,
        expiredDays,
        sentDate,
        receivedDate,
        custodianName,
        typeId: typeId.toString(),
        name,
      }
    })
  }
  onUpdateEditingReceived = (key, value) => {
    let { newReceived } = this.state;
    newReceived[key] = value;
    this.setState({ newReceived });
  }
  onUpdateDocketId = (type, docketValue) => {
    let docketId = '';
    Object.entries(this.props.dockets.toJS()).map(([key, value]) => {
      if( docketValue === value ) {
        docketId = key;
        this.setState({ docketName: value })
      }
    })
    if(type === 'editingReceived'){
      this.onUpdateEditingReceived('docketId', docketId)
    };
  }

  onSubmitUpdateReceived = () => {
    const { editReceivedId, newReceived } = this.state;
    if(newReceived['sender'] === '' && newReceived['expiredDays'] === '' && newReceived['receivedDate'] === '' && newReceived['typeId'] === '' && newReceived['name']){
      return message.error('請輸入收文欄位')
    } else {
      this.props.onUpdateReceived(
        editReceivedId,
        newReceived['sender'],
        Number(newReceived['docketId']),
        Number(newReceived['expiredDays']),
        moment(newReceived['sentDate']).format('YYYY/MM/DD'),
        moment(newReceived['receivedDate']).format('YYYY/MM/DD'),
        newReceived['custodianName'],
        Number(newReceived['typeId']),
        newReceived['name'],
      )
      this.setState({ showEditReceived: false, editReceivedId: '', newReceived: JSON.parse(JSON.stringify(emptyEditingReceived)) })
    }
  }
  onClickToReview(caseId, folderId) {
    if(caseId === null) {
      message.warning('尚未建立委任案件，請建立委任案件後檢閱檔案')
    } else {
      console.log(caseId, folderId)
      this.props.history.push(`attachment/${caseId}`);
      localStorage.setItem("attachmentSelectedFileId", folderId)
      localStorage.setItem("attachmentSelectedFileFolderId", folderId);
    }
  }
  
  onClickDeleteReceived(receivedId) {
    Modal.confirm({
      title: '刪除收文',
      content: '按下"確認"按鈕將刪除此收文。',
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteReceived(receivedId);
      },
    });
  };
  onClickToAppointment(appointmentId) {
    if(!appointmentId){
      return
    } else {
      this.props.onUpdateCachedAppointmentId(parseInt(appointmentId));
      this.props.history.push(`/cases`)
    }
  }
  onCopyDocketIdToCustodianName(docketName) {
    const { newReceived } = this.state;
    const custodianNames = this.props.docketNames.toJS();
    Object.entries(this.props.dockets.toJS()).map(([key, value]) => {
      if(docketName === value) {
        this.setState({
          newReceived: {
            ...newReceived,
            custodianName: custodianNames[key],
          }
        })
      }
    })
  }

  render() {
    const { expandReceivedSet, editingFileLists, showEditReceived, newReceived, docketName, currentReceivedId } = this.state;
    return (
      <div className="section-list mailroomreceivelist">
        <ul className="case-list-head">
          <li className="head-name">函文名稱</li>
          <li className="head-docketName">案號</li>
          <li className="head-custodianName">當事人</li>
          <li className="head-type">類型</li>
          <li className="head-expiredDate">期限日</li>
          <li className="head-receivedDate">收文日</li>
          <li className="head-sender">來文者</li>
          {/* <li className="head-sentDate">函文發文日期</li> */}
          <li className="head-files">檔案</li>
          <li className="head-action">操作</li>
        </ul>
        {
          this.props.receivedList && this.props.receivedList.map(received =>
            <div key={`mailroom-received-${received.getIn(['id'])}`} className={`case-item ${currentReceivedId === received.getIn(['id']) ? 'active': ''}`} onClick={e => this.onChangeCurrentReceivedId(received.getIn(['id']))}>
              <ul className="case-list-body">
                <li className="body-name" data-th="函文名稱：">{received.getIn(['name'])}</li>
                <li className="body-docketName" data-th="案號：" onClick={e => this.onClickToAppointment(received.getIn(['caseId']))}>{received.getIn(['docketName'])}</li>
                <li className="body-custodianName" data-th="當事人：">{received.getIn(['custodianName'])}</li>
                <li className="body-type" data-th="類型：">{this.props.types && this.props.types.getIn([`${received.getIn(['typeId'])}`])}</li>
                <li className="body-expiredDate" data-th="期限日：">{received.getIn(['expiredDate'])}</li>
                <li className="body-receivedDate" data-th="收文日：">{received.getIn(['receivedDate'])}</li>
                <li className="body-sender" data-th="來文者：">{received.getIn(['sender'])}</li>
                {/* <li className="body-sentDate" data-th="函文發文日期：">{ received.getIn(['sentDate']) }</li> */}
                <li className="body-files" data-th="檔案：">
                  <Tooltip title={expandReceivedSet.has(received.getIn(['id'])) ? '收起檔案' : '展開檔案'}>
                    <button className="btn-empty"
                      onClick={e => this.onToggleFileList(received.getIn(['id']))}
                    ><FontAwesomeIcon icon={fas.faPaperclip} /></button>
                    {
                      received.getIn(['fileList']).size > 0
                      &&
                      `${received.getIn(['fileList']).size}`
                    }
                  </Tooltip>
                </li>
                <li className="head-action" data-th="操作：">
                  <Tooltip title="編輯">
                    <button 
                      onClick={e => this.onClickHandleReceived(
                        received.getIn(['id']),
                        received.getIn(['sender']),
                        received.getIn(['docketId']),
                        received.getIn(['docketName']),
                        received.getIn(['expiredDays']),
                        received.getIn(['sentDate']),
                        received.getIn(['receivedDate']),
                        received.getIn(['custodianName']),
                        received.getIn(['typeId']),
                        received.getIn(['name']),
                      )} 
                    ><FontAwesomeIcon icon={fas.faPencilAlt} /></button>
                  </Tooltip>
                  <Tooltip title="刪除收文">
                    <button className="btn-warn" onClick={e => this.onClickDeleteReceived(received.getIn(['id']))}>
                      <i><FontAwesomeIcon icon={fas.faMinus} /></i>
                    </button>
                  </Tooltip>
                </li>
              </ul>
              {
                expandReceivedSet.has(received.getIn(['id']))
                &&
                <div className="case-file-extend">
                  <div className="case-file-extend-head">
                    <div className="case-file-extend-title">
                      <div>檔案列表</div>
                      <div className="case-file-list">
                        {
                          received.getIn(['fileList']).size > 0
                          ?
                          <div className="case-file-group">
                            {
                              received.getIn(['fileList']).map(file_ =>
                                <div key={`mailroom-file-${file_.getIn(['id'])}`} className="case-file">
                                  <div className="case-file-title" onClick={e => this.onClickToReview(received.getIn(['caseId']), file_.getIn(['folderId']))}>{file_.getIn(['name'])}</div>
                                  <div className="case-file-tool">
                                    <button
                                      onClick={e => this.onDownloadReceivedFile(file_.getIn(['id']), file_.getIn(['name']))}
                                    ><FontAwesomeIcon icon={fas.faDownload} /></button>
                                    <button
                                      onClick={e => this.onDeleteReceivedFile(file_.getIn(['id']), file_.getIn(['name']))}
                                    ><FontAwesomeIcon icon={fas.faTrash} /></button>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                          :
                          <div className="case-file-group">
                            <div className="case-file-group-title">尚無檔案</div>
                          </div>
                        }
                      </div>
                    </div>
                    <Form onSubmit={e => { e.preventDefault(); this.onSubmitReceivedFile(received.getIn(['id'])) }}>
                      <Upload multiple={true}
                        fileList={editingFileLists[received.getIn(['id'])]}
                        onRemove={(file_, fileList) => this.onSelectReceivedFile(received.getIn(['id']), file_)}
                        beforeUpload={(file_, fileList) => { this.onSelectReceivedFile(received.getIn(['id']), fileList); return false; }}
                      >
                        <Button className="btn-upload">
                          <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>選擇上傳檔案
                        </Button>
                      </Upload>
                      {
                        editingFileLists[received.getIn(['id'])]
                        &&
                        <button className="upload"><FontAwesomeIcon icon={fas.faPlus} />上傳</button>
                      }
                    </Form>
                  </div>
                </div>
              }
            </div>
          )
        }
        <Modal className="edit-received"
          title="編輯收文"
          visible={showEditReceived}
          okText="儲存收文"
          cancelText="取消"
          onOk={() => this.onSubmitUpdateReceived()}
          onCancel={() => this.setState({showEditReceived: !showEditReceived, editReceivedId: ''})}
        >
          <div className="col-item">
            <div className="col-title">來文者：</div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="text"
              value={newReceived['sender']}
              onChange={e => this.onUpdateEditingReceived('sender', e.target.value)}
              required
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span>案號：</span></div>
            <Select className="col-edit"
              size="small"
              placeholder="請輸入關鍵字"
              notFoundContent={this.props.isFetching ? <Spin size="small" /> : '無相關資料'}
              showSearch
              value={ docketName }
              onSearch={value => this.props.onLoadDocketList(value, 10)}
              onChange={value => {this.onUpdateDocketId('editingReceived', value); this.onCopyDocketIdToCustodianName(value)}}
            >
              {
                this.props.docketList && this.props.docketList.toJS().map((docket, idx) =>
                  <Option key={idx} value={docket}>{`${docket}`}</Option>
                )
              }
            </Select>
          </div>
          <div className="col-item">
            <div className="col-title">期限天數：</div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="number"
              value={newReceived['expiredDays']}
              onChange={e => this.onUpdateEditingReceived('expiredDays', e.target.value)}
              required
            />
          </div>
          {/* <div className="col-item">
            <div className="col-title"><span className="required">函文發文日：</span></div>
            <DatePicker className="col-edit"
              size="small"
              placeholder="請選擇日期"
              inputReadOnly={true}
              showToday={false}
              format={dateFormat}
              value={newReceived['sentDate'] ? moment(newReceived['sentDate'], dateFormat) : null}
              onChange={value => this.onUpdateEditingReceived('sentDate', value)}
              required
            />
          </div> */}
          <div className="col-item">
            <div className="col-title"><span>收文日：</span></div>
            <DatePicker className="col-edit"
              size="small"
              placeholder="請選擇日期"
              inputReadOnly={true}
              showToday={false}
              format={dateFormat}
              value={newReceived['receivedDate'] ? moment(newReceived['receivedDate'], dateFormat) : null}
              onChange={value => this.onUpdateEditingReceived('receivedDate', value)}
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span>當事人：</span></div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="text"
              value={newReceived['custodianName']}
              onChange={e => this.onUpdateEditingReceived('custodianName', e.target.value)}
            />
          </div>
          <div className="col-item">
            <div className="col-title"><span className="required">類型：</span></div>
            <Select className="col-edit"
              size="small"
              showSearch
              value={newReceived['typeId']}
              onChange={value => this.onUpdateEditingReceived('typeId', value)}
              required
            >
              {
                this.props.typeIdList && this.props.typeIdList.map(typeId =>
                  <Option key={`mailroom-sent-type-${typeId}`} value={typeId}>{this.props.types.getIn([typeId])}</Option>
                )
              }
            </Select>
          </div>
          <div className="col-item">
            <div className="col-title"><span className="required">函文名稱：</span></div>
            <Input className="col-edit"
              size="small"
              allowClear
              type="text"
              value={newReceived['name']}
              onChange={e => this.onUpdateEditingReceived('name', e.target.value)}
              required
            />
          </div>
        </Modal>
      </div>
    )
  }
}
export default withRouter(MailroomReceivedListComponent);
