import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dnaLogoIcon from '../../src/assets/img/dna-logo.svg';
import { agreeTermsOfUse } from '../utils/api';


class ContractPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  onClickAgree(){
    const userId = localStorage.getItem('userId')
    agreeTermsOfUse(userId).then(data => {
      this.props.history.push('/cases');
    }).catch((errMsg) => {
      // alert(errMsg);
    })
  }
  render() {
    return (
      <div id="page-contract" className="container registration">
        <div className="registration-nav">
          <Link className="login-form-forgotpw" to={`/`}><img className="login-logo" alt="" src={dnaLogoIcon} /></Link>
          <a className="login-newmember" href="http://www.ediscovery.com.tw/lawdna.html">了解有關 LAW DNA</a>
        </div>
        <section className="lawdnaStart-content">
          <h2 className="text-center">Law DNA 同意使用條款</h2>
          <div>
            歡迎蒞臨「LAW DNA」，為保障您的權益及讓您合法使用網站上的服務，特訂定「會員條款」說明之，凡是進入本網站者，皆應詳細閱讀下列所訂條款。使用本網站所提供之服務者（不論是否已加入會員），視為已閱讀、知悉並同意本約定條款所訂之內容。
          </div>
          <div className="lawdnaStart-item">
            <h4>壹、會員規範</h4>
            <div>
              本網站之會員應遵守本約定條款所訂之內容、本網站公佈之相關規範、中華民國相關法規以及網際網路之慣例或規範。相關規範若有修訂，本網站不負告知之義務，請會員自行注意，以確保自身權益。
          </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>貳、會員權利</h4>
            <div>
              本網站之會員於付款後享有使用「LAW DNA服務平台」。本網站於會員申請日起授權會員使用「LAW DNA服務平台」，授權期間為1年。
          </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>參、會員基本資料</h4>
            <div>
              <ul>
                <li>
                  <div>一、會員基本資料</div>
                  <div>
                    會員須確實、詳細填寫會員真實姓名、地址、E-mail、電話及其他資料，不得有不實、不全、匿飾或使人誤解之情形。會員資料如有變更，應主動至「修改會員資料」網頁進行修正，若因未修正而致會員權益受損，由會員自行負責，本網站不負其責。
                </div>
                </li>
                <li>
                  <div>二、會員使用本網站提供之個人化服務之資料</div>
                  <div>會員使用個人化服務功能，資料由會員自行負責維護，會員於使用本服務時宜自行採取備份或防護措施，本網站不負任何責任。</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>肆、會員密碼</h4>
            <div>
              會員對於帳號或密碼，應負保密之責，不得轉讓或以任何方式告知第三人，如造成個人無法使用、資料喪失、錯誤、遭人篡改及本網站之損失等情形，將由會員自行承擔，並負損害賠償責任。
          </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>伍、隱私權保護</h4>
            <div>
              <ul>
                <li>
                  <div>一 會員資料之保護</div>
                  <div>本網站對於會員登錄之身分資料、帳號及密碼、或儲存之資料，在未經會員同意前，不會任意將其透露、租借或轉售給第三人。</div>
                </li>
                <li>
                  <div>二 資料保護之例外</div>
                  <div>
                    在下列幾種情況下，將會使用或透露會員資料：<br />
                  （一）司法機關或其他有權機關符合法定程序提出要求時。<br />
                  （二）經會員同意之情形。<br />
                  （三）本網站於行銷或提供服務時所需。
                </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>陸、會員行為規範</h4>
            <div>
              一、上傳至網站或下載的所有文件、圖檔、照片或任何資料、資訊等均由會員自行為之，會員應保證來源正當，且無侵害他人著作權、商標、專利、營業秘密等智慧財產權或違反任何法律、行政命令等。<br />
            二 不得從事破壞、入侵、毀損、盜取等任何有損本網站或本網站內容之行為。<br />
            三 不得妨礙或干擾其他會員使用本網站之權利。<br />
            </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>柒、網站責任</h4>
            <div>
              會員或網站使用者在本網站所上載之資料或經由本網站所傳遞之資料，由其個人自行負責，本網站不負審查、核對或查證之責。會員需知本網站使用第三方承租雲端儲存空間，會員應知悉上傳至雲端儲存空間之資料可能有外流之風險。
          </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>捌、智慧財產權</h4>
            <div>
              除非另有書面同意外，會員不能授權或讓與任何產品專利權、著作權、商標、營業秘密、技術秘竅(KNOW-HOW)、其他智慧財產權或其他財產權予他方。
          </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>玖、終止服務</h4>
            <div>
              會員若違反本約定條款或其他相關規定，本網站有權決定是否暫時停止或終止會員權利。遭暫時停止或終止權利之會員，不得要求退還相關費用，如有相關事證可證明未違規者，可向本網站提出要求重新處理。<br />
            會員於契約期滿時，此平台會將儲存於雲端儲存空間之資料刪除。
          </div>
          </div>
          <div className="lawdnaStart-item">
            <h4>拾、修改會員條款之權利</h4>
            <div>
              本網站得因應需要隨時修正本約定條款之內容，修正後之約定條款將公佈於本網站相關網頁，並自公佈之日起即時生效，將不對會員進行個別通知，請會員自行留意。
          </div>
          </div>
          <div className="text-center mt-5">
            <button type="button" className="btn btn-primary" onClick={() => this.onClickAgree()}>
              我同意上述條款，開始使用
          </button>
          </div>
        </section>
      </div>
    )
  }
}

export default ContractPageComponent;
