// common
export const POST_REQUEST = 'appointmentFiles/POST_REQUEST'
export const POST_SUCCESS = 'appointmentFiles/POST_SUCCESS'
export const POST_FAILURE = 'appointmentFiles/POST_FAILURE'
export const GET_REQUEST = 'appointmentFiles/GET_REQUEST'
export const GET_SUCCESS = 'appointmentFiles/GET_SUCCESS'
export const GET_FAILURE = 'appointmentFiles/GET_FAILURE'
export const PUT_REQUEST = 'appointmentFiles/PUT_REQUEST'
export const PUT_SUCCESS = 'appointmentFiles/PUT_SUCCESS'
export const PUT_FAILURE = 'appointmentFiles/PUT_FAILURE'
export const DELETE_REQUEST = 'appointmentFiles/DELETE_REQUEST'
export const DELETE_SUCCESS = 'appointmentFiles/DELETE_SUCCESS'
export const DELETE_FAILURE = 'appointmentFiles/DELETE_FAILURE'