import { handleActions } from 'redux-actions';
import { State } from './model';

const Reducer = handleActions({
  // common
  'casesContract/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'casesContract/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'casesContract/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'casesContract/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'casesContract/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'casesContract/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'casesContract/PUT_REQUEST': (state, { payload }) => {
    return state.setIn(['isPutting'], true)
                .setIn(['errMsg'], null);
  },
  'casesContract/PUT_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], null);
  },
  'casesContract/PUT_FAILURE': (state, { payload }) => {
    return state.setIn(['isPutting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'casesContract/UPDATE_POWER_OF_ATTORNEY_INFO': (state, { payload }) => {
    return state.setIn(['appointorType'], payload.appointorType)
                .setIn(['typeId'], payload.typeId)
                .setIn(['lawer', 'address'], payload.lawer.address)
                .setIn(['lawer', 'faxNo'], payload.lawer.faxNo)
                .setIn(['lawer', 'lawFirmName'], payload.lawer.lawFirmName)
                .setIn(['lawer', 'lawFirmAccountName'], payload.lawer.lawFirmAccountName)
                .setIn(['lawer', 'lawFirmAccountNo'], payload.lawer.lawFirmAccountNo)
                .setIn(['lawer', 'name'], payload.lawer.name)
                .setIn(['lawer', 'phoneNo'], payload.lawer.phoneNo);
  },
  'casesContract/UPDATE_POWER_OF_ATTORNEY_APPOINTOR': (state, { payload }) => {
    return state.setIn(['appointor', 'ssid'], payload.appointor.ssid)
                .setIn(['appointor', 'address'], payload.appointor.address)
                .setIn(['appointor', 'birthday'], payload.appointor.birthday)
                .setIn(['appointor', 'email'], payload.appointor.email)
                .setIn(['appointor', 'name'], payload.appointor.name)
                .setIn(['appointor', 'phone'], payload.appointor.phone)
                .setIn(['appointor', 'relationship'], payload.appointor.relationship);
  },
  'casesContract/UPDATE_POWER_OF_ATTORNEY_COMPANY': (state, { payload }) => {
    return state.setIn(['company', 'ban'], payload.company.ban)
                .setIn(['company', 'address'], payload.company.address)
                .setIn(['company', 'contactPerson'], payload.company.contactPerson)
                .setIn(['company', 'email'], payload.company.email)
                .setIn(['company', 'name'], payload.company.name)
                .setIn(['company', 'phone'], payload.company.phone)
                .setIn(['company', 'representative'], payload.company.representative);
  },
}, State);

export default Reducer;