import {message} from "antd";
import { update } from "immutable";
import {createAction} from 'redux-actions';
import * as pdfjsLib from 'pdfjs-dist';
import {
  addKeyword,
  breakPageType,
  changeSequence,
  combineToPages,
  isExceededUploadQuota,
  createCaseFiles,
  createDocSearch,
  createFilesFolder,
  createLabel,
  createLabelPages,
  createPageTypesFolder,
  deleteFileFolder,
  deleteFolder,
  deleteHighlights,
  deleteLabel,
  deleteLabelPages,
  exportCase,
  exportFile,
  exportLabel,
  exportSingleTypes,
  exportTags,
  exportTypes,
  fileDelete,
  fileEdit,
  getCase,
  getCaseAnnotation,
  getCaseCategories,
  getDuplicates,
  getCaseNames,
  getFolderList,
  getLabelPages,
  getLabels,
  copyLabelToFolder,
  mergePageType,
  modifyFilesFolder,
  modifyFolder,
  movingFileToFolder,
  movingToFolder,
  movingToPageType,
  movingToPageTypeInFolder,
  pageTypeEdit,
  tagAdd,
  tagEdit,
  tagEditTab,
  tagMigrateEdit,
  updateLabelNote
} from '../../utils/api';
import * as types from './actionTypes';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;


// common
export const postRequest = createAction(types.POST_REQUEST);
export const postSuccess = createAction(types.POST_SUCCESS);
export const postFailure = createAction(types.POST_FAILURE);
export const getRequest = createAction(types.GET_REQUEST);
export const getSuccess = createAction(types.GET_SUCCESS);
export const getFailure = createAction(types.GET_FAILURE);
export const putRequest = createAction(types.PUT_REQUEST);
export const putSuccess = createAction(types.PUT_SUCCESS);
export const putFailure = createAction(types.PUT_FAILURE);
export const deleteRequest = createAction(types.DELETE_REQUEST);
export const deleteSuccess = createAction(types.DELETE_SUCCESS);
export const deleteFailure = createAction(types.DELETE_FAILURE);

// pageDuplicates
export const updateDuplicates = createAction(types.UPDATE_DUPLICATES);

export function doLoadDuplicates(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getDuplicates(caseId).then(data => {
      dispatch(updateDuplicates(data));
      dispatch(getSuccess());
      return true;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}

// case
export const updateCaseName = createAction(types.UPDATE_CASE_NAME);

export function doLoadCaseName(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getCase(caseId).then(data => {
      dispatch(updateCaseName(data));
      dispatch(getSuccess());
      return true;
    }).catch((errMsg) => {
      message.error(`讀取案件名稱失敗，請稍後再試 [${errMsg}]`);
      return dispatch(getFailure({ errMsg }));
    })
  }
}

const readFileToArrayBuffer = async (file) => {
  return new Promise( (resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const result = reader.result
      // Convert to array buffer
      const bytes = new Uint8Array(result);
      resolve(bytes);
    };
  });
}

export const updateIsExceededQuota = createAction(types.UPDATE_IS_EXCEEDED_QUOTA)
export const updateEncryptedPdfNameList = createAction(types.UPDATE_ENCRYPTED_PDF_NAME_LIST)
export function doSubmitUploadFiles(caseId, uploadFileList) {
  return async dispatch => {
    dispatch(postRequest());
    let totalFileSize = 0;
    const encryptedPdfNameList = [];
    for (let [idx, f] of uploadFileList.entries()) {
      const bytes = await readFileToArrayBuffer(f);
      try {
        const pdfDocument = await pdfjsLib.getDocument(bytes).promise;
        totalFileSize += f.size;
      } catch(err) {
        if (err.name === 'PasswordException') {
          uploadFileList.splice(idx, 1);
          encryptedPdfNameList.push(f.name);
        }
      }
    }
    dispatch(updateEncryptedPdfNameList(encryptedPdfNameList));

    const userId = localStorage.getItem('userId');
    isExceededUploadQuota(userId, totalFileSize).then(data => {
      if (data.isExceeded === false) {
        createCaseFiles(caseId, uploadFileList).then(data => {
          dispatch(doLoadCaseName(caseId));
          dispatch(updateIsExceededQuota(false));
          dispatch(postSuccess());
          dispatch(doLoadCaseCategory(caseId));
          return true;
        }).catch((errMsg) => {
          console.log(errMsg);
          return dispatch(postFailure({ errMsg }));
        })
      } else {
        dispatch(updateIsExceededQuota(true));
        dispatch(postSuccess());
      }
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export const updateIsLoading = createAction(types.UPDATE_IS_LOADING);

export function updateLoading() {
  return (dispatch, getState) => {
    const state = getState();
    const items = state.getIn(['doc', 'fileList']).toJS();
    const isLoading = items.map(item =>
      item.statusId !== 4
    ).indexOf(true);
    if (isLoading === -1) {
      return dispatch(updateIsLoading(false))
    } else {
      return dispatch(updateIsLoading(true))
    }
  }
}

export function doChangeSequence(caseId, fileId, newSerialNumber) {
  return dispatch => {
    dispatch(putRequest());
    console.log(caseId, fileId, newSerialNumber);
    changeSequence(caseId, fileId, newSerialNumber).then(data => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      return dispatch(putFailure({ errMsg }));
    })
  }
}

// category
export const updateCategoryAndPageUrls = createAction(types.UPDATE_CATEGORY_AND_PAGEURLS);
export const getCaseCategoryRequest = createAction(types.GET_CASE_CATEGORY_REQUEST);
export const getCaseCategorySuccess = createAction(types.GET_CASE_CATEGORY_SUCCESS);
export const getCaseCategoryFailure = createAction(types.GET_CASE_CATEGORY_FAILURE);

export function doLoadCaseCategory(caseId) {
  return dispatch => {
    dispatch(updateCategoryAndPageUrls({}));
    dispatch(getCaseCategoryRequest());
    return getCaseCategories(caseId).then(data => {
      dispatch(updateCategoryAndPageUrls(data));
      dispatch(updateLoading());
      dispatch(getCaseCategorySuccess());
      return true;
    }).catch((errMsg) => {
      return dispatch(getCaseCategoryFailure({ errMsg }));
    })
  }
}

export const downloadRequest = createAction(types.DOWNLOAD_REQUEST);
export const downloadSuccess = createAction(types.DOWNLOAD_SUCCESS);
export const downloadFailure = createAction(types.DOWNLOAD_FAILURE);

export function doDownloadCase(caseId, caseName) {
  return dispatch => {
    dispatch(downloadRequest());
    exportCase(caseId, `${caseName}_文件.zip`).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg }));
    })
  }
}

export function doDownloadFile(fileId, fileTitle) {
  return dispatch => {
    dispatch(downloadRequest());
    exportFile(fileId, true, `${fileTitle}.pdf`).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg }));
    })
  }
}

export function doDownloadTypes(caseId, caseName) {
  return dispatch => {
    dispatch(downloadRequest());
    exportTypes(caseId, `${caseName}_目錄.zip`).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg }));
    })
  }
}

export function doPageTypeDownload(pageTypeIdList, pageTypeName) {
  return dispatch => {
    dispatch(downloadRequest());
    exportSingleTypes(pageTypeIdList, `${pageTypeName}_目錄.zip`).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg }));
    })
  }
}

export function doDownloadTags(caseId, caseName) {
  return dispatch => {
    dispatch(downloadRequest());
    exportTags(caseId, `${caseName}_索引.zip`).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg }));
    })
  }
}


// FileEdit
export function doFileEdit(fileId, newFileTitle, caseId) {
  return dispatch => {
    dispatch(putRequest());
    fileEdit(fileId, newFileTitle, caseId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(putFailure({ errMsg }));
    })
  }
}

export function doFileDelete(fileId, caseId) {
  return dispatch => {
    dispatch(deleteRequest());
    fileDelete(fileId, caseId).then(() => {
      dispatch(deleteSuccess());
      dispatch(doLoadCaseCategory(caseId));
      dispatch(doChangeCategoryPdf([]));
      dispatch(doLoadLabels(caseId));
      dispatch(doLoadDuplicates(caseId));
      dispatch(updateHitList([]));
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doPageTypeEdit(pageTypeIdList, newPageType, caseId) {
  return dispatch => {
    dispatch(putRequest());
    pageTypeEdit(pageTypeIdList, newPageType, caseId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(putFailure({ errMsg }));
    })
  }
}

export function doTagAdd(pageTypeId, addTag, caseId) {
  return dispatch => {
    dispatch(putRequest());
    tagAdd(pageTypeId, addTag).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(putFailure({ errMsg }));
    })
  }
}

export function doTagEdit(tagId, newTag, pageTypeId, caseId) {
  return dispatch => {
    dispatch(putRequest());
    if (pageTypeId === null) {
      tagEditTab(tagId, newTag, caseId).then(() => {
        dispatch(putSuccess());
        dispatch(doLoadCaseCategory(caseId));
      }).catch((errMsg) => {
        console.log(errMsg);
        return dispatch(putFailure({ errMsg }));
      })
    } else {
      tagEdit(tagId, newTag, pageTypeId, caseId).then(() => {
        dispatch(putSuccess());
        dispatch(doLoadCaseCategory(caseId));
      }).catch((errMsg) => {
        console.log(errMsg);
        return dispatch(putFailure({ errMsg }));
      })
    }
  }
}

export function doTagMigrateEdit(pageTypeId, fromTagId, toTagName, caseId) {
  return dispatch => {
    dispatch(putRequest());
    if (toTagName !== "") {
      tagMigrateEdit(pageTypeId, fromTagId, toTagName).then(() => {
        dispatch(putSuccess());
        dispatch(doLoadCaseCategory(caseId));
      }).catch((errMsg) => {
        console.log(errMsg);
        return dispatch(putFailure({ errMsg }));
      })
    }
  }
}

export function doAddKeyword(caseId, keyword) {
  return dispatch => {
    dispatch(postRequest());
    addKeyword(caseId, keyword).then(() => {
      dispatch(postSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export function doLabelDownload(labelId, labelName) {
  return dispatch => {
    dispatch(downloadRequest());
    exportLabel(labelId, `${labelName}_標籤.zip'`).then(data => {
      dispatch(downloadSuccess());
    }).catch((errMsg) => {
      dispatch(downloadFailure({ errMsg: '下載失敗' }));
    })
  }
}

export function doSubmitSaveNewPageTypeList(pageTypeId, pageTypeList, caseId) {
  return (dispatch) => {
    // const state = getState();
    // let pdfPageList = state.getIn(['doc', 'pdfPageList']);
    // let newPdfPageList = pdfPageList.slice(0, pageTypeList[0].toPageNo-pageTypeList[0].fromPageNo+1);
    // let newPdfPageIdList = newPdfPageList.map(newPdfPage => newPdfPage.getIn(['pageId']));
    dispatch(putRequest());
    breakPageType(pageTypeId, pageTypeList).then(() => {
      // dispatch(doChangeCategoryPdf(newPdfPageIdList.toJS()));
      dispatch(doLoadCaseCategory(caseId));
      dispatch(putSuccess());
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doSubmitMergePageType(pageTypeId, caseId, isDisplayFile) {
  return (dispatch) => {
    dispatch(postRequest());
    mergePageType(pageTypeId).then(() => {
      dispatch(doLoadCaseCategory(caseId));
      if (isDisplayFile) {
        dispatch(doChangeCategoryPdf([]));
      }
      dispatch(doLoadLabels(caseId));
      dispatch(doLoadDuplicates(caseId));
      dispatch(updateHitList([]));
      dispatch(postSuccess());
    }).catch((errMsg) => {
      console.log(errMsg);
      dispatch(postFailure({ errMsg }));
    })
  }
}

// pdf
export const updatePdfScale = createAction(types.UPDATE_PDF_SCALE);
export const updatePdfPage = createAction(types.UPDATE_PDF_PAGE);

export function doChangeCategoryPdf(pageIdList) {
  return (dispatch, getState) => {
    const state = getState();
    const pageNos = state.getIn(['doc', 'pageNos']);
    const pageList = [];
    pageIdList.forEach(pageId =>
      pageList.push({
        pageId,
        pageNo: pageNos.getIn([`${pageId}`])
      })
    );
    return dispatch(updatePdfPage({ pdfPageList: pageList, pdfPageIndex: 0, isLabelPdf: false }));
  }
}

export function doChangeLabelPdf(labelId) {
  return dispatch => {
    dispatch(getRequest());
    getLabelPages(labelId).then(data => {
      dispatch(updatePdfPage({ pdfPageList: data, pdfPageIndex: 0, isLabelPdf: true }));
      dispatch(getSuccess());
      return true;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}

export const updatePdfPageIndex = createAction(types.UPDATE_PDF_PAGE_INDEX);

// label
export const updateCaseList = createAction(types.UPDATE_CASE_LIST);
export function doLoadCaseList(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getCaseNames().then(data => {
      dispatch(updateCaseList(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export const updateUserFolderList = createAction(types.UPDATE_USER_FOLDER_LIST);
export function doLoadUserFolderList(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getFolderList().then(data => {
      dispatch(updateUserFolderList(data));
      dispatch(getSuccess());
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

export const updateLabels = createAction(types.UPDATE_LABELS);
export function doLoadLabels(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getLabels(caseId).then(data => {
      dispatch(updateLabels(data));
      dispatch(getSuccess());
      return true;
    }).catch((errMsg) => {
      return dispatch(getFailure({ errMsg }));
    })
  }
}

export function doSubmitLabel(caseId, labelName) {
  return dispatch => {
    dispatch(postRequest());
    createLabel(caseId, labelName).then(() => {
      dispatch(doLoadLabels(caseId));
      dispatch(postSuccess());
      return true;
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export function doSubmitAddPagesToLabel(caseId, isAddingRangeToLabel, labelId) {
  return (dispatch, getState) => {
    const state = getState();
    let pageIdList;
    if (isAddingRangeToLabel === true) {
      const pdfPageList = state.getIn(['doc', 'pdfPageList']);
      pageIdList = pdfPageList.map(pdfPage => pdfPage.getIn(['pageId'])).toJS();
    } else {
      const pdfPageIndex = state.getIn(['doc', 'pdfPageIndex']);
      const pageId = state.getIn(['doc', 'pdfPageList', pdfPageIndex, 'pageId']);
      pageIdList = [pageId];
    }
    if (pageIdList.length <= 0) {
      return dispatch(postFailure({ errMsg: 'pageIdList is empty.' }));
    }
    dispatch(postRequest());
    createLabelPages(labelId, pageIdList).then(() => {
      dispatch(doLoadLabels(caseId));
      dispatch(postSuccess());
      return true;
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(postFailure({ errMsg }));
    })
  }
}

export function doCopyLabelToFolder(userFolderId, labelId) {
  return dispatch => {
    dispatch(postRequest());
    copyLabelToFolder(userFolderId, labelId).then(() => {
      dispatch(postSuccess());
    }).catch((errMsg) => {
      console.log(errMsg);
      dispatch(postFailure({ errMsg }));
    })
  }
}

export const doChangeLabel = createAction(types.UPDATE_LABEL);

export function doSaveLabelNote(labelId) {
  return (dispatch, getState) => {
    const state = getState();
    const note = state.getIn(['doc', 'labels', labelId, 'note']);
    dispatch(putRequest());
    updateLabelNote(labelId, note).then(() => {
      dispatch(putSuccess());
      return true;
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDeleteLabel(caseId, labelId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteLabel(labelId).then(() => {
      dispatch(doLoadLabels(caseId));
      dispatch(deleteSuccess());
      return true;
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doRemoveLabelPage(caseId, labelId, fileId) {
  return (dispatch, getState) => {
    const state = getState();
    const pageIdList = state.getIn(['doc', 'labels', labelId, 'files', fileId, 'pageIdList']).toJS();
    dispatch(deleteRequest());
    deleteLabelPages(labelId, pageIdList).then(() => {
      dispatch(doLoadLabels(caseId));
      dispatch(deleteSuccess());
      return true;
    }).catch((errMsg) => {
      console.log(errMsg);
      return dispatch(deleteFailure({ errMsg }));
    })
  }
}

// search
export const updateHitList = createAction(types.UPDATE_HIT_LIST);

export function doSubmitSearch(caseId, searchWord) {
  return dispatch => {
    // dispatch(postRequest());
    createDocSearch(caseId, searchWord).then(data => {
      if (data.length === 0) {
        dispatch(updateHitList([{ 'fileTitle': '查無結果', 'pages': [] }]));
      } else {
        dispatch(updateHitList(data));
      }
      // dispatch(postSuccess());
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

// highlight
export function doDeleteAnnotations(pageId, type) {
  return dispatch => {
    dispatch(deleteRequest());
    if (type === 'highlight') {
      deleteHighlights(pageId).then(() => {
        dispatch(deleteSuccess());
      }).catch((errMsg) => {
        dispatch(deleteFailure({ errMsg }));
      })
    }
  }
}

export const updateAnnotation = createAction(types.UPDATE_ANNOTATION);

export function doLoadAnnotation(caseId) {
  return dispatch => {
    dispatch(getRequest());
    getCaseAnnotation(caseId).then(data => {
      dispatch(getSuccess());
      dispatch(updateAnnotation(data));
    }).catch((errMsg) => {
      dispatch(getFailure({ errMsg }));
    })
  }
}

// folder
export function doCreateFilesFolder(caseId, folderName) {
  return dispatch => {
    dispatch(postRequest());
    createFilesFolder(caseId, folderName).then(() => {
      dispatch(postSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doModifyFilesFolder(caseId, folderId, folderName) {
  return dispatch => {
    dispatch(putRequest());
    modifyFilesFolder(folderId, folderName).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDeleteFileFolder(caseId, folderId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteFileFolder(folderId).then(() => {
      dispatch(deleteSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doMovingFileToFolder(caseId, fileId, toFolderId) {
  return dispatch => {
    dispatch(putRequest());
    movingFileToFolder(fileId, toFolderId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doCombineDocPageToPages(caseId, pageTypeIdList, toPageTypeId) {
  return dispatch => {
    dispatch(putRequest());
    combineToPages(pageTypeIdList, toPageTypeId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doCreatePageTypesFolder(caseId, folderName) {
  return dispatch => {
    dispatch(postRequest());
    createPageTypesFolder(caseId, folderName).then(() => {
      dispatch(postSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(postFailure({ errMsg }));
    })
  }
}

export function doModifyFolder(caseId, folderId, folderName) {
  return dispatch => {
    dispatch(putRequest());
    modifyFolder(folderId, folderName).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doDeleteFolder(caseId, folderId) {
  return dispatch => {
    dispatch(deleteRequest());
    deleteFolder(folderId).then(() => {
      dispatch(deleteSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(deleteFailure({ errMsg }));
    })
  }
}

export function doMovingToFolder(caseId, pageTypeIdList, toFolderId) {
  return dispatch => {
    dispatch(putRequest());
    movingToFolder(pageTypeIdList, toFolderId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doMovingToPageTypeInFolder(caseId, pageTypeIdList, toType, toFolderId) {
  return dispatch => {
    dispatch(putRequest());
    movingToPageTypeInFolder(pageTypeIdList, toType, toFolderId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doMovingToPageType(caseId, pageTypeIdList, toType) {
  return dispatch => {
    dispatch(putRequest());
    movingToPageType(pageTypeIdList, toType).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}

export function doCombinePageToPages(caseId, pageTypeIdList, toPageTypeId) {
  return dispatch => {
    dispatch(putRequest());
    combineToPages(pageTypeIdList, toPageTypeId).then(() => {
      dispatch(putSuccess());
      dispatch(doLoadCaseCategory(caseId));
    }).catch((errMsg) => {
      dispatch(putFailure({ errMsg }));
    })
  }
}
