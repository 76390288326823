import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import PlanComponent from './component';


const mapStateToProps = (state) => ({
  isGetting: selectors.getIsGetting(state),
  isPosting: selectors.getIsPosting(state),
  storage: selectors.getStorage(state),
  plan: selectors.getPlan(state),
  expire: selectors.getExpire(state),
  upgradeList: selectors.getUpgradeList(state),
});

const mapDispatchToProps = dispatch => ({  
  onLoadPlan: (userId) => {
    dispatch(actions.doLoadPlan(userId));
  },
  onLoadUpdatable: (userId) => {
    dispatch(actions.doLoadUpdatable(userId));
  },
  onUpgrade: (userId, planId) => {
    dispatch(actions.doUpgrade(userId, planId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanComponent);