import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  errMsg: null,  
  totalHits: 0,  
  hitList: [],
  lawData: {
    name: '',
    no: '',
    date: '',
    desc: ''
  },
  caseList: []
});