// common
export const POST_REQUEST = 'creatConsultations/POST_REQUEST'
export const POST_SUCCESS = 'creatConsultations/POST_SUCCESS'
export const POST_FAILURE = 'creatConsultations/POST_FAILURE'
export const GET_REQUEST = 'creatConsultations/GET_REQUEST'
export const GET_SUCCESS = 'creatConsultations/GET_SUCCESS'
export const GET_FAILURE = 'creatConsultations/GET_FAILURE'
// cases
export const UPDATE_ERR_MSG = 'creatConsultations/UPDATE_ERR_MSG'
export const UPDATE_APPOINTED_HISTORY_LIST = 'creatConsultations/UPDATE_APPOINTED_HISTORY_LIST'
export const UPDATE_CASE_LIST = 'creatConsultations/UPDATE_CASE_LIST'
export const UPDATE_CASE_RELATE_LIST = 'creatConsultations/UPDATE_CASE_RELATE_LIST'
export const GET_CASE_RELATED_REQUEST = 'creatConsultations/GET_CASE_RELATED_REQUEST'
export const GET_CASE_RELATED_SUCCESS = 'creatConsultations/GET_CASE_RELATED_SUCCESS'
export const GET_CASE_RELATED_FAILURE = 'creatConsultations/GET_CASE_RELATED_FAILURE'