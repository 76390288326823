import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'login/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'login/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'login/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'login/GET_REQUEST': (state, { payload }) => {
    return state.setIn(['isGetting'], true)
                .setIn(['errMsg'], null);
  },
  'login/GET_SUCCESS': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], null);
  },
  'login/GET_FAILURE': (state, { payload }) => {
    return state.setIn(['isGetting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'login/UPDATE_REDIRECT_TO': (state, { payload }) => {
    return state.setIn(['redirectTo'], payload)
                .setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'login/UPDATE_TWO_FACTOR_AUTHENTICATION_ID': (state, { payload }) => {
    return state.setIn(['twoFactorAuthenticationId'], payload)
                .setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'login/UPDATE_LOGIN_CHECK_NOTIFICATION':  (state, { payload }) => {
    return state.setIn(['loginCheckNotification'], payload);
  },
}, State);

export default Reducer;