import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { message, Modal, Tooltip, Input, Tabs, DatePicker, Typography } from 'antd';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { CASE_TYPE, DATE_FORMAT, CONSULTATION_CASE_STATEID } from '../../config'
import Spinner from '../../utils/Spinner';
import FullSpinner from '../../utils/FullSpinner';
import consultationAppoint from '../consultationAppoint';
import consultationCase from '../consultationCase';
import consultationFee from '../consultationFee';
import consultationFiles from '../consultationFiles';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Text } = Typography;

class ConsultationsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchName: '',
      startDay: null,
      endDay: null,
      isEditing: false,
      showRWDDetailModal: false,
      detailItems: [
        {'name': '諮詢人',},
        {'name': '案件相關',},
        {'name': '費用',},
        {'name': '委任檔案',},
      ],
      RWDcurrentDetail: '',
    };
    this.onChangeIsEditing = this.onChangeIsEditing.bind(this);
    this.onChangeDetail = this.onChangeDetail.bind(this);
  }
  componentDidMount() {
    this.props.onLoadConsultationList();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDeleting === true && this.props.isDeleting === false) {
      if (this.props.errMsg === null) {
        message.success('刪除成功');
        this.props.onLoadConsultationList(this.state.searchName, this.state.searchText);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        message.success('轉委任成功');
        this.props.onLoadConsultationList(this.state.searchName, this.state.searchText);        
        this.props.history.push(`/cases`);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.currentConsultationId === null && prevProps.currentConsultationId !== this.props.currentConsultationId) {
      this.onChangeConsultation(String(this.props.currentConsultationId))
    }
  }
  onCleanSearch() {
    this.setState({ searchName: '', startDay: null, endDay: null }, () => this.props.onLoadConsultationList())
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onChangeDate(startDay, endDay) {
    this.setState({ startDay, endDay })
  }
  onChangeConsultation(nextConsultationId) {
    const { isEditing } = this.state;
    if (isEditing === true) {
      this.onConfirmChangeTab(nextConsultationId, this.props.currentDetailTab)
    } else {
      this.props.onLoadConsultationDetail(nextConsultationId);
    }
  }
  onClickDeleteConsultation(consultationId) {
    Modal.confirm({
      title: '刪除案件',
      content: '按下"確認"按鈕將刪除此案件。',
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteConsultation(consultationId);
      },
    });
  };
  onSubmitSearch() {
    let { searchName, startDay, endDay } = this.state;
    searchName = searchName.trim();
    if (!searchName && !startDay && !startDay) {
      return message.error('請輸入至少一個欄位');
    }
    startDay = startDay ? moment(startDay).format('YYYY/MM/DD') : '';
    endDay = endDay ? moment(endDay).format('YYYY/MM/DD') : '';
    this.props.onLoadConsultationList(searchName, startDay, endDay)
  }
  onChangeIsEditing(isEditing) {
    this.setState({ isEditing })
  }
  onClickTab(currentDetailTab) {
    const { isEditing } = this.state;
    if (isEditing === true) {
      this.onConfirmChangeTab(this.props.currentConsultationId, currentDetailTab)
    } else {
      this.props.onUpdateCurrentDetailTab(currentDetailTab)
    }
  }
  onConfirmChangeTab(nextConsultationId, currentDetailTab) {
    Modal.warning({
      title: '編輯提醒',
      content: '尚有編輯中的內容，請先儲存更新後再切換分頁。',
      okText: '了解',
      onOk: () => {
        // if (nextConsultationId === this.props.currentConsultationId) {  // 同一筆委任切換 tab
        //   this.setState({ isEditing: false, currentDetailTab });
        //   this.props.onUpdateCurrentDetailTab(currentDetailTab);
        // } else {  // 切換委任
        //   this.setState({ isEditing: false });
        //   this.props.onUpdateCurrentDetailTab(currentDetailTab);
        //   this.props.onLoadConsultationDetail(nextConsultationId);
        // }
      }
    });
  }
  onClickAppointConsultation = (consultationId) => {
    console.log(consultationId)
    let appointNoId = '';
    let appointName = '';
    Modal.confirm({
      title: `確認此諮詢紀錄正式委託案件`,
      content:
        <>
          <Input className="mb-3"
            placeholder="請輸入委任案由"
            defaultValue={appointName}
            onChange={e => appointName = e.target.value}
          />
          <Input placeholder="請輸入所內編號"
            defaultValue={appointNoId}
            onChange={e => appointNoId = e.target.value}
          />
        </>,
      okText: '確認轉委任',
      cancelText: '取消',
      onOk: () => {
        appointName = appointName.trim();
        appointNoId = appointNoId.trim();
        if (appointName !== '' && appointNoId !== '') {
          this.props.onAppointConsultation(parseInt(consultationId), appointName, appointNoId)
        } else {
          message.error('請輸入委任案由、所內編號')
        }
      },
    });
  };

  onChangeDetail() {
    const { searchName, startDay, endDay } = this.state;
    this.props.onLoadConsultationList(searchName, startDay, endDay);
    this.props.onLoadConsultationDetail(this.props.currentConsultationId);
  }
  onToggleRWDDetailMode(detailTab) {
    this.setState({ showRWDDetailModal: true, RWDcurrentDetail: detailTab })
  }

  render() {
    const { 
      searchName, startDay, endDay,
      showRWDDetailModal, detailItems, RWDcurrentDetail
    } = this.state;
    return (
      <>
        {
          this.props.isGettingConsultationList
          &&
          <FullSpinner />
        }
        <div className="tool-bar">
          <Form className="search"
            onSubmit={e => { e.preventDefault(); this.onSubmitSearch() }}
          >
            <div className="search-col">
              <Input
                placeholder="請輸入諮詢人"
                allowClear
                type="text"
                value={searchName}
                onChange={e => this.onChangeState('searchName', e.target.value)}
              />
            </div>
            <div className="search-col">
              <RangePicker
                format={DATE_FORMAT}
                value={[startDay?moment(startDay, DATE_FORMAT):null, endDay?moment(endDay, DATE_FORMAT):null]}
                onChange={(value, dateString) => this.onChangeDate(dateString[0], dateString[1])}
              />
            </div>
            <button data-txt="搜尋諮詢" className="btn-search btn-empty" variant="primary" type="submit">
              <i><FontAwesomeIcon icon={fas.faSearch} /></i>
            </button>
            <Tooltip title="重新搜尋">
              <button className="btn-empty" data-txt="重新搜尋"
                type="button"
                onClick={e => this.onCleanSearch()}
              ><FontAwesomeIcon icon={fas.faRedoAlt} /></button>
            </Tooltip>
          </Form>
          <div className="btn-tool">
            <Link to="/creat-consultations"><i><FontAwesomeIcon icon={fas.faPlus} /></i>新增諮詢</Link>
          </div> 
        </div>
        <div className="consultations section-row">
          <div className="section-col section-list">
            <ul className="case-list-head">
              <li className="head-date">諮詢日期</li>
              <li className="head-appointorName">諮詢人</li>
              <li className="head-consultations-typeId">案件種類</li>
              <li className="head-content">案件內容</li>
            </ul>
            <div className="case-list-body-wrap">
              {
                !this.props.consultationIdList || this.props.consultationIdList.size === 0 
                ?
                <div className="case-item-tip">無諮詢案件，請先新增諮詢</div>
                :
                this.props.consultationIdList.map(consultationId =>
                  <div key={`case-${consultationId}`} className={`case-item ${this.props.currentConsultationId === consultationId ? 'active' : ''}`} onClick={e => this.onChangeConsultation(consultationId)}>
                    <ul className="case-list-body">
                      <li className="body-date" data-th="諮詢日期：">{this.props.consultations.getIn([consultationId, 'consultationDate'])}</li>
                      <li className="body-appointorName" data-th="諮詢人：">
                        { this.props.consultations.getIn([consultationId, 'appointName']) }
                      </li>
                      <li className="body-consultations-typeId" data-th="案件種類：">
                        { CASE_TYPE.find(type => type['id'] === this.props.consultations.getIn([consultationId, 'caseTypeId'])).name }
                      </li>
                      <li className="body-content" data-th="內容：">
                        { this.props.consultations.getIn([consultationId, 'consultationContent']) }
                      </li>
                    </ul>
                  </div>
                )
              }
            </div>
          </div>
          
          <div className="section-col section-col-item consultationdetail-section">
            {
              this.props.isGettingConsultationDetail
              ?
                <Spinner />
              :
                <>
                  <div className="consultationdetail-header">
                    <div className="consultationdetail-header-title">
                      <div>
                        { 
                          this.props.consultationDetail.getIn(['case', 'typeId'])
                          &&
                          `${CASE_TYPE.find(caseType => caseType.id===this.props.consultationDetail.getIn(['case', 'typeId'])).name} ${this.props.consultationDetail.getIn(['appoint', 'appointTypeId'])===1?this.props.consultationDetail.getIn(['appoint', 'appointor', 'name']):this.props.consultationDetail.getIn(['appoint', 'company', 'name'])} ${this.props.consultationDetail.getIn(['case', 'statusId']) === 2 ? `-${CONSULTATION_CASE_STATEID[this.props.consultationDetail.getIn(['case', 'statusId'])]}` : ''}`
                        }
                      </div>
                      {
                        this.props.currentConsultationId !== null
                        &&
                        <div className="detail-tool">
                          {/* <Tooltip title="預覽列印-諮詢紀錄">
                            <Link target="_blank" to={`/export-consultations`}><i><FontAwesomeIcon icon={fas.faPrint} /></i></Link>
                          </Tooltip> */}
                          <Tooltip title="此紀錄轉委任">
                            <button className="btn-empty btn-assign"
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.onClickAppointConsultation(this.props.currentConsultationId)
                                    }}
                            ><i><FontAwesomeIcon icon={fas.faShareSquare} /></i></button>
                          </Tooltip>
                          <Tooltip title="刪除諮詢">
                            <button className="btn-warn" onClick={e => this.onClickDeleteConsultation(this.props.currentConsultationId)}>
                              <i><FontAwesomeIcon icon={fas.faMinus} /></i>
                            </button>
                          </Tooltip>
                        </div>
                      }
                    </div>
                  </div>
                  {
                    this.props.consultationIdList.size !== 0
                    ?
                      <Tabs className="consultationdetail-tabpane" 
                        defaultActiveKey={this.props.currentDetailTab} 
                        activeKey={this.props.currentDetailTab} 
                        onTabClick={value => this.onClickTab(value)} 
                        destroyInactiveTabPane={true}
                      >
                        <TabPane tab="諮詢人" key="appointor">
                          <consultationAppoint.container 
                            consultationId={this.props.currentConsultationId}
                            appoint={this.props.consultationDetail.getIn(['appoint'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="案件相關" key="case">
                          <consultationCase.container 
                            consultationId={this.props.currentConsultationId}
                            caseDetail={this.props.consultationDetail.getIn(['case'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="費用" key="fee">
                          <consultationFee.container
                            consultationId={this.props.currentConsultationId}
                            fee={this.props.consultationDetail.getIn(['fee'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="委任檔案" key="userFiles">
                          <consultationFiles.container
                            consultationId={this.props.currentConsultationId}
                            consultationFileList={this.props.consultationDetail.getIn(['consultationFileList'])}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                      </Tabs>
                    :
                      <Tabs className="consultationdetail-tabpane" >
                        <TabPane tab="諮詢人" key="appointor"><Text className="disabled" disabled>請新增諮詢</Text></TabPane>
                        <TabPane tab="案件相關" key="case"><Text className="disabled" disabled>請新增諮詢</Text></TabPane>
                        <TabPane tab="費用" key="fee"><Text className="disabled" disabled>請新增諮詢</Text></TabPane>
                        <TabPane tab="委任檔案" key="userFiles"><Text className="disabled" disabled>請新增諮詢</Text></TabPane>
                      </Tabs>
                  }
                </>
            }
          </div>
          <ul className="RWD-consultationdetail-section">
            {
              detailItems.map(detailItem => (
                <li key={`${detailItem.name}`}><button onClick={e => this.onToggleRWDDetailMode(detailItem.name)}>{detailItem.name}</button></li>
              ))
            }
          </ul>
        </div>

        <Modal
          className="RWDDetailModal"
          visible={showRWDDetailModal}
          onCancel={() => this.setState({ showRWDDetailModal: false })}
        >
          {
            RWDcurrentDetail === '諮詢人' ?
              <div>
                <consultationAppoint.container 
                  consultationId={this.props.currentConsultationId}
                  appoint={this.props.consultationDetail.getIn(['appoint'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '案件相關' ?
              <div>
                <consultationCase.container 
                  consultationId={this.props.currentConsultationId}
                  caseDetail={this.props.consultationDetail.getIn(['case'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '費用' ?
              <div>
                <consultationFee.container
                  consultationId={this.props.currentConsultationId}
                  fee={this.props.consultationDetail.getIn(['fee'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            :  RWDcurrentDetail === '委任檔案' ?
              <div>
                <consultationFiles.container
                  consultationId={this.props.currentConsultationId}
                  consultationFileList={this.props.consultationDetail.getIn(['consultationFileList'])}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            :
              null
          }
        </Modal>
      </>
    )
  }
}

export default withRouter(ConsultationsComponent);