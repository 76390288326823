// common
export const DOWNLOAD_REQUEST = 'appointmentUserFiles/DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'appointmentUserFiles/DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAILURE = 'appointmentUserFiles/DOWNLOAD_FAILURE'
export const POST_REQUEST = 'appointmentUserFiles/POST_REQUEST'
export const POST_SUCCESS = 'appointmentUserFiles/POST_SUCCESS'
export const POST_FAILURE = 'appointmentUserFiles/POST_FAILURE'
export const GET_REQUEST = 'appointmentUserFiles/GET_REQUEST'
export const GET_SUCCESS = 'appointmentUserFiles/GET_SUCCESS'
export const GET_FAILURE = 'appointmentUserFiles/GET_FAILURE'
export const PUT_REQUEST = 'appointmentUserFiles/PUT_REQUEST'
export const PUT_SUCCESS = 'appointmentUserFiles/PUT_SUCCESS'
export const PUT_FAILURE = 'appointmentUserFiles/PUT_FAILURE'
export const DELETE_REQUEST = 'appointmentUserFiles/DELETE_REQUEST'
export const DELETE_SUCCESS = 'appointmentUserFiles/DELETE_SUCCESS'
export const DELETE_FAILURE = 'appointmentUserFiles/DELETE_FAILURE'
export const BACKUP_REQUEST = 'appointmentUserFiles/BACKUP_REQUEST'
export const BACKUP_SUCCESS = 'appointmentUserFiles/BACKUP_SUCCESS'
export const BACKUP_FAILURE = 'appointmentUserFiles/BACKUP_FAILURE'

export const UPDATE_IS_EXCEEDED_QUOTA = 'attachment/UPDATE_IS_EXCEEDED_QUOTA'
