import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  isPutting: false,
  isDeleting: false,
  errMsg: '',
  isLoading: false,
  isExceededQuota: false,
  encryptedPdfNameList: [],
  // category
  fileList: [],
  fileFolderList: [],
  typeList: [],
  pageTypeFolderList: [],
  tagList: [],
  keywordList: [],
  isDownloading: false,
  isGettingCaseCategory: false,
  // pageDuplicates
  duplicates: {},
  fileNames: {},
  // case
  caseName: '',
  // pdf
  isLabelPdf: false,
  pdfPageIndex: 0,
  pdfPageList: [],
  pdfScale: "auto", // auto是寬度符合容器寬度，1是正常比例
  // label
  caseList: [],
  userFolderList: [],
  labelIdList: [],
  labels: {},
  // Highlight
  highlightList: [],
  highlightDict: {},
});
