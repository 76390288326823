import { message } from "antd";
import { createAction } from 'redux-actions';
import { getNotification, getNotificationHistory, updateNotificationStatus, cLogout } from '../../utils/api';
import * as types from './actionTypes';
import * as config from '../../config'

// common
export const httpRequest = createAction(types.HTTP_REQUEST);
export const httpSuccess = createAction(types.HTTP_SUCCESS);
export const httpFailure = createAction(types.HTTP_FAILURE);

export const updateNotification = createAction(types.UPDATE_NOTIFICATION);
export const updateHistory = createAction(types.UPDATE_HISTORY);
export const updateHistoryCount = createAction(types.UPDATE_HISTORY_COUNT);
export const updateRWDNavbar = createAction(types.UPDATE_RWD_NAVBAR);
export const updateLoginCheckNotification = createAction(types.UPDATE_LOGIN_CHECK_NOTIFICATION);

export function doLoadNotification() {
  return (dispatch) => {
    dispatch(updateNotification([]));
    dispatch(httpRequest());
    const mockData = [
      { type: "alert", message: "121", messageId: 1, isRead: true },
      { type: "system", message: "121", messageId: 2, isRead: true },
      { type: "ads", message: "121", messageId: 3, isRead: true },
    ];
    getNotification().then((res) => {
      dispatch(updateNotification(res.data));
      // dispatch(updateNotification(mockData));
      dispatch(httpSuccess());
    }).catch((errMsg) => {
      message.error(`讀取系統訊息失敗，請稍後再試 [${errMsg.toString()}]`);
      dispatch(httpFailure({ errMsg: "" }));
    })
  }
}

export function doLoadHistory(page) {
  return (dispatch) => {
    dispatch(updateHistory([]));
    dispatch(updateHistoryCount(0));
    dispatch(httpRequest());
    getNotificationHistory(page).then((res) => {
      dispatch(updateHistory(res.data));
      dispatch(updateHistoryCount(res.totalCount));
      dispatch(httpSuccess());
    }).catch((errMsg) => {
      message.error(`讀取歷史訊息失敗，請稍後再試 [${errMsg.toString()}]`);
      dispatch(httpFailure({ errMsg: "" }));
    })
  }
}

export function doUpdateNotificationStatus(messageId) {
  return (dispatch) => {
    dispatch(httpRequest());
    updateNotificationStatus(messageId).then(() => {
      dispatch(httpSuccess());
      dispatch(doLoadNotification());
    }).catch((errMsg) => {
      message.error(`更新系統狀態失敗，請稍後再試 [${errMsg.toString()}]`);
      dispatch(httpFailure({ errMsg: "" }));
    })
  }
}
export function doLogout() {
  return dispatch => {
    cLogout().then(() => {
      localStorage.clear('token', 'userId');
      window.location.href = `${config.logoutPage}`;
    })
  }
}