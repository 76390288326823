import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import RegistrationComponent from './component';


const mapStateToProps = (state) => ({
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmitApply: (email, password, lastName, firstName, lawOffice, phoneNo, mobile) => {
    dispatch(actions.doSubmitApply(email, password, lastName, firstName, lawOffice, phoneNo, mobile));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationComponent);