import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Input, message, Modal, Select, Tooltip, Upload} from 'antd';
import {fromJS} from "immutable";
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import dnaLogoIcon from '../../assets/img/dna-onlyLogo.svg'
import {AttachmentPopupString} from "../../constants/AttachmentPopupString";
import FullSpinner from '../../utils/FullSpinner';
import PdfViewer from "./PdfViewer";


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

class AttachmentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // side bar
      expandCategorySet: new Set(),
      showLeftBar: true,
      showUserTool: false,
      // attachment
      selectedFileList: [],
      selectedFileTool: null,
      selectedFileToolPosition: null,
      // middle
      switchView: "pdf",
      showSearchForm: false,
      searchCriteria: "",
      currentPage: "",
      // popup
      showAttachmentPopup: false,
      currentPopupAction: null,
      attachmentPopupString: AttachmentPopupString,
      selectedFile: null,
      uploadFolderId: null,
      uploadFolderName: "",
      popupErrorMessage: "",
    };
  }

  componentDidMount() {
    this.props.onLoadCaseName(this.props.caseId);
    this.props.onLoadAttachments(this.props.caseId);
    this.props.onLoadFolderList();

    const windowWidth = window.innerWidth || document.body.clientWidth;
    if (windowWidth < 1025) {
      this.setState({ showLeftBar: false });
    }
    this.restoreAttachmentSetting();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.restoreAttachmentCache();
    if (prevProps.isUploading === true && this.props.isUploading === false) {
      if (this.props.errMsg === null) {
        if (this.props.isExceededQuota === true) {
          message.warning('已超出限額');
        } else {  // 成功上傳。
          message.success("委任檔案上傳成功");
          this.setState({
            selectedFileList: [],
            uploadFolderId: null,
            showAttachmentPopup: false,
            currentPopupAction: null,
            popupErrorMessage: ""
          });
        }
      } else {
        message.error(`委任檔案上傳失敗 [${this.props.errMsg}]`);
      }
    }
  }

  componentWillUnmount() {
    const history = this.props.history.location.pathname;
    if (history.indexOf("review") === -1) {
      localStorage.removeItem("attachmentCache");
      localStorage.removeItem("attachmentCacheExpandCategorySet");
      localStorage.removeItem("attachmentCacheSwitchView");
      localStorage.removeItem("attachmentCacheSearchCriteria");
      localStorage.removeItem("reviewCacheHasPageIndex");
      localStorage.removeItem("reviewCacheSwitchView");
      localStorage.removeItem("reviewCacheHighlight");
      localStorage.removeItem("reviewCacheTabValue");
      localStorage.removeItem("reviewCacheExpandCategorySet");
      localStorage.removeItem("reviewCacheHasItem");
      localStorage.removeItem("reviewCacheSearchWord");
      localStorage.removeItem("attachmentSelectedFileFolderId");
    }
  }

  getAttachmentPopupStyle(key) {
    return this.state.currentPopupAction &&
      this.state.attachmentPopupString[this.state.currentPopupAction][key];
  }

  getAttachmentPopupContent() {
    const { Option } = Select;
    const { selectedFile, currentPopupAction, selectedFileList } = this.state;
    switch (currentPopupAction) {
      case "createFolder":
        return <div className="modal-createFolder">
          <div className="popup-item-wrapper">
            <span className="popup-item-label">資料夾名稱</span>
            <Input
              className="popup-item-value"
              placeholder="資料夾名稱"
              onChange={(event) => this.setState({ uploadFolderName: event.target.value })}
            />
          </div>
        </div>;
      case "uploadFile":
        const UploadProps = {
          accept: ".doc,.docx,.ppt,.pptx,.jpg,.png,.pdf,.txt",
          multiple: true,
          defaultFileList: selectedFileList,
          onRemove: file => {
            this.setState(state => {
              const index = state.selectedFileList.indexOf(file);
              const newUploadFileList = state.selectedFileList.slice();
              newUploadFileList.splice(index, 1);
              return {
                selectedFileList: newUploadFileList,
              };
            });
          },
          beforeUpload: file => {
            this.setState(state => ({
              selectedFileList: [...state.selectedFileList, file],
            }));
            return false;
          }
        };
        return <div className="modal-uploadFile">
          <div className="popup-item-wrapper">
            <span className="popup-item-label">選擇資料夾</span>
            <Select
              className="popup-item-value"
              placeholder="請選擇資料夾"
              onChange={(value) => this.setState({ uploadFolderId: value })}>
              {
                Object.keys(this.props.folderDict.toJS()).map((folderId) =>
                  <Option key={folderId} value={folderId}>{this.props.folderDict.getIn([folderId])}</Option>
                )
              }
            </Select>
          </div>
          <div className="popup-item-wrapper popup-item-file">
            <span className="popup-item-label">檔案</span>
            <Upload className="popup-item-value" {...UploadProps}>
              <Button className="btn btn-primary upload-btn">
                <FontAwesomeIcon icon={fas.faFileUpload}/>
                <span className="upload-label">請選擇一/數個檔案</span>
              </Button>
            </Upload>
          </div>
        </div>;
      case "downloadFile":
        return <div className="modal-downloadFile">
          <p className="popup-content">
            {`欲下載《${selectedFile.getIn(["name"])}》委任檔案`}
          </p>
        </div>;
      case "moveFile":
        return <div className="modal-moveFile">
          <div className="popup-item-wrapper">
            <span className="popup-item-label">移動資料夾</span>
            <Select
              className="popup-item-value"
              placeholder="請選擇資料夾"
              defaultValue={selectedFile.getIn(["folderId"])}
              onChange={(value) => this.setState({ uploadFolderId: value })}>
              {
                Object.keys(this.props.folderDict.toJS()).map((folderId) =>
                  <Option key={folderId} value={folderId}>{this.props.folderDict.getIn([folderId])}</Option>
                )
              }
            </Select>
          </div>
          <div className="popup-item-wrapper">
            <span className="popup-item-label">移動檔案</span>
            <span className="popup-item-value">{selectedFile.getIn(["name"])}</span>
          </div>
        </div>;
      case "deleteFile":
        return <div className="modal-deleteFile">
          <p className="popup-content">
            {`欲刪除《${selectedFile.getIn(["name"])}》委任檔案`}
          </p>
        </div>;
      default:
        break;
    }
  }

  onSubmitAttachmentPopup() {
    this.setState({ popupErrorMessage: "" });
    const { uploadFolderId, uploadFolderName, selectedFileList, selectedFile, currentPopupAction } = this.state;
    switch (currentPopupAction) {
      case "createFolder":
        this.props.onSubmitCreateFolder(uploadFolderName).then((res) => {
          if (res) {
            message.success("資料夾新增成功");
            this.setState({
              uploadFolderName: "",
              showAttachmentPopup: false,
              currentPopupAction: null,
              popupErrorMessage: ""
            });
          } else {
            message.error(`資料夾新增失敗 [${this.props.errMsg}]`);
          }
        });
        break;
      case "uploadFile":
        if (selectedFileList.length === 0) {
          this.setState({ popupErrorMessage: "請選擇欲上傳之檔案" });
        } else if (!uploadFolderId) {
          this.setState({ popupErrorMessage: "請選擇資料夾" });
        } else {
          this.props.onSubmitUploadAttachments(this.props.caseId, selectedFileList, uploadFolderId);
        }
        break;
      case "downloadFile":
        this.props.onDownloadAttachment(selectedFile.getIn(["id"]), selectedFile.getIn(["name"])).then((res) => {
          if (res) {
            message.success("委任檔案下載成功");
          } else {
            message.error(`委任檔案下載失敗 [${this.props.errMsg}]`);
          }
          this.setState({
            selectedFile: null,
            showAttachmentPopup: false,
            currentPopupAction: null,
            popupErrorMessage: "",
          });
        });
        break;
      case "moveFile":
        if (!uploadFolderId) {
          this.setState({ popupErrorMessage: "請選擇資料夾" });
        } else {
          this.props.onSubmitMoveFolder(this.props.caseId, uploadFolderId, selectedFile.get("id")).then((res) => {
            if (res) {
              message.success("委任檔案移動成功");
              this.setState({
                selectedFile: null,
                uploadFolderId: null,
                showAttachmentPopup: false,
                currentPopupAction: null,
                popupErrorMessage: "",
              });
            } else {
              message.error(`委任檔案移動失敗 [${this.props.errMsg}]`);
            }
          });
        }
        break;
      case "deleteFile":
        this.props.onDeleteAttachment(this.props.caseId, selectedFile.getIn(["id"])).then((res) => {
          if (res) {
            message.success("委任檔案刪除成功");
          } else {
            message.error(`委任檔案刪除失敗 [${this.props.errMsg}]`);
          }
          this.setState({
            selectedFile: null,
            showAttachmentPopup: false,
            currentPopupAction: null,
            popupErrorMessage: "",
          });
        });
        break;
    }
  }

  onCancelAttachmentPopup() {
    this.setState({
      currentPopupAction: null, showAttachmentPopup: false,
      selectedFile: null, uploadFolderId: null, selectedFileList: [],
      uploadFolderName: "",
    });
  }

  onSubmitSearch() {
    this.props.onCleanSearchResult();
    this.props.onSearchAttachment(this.props.caseId, this.state.searchCriteria);
  }

  toggleCategory = (item, action) => {
    const { expandCategorySet } = this.state;
    if (expandCategorySet.has(item)) {
      if (action === "close" || action === undefined) {
        expandCategorySet.delete(item);
      }
    } else {
      if (action === "open" || action === undefined) {
        expandCategorySet.add(item);
      }
    }
    this.setState({ expandCategorySet })
  };

  onChangeAttachmentPdf = (attachment) => {
    this.setState({
      currentPage: attachment
    });
  };

  gotoReviewPage() {
    if (this.state.currentPage) {
      localStorage.setItem("attachmentCache", JSON.stringify(this.state.currentPage.toJS()));
    }
    localStorage.setItem("attachmentCacheExpandCategorySet", Array.from(this.state.expandCategorySet).join(","));
    localStorage.setItem("attachmentCacheSwitchView", this.state.switchView);
    localStorage.setItem("attachmentCacheSearchCriteria", this.state.searchCriteria);
    this.props.history.push(`/review/${this.props.caseId}`)
  }

  restoreAttachmentCache() {
    const attachmentCache = localStorage.getItem("attachmentCache");
    const attachmentCacheMap = fromJS(JSON.parse(attachmentCache));
    const selectedFileFolderId = localStorage.getItem("attachmentSelectedFileFolderId");
    const selectedFileId = localStorage.getItem("attachmentSelectedFileId");
    if (attachmentCacheMap) {
      this.onChangeAttachmentPdf(attachmentCacheMap);
      localStorage.removeItem("attachmentCache");
    }
    if (this.props.attachmentDict.size > 0 && selectedFileId && selectedFileFolderId) {
      let selectedFile = this.props.attachmentDict.get(selectedFileFolderId).find((attach) => attach.get("id") === +selectedFileId);
      if (selectedFile) {
        this.onChangeAttachmentPdf(selectedFile.set("folderId", selectedFileFolderId));
      }
      this.toggleCategory(`folder-${selectedFileFolderId}`, "open");
      localStorage.removeItem("attachmentSelectedFileFolderId");
      localStorage.removeItem("attachmentSelectedFileId");
    }
  }

  restoreAttachmentSetting() {
    const expandCategorySet = localStorage.getItem("attachmentCacheExpandCategorySet");
    const switchView = localStorage.getItem("attachmentCacheSwitchView");
    const searchCriteria = localStorage.getItem("attachmentCacheSearchCriteria");
    if (expandCategorySet) {
      this.setState({
        expandCategorySet: new Set(expandCategorySet.split(",")),
      });
      localStorage.removeItem("attachmentCacheExpandCategorySet");
    }
    if (switchView) {
      this.setState({ switchView });
      localStorage.removeItem("attachmentCacheSwitchView");
    }
    if (searchCriteria) {
      this.setState({ searchCriteria, showSearchForm: true });
      setTimeout(() => this.onSubmitSearch(), 500);
      localStorage.removeItem("attachmentCacheSearchCriteria");
    }
  }
  render() {
    const {
      expandCategorySet, selectedFileTool, selectedFileToolPosition,
      showLeftBar, showUserTool, showAttachmentPopup,
      currentPopupAction, popupErrorMessage,
      switchView, showSearchForm, searchCriteria, currentPage
    } = this.state;
    return (
      <div className="main-container" id="attachment-container"
           onClick={() => this.setState({ showUserTool: false, selectedFileTool: null, selectedFileToolPosition: null })}>
        <div className={`sideBar-container ${showLeftBar ? "open" : ""}`} id="left-bar">
          <div className="user-container" onClick={(event) => event.stopPropagation()}>
            <div className="user-wrapper">
              <div className="user-left">
                <Tooltip title="回首頁">
                  <p className="user-logo-wrapper"><img className="user-logo" alt="回首頁" src={dnaLogoIcon} onClick={() => this.props.history.push(`/cases`)}/></p>
                </Tooltip>
                <Tooltip title="至法律卷證頁">
                  <p className="user-tab-wrapper" onClick={() => this.gotoReviewPage()}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faBookReader}/>
                  </p>
                </Tooltip>
                <p className="user-title-wrapper"><span className="user-mainTitle">委任檔案管理</span></p>
              </div>
              <div className="user-right user-toolBar-active">
                {
                  showLeftBar &&
                  <Tooltip title="關閉側邊欄" placement="right">
                    <p className="toolBar-active-icon"
                       onClick={(event) => {
                         event.stopPropagation();
                         this.setState({ showLeftBar: false, showUserTool: false, selectedFileTool: null, selectedFileToolPosition: null });
                       }}>
                      <FontAwesomeIcon icon={fas.faChevronLeft}/>
                    </p>
                  </Tooltip>
                }
              </div>
              {
                showUserTool &&
                <div className="user-toolBar-popup">
                  {/* <p className="toolBar-wrapper"
                     onClick={() => this.gotoReviewPage()}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faBookReader}/>
                    <span className="toolBar-label">至法律卷證頁</span>
                  </p> */}
                  {/* <p className="toolBar-wrapper"
                     onClick={() => this.props.history.push(`/cases`)}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faStream}/>
                    <span className="toolBar-label">返回委任管理列表</span>
                  </p>
                  <p className="toolBar-wrapper"
                     onClick={() => this.props.onClickLogout()}>
                    <FontAwesomeIcon className="toolBar-icon" icon={fas.faSignOutAlt}/>
                    <span className="toolBar-label">登出</span>
                  </p> */}
                </div>
              }
            </div>
          </div>
          <div className="generator-tool-container">
            <p className="generator-tool"
               onClick={() => this.setState({ showAttachmentPopup: true, currentPopupAction: "createFolder" })}>
              <FontAwesomeIcon className="generator-tool-icon" icon={fas.faFolderPlus}/>
              <span className="generator-tool-label">新增資料夾</span>
            </p>
            <p className="generator-tool"
               onClick={() => this.setState({ showAttachmentPopup: true, currentPopupAction: "uploadFile", selectedFileList: [] })}>
              <FontAwesomeIcon className="generator-tool-icon" icon={fas.faCloudUploadAlt}/>
              <span className="generator-tool-label">上傳委任檔案</span>
            </p>
            {
              !showSearchForm &&
              <p className="generator-tool"
                 onClick={() => {
                   this.props.onCleanSearchResult();
                   this.setState({ showSearchForm: true, searchCriteria: "" });
                 }}>
                <FontAwesomeIcon className="generator-tool-icon" icon={fas.faSearch}/>
                <span className="generator-tool-label">搜尋檔案關鍵字</span>
              </p>
            }
          </div>
          <hr className="sideBar-line"/>
          {
            showSearchForm &&
            <form className="view_tool search-wrapper"
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.onSubmitSearch();
                  }}>
              {
                searchCriteria !== "" ?
                  <Tooltip title="清除關鍵字" placement="right">
                    <i className="search-icon search-clean"
                       onClick={() => this.setState({ searchCriteria: "" })}
                    ><FontAwesomeIcon className="view-tool-icon" icon={far.faTimesCircle}/></i>
                  </Tooltip>
                  :
                  <i className="search-icon"><FontAwesomeIcon className="view-tool-icon" icon={fas.faSearch}/></i>
              }
              <Input
                className="search-input"
                placeholder="請輸入關鍵字"
                value={searchCriteria}
                required={true}
                onChange={(event) => this.setState({ searchCriteria: event.target.value })}/>
              <Tooltip title="搜尋" placement="right">
                <button className="search-enter" type="submit">
                  <FontAwesomeIcon className="view-tool-icon" icon={fas.faSearch}/>
                </button>
              </Tooltip>
            </form>
          }
          <div className={`file-container ${showSearchForm && "isSearchStage"}`}>
            <p
              className="file-title">{!showSearchForm ? `檔案` : `搜尋結果`}{(showSearchForm && this.props.hitList) && `：共${this.props.hitList.size}筆檔案`}</p>
            {
              (this.props.attachmentDict && this.props.folderDict && !showSearchForm) &&
              Object.keys(this.props.attachmentDict.toJS()).map((folderId) => (
                  this.props.attachmentDict.getIn([folderId]).size > 0
                  &&
                  <div className="folder-wrapper isFolder" key={folderId}>
                    <div className={`folder-name-wrapper ${(currentPage && currentPage.get("folderId") === folderId) ? "selected" : ""}`}
                         onClick={() => this.toggleCategory(`folder-${folderId}`)}>
                      <p className="folder-icon">
                        {
                          expandCategorySet.has(`folder-${folderId}`)
                            ?
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faCaretDown}/>
                            :
                            <FontAwesomeIcon className="haveItemIcon" icon={fas.faCaretRight}/>
                        }
                      </p>
                      <p className="folder-name">
                        <FontAwesomeIcon className="mr-1" icon={far.faFolder}/>{this.props.folderDict.get(folderId)}
                      </p>
                      <p className="folder-tools">
                      </p>
                    </div>
                    <div className="folder-children">

                      {
                        expandCategorySet.has(`folder-${folderId}`)
                        &&
                        this.props.attachmentDict.getIn([folderId]).map((attach, attachIdx) =>
                          <div
                            className={`system_item${(currentPage && currentPage.get("id") === attach.getIn(["id"])) ? " selected" : ""}${(attach.get("statusId") === 3) ? " failed" : ""}${(attach.get("statusId") === 1 || attach.get("statusId") === 2) ? " pending" : ""}`}
                            key={`file-${attachIdx}`}
                            onClick={() => attach.get("statusId") === 4 && this.onChangeAttachmentPdf(attach.set("folderId", folderId))}>
                          <span className="item_title">
                            {
                              attach.get("statusId") === 3
                              &&
                              <Tooltip title="檔案轉檔失敗" placement="right">
                                <FontAwesomeIcon className="item_status" icon={fas.faExclamationTriangle}/>
                              </Tooltip>
                            }
                            {
                              (attach.get("statusId") === 1 || attach.get("statusId") === 2)
                              &&
                              <Tooltip title="檔案轉檔中" placement="right">
                                <FontAwesomeIcon className="item_status" icon={fas.faSpinner}/>
                              </Tooltip>
                            }
                            {attach.getIn(['name'])}
                          </span>
                            <div className="edit_tool">
                              <p className="edit_tool-wrapper" onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                  selectedFileTool: selectedFileTool === attach.getIn(["id"]) ? null : attach.getIn(["id"]),
                                  selectedFileToolPosition: { top: event.clientY, left: event.clientX }
                                });
                              }}>
                                <Tooltip title="更多功能" placement="right">
                                  <FontAwesomeIcon icon={fas.faEllipsisH}/>
                                </Tooltip>
                              </p>
                            </div>
                            {
                              selectedFileTool === attach.getIn(["id"])
                              &&
                              <div className="file-tool-popup" onClick={(event) => event.stopPropagation()}
                                   style={{
                                     position: "fixed",
                                     top: selectedFileToolPosition.top,
                                     left: selectedFileToolPosition.left,
                                   }}>
                                <p className="file-tool-wrapper"
                                   onClick={(event) => {
                                     event.stopPropagation();
                                     this.setState({
                                       currentPopupAction: "downloadFile",
                                       showAttachmentPopup: true,
                                       selectedFile: attach,
                                     });
                                   }}>
                                  <FontAwesomeIcon className="file-tool-icon" icon={far.faArrowAltCircleDown}/>
                                  <span className="file-tool-label">下載委任檔案</span>
                                </p>
                                <p className="file-tool-wrapper"
                                   onClick={(event) => {
                                     event.stopPropagation();
                                     this.setState({
                                       currentPopupAction: "moveFile",
                                       showAttachmentPopup: true,
                                       selectedFile: attach.set("folderId", folderId),
                                     });
                                   }}>
                                  <FontAwesomeIcon className="file-tool-icon" icon={fas.faExchangeAlt}/>
                                  <span className="file-tool-label">移動至其他資料夾</span>
                                </p>
                                <p className="file-tool-wrapper"
                                   onClick={(event) => {
                                     event.stopPropagation();
                                     this.setState({
                                       currentPopupAction: "deleteFile",
                                       showAttachmentPopup: true,
                                       selectedFile: attach,
                                     });
                                   }}>
                                  <FontAwesomeIcon className="file-tool-icon" icon={far.faTrashAlt}/>
                                  <span className="file-tool-label">刪除委任檔案</span>
                                </p>
                              </div>
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                )
              )
            }
            {
              (showSearchForm && this.props.hitList)
              &&
              this.props.hitList.map((hitItem, hitIndex) =>
                <div className={`system_item ${(currentPage && currentPage.get("id") === hitItem.getIn(["id"])) ? "selected" : ""}`}
                     key={hitIndex}
                     onClick={() => this.onChangeAttachmentPdf(hitItem)}>
                  <p className="item_title-wrapper">
                    <span className="item_title">{hitItem.get("name")}</span>
                    <span className="item_subtitle">{this.props.folderDict.get(hitItem.get("folderId"))}</span>
                  </p>
                  <div className="edit_tool">
                    <p className="edit_tool-wrapper" onClick={(event) => {
                      event.stopPropagation();
                      this.setState({
                        selectedFileTool: selectedFileTool === hitItem.get("id") ? null : hitItem.get("id"),
                        selectedFileToolPosition: { top: event.clientY, left: event.clientX }
                      });
                    }}>
                      <Tooltip title="更多功能" placement="right">
                        <FontAwesomeIcon icon={fas.faEllipsisH}/>
                      </Tooltip>
                    </p>
                  </div>
                  {
                    selectedFileTool === hitItem.get("id")
                    &&
                    <div className="file-tool-popup" onClick={(event) => event.stopPropagation()}
                         style={{
                           position: "fixed",
                           top: selectedFileToolPosition.top,
                           left: selectedFileToolPosition.left,
                         }}>
                      <p className="file-tool-wrapper"
                         onClick={(event) => {
                           event.stopPropagation();
                           this.setState({
                             currentPopupAction: "downloadFile",
                             showAttachmentPopup: true,
                             selectedFile: hitItem,
                           });
                         }}>
                        <FontAwesomeIcon className="file-tool-icon" icon={far.faArrowAltCircleDown}/>
                        <span className="file-tool-label">下載委任檔案</span>
                      </p>
                      <p className="file-tool-wrapper"
                         onClick={(event) => {
                           event.stopPropagation();
                           this.setState({
                             currentPopupAction: "moveFile",
                             showAttachmentPopup: true,
                             selectedFile: hitItem,
                           });
                         }}>
                        <FontAwesomeIcon className="file-tool-icon" icon={fas.faExchangeAlt}/>
                        <span className="file-tool-label">移動至其他資料夾</span>
                      </p>
                      <p className="file-tool-wrapper"
                         onClick={(event) => {
                           event.stopPropagation();
                           this.setState({
                             currentPopupAction: "deleteFile",
                             showAttachmentPopup: true,
                             selectedFile: hitItem,
                           });
                         }}>
                        <FontAwesomeIcon className="file-tool-icon" icon={far.faTrashAlt}/>
                        <span className="file-tool-label">刪除委任檔案</span>
                      </p>
                    </div>
                  }
                </div>
              )
            }
          </div>
          {
            showSearchForm &&
            <button className="search-close" type="button"
                    onClick={() => {
                      this.props.onCleanSearchResult();
                      this.setState({ showSearchForm: false, searchCriteria: "" });
                    }}>
              <FontAwesomeIcon className="search-close-icon" icon={fas.faTimes}/>關閉搜尋欄
            </button>
          }
          {
            !showLeftBar &&
            <Tooltip title="開啟側邊欄" placement="right">
              <div className="active-bar" onClick={() => this.setState({ showLeftBar: !showLeftBar })}>
                <FontAwesomeIcon className="logo" icon={fas.faBars}/>
              </div>
            </Tooltip>
          }
        </div>
        <PdfViewer
          caseId={this.props.caseId}
          switchView={switchView}
          currentPage={currentPage}
          onLoadAttachmentUrl={this.props.onLoadAttachmentUrl}
          onLoadAttachmentText={this.props.onLoadAttachmentText}
        />
        <Modal className={`attachment-popup ${this.getAttachmentPopupStyle("className")}`}
               title={this.getAttachmentPopupStyle("title")}
               visible={showAttachmentPopup && currentPopupAction !== null}
               okText={this.getAttachmentPopupStyle("okText")}
               okType={this.getAttachmentPopupStyle("okType")}
               cancelText={this.getAttachmentPopupStyle("cancelText")}
               onOk={() => this.onSubmitAttachmentPopup()}
               onCancel={() => this.onCancelAttachmentPopup()}>
          <div className="attachment-popup-content">
            {this.getAttachmentPopupContent()}
            {
              popupErrorMessage
              &&
              <p className="err-msg">{popupErrorMessage}</p>
            }
            {
              this.props.errMsg
              &&
              <p className="err-msg">{this.props.errMsg}</p>
            }
          </div>
        </Modal>
        {
          this.props.isLoading
          &&
          <FullSpinner/>
        }
      </div>
    )
  }
}

export default withRouter(AttachmentComponent);
