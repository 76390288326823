import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AppointmentFeeComponent from './component';


const mapStateToProps = (state) => ({
  isPutting: selectors.getIsPutting(state),
  errMsg: selectors.getErrMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateFee: (appointmentId, isTaxabled, taxAmount, appointFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason) => {
    dispatch(actions.doUpdateFee(appointmentId, isTaxabled, taxAmount, appointFee, payDate, taxYear, taxVoucher, receiptName, receiptCategory, receiptNote, receiptDate, receiptCost, receiptReason));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentFeeComponent);