import { fromJS  } from 'immutable';

export const State = fromJS({
  isPosting: false,
  isGetting: false,
  errMsg: '',
  redirectTo: '',
  twoFactorAuthenticationId: 1,
  loginCheckNotification: false,
});
