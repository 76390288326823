import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import CasesContractComponent from './component';

const mapStateToProps = (state) => ({
  // common
  isPosting: selectors.getIsPosting(state),
  isGeting: selectors.getIsGeting(state),
  errMsg: selectors.getErrMsg(state),  
  appointorType: selectors.getAppointorType(state),
  typeId: selectors.getTypeId(state),
  appointor: selectors.getAppointor(state),
  company: selectors.getCompany(state),
  lawer: selectors.getLawer(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadCasesContract: (caseId) => {
    dispatch(actions.doLoadCasesContract(caseId));
  },
  onUpdateReceipt: (caseId, category, cost, date, name, note, reason) => {
    dispatch(actions.doUpdateReceipt(caseId, category, cost, date, name, note, reason));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasesContractComponent);