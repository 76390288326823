import { fromJS  } from 'immutable';

export const State = fromJS({
  isGetting: false,
  isPosting: false,
  isDeleting: false,
  isGettingConsultationList: false,
  isGettingConsultationDetail: false,
  currentConsultationId: null,
  currentDetailTab: 'case',
  errMsg: '',
  consultationIdList: [],
  consultations: {},
  consultationDetail: {
    appoint: {},
    case: {},
    fee: {},
    files: {},
  }
});