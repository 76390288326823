import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Select, Upload, message, Modal, Input, Tooltip, Spin } from 'antd';
import { Button } from 'react-bootstrap';
const qs = require('qs')

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { Option } = Select;


class AppointmentUserFilesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserFileId: null,
      currentUserFileName: '',
      showFileUploader: false,
      selectedUserFolderId: '',
      uploadFileList: [],
      expandUserFolderySet: new Set(),
      showUpdateFileName: false,
      updateFileName: '',
      updateFileId: '',
    };
  }
  componentDidMount() {
    const queryString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (queryString.state) {
      this.props.onBackupUserFile(queryString.state, queryString.code);
      this.props.history.replace('cases');
    }
    if( localStorage.getItem('expandUserFoldery') !== null ) {
      const expandUserFoldery = localStorage.getItem('expandUserFoldery');
      let expandUserFolderySet = new Set();
      expandUserFoldery.split(',').forEach(f => f ? expandUserFolderySet.add(parseInt(f)) : null);
      this.setState({ expandUserFolderySet })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDownloading === true && this.props.isDownloading === false) {
      if (this.props.errMsg === null) {
        message.success("下載成功");
      }
    }
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        if (this.props.isExceededQuota === true) {
          message.warning('已超出限額');
        } else {  // 成功上傳。
          message.success("檔案上傳成功");
          this.props.onChangeDetail();
          this.onToggleFileUploader(false, null, '');
        }
      } else {
        message.error(`檔案上傳失敗 [${this.props.errMsg}]`);
      }
    }
    if ((prevProps.isPutting === true && this.props.isPutting === false)
      || (prevProps.isDeleting === true && this.props.isDeleting === false)) {
      if (this.props.errMsg === null) {
        message.success('更新成功');
        this.props.onChangeDetail();
        this.onToggleFileUploader(false, null, '');
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.isBackuping === false && this.props.isBackuping === true) {
      message.info('開始雲端備份');
    }
    if (prevProps.isBackuping === true && this.props.isBackuping === false) {
      if (this.props.errMsg === null) {
        message.success("雲端備份完成");
      }
    }
    if (prevState.appointmentId !== this.props.appointmentId) {
      localStorage.removeItem("expandUserFoldery");
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onCacheExpandUserFoldery() {
    let expandUserFoldery = Array.from(this.state.expandUserFolderySet);
    localStorage.removeItem("expandUserFoldery");
    localStorage.setItem("expandUserFoldery", expandUserFoldery);
  }
  onToggleFileList(userFolderId) {
    const { expandUserFolderySet } = this.state;
    if (expandUserFolderySet.has(userFolderId)) {
      expandUserFolderySet.delete(userFolderId);
    } else {
      expandUserFolderySet.add(userFolderId);
    }
    this.setState({ expandUserFolderySet })
  }
  onToggleFileUploader(showFileUploader, currentUserFileId, currentUserFileName) {
    this.setState({ showFileUploader, currentUserFileId, currentUserFileName, selectedUserFolderId: '' })
  }
  onClickDeleteUserFile(userFileId, userFileName) {
    Modal.confirm({
      title: '刪除檔案',
      content: `按下"確認"按鈕將刪除 ${userFileName}`,
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteUserFile(userFileId);
      },
    });
  }
  onSubmitUploadOrMoveFile() {
    const { selectedUserFolderId, uploadFileList, currentUserFileId } = this.state;
    if (currentUserFileId === null) {
      if (selectedUserFolderId === '') {
        return message.error('請選擇資料夾');
      } else if (uploadFileList.length === 0) {
        return message.warning('請新增檔案')
      } else {
        this.props.onUploadUserFile(this.props.appointmentId, selectedUserFolderId, uploadFileList);
      }
    } else {
      this.props.onMoveUserFile(selectedUserFolderId, currentUserFileId)
    }
  }
  onClickSubmitUpdateFileName(fileId) {
    const { updateFileName } = this.state;
    if(updateFileName){
      let name = updateFileName ? updateFileName.trim() : '';
      this.props.onSubmitUpdateFileName(fileId, name);
      this.setState({ updateFileName: '', updateFileId: '' });
    }else{
      return message.error('請勿空白欄位')
    }
  }
  onClickBackupUserFile(appointmentId) {
    this.props.onDirectToOauthPage(appointmentId)
  }

  render() {
    const { showFileUploader, currentUserFileId, currentUserFileName, selectedUserFolderId, uploadFileList, expandUserFolderySet, showUpdateFileName, updateFileName } = this.state;
    if (!this.props.userFolderList || this.props.userFolderList.size === 0) {
      return null;
    }
    const UploadProps = {
      accept: ".doc,.docx,.jpg,.jpeg,.png,.pdf,.heic,.heif,.hevc",
      multiple: true,
      defaultFileList: uploadFileList,
      onRemove: file => {
        this.setState(state => {
          const index = state.uploadFileList.indexOf(file);
          const newFileList = state.uploadFileList.slice();
          newFileList.splice(index, 1);
          return {
            uploadFileList: newFileList,
          };
        });
      },
      beforeUpload: (file, fileList) => {
        this.setState(state => ({
          uploadFileList: [...state.uploadFileList, file]
        }));
        return false;
      }
    };
    return (
      <div className="appointmentfiles-section section-col-item">
        <div className="case-file-extend">
          <div className="case-file-extend-head">
            <button className="btn-empty"
              onClick={e => this.onToggleFileUploader(true, null, '')}
            >
              <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
              上傳檔案
            </button>
            <button className="btn-empty"
              onClick={e => this.props.history.push(`attachment/${this.props.appointmentId}`)}>
              <i><FontAwesomeIcon icon={fas.faSignInAlt} /></i>
              管理頁面
            </button>
            {
              this.props.userFolderFiles.size > 0
              &&
              <>
                {
                  this.props.isDownloading
                  ?
                    <button className="btn-empty spin"><Spin /> 下載中</button>
                  :
                    <button className="btn-empty"
                      onClick={e => this.props.onDownloadUserAllFile(this.props.appointmentId)}>
                      <i><FontAwesomeIcon icon={fas.faDownload} /></i>
                      下載全部檔案
                    </button>
                }
              </>
            }
            {
              this.props.userFolderFiles.size > 0
              &&
              <button className="btn-empty"
                onClick={e => this.onClickBackupUserFile(this.props.appointmentId)}>
                <i><FontAwesomeIcon icon={fas.faCopy} /></i>
                雲端備份
              </button>
            }
          </div>
          <div className="case-file-list">
            {
              this.props.userFolderList.filter(userFolder => this.props.userFolderFiles.hasIn([`${userFolder.getIn(['id'])}`])).map(userFolder =>
                <div className="case-file-group" key={`userfolder-${userFolder.getIn(['id'])}`}>
                  <div className="case-file-group-title" onClick={e => this.onToggleFileList(userFolder.getIn(['id']))}>
                    <i><FontAwesomeIcon icon={far.faFolder} /></i>
                    { userFolder.getIn(['name']) }
                  </div>
                  {
                    expandUserFolderySet.has(userFolder.getIn(['id'])) && this.props.userFolderFiles.getIn([`${userFolder.getIn(['id'])}`]).map(userFile =>
                      <div className="case-file" key={`userfile-${userFile.getIn(['id'])}`}>
                        <div className="case-file-title"
                             onClick={e => {
                               this.props.history.push(`attachment/${this.props.appointmentId}`);
                               localStorage.setItem("attachmentSelectedFileId", userFile.getIn(['id']));
                               localStorage.setItem("attachmentSelectedFileFolderId", userFolder.getIn(['id']));
                             }}>
                          { userFile.getIn(['name']) }</div>
                        <div className="case-file-tool">
                          <Tooltip title="檔案更名">
                            <button className="btn-empty"
                              onClick={e => {this.setState({showUpdateFileName: !showUpdateFileName, updateFileName: userFile.getIn(['name']), updateFileId: userFile.getIn(['id'])}); this.onCacheExpandUserFoldery()}}
                            ><FontAwesomeIcon icon={fas.faPencilAlt} /></button>
                          </Tooltip>
                          <Tooltip title="刪除檔案">
                            <button className="btn-empty"
                              onClick={e => this.onClickDeleteUserFile(userFile.getIn(['id']), userFile.getIn(['name']))}
                            ><FontAwesomeIcon icon={far.faTrashAlt} /></button>
                          </Tooltip>
                          <Tooltip title="下載檔案">
                            <button className="btn-empty"
                              onClick={e => this.props.onDownloadUserFile(userFile.getIn(['id']), userFile.getIn(['name']))}
                            ><FontAwesomeIcon icon={fas.faFileDownload} /></button>
                          </Tooltip>
                          <Tooltip title="移動至其他資料夾">
                            <button className="btn-empty"
                              onClick={e => this.onToggleFileUploader(true, userFile.getIn(['id']), userFile.getIn(['name']))}
                            ><FontAwesomeIcon icon={fas.faFileExport} /></button>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>

        <Modal className="case-file-upload"
          title={currentUserFileId ? '移動至其他資料夾' : '檔案上傳'}
          visible={showFileUploader}
          onOk={() => this.onSubmitUploadOrMoveFile()}
          onCancel={() => this.onToggleFileUploader(false, null, '')}
        >
          <div className="case-file-upload-item">
            <div className="case-file-upload-title">選擇資料夾</div>
            <Select className="case-file-upload-select"
              value={selectedUserFolderId}
              onChange={value => this.onChangeState('selectedUserFolderId', value)}
            >
              {
                this.props.userFolderList.map(userFolder =>
                  <Option key={`cases-folder-${userFolder.getIn(['id'])}`} value={userFolder.getIn(['id'])}>{ userFolder.getIn(['name']) }</Option>
                )
              }
            </Select>
          </div>
          {
            currentUserFileId
            ?
              <div className="case-file-upload-item">
                <div className="case-file-upload-title">選擇移動檔案</div>
                <div className="case-file-upload-select">{ currentUserFileName }</div>
              </div>
            :
              <div className="case-file-upload-item">
                <div className="case-file-upload-title">檔案</div>
                <Upload className="case-file-upload-select" {...UploadProps}>
                  <Button>
                    <i><FontAwesomeIcon icon={fas.faFileUpload} /></i>
                    選擇上傳檔案
                  </Button>
                </Upload>
              </div>
          }
        </Modal>
        <Modal className="update-fileName"
          title="更改檔名"
          visible={this.state.showUpdateFileName}
          okText="確認"
          cancelText="取消"
          onOk={() => {this.onClickSubmitUpdateFileName(this.state.updateFileId); this.setState({ showUpdateFileName: false })}}
          onCancel={() => this.setState({ showUpdateFileName: false })}
        >
          <div className="docket-col">
            <Input className="input"
              placeholder="更新檔案名"
              allowClear
              type="text"
              value={updateFileName}
              onChange={e => this.setState({ updateFileName: e.target.value })}
            />
          </div>
        </Modal>

      </div>
    )
  }
}
export default withRouter(AppointmentUserFilesComponent);
