// common
export const POST_REQUEST = 'powerOfAttorney/POST_REQUEST'
export const POST_SUCCESS = 'powerOfAttorney/POST_SUCCESS'
export const POST_FAILURE = 'powerOfAttorney/POST_FAILURE'
export const GET_REQUEST = 'powerOfAttorney/GET_REQUEST'
export const GET_SUCCESS = 'powerOfAttorney/GET_SUCCESS'
export const GET_FAILURE = 'powerOfAttorney/GET_FAILURE'
export const PUT_REQUEST = 'powerOfAttorney/PUT_REQUEST'
export const PUT_SUCCESS = 'powerOfAttorney/PUT_SUCCESS'
export const PUT_FAILURE = 'powerOfAttorney/PUT_FAILURE'

export const UPDATE_POWER_OF_ATTORNEY_INFO = 'powerOfAttorney/UPDATE_POWER_OF_ATTORNEY_INFO'
export const UPDATE_POWER_OF_ATTORNEY_APPOINTOR = 'powerOfAttorney/UPDATE_POWER_OF_ATTORNEY_APPOINTOR'
export const UPDATE_POWER_OF_ATTORNEY_COMPANY = 'powerOfAttorney/UPDATE_POWER_OF_ATTORNEY_COMPANY'