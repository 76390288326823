import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ConsultationsComponent from './component';

const mapStateToProps = (state) => ({
  isDeleting: selectors.getIsDeleting(state),
  isPosting: selectors.getIsPosting(state),
  isGettingConsultationList: selectors.getIsGettingConsultationList(state),
  isGettingConsultationDetail: selectors.getIsGettingConsultationDetail(state),
  currentConsultationId: selectors.getCurrentConsultationId(state),
  currentDetailTab: selectors.getCurrentDetailTab(state),
  errMsg: selectors.getErrMsg(state),
  consultationIdList: selectors.getConsultationIdList(state),
  consultations: selectors.getConsultations(state),
  consultationDetail: selectors.getConsultationDetail(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateCurrentDetailTab: (currentDetailTab) => {
    dispatch(actions.doUpdateCurrentDetailTab(currentDetailTab));
  },
  onLoadConsultationList: (name, startDay, endDay) => {
    dispatch(actions.doLoadConsultationList(name, startDay, endDay));
  },
  onLoadConsultationDetail: (consultationId) => {
    dispatch(actions.doLoadConsultationDetail(consultationId));
  },
  onDeleteConsultation: (consultationId) => {
    dispatch(actions.doDeleteConsultation(consultationId));
  },
  onAppointConsultation: (consultationId, appointName, appointNoId) => {
    dispatch(actions.doAppointConsultation(consultationId, appointName, appointNoId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationsComponent);