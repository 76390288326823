import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { message, Modal, Tooltip, Input, Tabs, Select, Typography } from 'antd';
import { Form } from 'react-bootstrap';
import { CASE_STATEID, CASE_TYPE, CASE_INSTANCE_LEVEL } from '../../config'
import Spinner from '../../utils/Spinner';
import FullSpinner from '../../utils/FullSpinner';
import appointmentAppoint from '../appointmentAppoint';
import appointmentCustodian from '../appointmentCustodian';
import appointmentCase from '../appointmentCase';
import appointmentFee from '../appointmentFee';
import appointmentDates from '../appointmentDates';
import appointmentUserFiles from '../appointmentUserFiles';
import appointmentFiles from '../appointmentFiles';
import appointmentNotes from '../appointmentNotes';
const qs = require('qs')

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const DEFAULT_DOCKET = {
  level: 0,
  courts: '',
  trial: '',
  year: '',
  word: '',
  no: '',
  unit: '',
  ext: ''
}

class AppointmentListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRWDsearch: false,
      searchName: '',
      searchText: '',
      isEditing: false,
      showDocketModal: false,
      docket: DEFAULT_DOCKET,
      showDelDocketModal: false,
      showRWDDetailModal: false,
      detailItems: [
        {'name': '委任人',},
        {'name': '當事人',},
        {'name': '案件相關',},
        {'name': '筆記',},
        {'name': '費用',},
        {'name': '庭期',},
        {'name': '委任檔案',},
        {'name': '法律卷證',},
      ],
      RWDcurrentDetail: '',
    };
    this.onChangeIsEditing = this.onChangeIsEditing.bind(this);
    this.onChangeDetail = this.onChangeDetail.bind(this);
  }
  componentDidMount() {
    const queryString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.props.onLoadAppointmentList(null, null, `${queryString.state}`);
    if (queryString.state) {
      this.onClickTab('userFiles')
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDeleting === true && this.props.isDeleting === false) {
      if (this.props.errMsg === null) {
        message.success('刪除成功');
        this.props.onLoadAppointmentList(this.state.searchName, this.state.searchText);
      } else {
        message.error(this.props.errMsg);
      }
    }
    if (prevProps.currentAppointmentId === null && prevProps.currentAppointmentId !== this.props.currentAppointmentId) {
      this.onChangeAppointment(String(this.props.currentAppointmentId))
    }
    if (this.props.cachedAppointmentId !== null) {
      this.onChangeAppointment(`${this.props.cachedAppointmentId}`);
      this.onClickTab('dates')
      this.props.onUpdateCachedAppointmentId(null);
    }
  }
  onChangeState(key, value) {
    this.setState({ [`${key}`]: value })
  }
  onChangeAppointment(nextAppointmentId) {
    const { isEditing } = this.state;
    if (isEditing === true) {
      this.onConfirmChangeTab(nextAppointmentId, this.props.currentDetailTab)
    } else {
      this.props.onLoadAppointmentDetail(nextAppointmentId);
    }
  }
  onClickDeleteAppointment(appointmentId) {
    Modal.confirm({
      title: '刪除案件',
      content: '按下"確認"按鈕將刪除此案件。',
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteAppointment(appointmentId);
      },
    });
  };
  onSubmitSearch() {
    let { searchName, searchText } = this.state;
    searchName = searchName.trim();
    searchText = searchText.trim();
    if(searchName === '' && searchText === '') {
      return message.error('請輸入至少一個欄位');
    }
    this.props.onLoadAppointmentList(searchName, searchText);
  }
  onCleanSearch() {
    this.setState({ searchName: '', searchText: '', showRWDsearch: false }, () => this.props.onLoadAppointmentList())
  }
  onChangeIsEditing(isEditing) {
    this.setState({ isEditing })
  }
  onClickTab(currentDetailTab) {
    const { isEditing } = this.state;
    if (isEditing === true) {
      this.onConfirmChangeTab(this.props.currentAppointmentId, currentDetailTab)
    } else {
      this.props.onUpdateCurrentDetailTab(currentDetailTab)
    }
  }
  onConfirmChangeTab(nextAppointmentId, currentDetailTab) {
    Modal.warning({
      title: '編輯提醒',
      content: '尚有編輯中的內容，請先儲存更新後再切換分頁。',
      okText: '了解',
      onOk: () => {
        // if (nextAppointmentId === this.props.currentAppointmentId) {  // 同一筆委任切換 tab
        //   this.setState({ isEditing: false, currentDetailTab });
        //   this.props.onUpdateCurrentDetailTab(currentDetailTab);
        // } else {  // 切換委任
        //   this.setState({ isEditing: false });
        //   this.props.onUpdateCurrentDetailTab(currentDetailTab);
        //   this.props.onLoadAppointmentDetail(nextAppointmentId);
        // };
      }
    });
  }
  onChangeDetail() {
    const { searchName, searchText } = this.state;
    this.props.onLoadAppointmentList(searchName, searchText);
    this.props.onLoadAppointmentDetail(this.props.currentAppointmentId);
  }
  onToggleDocketModal(show, action) {
    if( action === 'creat' ) {
      this.setState({ showDocketModal: show, docket: DEFAULT_DOCKET })
    };
    if( action === 'edit' ) {
      this.setState({ showDocketModal: show, docket: DEFAULT_DOCKET })
    }
    if( action === 'close') {
      this.setState({ showDocketModal: false })
    }
  };
  onChangeDocket(key, value) {
    this.setState({ docket: { ...this.state.docket, [`${key}`]: value }})
  };
  onUpdateDocket(docketId, level, courts, trial, year, word, no, unit, ext) {
    this.setState({ docket: { ...this.state.docket, docketId: docketId, level, courts, trial, year, word, no, unit, ext }})
  };
  onSubmitDocket(caseId) {
    const  { docket } = this.state;
    if(docket.docketId) {
      this.props.onUpdateDocket(caseId, docket);
    };
    if(!docket.docketId) {
      this.props.onCreatDocket(caseId, docket);
    }
  };
  onClickDelDocketModal(caseId, docketId) {
    Modal.confirm({
      title: '刪除檔案',
      content: `按下"確認"按鈕將刪除`,
      okText: '確認刪除',
      cancelText: '取消刪除',
      onOk: () => {
        this.props.onDeleteDocket(caseId, docketId); 
      },
    });
  }
  onToggleRWDDetailMode(detailTab) {
    this.setState({ showRWDDetailModal: true, RWDcurrentDetail: detailTab })
  }

  render() {
    const { 
      searchText,
      showDocketModal, docket,
      showDelDocketModal,
      showRWDDetailModal, detailItems, RWDcurrentDetail
    } = this.state;
    return (
      <>
        {
          this.props.isGettingAppointmentList
          &&
          <FullSpinner />
        }
        <div className="tool-bar">
          <Form className="search"
            onSubmit={e => {e.preventDefault(); this.onSubmitSearch()}}
          >
            <div className="search-col">
              <label htmlFor="searchText">全文檢索：</label>
              <Input id="searchText"
                placeholder="請輸入案件種類 / 案件相關"
                value={searchText}
                onChange={e => this.onChangeState('searchText', e.target.value)}
              />
            </div>
            <button data-txt="搜尋案件" className="btn-search btn-empty" variant="primary" type="submit">
              <i><FontAwesomeIcon icon={fas.faSearch} /></i>搜尋案件
            </button>
            <Tooltip title="重新搜尋">
              <button className="btn-empty" data-txt="重新搜尋"
                type="button"
                onClick={e => this.onCleanSearch()}
              ><FontAwesomeIcon icon={fas.faRedoAlt} /></button>
            </Tooltip>
          </Form>
          <div className="btn-tool">
            <Link to="/creat-case"><i><FontAwesomeIcon icon={fas.faPlus} /></i>新增案件</Link>
          </div> 
        </div>
        <div className="appointmentList section-row">
          <div className="section-col section-list">
            <ul className="case-list-head">
              <li className="head-appointDate">委任日期</li>
              <li className="head-appointorName">當事人</li>
              <li className="head-typeId">案件狀態</li>
              <li className="head-docket">案號</li>
            </ul>
            <div className="case-list-body-wrap">
              {
                !this.props.appointmentIdList || this.props.appointmentIdList.size === 0 
                ?
                <div className="case-item-tip">無委任案件，請先新增案件</div>
                :
                this.props.appointmentIdList.map(appointmentId =>
                  <div key={`case-${appointmentId}`} className={`case-item ${String(this.props.currentAppointmentId) === appointmentId ? 'active' : ''}`} onClick={e => this.onChangeAppointment(appointmentId)}>
                    <ul className="case-list-body">
                      <li className="body-appointDate" data-th="委任日期：">{this.props.appointments.getIn([appointmentId, 'appointDate'])}</li>
                      <li className="body-appointorName" data-th="當事人：">
                        { this.props.appointments.getIn([appointmentId, 'custodianName']) }
                      </li>
                      <li className="body-typeId" data-th="案件狀態：">
                        { this.props.appointments.getIn([appointmentId, 'caseStatus']) }
                      </li>
                      <li className="body-docket" data-th="案號：">
                        { this.props.appointments.getIn([appointmentId, 'docketName']) }
                      </li>
                    </ul>
                    {
                      this.props.currentAppointmentId === appointmentId
                      &&
                      <div className="RWD-appointmentdetail">
                        <div className="appointmentdetail-header-docket">
                          <div className="appointmentdetail-header-docket-wrap">
                            {
                              this.props.appointmentDetail.getIn(['case', 'docketList']) && this.props.appointmentDetail.getIn(['case', 'docketList']).map(docket =>
                                <div className="docketList" key={`docket-${docket.getIn(['id'])}`}>
                                  { docket.getIn(['level']) === 0 ? null : <span className="level">{ CASE_INSTANCE_LEVEL[docket.getIn(['level'])] }</span> }
                                  { docket.getIn(['courts']) && <span>{ docket.getIn(['courts']) }</span> }
                                  { docket.getIn(['trial']) && <span>{ docket.getIn(['trial']) }庭</span> }
                                  { docket.getIn(['year']) && <span>{ docket.getIn(['year']) }年度</span> }
                                  { docket.getIn(['word']) && <span>{ docket.getIn(['word']) }字</span> }
                                  { docket.getIn(['no']) && <span>{ docket.getIn(['no']) }號</span> }
                                  { docket.getIn(['unit']) && <span>{ docket.getIn(['unit']) }股</span> }
                                  { docket.getIn(['ext']) && <span className="ext">分機:{ docket.getIn(['ext']) }</span> }
                                  <Tooltip title="編輯">
                                    <button className="btn-edit" 
                                      onClick={e => { this.onToggleDocketModal(true, 'edit'); 
                                                      this.onUpdateDocket(
                                                        docket.getIn(['id']),
                                                        docket.getIn(['level']),
                                                        docket.getIn(['courts']),
                                                        docket.getIn(['trial']),
                                                        docket.getIn(['year']),
                                                        docket.getIn(['word']),
                                                        docket.getIn(['no']),
                                                        docket.getIn(['unit']),
                                                        docket.getIn(['ext'])
                                      )}}><i><FontAwesomeIcon icon={fas.faPencilAlt} 
                                    /></i></button>
                                  </Tooltip>
                                  <Tooltip title="刪除案號"><button className="btn-del" onClick={e => {this.setState({ showDelDocketModal: !showDelDocketModal }); this.onClickDelDocketModal(this.props.currentAppointmentId, docket.getIn(['id']))}}><i><FontAwesomeIcon icon={fas.faMinus} /></i></button></Tooltip>
                                </div>
                              )
                            }
                          </div>
                          <div className="appointmentdetail-header-docket-tool">
                            <Tooltip title="增加案號"><button className="btn-creat" onClick={e => this.onToggleDocketModal(true, 'creat')}><i><FontAwesomeIcon icon={fas.faPlus} /></i></button></Tooltip>
                          </div>
                        </div>
                        <div className="RWD-detail-tool">
                          <Link target="_blank" to={`/power-of-attorney/${this.props.currentAppointmentId}`}>委任狀</Link>
                          <Link target="_blank" to={`/case-receipt/${this.props.currentAppointmentId}`}>委任收據</Link>
                          <Link target="_blank" to={`/case-contract/${this.props.currentAppointmentId}`}>委任契約書</Link>
                          <button className="btn-warn" onClick={e => this.onClickDeleteAppointment(this.props.currentAppointmentId)}>
                            <i><FontAwesomeIcon icon={fas.faMinus} /></i>
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                )
              }
            </div>
          </div>

          <div className="section-col section-col-item appointmentdetail-section">
            {
              this.props.isGettingAppointmentDetail
              ?
                <Spinner />
              :
                <>
                  <div className="appointmentdetail-header">
                    <div className="appointmentdetail-header-title">
                      {
                        this.props.appointmentIdList.size !== 0 
                        &&
                        <>
                          <div>
                            { 
                              this.props.appointmentDetail.getIn(['case', 'typeId'])
                              &&
                              `${CASE_TYPE.find(caseType => caseType.id===this.props.appointmentDetail.getIn(['case', 'typeId'])).name} ${this.props.appointmentDetail.getIn(['case', 'appointNoId'])} ${this.props.appointmentDetail.getIn(['appoint', 'appointTypeId'])===1?this.props.appointmentDetail.getIn(['appoint', 'appointor', 'name']):this.props.appointmentDetail.getIn(['appoint', 'company', 'name'])} -${CASE_STATEID[this.props.appointmentDetail.getIn(['case', 'statusId'])]}`
                            }
                          </div>
                          <div className="detail-tool">
                            <Tooltip title="預覽列印-委任狀">
                              <Link target="_blank" to={`/power-of-attorney/${this.props.currentAppointmentId}`}><i><FontAwesomeIcon icon={fas.faPrint} /></i></Link>
                            </Tooltip>
                            <Tooltip title="預覽列印-委任收據">
                              <Link target="_blank" to={`/case-receipt/${this.props.currentAppointmentId}`}><i><FontAwesomeIcon icon={fas.faReceipt} /></i></Link>
                            </Tooltip>
                            <Tooltip title="預覽列印-委任契約書">
                              <Link target="_blank" to={`/case-contract/${this.props.currentAppointmentId}`}><i><FontAwesomeIcon icon={fas.faFileSignature} /></i></Link>
                            </Tooltip>
                            <Tooltip title="刪除案件">
                              <button className="btn-warn" onClick={e => this.onClickDeleteAppointment(this.props.currentAppointmentId)}>
                                <i><FontAwesomeIcon icon={fas.faMinus} /></i>
                              </button>
                            </Tooltip>
                          </div>
                        </>
                      }
                    </div>
                    {
                      this.props.appointmentIdList.size !== 0 
                      &&
                      <div className="appointmentdetail-header-docket">
                        <div className="appointmentdetail-header-docket-wrap">
                          {
                            this.props.appointmentDetail.getIn(['case', 'docketList']) && this.props.appointmentDetail.getIn(['case', 'docketList']).map(docket =>
                              <div className="docketList" key={`docket-${docket.getIn(['id'])}`}>
                                { docket.getIn(['level']) === 0 ? null : <span className="level">{ CASE_INSTANCE_LEVEL[docket.getIn(['level'])] }</span> }
                                { docket.getIn(['courts']) && <span>{ docket.getIn(['courts']) }</span> }
                                { docket.getIn(['trial']) && <span>{ docket.getIn(['trial']) }庭</span> }
                                { docket.getIn(['year']) && <span>{ docket.getIn(['year']) }年度</span> }
                                { docket.getIn(['word']) && <span>{ docket.getIn(['word']) }字</span> }
                                { docket.getIn(['no']) && <span>{ docket.getIn(['no']) }號</span> }
                                { docket.getIn(['unit']) && <span>{ docket.getIn(['unit']) }股</span> }
                                { docket.getIn(['ext']) && <span className="ext">分機:{ docket.getIn(['ext']) }</span> }
                                <Tooltip title="編輯">
                                  <button className="btn-edit" 
                                    onClick={e => { this.onToggleDocketModal(true, 'edit'); 
                                                    this.onUpdateDocket(
                                                      docket.getIn(['id']),
                                                      docket.getIn(['level']),
                                                      docket.getIn(['courts']),
                                                      docket.getIn(['trial']),
                                                      docket.getIn(['year']),
                                                      docket.getIn(['word']),
                                                      docket.getIn(['no']),
                                                      docket.getIn(['unit']),
                                                      docket.getIn(['ext'])
                                    )}}><i><FontAwesomeIcon icon={fas.faPencilAlt} 
                                  /></i></button>
                                </Tooltip>
                                <Tooltip title="刪除案號"><button className="btn-del" onClick={e => {this.setState({ showDelDocketModal: !showDelDocketModal }); this.onClickDelDocketModal(this.props.currentAppointmentId, docket.getIn(['id']))}}><i><FontAwesomeIcon icon={fas.faMinus} /></i></button></Tooltip>
                              </div>
                            )
                          }
                        </div>
                        <div className="appointmentdetail-header-docket-tool">
                          <Tooltip title="增加案號"><button className="btn-creat" onClick={e => this.onToggleDocketModal(true, 'creat')}><i><FontAwesomeIcon icon={fas.faPlus} /></i></button></Tooltip>
                        </div>
                      </div>
                    }
                  </div>
                  {
                    this.props.appointmentIdList.size !== 0 
                    ?
                      <Tabs className="appointmentdetail-tabpane" 
                        defaultActiveKey={this.props.currentDetailTab} 
                        activeKey={this.props.currentDetailTab} 
                        onTabClick={value => this.onClickTab(value)} 
                        destroyInactiveTabPane={true}
                      >
                        <TabPane tab="委任人" key="appointor">
                          <appointmentAppoint.container 
                            appointmentId={this.props.currentAppointmentId}
                            appoint={this.props.appointmentDetail.getIn(['appoint'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="當事人" key="custodian">
                          <appointmentCustodian.container 
                            appointmentId={this.props.currentAppointmentId}
                            appoint={this.props.appointmentDetail.getIn(['appoint'])}
                            custodian={this.props.appointmentDetail.getIn(['custodian'])}
                            caseDetail={this.props.appointmentDetail.getIn(['case'])}
                            caseTypeId={this.props.appointmentDetail.getIn(['case', 'typeId'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="案件相關" key="case">
                          <appointmentCase.container 
                            appointmentId={this.props.currentAppointmentId}
                            caseDetail={this.props.appointmentDetail.getIn(['case'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="筆記" key="notes">
                          <appointmentNotes.container 
                            appointmentId={this.props.currentAppointmentId}
                            caseDetail={this.props.appointmentDetail.getIn(['notes'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="費用" key="fee">
                          <appointmentFee.container
                            appointmentId={this.props.currentAppointmentId}
                            fee={this.props.appointmentDetail.getIn(['fee'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="庭期" key="dates">
                          <appointmentDates.container
                            appointmentId={this.props.currentAppointmentId}
                            dates={this.props.appointmentDetail.getIn(['dates'])}
                            caseDetail={this.props.appointmentDetail.getIn(['case'])}
                            onChangeIsEditing={this.onChangeIsEditing}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="委任檔案" key="userFiles">
                          <appointmentUserFiles.container
                            appointmentId={this.props.currentAppointmentId}
                            userFolderList={this.props.appointmentDetail.getIn(['userFiles', 'userFolderList'])}
                            userFolderFiles={this.props.appointmentDetail.getIn(['userFiles', 'userFolderFiles'])}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                        <TabPane tab="法律卷證" key="files">
                          <appointmentFiles.container
                            appointmentId={this.props.currentAppointmentId}
                            fileList={this.props.appointmentDetail.getIn(['files', 'fileList'])}
                            onChangeDetail={this.onChangeDetail}
                          />
                        </TabPane>
                      </Tabs>
                    :
                      <Tabs className="appointmentdetail-tabpane">
                        <TabPane tab="委任人" key="appointor"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="當事人" key="custodian"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="案件相關" key="case"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="筆記" key="notes"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="費用" key="fee"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="庭期" key="dates"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="委任檔案" key="userFiles"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                        <TabPane tab="法律卷證" key="files"><Text className="disabled" disabled>請新增案件</Text></TabPane>
                      </Tabs>
                  }
                </>
            }
          </div>
          <ul className="RWD-appointmentdetail-section">
            {
              detailItems.map(detailItem => (
                <li key={`${detailItem.name}`}><button onClick={e => this.onToggleRWDDetailMode(detailItem.name)}>{detailItem.name}</button></li>
              ))
            }
          </ul>
        </div>
        
        <Modal className="creat-docket"
          title={docket.docketId ? '編輯案號' : '新增案號'}
          visible={showDocketModal}
          okText="確認"
          cancelText="取消"
          onOk={() => {this.onSubmitDocket(this.props.currentAppointmentId);this.onToggleDocketModal(false, 'close')}}
          onCancel={() => this.onToggleDocketModal(false, 'close')}
        >
          <div className="docket-col">
            <div className="docket-col-title">審級：</div>
            <Select size="small" className="docket-col-txt" placeholder="請選擇審級"
              value={`${docket.level}`}
              onChange={value => this.onChangeDocket('level', value)}
            >
              {
                Object.entries(CASE_INSTANCE_LEVEL).map(([key, value]) => 
                  <Option key={`dockerlevel-${key}`} value={key}>{value}</Option>
                )
              }
            </Select>
          </div>
          <div className="docket-col">
            <Input className="docket-col-txt"
              size="small"
              type="text"
              placeholder="法院/地檢"
              value={docket.courts}
              onChange={e => this.onChangeDocket('courts', e.target.value)}
            />
          </div>
          <div className="docket-col">
            <Input className="docket-col-txt"
              size="small"
              type="text"
              suffix="庭"
              value={docket.trial}
              onChange={e => this.onChangeDocket('trial', e.target.value)}
            />
          </div>
          <div className="docket-col">
            <Input className="docket-col-txt"
              size="small"
              type="number"
              suffix="年度"
              value={docket.year}
              onChange={e => this.onChangeDocket('year', e.target.value)}
            />
          </div>
          <div className="docket-col">
            <Input className="docket-col-txt"
              size="small"
              type="text"
              suffix="字"
              value={docket.word}
              onChange={e => this.onChangeDocket('word', e.target.value)}
            />
          </div>
          <div className="docket-col">
            <Input className="docket-col-txt"
              size="small"
              type="number"
              suffix="號"
              value={docket.no}
              onChange={e => this.onChangeDocket('no', e.target.value)}
            />
          </div>
          <div className="docket-col">
            <Input className="docket-col-txt"
              size="small"
              type="text"
              suffix="股"
              value={docket.unit}
              onChange={e => this.onChangeDocket('unit', e.target.value)}
            />
          </div>
          <div className="docket-col">
            <div className="docket-col-title">分機：</div>
            <Input className="docket-col-txt"
              size="small"
              type="text"
              value={docket.ext}
              onChange={e => this.onChangeDocket('ext', e.target.value)}
            />
          </div>
        </Modal>
        <Modal
          className="RWDDetailModal"
          visible={showRWDDetailModal}
          onCancel={() => this.setState({ showRWDDetailModal: false })}
        >
          {
            RWDcurrentDetail === '委任人' ?
              <div>
                <appointmentAppoint.container 
                  appointmentId={this.props.currentAppointmentId}
                  appoint={this.props.appointmentDetail.getIn(['appoint'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '當事人' ?
              <div>
                <appointmentCustodian.container 
                  appointmentId={this.props.currentAppointmentId}
                  appoint={this.props.appointmentDetail.getIn(['appoint'])}
                  custodian={this.props.appointmentDetail.getIn(['custodian'])}
                  caseDetail={this.props.appointmentDetail.getIn(['case'])}
                  caseTypeId={this.props.appointmentDetail.getIn(['case', 'typeId'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '案件相關' ?
              <div>
                <appointmentCase.container 
                  appointmentId={this.props.currentAppointmentId}
                  caseDetail={this.props.appointmentDetail.getIn(['case'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '筆記' ?
              <appointmentNotes.container 
                appointmentId={this.props.currentAppointmentId}
                caseDetail={this.props.appointmentDetail.getIn(['notes'])}
                onChangeIsEditing={this.onChangeIsEditing}
                onChangeDetail={this.onChangeDetail}
              />
            :  RWDcurrentDetail === '費用' ?
              <div>
                <appointmentFee.container
                  appointmentId={this.props.currentAppointmentId}
                  fee={this.props.appointmentDetail.getIn(['fee'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '庭期' ?
              <div>
                <appointmentDates.container
                  appointmentId={this.props.currentAppointmentId}
                  dates={this.props.appointmentDetail.getIn(['dates'])}
                  caseDetail={this.props.appointmentDetail.getIn(['case'])}
                  onChangeIsEditing={this.onChangeIsEditing}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '委任檔案' ?
              <div>
                <appointmentUserFiles.container
                  appointmentId={this.props.currentAppointmentId}
                  userFolderList={this.props.appointmentDetail.getIn(['userFiles', 'userFolderList'])}
                  userFolderFiles={this.props.appointmentDetail.getIn(['userFiles', 'userFolderFiles'])}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            : RWDcurrentDetail === '法律卷證' ?
              <div>
                <appointmentFiles.container
                  appointmentId={this.props.currentAppointmentId}
                  fileList={this.props.appointmentDetail.getIn(['files', 'fileList'])}
                  onChangeDetail={this.onChangeDetail}
                />
              </div>
            :
              null
          }
        </Modal>
      </>
    )
  }
}

export default withRouter(AppointmentListComponent);