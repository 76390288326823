import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import CreatCaseComponent from './component';

const mapStateToProps = (state) => ({
  // common
  isPosting: selectors.getIsPosting(state),
  isChecking: selectors.getIsChecking(state),
  errMsg: selectors.getErrMsg(state),  
  caseRelateList: selectors.getCaseRelateList(state),
  isFetching: selectors.getIsFetching(state),
  custodianConflict: selectors.getCustodianConflict(state),
});

const mapDispatchToProps = dispatch => ({
  resetErrMsg: () => {
    dispatch(actions.updateErrMsg());
  },
  onLoadRelatedList: (contain, size) => {
    dispatch(actions.doLoadRelatedList(contain, size));
  },
  onSubmitCreateCase: (docketList, appointDate, appointorType, caseDetail, appointor, company, custodianAppointor, custodianCompany, fee, caseDate) => {
    dispatch(actions.doSubmitCreateCase(docketList, appointDate, appointorType, caseDetail, appointor, company, custodianAppointor, custodianCompany, fee, caseDate));
  },
  onUpdateCustodianConflict: (conflict) => {
    dispatch(actions.updateCustodianConflict(conflict));
  },
  onCheckConflictOfInterest: (custodianName) => {
    dispatch(actions.doCheckConflictOfInterest(custodianName));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatCaseComponent);