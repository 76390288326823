import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ProfileComponent from './component';


const mapStateToProps = (state) => ({
  isGetting: selectors.getIsGetting(state),
  isPutting: selectors.getIsPutting(state),
  isPosting: selectors.getIsPosting(state),
  errMsg: selectors.getErrMsg(state),
  lastName: selectors.getLastName(state),
  firstName: selectors.getFirstName(state),
  address: selectors.getAddress(state),
  phoneNo: selectors.getPhoneNo(state),
  email: selectors.getEmail(state),
  mobile: selectors.getMobile(state),
  bank: selectors.getBank(state),
  bankAccount: selectors.getBankAccount(state),
  twoFactorAuthenticationId: selectors.getTwoFactorAuthenticationId(state),
  lawFirmLogo: selectors.getLawFirmLogo(state),
  lawFirmName: selectors.getLawFirmName(state),
  pinCode: selectors.getPinCode(state),
});

const mapDispatchToProps = dispatch => ({  
  onLoadProfile: (userId) => {
    dispatch(actions.doLoadProfile(userId));
  },
  onSubmitProfile: (userId, firstName, lastName, address, phoneNo, mobile, bank, bankAccount, twoFactorAuthenticationId) => {
    dispatch(actions.doSubmitProfile(userId, firstName, lastName, address, phoneNo, mobile, bank, bankAccount, twoFactorAuthenticationId));
  },
  onSubmitLogo: (userId, uploadFileList) => {
    dispatch(actions.doSubmitLogo(userId, uploadFileList));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent);