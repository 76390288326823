import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Input, DatePicker, Select } from 'antd';
import { APPOINTOR_TYPE_FORM, CASE_TYPE } from '../../config';
import moment from 'moment';

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});
const DEFAULT_APPOINTOR = {
  name: '',
  address: '',
  phone: '',
  email: '',
  relationship: '',
  ssid: '',
  birthday: null
}
const DEFAULT_COMPANY = {
  name: '',
  address: '',
  phone: '',
  email: '',
  ban: '',
  representative: '',
  contactPerson: ''
}

class ConsultationsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointorType: 1,
      typeId: 1,
      plaintiffList: [],
      defendantList: [],
      appointor: DEFAULT_APPOINTOR,
      company: DEFAULT_COMPANY,
      consultationContent: '',
    };
  }
  componentDidMount() {
    const pincode = this.props.history.location.pathname.match(/\w+\/(\w+)/)[1];
    this.props.onLoadConsultationsForm(pincode);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting === true && this.props.isPosting === false) {
      if (this.props.errMsg === null) {
        message.success('謝謝您的填寫，您的表單已經傳送。');
        this.setState({
          appointorType: 1,
          typeId: 1,
          plaintiffList: [],
          defendantList: [],
          appointor: DEFAULT_APPOINTOR,
          company: DEFAULT_COMPANY,
          consultationContent: '',
        })
      } else {
        message.error(this.props.errMsg);
      }
    }
  }
  onChangeAppointor(key, value) {
    let { appointor } = this.state;
    appointor = JSON.parse(JSON.stringify(appointor));
    appointor[key] = value;
    if (key === 'phone' && appointor['phone'].length > 20) { appointor['phone'] = appointor['phone'].slice(0, 20) };
    if (key === 'ssid' && appointor['ssid'].length > 10) { appointor['ssid'] = appointor['ssid'].slice(0, 10) };
    this.setState({ appointor })
  }
  onChangeCompany(key, value) {
    let { company } = this.state;
    company = JSON.parse(JSON.stringify(company));
    company[key] = value;
    this.setState({ company })
  }
  onClickSubmitConsultationsForm() {
    let { appointorType, typeId, plaintiffList, defendantList, appointor, company, consultationContent } = this.state;
    if( appointorType === 1 ) {
      appointor['ssid'] = appointor['ssid'] ? appointor['ssid'].trim() : '';
      appointor['address'] = appointor['address'] ? appointor['address'].trim() : '';
      appointor['birthday'] = appointor['birthday'] ? moment(appointor['birthday']).format('YYYY/MM/DD') : '';
      appointor['email'] = appointor['email'] ? appointor['email'].trim() : '';
      appointor['name'] = appointor['name'] ? appointor['name'].trim() : '';
      appointor['phone'] = appointor['phone'] ? appointor['phone'] : '';
      appointor['relationship'] = appointor['relationship'] ? appointor['relationship'] : '';
    };
    if ( appointorType === 2 ) {
      company['ban'] = company['ban'] ? company['ban'] : '';
      company['address'] = company['address'] ? company['address'].trim() : '';
      company['contactPerson'] = company['contactPerson'] ? company['contactPerson'].trim() : '';
      company['name'] = company['name'] ? company['name'].trim() : '';
      company['representative'] = company['representative'] ? company['representative'].trim() : '';
      company['email'] = company['email'] ? company['email'].trim() : '';
      company['phone'] = company['phone'] ? company['phone'] : '';
    };
    consultationContent = consultationContent ? consultationContent.trim() : '';
    let pincode = this.props.history.location.pathname.match(/\w+\/(\w+)/)[1];
    this.props.onSubmitConsultationsForm(pincode, appointorType, typeId, plaintiffList, defendantList, appointor, company, consultationContent);
  }

  render() {
    const { appointorType, typeId, plaintiffList, defendantList, appointor, company, consultationContent } = this.state;
    return (
      <div id="consultationsForm-component">
        <div className="lawyer-title-wrap">
          <div className="container lawyer-title">
            <img src={`${this.props.lawyer.logoUrl}`} alt={`${this.props.lawyer.lawFirmName}`}/>
            <div className="lawyer">
              <div>{this.props.lawyer.lawFirmName}</div>
              <div>{this.props.lawyer.name}</div>
            </div>
          </div>
        </div>
        <form className="container" onSubmit={e => { e.preventDefault(); this.onClickSubmitConsultationsForm() }}>
          <div className="qr-title">諮詢表單</div>
          <div className="col-item-wrap">
            <div className="col-item-group">
              <div className="col-item">
                <div className="col-title">諮詢身份：</div>
                <div className="col-edit">
                  {
                    Object.entries(APPOINTOR_TYPE_FORM).map(([key, value]) =>
                      <span key={`appointorType-${key}`}>
                        <input type="radio" id={`appointorType-${key}`}
                          value={key}
                          checked={appointorType === parseInt(key)}
                          onChange={e => this.setState({ appointorType: parseInt(e.target.value) })}
                        />
                        <label htmlFor={`appointorType-${key}`} className="edit-appointorType">{value}</label>
                      </span>
                    )
                  }
                </div>
              </div>
              {
                appointorType === 1
                &&
                <>
                  <div className="col-item">
                    <div className="col-title">諮詢人：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={appointor['name']}
                      onChange={e => this.onChangeAppointor('name', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">身分證字號：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={appointor['ssid']}
                      onChange={e => this.onChangeAppointor('ssid', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">生日：</div>
                    <DatePicker className="col-edit"
                      size="large"
                      placeholder="請選擇日期"
                      inputReadOnly={true}
                      showToday={false}
                      format={dateFormat}
                      value={appointor['birthday'] ? moment(appointor['birthday'], dateFormat) : null}
                      onChange={value => this.onChangeAppointor('birthday', value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">住址：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={appointor['address']}
                      onChange={e => this.onChangeAppointor('address', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">聯絡電話：</div>
                    <Input className="col-edit"
                      size="large"
                      type="number"
                      value={appointor['phone']}
                      onChange={e => this.onChangeAppointor('phone', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">Email：</div>
                    <Input className="col-edit"
                      size="large"
                      type="email"
                      value={appointor['email']}
                      onChange={e => this.onChangeAppointor('email', e.target.value)}
                    />
                  </div>
                </>
              }
              {
                appointorType === 2
                &&
                <>
                  <div className="col-item">
                    <div className="col-title">公司名稱：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={company['name']}
                      onChange={e => this.onChangeCompany('name', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">統一編號：</div>
                    <Input className="col-edit"
                      size="large"
                      type="number"
                      value={company['ban']}
                      onChange={e => this.onChangeCompany('ban', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">代表人：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={company['representative']}
                      onChange={e => this.onChangeCompany('representative', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">聯絡人：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={company['contactPerson']}
                      onChange={e => this.onChangeCompany('contactPerson', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">住址：</div>
                    <Input className="col-edit"
                      size="large"
                      type="text"
                      value={company['address']}
                      onChange={e => this.onChangeCompany('address', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">聯絡電話：</div>
                    <Input className="col-edit"
                      size="large"
                      type="number"
                      value={company['phone']}
                      onChange={e => this.onChangeCompany('phone', e.target.value)}
                    />
                  </div>
                  <div className="col-item">
                    <div className="col-title">Email：</div>
                    <Input className="col-edit"
                      size="large"
                      type="email"
                      value={company['email']}
                      onChange={e => this.onChangeCompany('email', e.target.value)}
                    />
                  </div>
                </>
              }
            </div>
          </div>
          <div className="col-item-wrap">
            <div className="col-item-group">
              <div className="col-item">
                <div className="col-title">案件種類：</div>
                <Select className="col-edit"
                  size="large"
                  showSearch
                  value={typeId}
                  onChange={value => this.setState({ typeId: value })}
                >
                  {
                    CASE_TYPE.map(caseType =>
                      <Option key={`casetypeedit-${caseType.id}`} value={caseType.id}>{caseType.name}</Option>
                    )
                  }
                </Select>
              </div>
              {
                typeId !== 6
                &&
                <>
                  <div className="col-item">
                    <div className="col-title">
                      {
                        CASE_TYPE.map(type => {
                          if (type['id'] === typeId) {
                            return type['plaintiff']
                          }
                        })
                      }：
                    </div>
                    <Select className="col-edit"
                      size="large"
                      mode="tags"
                      dropdownStyle={{ display: 'none' }}
                      defaultValue={plaintiffList}
                      onChange={value => this.setState({ plaintiffList: value })}
                      tokenSeparators={[',']}>
                    </Select>
                  </div>
                  <div className="col-item">
                    <div className="col-title">
                      {
                        CASE_TYPE.map(type => {
                          if (type['id'] === typeId) {
                            return type['defendant']
                          }
                        })
                      }：
                    </div>
                    <Select className="col-edit"
                      size="large"
                      mode="tags" dropdownStyle={{ display: 'none' }}
                      defaultValue={defendantList}
                      onChange={value => this.setState({ defendantList: value })}
                      tokenSeparators={[',']}>
                    </Select>
                  </div>
                </>
              }
              <div className="col-item">
                <div className="col-title">諮詢內容：</div>
                <Input.TextArea className="col-edit"
                  rows={5}
                  size="large"
                  type="text"
                  value={consultationContent}
                  placeholder="請簡單說明諮詢需求"
                  onChange={e => { this.setState({ consultationContent: e.target.value }) }}
                />
              </div>
            </div>
          </div>
          <div className="submit"><button>提交表單</button></div>
        </form>
      </div>
    )
  }
}

export default withRouter(ConsultationsFormComponent);