import React, { Component } from 'react';
import powerOfAttorney from '../modules/powerOfAttorney';

class PowerOfAttorneyPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
  	return (
	    <div id="page-powerOfAttorney">
        <powerOfAttorney.container caseId={this.props.match.params.caseId} />
	    </div>
	  )
  }
}

export default PowerOfAttorneyPageComponent;
